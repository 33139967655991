import * as yup from 'yup';

export const UpdateEmailModal = (oldEmail: string) => ({
  email: '',
  oldEmail: oldEmail,
});

export const UpdateEmailSchema = (localize: any): any =>
  yup.object({
    email: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        localize('input.error.invalid')
      ),
  });
