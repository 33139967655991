import React from 'react';
import './Stepper.less';
import { Stepper as CustomStepper, Step } from 'react-form-stepper';

type Props = {
  activeStep?: number;
  numberOfSteps?: number;
};

const Stepper = ({ activeStep, numberOfSteps }: Props) => {
  return (
    <div key="custom-stepper-container" className="navigation-stepper">
      <CustomStepper
        key="custom-stepper"
        className="stepper"
        activeStep={activeStep}
        // @ts-ignore
        styleConfig={{
          size: 16,
          activeBgColor: 'white',
          inactiveBgColor: 'white',
          completedBgColor: 'white',
        }}
        // @ts-ignore
        connectorStyleConfig={{
          activeColor: 'black',
          disabledColor: 'white',
          completedColor: 'black',
        }}
      >
        {[...Array(numberOfSteps)].map((step, index) => (
          <Step label="" key={`step-${index}`}>
            {activeStep === index ? (
              ' '
            ) : // @ts-ignore
            activeStep > index ? (
              <div key="stepper-tick" className="stepper-tick">
                ✓
              </div>
            ) : (
              <div key="stepper-dot" className="stepper-dot" />
            )}
          </Step>
        ))}
      </CustomStepper>
    </div>
  );
};

Stepper.defaultProps = {
  activeStep: null,
  numberOfSteps: 4,
};

export default React.memo(Stepper);
