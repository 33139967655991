import * as React from 'react';
import {
  isFreeInsurance,
  getBrandColor,
  getLightBrandColor,
} from 'getaway-data-layer';
import { shieldActive } from '../../../assets/brandImages';
import './insuranceItem.less';

type Props = {
  title: string;
  description: string;
  onPress?: () => void;
  checked?: boolean;
  value?: string;
  dailyRate: number;
};

const InsuranceItem = ({
  title,
  description,
  onPress,
  checked,
  value,
  dailyRate,
}: Props) => (
  <div
    className="insurance-container"
    style={checked ? { boxShadow: 'none' } : {}}
  >
    <div
      className="insurance-itemsContainer"
      style={
        checked
          ? isFreeInsurance(dailyRate)
            ? {
                backgroundColor: 'rgba(227, 7, 19, 0.1)',
                borderWidth: 3,
                borderColor: 'red',
              }
            : {
                backgroundColor: getLightBrandColor(),
                borderWidth: 3,
                borderColor: getBrandColor(),
              }
          : {}
      }
      onClick={onPress}
    >
      <div className="insurance-title">{title}</div>
      <div className="insurance-description">{description}</div>
      {value && <div className="insurance-value">{value}</div>}
      <>
        <img
          className="insurance-shield"
          alt="shield"
          src={
            isFreeInsurance(dailyRate)
              ? '/images/shieldNoInsurance.png'
              : shieldActive
          }
        />
      </>
    </div>
  </div>
);

InsuranceItem.defaultProps = {
  onPress: () => {},
  checked: false,
  value: null,
};

export default InsuranceItem;
