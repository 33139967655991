import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { State } from '../@types';
import withDevice from './withDevice';
import withTextLocalizer from './withTextLocalizer';
import withUser from './withUser';
import withNotifications from './withNotifications';
import { handleRentalViolations } from '../helpers/rentalsViolationsHelpers';

export type Props = {
  user: any;
  bookingViolations: Record<string, any>;
  rentalViolations: Record<string, any>;
  handleRentalViolations: (
    arg0: Record<string, any>,
    arg1: Record<string, any>,
    arg3: any,
    arg4: any
  ) => void;
  features: any;
};

const withBookingViolations: any = compose(
  connect(
    ({
      appData: { features },
      userData: {
        bookings: { bookingViolations },
        rental: { rentalViolations },
      },
    }: State) => ({
      bookingViolations,
      rentalViolations,
      features,
    })
  ),
  withUser,
  withTextLocalizer,
  withNotifications,
  withDevice,
  withHandlers({
    handleRentalViolations:
      ({
        user,
        localize,
        setNotification,
        devicePaired,
        sendVerificationCode,
        isPaymentVerified,
        submitIdentityShopper,
        submitChallenge,
        setRecoverPaymentAuth,
      }: any) =>
      (
        bookingViolations: Record<string, any>,
        navigation: any,
        features: Array<string>,
        helpAction: any
      ) =>
        handleRentalViolations(
          bookingViolations,
          user,
          localize,
          setNotification,
          devicePaired,
          navigation,
          features,
          sendVerificationCode,
          helpAction,
          isPaymentVerified,
          submitIdentityShopper,
          submitChallenge,
          setRecoverPaymentAuth
        ),
  })
);
export default withBookingViolations;
