export const handleRedirect = () => {
  window.open('/onboarding/redirectAction');
};

export const handleChallenge = () => {
  window.open('/onboarding/challengeAction');
};

export const handleIdentifyShopper = () => {
  window.open('/onboarding/identifyShopperAction');
};

export const handle3dsData = (paymentData: any) => {
  const { threeDS1, identifyShopperData, challengeShopperData } = paymentData;

  if (threeDS1) {
    handleRedirect();
  } else if (identifyShopperData) {
    handleIdentifyShopper();
  } else if (challengeShopperData) {
    handleChallenge();
  }
};

export const parsePaymentMethods = (paymentMethods: any) => ({
  paymentMethods: paymentMethods.paymentMethods
    .map((payment: any) =>
      payment?.type === 'paypal'
        ? {
            ...payment,
            configuration: {
              merchantId: payment.configuration.entry.find(
                (entry: any) => entry.key === 'merchantId'
              ).value,
              intent: payment.configuration.entry.find(
                (entry: any) => entry.key === 'intent'
              ).value,
            },
          }
        : null
    )
    .filter((n: any) => n),
});

export const getAdyenCheckoutConfigs = (
  locale,
  country,
  clientKey,
  paymentMethods,
  onSubmitDropinPayment,
  onSubmitDropinPaymentDetails
) => ({
  paymentMethodsResponse: paymentMethods,
  clientKey: clientKey,
  locale: locale,
  environment: (process.env.REACT_APP_STAGE === 'production'
    ? 'live'
    : 'test') as
    | 'live'
    | 'test'
    | 'live-us'
    | 'live-au'
    | 'live-apse'
    | 'live-in',
  amount: {
    currency: 'EUR',
    value: 0,
  },
  countryCode: country, // Mandatory in v6
  analytics: {
    enabled: false,
  },
  onSubmit: (state, component, actions) => {
    onSubmitDropinPayment(state, component, actions);
  },
  onAdditionalDetails: (state, component, actions) => {
    onSubmitDropinPaymentDetails(state, component, actions);
  },
  paymentMethodsConfiguration: {
    card: {
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: true,
      hideCVC: false,
      name: 'Credit or debit card',
    },
  },
});
