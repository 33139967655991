import React, { useEffect, useRef } from 'react';

type Props = {
  acsURL: string;
  encodedcReq: string;
};

const ChallengeView = ({ acsURL, encodedcReq }: Props) => {
  const formRef = useRef<any>(null);

  useEffect(() => {
    formRef?.current?.submit();
  }, []);

  return (
    <form
      ref={formRef}
      method="POST"
      action={acsURL}
      id="3dschallenge"
      target="challenge-iframe"
    >
      <input type="hidden" name="creq" value={encodedcReq} />
    </form>
  );
};

export default ChallengeView;
