import React from 'react';
import { useDispatch } from 'react-redux';
import {
  forgetPassword,
  LocalizePropType,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import ForgotPasswordView from './ForgotPasswordView';
import { ForgotPasswordModal, ForgotPasswordSchema } from '../../../validation';
import { GA_TITLES, navigateTo, reportGAEvent } from '../../../utils';

interface Values {
  email: string;
}

type Props = {
  localize: LocalizePropType;
};

const ForgotPasswordContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = ({ email }: Values) => {
    dispatch(forgetPassword(email, localize, onClose));
    reportGAEvent(GA_TITLES.AUTH.FORGOT_SUBMITTED);
  };

  const forgotPasswordFormik = useFormik({
    initialValues: ForgotPasswordModal,
    validationSchema: ForgotPasswordSchema(localize),
    onSubmit,
  });

  const onClose = () => {
    navigateTo(history, '/login');
  };

  return (
    <ForgotPasswordView
      localize={localize}
      formik={forgotPasswordFormik}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(ForgotPasswordContainer);
