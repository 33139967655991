import { Dispatch, FleetUserDataOutput, CLIENT_TYPE } from '../../../../@types';
import { APP } from '../../../../enums';
import { setAdmin } from './actions';
import {
  setNetworkActivity,
  setNetworkError,
  setNetworkSuccess,
} from '../../networkStatus';
import { setNotification } from '../../ui/notifications';
import { connectClient } from '../../../clients/connectClient';
import { LocalizePropType } from '../../../../enhancers/withTextLocalizer';
import { setAuth } from './actions';
import { authClient } from '../../../clients/authClient';
import { injectedPersistor } from '../../../ConfigureStore';

export const clearAdminAuth = () => {
  injectedPersistor.purge();
};

export const getAdmin =
  (successCallback: (data: FleetUserDataOutput) => void = () => {}) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.GET_ADMIN));
    const { data, error } = await connectClient.get();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.GET_ADMIN, error));

      if ([401, 403].includes(error.detail.status)) {
        clearAdminAuth();
      } else if (error.detail.status === 404) {
        dispatch(
          setNotification({
            message: 'backend.error',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      }
    } else {
      if (data) {
        dispatch(setAdmin(data));
        if (typeof successCallback === 'function') {
          successCallback(data);
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.GET_ADMIN));
    }
  };

export const loginAdmin =
  (
    email: string,
    password: string,
    localize: LocalizePropType | undefined,
    callbackFunction?: () => any
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.LOGIN));
    dispatch(setAuth(email, password));
    const { data, error } = await connectClient.get();

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.LOGIN, error));

      if (error.detail.status === 401) {
        dispatch(
          setNotification({
            message: 'login.failed.alert.body',
            type: APP.NOTIFICATION_TYPE.ERROR,
          })
        );
      } else if (error.detail.status === 403) {
        clearAdminAuth();
      }
    } else {
      if (data) {
        dispatch(setAdmin(data));
        if (typeof callbackFunction === 'function') {
          callbackFunction();
        }
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.LOGIN));
    }
  };

export const forgetAdminPassword =
  (email: string, localize: LocalizePropType, successCallBack?: () => void) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.CONNECT_CLIENT.FORGET_PASSWORD));
    const { error } = await authClient.forgetPassword(email);

    if (error && error.detail.status !== 404) {
      dispatch(
        setNetworkError(CLIENT_TYPE.CONNECT_CLIENT.FORGET_PASSWORD, error)
      );
      dispatch(
        setNotification({
          message: 'update.password.forgot.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else {
      dispatch(
        setNotification({
          message: localize('update.password.text', email),
          type: APP.NOTIFICATION_TYPE.INFO,
          localize: false,
        })
      );
      if (typeof successCallBack === 'function') {
        successCallBack();
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.CONNECT_CLIENT.FORGET_PASSWORD));
    }
  };
