import { ChallengeSolutionInput, DeviceFingerprintInput } from '../@types';

export const CardPaymentManager = {
  nativeManager: null as any,
  identifyShopper(
    publicKey: string,
    directoryServerId: string,
    directoryServerRootCertificate: string,
    messageVersion: string,
    successCallback: (arg0: DeviceFingerprintInput) => void
  ) {
    this.nativeManager?.identifyShopper(
      publicKey,
      directoryServerId,
      directoryServerRootCertificate,
      messageVersion,
      successCallback
    );
  },

  performChallenge(
    threeDSServerTransID: string,
    acsTransID: string,
    acsReferenceNumber: string,
    acsSignedContent: string,
    successCallback: (arg0: ChallengeSolutionInput) => void
  ) {
    this.nativeManager?.performChallenge(
      threeDSServerTransID,
      acsTransID,
      acsReferenceNumber,
      acsSignedContent,
      successCallback
    );
  },
};
