import React from 'react';
import './EmbeddedCardView.less';
import { LocalizePropType, withTextLocalizer } from 'getaway-data-layer';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import { format } from 'date-fns';
import { GA_TITLES, isMobileBrowser } from '../../../../utils';
import BrandButton from '../../../components/BrandButton/BrandButton';
import { useQuery } from '../../../../hooks/useQuery';

type Props = {
  localize: LocalizePropType;
  selectedStartDate: Date;
  onChangeStartDate: () => void;
  selectedEndDate: Date;
  onChangeEndDate: () => void;
  minStart: string;
  minEnd: string;
  onReset: () => void;
  onPressFilter: () => void;
  customError: string;
  isSafari: boolean;
  nextBookingStart: Date | null;
  onPressVehicleTable: () => void;
};

const EmbeddedCardView = ({
  localize,
  selectedStartDate,
  onChangeStartDate,
  selectedEndDate,
  onChangeEndDate,
  minStart,
  minEnd,
  onReset,
  onPressFilter,
  customError,
  isSafari,
}: Props) => {
  const query = useQuery();
  return (
    <div className="embedded-card">
      <div className="embedded-card-title">
        {localize('map.embedded.card.title')}
      </div>
      <div className="embedded-card-content">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="embedded-card-inputs">
            <div>
              <MobileDateTimePicker
                inputFormat="dd. MMM, HH:mm"
                minDateTime={minStart}
                minutesStep={15}
                label={localize('map.embedded.from')}
                value={selectedStartDate}
                onChange={onChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    className="embedded-card-input"
                    sx={{ minWidth: 160, backgroundColor: 'white' }}
                    {...params}
                  />
                )}
              />
              {!isMobileBrowser() && (
                <div className="embedded-card-reset" onClick={onReset}>
                  {localize('map.filter.reset')}
                </div>
              )}
            </div>
            <MobileDateTimePicker
              inputFormat="dd. MMM, HH:mm"
              minDateTime={minEnd}
              minutesStep={15}
              label={localize('map.embedded.to')}
              value={selectedEndDate}
              onChange={onChangeEndDate}
              renderInput={(params) => (
                <TextField
                  className="embedded-card-input"
                  sx={{ minWidth: 160, backgroundColor: 'white' }}
                  {...params}
                />
              )}
            />
          </div>
        </LocalizationProvider>

        <div className="embedded-card-button">
          {isMobileBrowser() && (
            <div className="embedded-card-reset" onClick={onReset}>
              {localize('map.filter.reset')}
            </div>
          )}
          <BrandButton
            id={GA_TITLES.BOOKING.SET_FILTER}
            containerStyles={{
              width: isMobileBrowser() ? '200px' : '220px',
              position: 'unset',
              left: 'unset',
              bottom: 'unset',
              fontSize: '15px',
              borderRadius: '12px',
              boxShadow: '0px 0px 21px -5px rgba(0,0,0,0.3)',
              ...(query.get('buttonBg') && {
                backgroundColor: `#${query.get('buttonBg')}`,
              }),
            }}
            title={localize('map.embedded.card.button')}
            disabled={!!customError && isSafari}
            onClick={onPressFilter}
            enableKeyboardEffect={false}
          />
          {customError && (
            <div className="embedded-card-error">{customError}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTextLocalizer(EmbeddedCardView);
