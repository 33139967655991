import React from 'react';
import './LoadingView.less';

const LoadingView = () => {
  return (
    <img
      className="loading-splash"
      src="/images/splash.png"
      alt="oading-splash"
    />
  );
};

export default LoadingView;
