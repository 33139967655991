import React, { useEffect } from 'react';
import {
  withTextLocalizer,
  selectCarDamages,
  selectRentalData,
  getCarDamages,
  LocalizePropType,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BrowseDamagesView from './BrowseDamagesView';
import { navigateTo } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const BrowseDamagesContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentalData = useSelector(selectRentalData) as any;
  const carDamages = useSelector(selectCarDamages) as any;

  const onClose = () => {
    history.goBack();
  };

  const onAddDamage = () => {
    navigateTo(history, '/rental/addDamage');
  };

  useEffect(() => {
    if (carDamages?.length === 0 && getCarDamages) {
      dispatch(getCarDamages(rentalData?.carData?.id));
    }
  }, []);

  return (
    <BrowseDamagesView
      localize={localize}
      damages={carDamages}
      newDamages={[]}
      enableAddDamage
      onAddDamage={onAddDamage}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(BrowseDamagesContainer);
