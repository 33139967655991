import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import withTextLocalizer from './withTextLocalizer';

import { State } from '../@types';
import { getIsRentingUser, helpAction } from '../helpers/csHelpers';
import { isRentalStarted } from '../helpers/rentalHelpers';

export type Props = {
  isAuthorizedUser: boolean;
  isVerifiedUser: boolean;
  rentalStarted: boolean;
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never';
  supportContacted: boolean;
  helpAction: (arg?: any) => void;
  hideCS: boolean;
};

const withCustomerSupport: any = compose(
  withTextLocalizer,
  connect(
    ({
      config: { xBrandId },
      appData: {
        common: { firstSteps, supportContacted, brandSettings },
      },
      userData: {
        user: { basicAuth, user },
        rental: { rentalData, lastFinishedRentalDate },
        bookings: { bookings },
      },
    }: State) => ({
      isRentingUser: getIsRentingUser(
        xBrandId,
        rentalData!,
        bookings,
        lastFinishedRentalDate!
      ),
      intercomHandlingMode: brandSettings?.intercomHandlingMode,
      supportContacted,
      isAuthorizedUser: user && !!basicAuth,
      isVerifiedUser: firstSteps,
      rentalStarted: isRentalStarted(rentalData),
      brandSettings,
      hideCS:
        brandSettings?.phoneSupportType === 'duringTrip' &&
        !(user && !!basicAuth) &&
        brandSettings?.textSupportType === 'intercom' &&
        brandSettings?.intercomHandlingMode === 'onLogin',
    })
  ),
  withHandlers({
    helpAction:
      ({
        isRentingUser,
        localize,
        intercomHandlingMode,
        supportContacted,
        isAuthorizedUser,
        isVerifiedUser,
        rentalStarted,
        brandSettings,
      }: any) =>
      (onlyTextMode: any) =>
        helpAction(
          isRentingUser,
          localize,
          intercomHandlingMode,
          supportContacted,
          isAuthorizedUser,
          isVerifiedUser,
          rentalStarted,
          brandSettings,
          onlyTextMode
        ),
  } as any),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ dispatch, ...props }: any) => ({ ...props }))
);
export default withCustomerSupport;
