import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setInitialAreas } from '../redux/store/appData/areas';
import { getBrandSettings } from '../redux/store/appData/common';
import { UserDataOutput, State } from '../@types';
import { setMapContent, setShowHomeWT } from '../redux/store/ui/map';
import { setIsIntercomUserRegistered } from '../redux/store/appData/common/actions';

export type Props = {
  user: UserDataOutput | null | undefined;
  basicAuth: string | null | undefined;
  supportContacted: boolean;
  setInitialAreas: typeof setInitialAreas;
  setMapContent: typeof setMapContent;
  getBrandSettings: typeof getBrandSettings;
  isIntercomUserRegistered: boolean;
  setIsIntercomUserRegistered: typeof setIsIntercomUserRegistered;
  xBrandId: string;
  setShowHomeWT: typeof setShowHomeWT;
};

const withAuthContainer: any = compose(
  connect(
    ({
      userData: {
        user: { user, basicAuth },
      },
      appData: {
        common: { supportContacted, isIntercomUserRegistered },
      },
      ui: {
        map: { content },
      },
      config: { xBrandId },
    }: State) => ({
      user,
      basicAuth,
      supportContacted,
      isIntercomUserRegistered,
      mapContent: content,
      xBrandId,
    }),
    {
      setInitialAreas,
      setMapContent,
      getBrandSettings,
      setIsIntercomUserRegistered,
      setShowHomeWT,
    }
  )
);
export default withAuthContainer;
