import { Dispatch } from '../../../../@types';
import { setNotification } from '../../ui/notifications';
import { APP } from '../../../../enums';
import TYPES from '../../../../@types/redux/store/RentalTypes';

export const setFinishRentalViolations =
  (rentalViolations: Record<string, any>) => (dispatch: Dispatch) => {
    Object.keys(rentalViolations).forEach((key) => {
      if (key === 'online') {
        dispatch(
          setNotification({
            message: rentalViolations.online,
            type: APP.NOTIFICATION_TYPE.ERROR,
            localize: false,
          })
        );
      }
    });
    dispatch({
      type: TYPES.SET_FINISH_RENTAL_VIOLATIONS,
      payload: {
        rentalViolations,
      },
    });
  };

export const clearFinishRentalViolations = () => async (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_FINISH_RENTAL_VIOLATIONS,
  });
};

const clearRentalViolations = () => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_RENTAL_VIOLATIONS,
  });
};

export const setRentalViolations =
  (rentalViolations: Record<string, any>) => (dispatch: Dispatch) => {
    const updatedRentalViolations = { ...rentalViolations };

    if (updatedRentalViolations['api_error.block_payment_parameter_missing']) {
      delete updatedRentalViolations[
        'api_error.block_payment_parameter_missing'
      ];
    }

    if (
      updatedRentalViolations[
        'api_error.block_payment_parameter_missing.paypal'
      ]
    ) {
      delete updatedRentalViolations[
        'api_error.block_payment_parameter_missing.paypal'
      ];
    }

    dispatch({
      type: TYPES.SET_RENTAL_VIOLATIONS,
      payload: {
        rentalViolations: updatedRentalViolations,
      },
    });
    setTimeout(() => dispatch(clearRentalViolations()), 1000);
  };
