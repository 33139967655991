import {
  RentalData,
  CarStatusData,
  FuelInfo,
  OutputDamage,
  RidePoolingRentalData,
} from '../../Types.api';
import { MymUiTrip } from '../../Types';
export type RentalState = {
  readonly rentalData: (RentalData & RidePoolingRentalData) | null | undefined;
  readonly driverCarStatus: CarStatusData | null | undefined;
  readonly rentalViolations: any;
  readonly finishRentalViolations: Record<string, any>;
  readonly fuelInfo: FuelInfo | null | undefined;
  readonly canLock: boolean;
  readonly isServiceReportSubmitted: boolean;
  readonly isBookingStartedEventSent: boolean;
  readonly isCarChecked: boolean;
  readonly lastFinishedRentalDate: Date | null;
  readonly carDamages: Array<OutputDamage> | null;
  readonly forceRentalDriveMode: boolean;
  readonly mymUiTrip: MymUiTrip;
};
export type ReservationInputData = {
  carId: string;
  backBy: string;
  type: 'usual' | 'serviceTrip';
  createBlockPayment: boolean;
};
enum RentalTypes {
  SET_RENTAL_DATA = 'userData/rental/SET_RENTAL_DATA',
  SET_DRIVER_CAR_STATUS = 'userData/rental/SET_DRIVER_CAR_STATUS',
  SET_RENTAL_VIOLATIONS = 'userData/rental/SET_RENTAL_VIOLATIONS',
  SET_FINISH_RENTAL_VIOLATIONS = 'userData/rental/SET_FINISH_RENTAL_VIOLATIONS',
  SET_FUEL_INFO = 'userData/rental/SET_FUEL_INFO',
  SET_CAN_LOCK = 'userData/rental/SET_CAN_LOCK',
  CLEAR_FINISH_RENTAL_VIOLATIONS = 'userData/rental/CLEAR_FINISH_RENTAL_VIOLATIONS',
  CLEAR_RENTAL_VIOLATIONS = 'userData/rental/CLEAR_RENTAL_VIOLATIONS',
  CLEAR_RENTAL_DATA = 'userData/rental/CLEAR_RENTAL_DATA',
  SET_IS_SERVICE_REPORT_SUBMITTED = 'userData/rental/SET_IS_SERVICE_REPORT_SUBMITTED',
  SET_IS_BOOKING_STARTED_EVENT = 'userData/rental/SET_IS_BOOKING_STARTED_EVENT',
  SET_IS_CAR_CHECKED = 'userData/rental/SET_IS_CAR_CHECKED',
  SET_LAST_FINISHED_RENTAL_DATE = 'userData/rental/SET_LAST_FINISHED_RENTAL_DATE',
  SET_CAR_DAMAGES = 'userData/rental/SET_CAR_DAMAGES',
  CLEAR_CAR_DAMAGES = 'userData/rental/CLEAR_CAR_DAMAGES',
  FORCE_RENTAL_DRIVE_MODE = 'userData/FORCE_RENTAL_DRIVE_MODE',
  CLEAR_FORCE_RENTAL_DRIVE_MODE = 'userData/CLEAR_FORCE_RENTAL_DRIVE_MODE',
  SET_MYM_TRIP = 'userData/SET_MYM_TRIP',
  RESET_STORE = 'RESET_STORE',
}
export default RentalTypes;
export type SetRentalData = {
  type: RentalTypes.SET_RENTAL_DATA;
  payload: {
    rentalData: RentalData;
  };
};
export type SetRentalViolations = {
  type: RentalTypes.SET_RENTAL_VIOLATIONS;
  payload: {
    rentalViolations: Record<string, any>;
  };
};
export type SetFinishRentalViolations = {
  type: RentalTypes.SET_FINISH_RENTAL_VIOLATIONS;
  payload: {
    rentalViolations: Record<string, any>;
  };
};
export type SetFuelInfo = {
  type: RentalTypes.SET_FUEL_INFO;
  payload: {
    fuelInfo: FuelInfo;
  };
};
export type SetMymTrip = {
  type: RentalTypes.SET_MYM_TRIP;
  payload: {
    trip: MymUiTrip;
  };
};
export type SetLastFinishedRentalDate = {
  type: RentalTypes.SET_LAST_FINISHED_RENTAL_DATE;
  payload: {
    lastFinishedRentalDate: Date;
  };
};
export type SetCarDamages = {
  type: RentalTypes.SET_CAR_DAMAGES;
  payload: {
    carDamages: Array<OutputDamage> | null;
  };
};
export type ClearCarDamages = {
  type: RentalTypes.CLEAR_CAR_DAMAGES;
};
export type SetCanLock = {
  type: RentalTypes.SET_CAN_LOCK;
  payload: {
    canLock: boolean;
  };
};
export type SetIsServiceReportSubmitted = {
  type: RentalTypes.SET_IS_SERVICE_REPORT_SUBMITTED;
  payload: {
    isServiceReportSubmitted: boolean;
  };
};
export type SetIsCarChecked = {
  type: RentalTypes.SET_IS_CAR_CHECKED;
  payload: {
    isCarChecked: boolean;
  };
};
export type SetIsBookingStartedEventSent = {
  type: RentalTypes.SET_IS_BOOKING_STARTED_EVENT;
  payload: {
    isBookingStartedEventSent: boolean;
  };
};
export type ClearFinishRentalViolations = {
  type: RentalTypes.CLEAR_FINISH_RENTAL_VIOLATIONS;
};
export type ClearRentalViolations = {
  type: RentalTypes.CLEAR_RENTAL_VIOLATIONS;
};
export type ClearRentalData = {
  type: RentalTypes.CLEAR_RENTAL_DATA;
};
export type SetDriverCarStatus = {
  type: RentalTypes.SET_DRIVER_CAR_STATUS;
  payload: {
    driverCarStatus: CarStatusData;
  };
};

export type ForceRentalDriveMode = {
  type: RentalTypes.FORCE_RENTAL_DRIVE_MODE;
};

export type ClearForceRentalDriveMode = {
  type: RentalTypes.CLEAR_FORCE_RENTAL_DRIVE_MODE;
};

export type RentalAction =
  | SetRentalData
  | SetDriverCarStatus
  | SetRentalViolations
  | SetFinishRentalViolations
  | SetFuelInfo
  | SetCanLock
  | SetIsServiceReportSubmitted
  | ClearFinishRentalViolations
  | ClearRentalData
  | ClearRentalViolations
  | SetIsBookingStartedEventSent
  | SetIsCarChecked
  | SetLastFinishedRentalDate
  | SetCarDamages
  | ClearCarDamages
  | ClearForceRentalDriveMode
  | ForceRentalDriveMode
  | SetMymTrip;
