import {
  APIResponse,
  CarEquipmentsData,
  DriverCarData,
  FoundCars,
  Location,
  PricingTableData,
} from '../../@types';

export const carsClient = {
  get: async (
    { latitude, longitude }: Location,
    radius: number,
    backBy: any,
    from: string | null | undefined = null,
    circleId: string | null | undefined = null
  ): Promise<APIResponse<FoundCars>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/cars',
      params: {
        mapCenterLocation: `${latitude},${longitude}`,
        radius,
        backBy,
        from,
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getCompact: async (
    { latitude, longitude }: Location,
    radius: number,
    backBy: any,
    from: string | null | undefined = null,
    circleId: string | null | undefined = null
  ): Promise<APIResponse<FoundCars>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/cars/compact',
      params: {
        mapCenterLocation: `${latitude},${longitude}`,
        radius,
        backBy,
        from,
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getById: async (
    id: string,
    circleId: string
  ): Promise<APIResponse<DriverCarData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/cars/find/${id}`,
      params: {
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getCarEquipmentsData: async (): Promise<APIResponse<CarEquipmentsData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/cars/equipments',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getCarPriceDetails: async (
    pricingId: string,
    carId: string,
    circleId?: string | null
  ): Promise<APIResponse<PricingTableData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/pricing/table/${pricingId}`,
      params: {
        carId,
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getCategories: async (circleId: string): Promise<APIResponse<FoundCars>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/cars/categories',
      params: {
        circleId,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
