import TYPES from '../../../../@types/redux/store/FirstStepsTypes';
import {
  ClearAddress,
  ClearPhoneNumber,
  ClearPhoneCode,
  SelectedAddress,
  SetAddress,
  SetPhoneNumber,
  SetPhoneCode,
  SetReferralCode,
  ClearReferralCode,
  SetCircleCode,
  ClearCircleCode,
  SetPhoneCodes,
  SetBDay,
  SetIban,
  SetBIC,
  SetIsVerifyingGDPREmail,
  SetIsEditingPhone,
  SetShowExternalBrowserScreen,
} from '../../../../@types';
import { PhoneCode } from '../../../../@types';

export const setIban = (iban: string): SetIban => ({
  type: TYPES.SET_IBAN,
  payload: {
    iban,
  },
});

export const setBIC = (bic: string): SetBIC => ({
  type: TYPES.SET_BIC,
  payload: {
    bic,
  },
});

export const setShowExternalBrowserScreen = (
  showExternalBrowserScreen: boolean
): SetShowExternalBrowserScreen => ({
  type: TYPES.SET_SHOW_EXTERNAL_BROWSER,
  payload: {
    showExternalBrowserScreen,
  },
});

export const setIsVerifyingGDPREmail = (
  isVerifyingGDPREmail: boolean
): SetIsVerifyingGDPREmail => ({
  type: TYPES.SET_IS_VERIFYING_GDPR_EMAIL,
  payload: {
    isVerifyingGDPREmail,
  },
});

export const setIsEditingPhone = (
  isEditingPhone: boolean
): SetIsEditingPhone => ({
  type: TYPES.SET_IS_EDITING_PHONE,
  payload: {
    isEditingPhone,
  },
});

export const setBDay = (dateOfBirth: string): SetBDay => ({
  type: TYPES.SET_BDAY,
  payload: {
    dateOfBirth,
  },
});

export const setAddress = (address: SelectedAddress): SetAddress => ({
  type: TYPES.SET_ADDRESS,
  payload: {
    address,
  },
});

export const clearAddress = (): ClearAddress => ({
  type: TYPES.CLEAR_ADDRESS,
});

export const setPhoneNumber = (phoneNumber: string): SetPhoneNumber => ({
  type: TYPES.SET_PHONE_NUMBER,
  payload: {
    phoneNumber,
  },
});

export const clearPhoneNumber = (): ClearPhoneNumber => ({
  type: TYPES.CLEAR_PHONE_NUMBER,
});

export const setPhoneCode = (phoneCode: PhoneCode): SetPhoneCode => ({
  type: TYPES.SET_PHONE_CODE,
  payload: {
    phoneCode,
  },
});

export const setPhoneCodes = (phoneCodes: Array<PhoneCode>): SetPhoneCodes => ({
  type: TYPES.SET_PHONE_CODES,
  payload: {
    phoneCodes,
  },
});

export const clearPhoneCode = (): ClearPhoneCode => ({
  type: TYPES.CLEAR_PHONE_CODE,
});

export const setReferralCode = (referralCode: string): SetReferralCode => ({
  type: TYPES.SET_REFERRAL_CODE,
  payload: {
    referralCode,
  },
});

export const clearReferralCode = (): ClearReferralCode => ({
  type: TYPES.CLEAR_REFERRAL_CODE,
});

export const setCircleCode = (circleCode: string): SetCircleCode => ({
  type: TYPES.SET_CIRCLE_CODE,
  payload: {
    circleCode,
  },
});

export const clearCircleCode = (): ClearCircleCode => ({
  type: TYPES.ClEAR_CIRCLE_CODE,
});
