import { isIOS } from './isIOS';
import { isBefore24hrs } from './dateHelpers';
import { PaymentMethodDataOutput, UserDataOutput } from '../@types';
import { UIManager } from '../native/UIManager';
import { LocalizePropType } from '../enhancers';
import { injectedActionSheet } from '../redux/ConfigureGlobals';

const isMobileBrowser = (): boolean => {
  if (window) {
    return window?.matchMedia('only screen and (max-width: 760px)')?.matches;
  } else return false;
};

export const showBlockPaymentDialog = async (
  localize: LocalizePropType,
  yesAction: () => void,
  violation: string
) => {
  const { showAlert } = await import('./showAlert');

  if (violation.includes('paypal')) {
    showAlert(
      localize('car.reserve.blockPayment.paypal.title'),
      localize('car.reserve.blockPayment.paypal.description'),
      localize('car.reserve.blockPayment.paypal.yes'),
      () => yesAction(),
      localize('cancel'),
      () => {}
    );
  } else {
    showAlert(
      localize('car.reserve.blockPayment.title'),
      localize('car.reserve.blockPayment.description'),
      localize('car.reserve.blockPayment.yes.text'),
      () => yesAction(),
      localize('cancel'),
      () => {}
    );
  }
};

const showAvailabilityDialog = async (
  localize: LocalizePropType,
  timeToReturn: any,
  yesAction: () => void
) => {
  const showAlert = await import('./showAlert');

  showAlert.showAlert(
    localize('car.reserve.carBooked.title', timeToReturn),
    localize('car.reserve.carBooked.description'),
    localize('car.reserve.carBooked.yes.text'),
    () => yesAction(),
    localize('cancel')
  );
};

export const showRentalTypeDialog = (
  localize: LocalizePropType,
  onServiceSelected: () => void,
  onRentalSelected: () => void
) => {
  if (isIOS()) {
    injectedActionSheet.showActionSheetWithOptions(
      {
        title: localize('drivercar.rental.dialog.title'),
        message: localize('drivercar.rental.dialog.message'),
        options: [
          'cancel',
          'drivercar.rental.dialog.reserve.rental',
          'drivercar.rental.dialog.reserve.service',
        ].map((key) => localize(key as any as string)),
        cancelButtonIndex: 0,
      },
      (buttonIndex: any) => {
        if (buttonIndex === 1) {
          onRentalSelected();
        }

        if (buttonIndex === 2) {
          onServiceSelected();
        }
      }
    );
  } else {
    UIManager.showListAlert(
      localize('drivercar.rental.dialog.title'),
      [
        'drivercar.rental.dialog.reserve.rental',
        'drivercar.rental.dialog.reserve.service',
      ].map((key) => localize(key)),
      (response) => {
        if (response.buttonIndex) {
          const selectedIndex = parseInt(response.buttonIndex, 10);

          if (selectedIndex === 0) {
            onRentalSelected();
          } else if (selectedIndex === 1) {
            onServiceSelected();
          }
        }
      }
    );
  }
};

export const isPayinPayment = (
  paymentMethods: Array<PaymentMethodDataOutput>
) => {
  const defaultPayment =
    paymentMethods?.find((payment) => payment?.defaultOne === true) ||
    paymentMethods?.[0];

  if (!defaultPayment) {
    return false;
  }

  const isVerified = defaultPayment.status === 'verified';
  const hasNoPaymentData =
    !defaultPayment.paymentData ||
    Object.keys(defaultPayment.paymentData).length === 0;
  const isPayIn = 'payIn' in defaultPayment;

  return isVerified && hasNoPaymentData && isPayIn;
};

export const onReserveCar = (
  localize: LocalizePropType,
  user: UserDataOutput,
  service: boolean,
  onReservationApproved: (service: boolean) => void,
  availableUntil: string,
  msToHMS: (number: number, localize: LocalizePropType) => void
) => {
  const timeToReturn = availableUntil
    ? // @ts-ignore
      msToHMS(Math.abs(new Date(availableUntil) - new Date()), localize)
    : '';
  const shouldShowAvailabilityDialog =
    !!availableUntil && isBefore24hrs(new Date(availableUntil));

  if (service) {
    onReservationApproved(true);
  } else if (shouldShowAvailabilityDialog) {
    showAvailabilityDialog(localize, timeToReturn, () =>
      onReservationApproved(false)
    );
  } else {
    onReservationApproved(false);
  }
};

export const onReserveCarClicked = (
  localize: LocalizePropType,
  startDate: Date | null | undefined,
  availableUntil: string,
  msToHMS: any,
  user: UserDataOutput,
  isServiceHero: boolean,
  isServiceCircle: boolean,
  availableForRental: boolean,
  onBookingApproved: () => void,
  onReservationApproved: (service: boolean) => void
) => {
  if (startDate) {
    if (availableForRental) {
      onBookingApproved();
    }
  } else if (isServiceCircle) {
    onReserveCar(
      localize,
      user,
      true,
      onReservationApproved,
      availableUntil,
      msToHMS
    );
  } else if (isServiceHero) {
    if (availableForRental) {
      showRentalTypeDialog(
        localize,
        () =>
          onReserveCar(
            localize,
            user,
            true,
            onReservationApproved,
            availableUntil,
            msToHMS
          ),
        () =>
          onReserveCar(
            localize,
            user,
            false,
            onReservationApproved,
            availableUntil,
            msToHMS
          )
      );
    } else {
      onReserveCar(
        localize,
        user,
        true,
        onReservationApproved,
        availableUntil,
        msToHMS
      );
    }
  } else {
    onReserveCar(
      localize,
      user,
      false,
      onReservationApproved,
      availableUntil,
      msToHMS
    );
  }
};
