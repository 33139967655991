import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  LocalizePropType,
  msToHMS,
  selectBookings,
  selectUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { isBefore } from 'date-fns';
import { isIncompleteProfile, navigateTo } from '../../../utils';
import { showAboutDialog } from '../../../utils/dialogsHelpers';
import DesktopDashboardView from './DesktopDashboardView';
import config from '../../../env.config';

type Props = {
  localize: LocalizePropType;
};

const DesktopDashboardContainer = ({ localize }: Props) => {
  const history = useHistory();
  const user = useSelector(selectUser) as any;
  const inCompleteProfile = isIncompleteProfile(user);
  const bookings = useSelector(selectBookings) as any;

  const countdownUpdaterId = useRef<any>(null);
  const [bookingCountdown, setBookingCountdown] = useState('');

  useEffect(() => {
    const activeBookings = bookings
      ?.filter((booking: any) =>
        ['confirmed', 'started'].includes(booking.status)
      )
      ?.sort(
        (a: any, b: any) =>
          (new Date(a.from) as any) - (new Date(b.from) as any)
      );
    const { from } = activeBookings?.length > 0 ? activeBookings[0] : '';
    if (isBefore(new Date(), new Date(from))) {
      msToHMS(
        Math.abs((new Date(from) as any) - (new Date() as any)),
        localize
      );
      countdownUpdaterId.current = setInterval(() => {
        setBookingCountdown(
          msToHMS(
            Math.abs((new Date(from) as any) - (new Date() as any)),
            localize
          )
        );
      }, 15000);
    }
    return () => {
      if (countdownUpdaterId?.current) {
        clearInterval(countdownUpdaterId.current);
      }
    };
  }, []);

  useEffect(() => {
    const activeBookings = bookings
      ?.filter((booking: any) =>
        ['confirmed', 'started'].includes(booking.status)
      )
      ?.sort(
        (a: any, b: any) =>
          (new Date(a.from) as any) - (new Date(b.from) as any)
      );
    const { from } = activeBookings?.length > 0 ? activeBookings[0] : '';
    if (from) {
      if (isBefore(new Date(), new Date(from))) {
        if (
          bookingCountdown !==
          msToHMS(
            Math.abs((new Date(from) as any) - (new Date() as any)),
            localize
          )
        ) {
          setBookingCountdown(
            msToHMS(
              Math.abs((new Date(from) as any) - (new Date() as any)),
              localize
            )
          );
        }
      } else if (countdownUpdaterId?.current) {
        clearInterval(countdownUpdaterId.current);
        if (bookingCountdown) {
          setBookingCountdown('');
        }
      }
    } else if (bookingCountdown) {
      setBookingCountdown('');
    }
  }, [bookings]);

  const activeBookings = useMemo(
    () =>
      bookings
        ?.filter((booking: any) =>
          ['confirmed', 'started'].includes(booking.status)
        )
        ?.sort(
          (a: any, b: any) =>
            (new Date(b.from) as any) - (new Date(a.from) as any)
        ),
    [bookings]
  );

  const onClickUserRentals = () => {
    navigateTo(history, '/rentals');
  };

  const onClickBalance = () => {
    navigateTo(history, '/balance');
  };

  const onClickProfile = () => {
    navigateTo(history, '/settings');
  };

  const onPressAbout = () => {
    showAboutDialog(config.brand.xBrandId!!, localize);
  };

  const onPressHelpCenter = () => {
    window.open(localize('dashboard.help.url'));
  };

  const onClose = () => {
    navigateTo(history, '/');
  };

  return (
    <DesktopDashboardView
      localize={localize}
      userName={`${user.firstName} ${user.lastName}`}
      balance={user?.balance}
      inCompleteProfile={inCompleteProfile}
      bookingCountdown={
        activeBookings?.length > 0 && activeBookings[0]?.status === 'started'
          ? activeBookings[0]?.rental?.carMode !== 'waitingForCheckin'
            ? localize('booking.label.started')
            : localize('booking.label.waiting.checkin')
          : bookingCountdown
      }
      isStarted={
        activeBookings?.length > 0 && activeBookings[0]?.status === 'started'
      }
      isWaitingForCheckin={
        activeBookings?.length > 0 &&
        activeBookings[0]?.rental?.carMode === 'waitingForCheckin'
      }
      onClickProfile={onClickProfile}
      onClickBalance={onClickBalance}
      onClickUserRentals={onClickUserRentals}
      onPressAbout={onPressAbout}
      onPressHelpCenter={onPressHelpCenter}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(DesktopDashboardContainer);
