import React from 'react';
import './HomeView.less';
import Modal from '@mui/material/Modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import SlideItem from '../../components/SlideItem/SlideItem';
import { homeWalkthrogh1, homeWalkthrogh2 } from '../../../assets/brandImages';
import {
  getVersionHeaderColor,
  LocalizePropType,
  isKYCApp,
  UserDataOutput,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../utils';

type Props = {
  localize: LocalizePropType;
  user: UserDataOutput;
  sliderIndex: number;
  sliderRef: any;
  onClickNext: () => void;
  onClickGuest: () => void;
  onClickLogin: () => void;
  afterSlide: (index: number) => void;
  onClickVersionHeader: () => void;
};

const HomeView = ({
  localize,
  user,
  sliderRef,
  sliderIndex,
  onClickNext,
  onClickGuest,
  afterSlide,
  onClickVersionHeader,
  onClickLogin,
}: Props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots home-slick-dots',
  };
  const open = !user && sliderIndex !== 2;
  const actions = (
    <>
      <div
        className="version-header"
        onClick={onClickVersionHeader}
        style={{ color: getVersionHeaderColor() }}
      >
        {localize('home.about')}
      </div>
      {isKYCApp() ? (
        <button
          className="homeWT-login-btn"
          type="submit"
          onClick={onClickLogin}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {localize('home.walkthrough.login')}
        </button>
      ) : (
        <button
          className="homeWT-guest-btn"
          type="submit"
          onClick={onClickGuest}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {localize('home.walkthrough.guest')}
        </button>
      )}
      <div>
        <button
          id={GA_TITLES.AUTH.OPEN_SIGNUP}
          className="home-signup-btn"
          type="submit"
          onClick={onClickNext}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {localize('home.walkthrough.signup')}
        </button>
      </div>
    </>
  );
  return (
    <Modal open={open} onClose={() => {}} hideBackdrop>
      <div>
        <Slider ref={sliderRef} {...settings} afterChange={afterSlide}>
          <SlideItem
            title={localize(`home.walkthrough.page1.title`)}
            description={localize(`home.walkthrough.page1.description`)}
            imageSrc={homeWalkthrogh1}
            extraComponents={actions}
          />
          <SlideItem
            title={localize(`home.walkthrough.page2.title`)}
            description={localize(`home.walkthrough.page2.description`)}
            imageSrc={homeWalkthrogh2}
            extraComponents={actions}
          />
          <div style={{ backgroundColor: 'transparent' }} />
        </Slider>
      </div>
    </Modal>
  );
};

export default HomeView;
