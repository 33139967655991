import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/ConnectUiTypes';
import { ConnectUiAction, ConnectUiState } from '../../../../@types';
export default (
  state: ConnectUiState = initialState,
  action: ConnectUiAction
): ConnectUiState => {
  switch (action.type) {
    case TYPES.SET_SELECTED_CAR: {
      return {
        ...state,
        selectedCar: action.payload.selectedCar!,
      };
    }
    case TYPES.SET_SELECTED_DEVICE: {
      return {
        ...state,
        selectedDevice: action.payload.selectedDevice!,
      };
    }
    case TYPES.SET_SELECTED_DEVICE_STATUS: {
      return {
        ...state,
        selectedDeviceStatus: action.payload.selectedDeviceStatus!,
      };
    }

    // @ts-ignore
    case PURGE:
      return { ...initialState };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
