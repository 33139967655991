import React from 'react';
import './AllAroundView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { LocalizePropType } from 'getaway-data-layer';
import CarCheckPhotoWrapper from '../../../components/CarCheckPhotoWrapper/CarCheckPhotoWrapper';
import BrandButton from '../../../components/BrandButton/BrandButton';
import { GA_TITLES } from '../../../../utils';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
  carCheckImages: any;
  onPressRemoveImage: (index: number) => void;
  onClickSubmit: () => void;
  isSubmitDisabled: boolean;
};

const AllAroundView = ({
  localize,
  carCheckImages,
  onPressRemoveImage,
  onClickSubmit,
  onClose,
  isSubmitDisabled,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('rental.carCheck.header.title')}
        description={localize('rental.carCheck.header.desc')}
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body" style={{ padding: 0, paddingTop: 24 }}>
        <div className="check-card-photo-container">
          <CarCheckPhotoWrapper
            carCheckImages={carCheckImages}
            onPressRemoveImage={onPressRemoveImage}
          />
          <div className="check-card-footnote">
            {localize('rental.carCheck.footnote')}
          </div>
        </div>
      </div>
      <BrandButton
        id={GA_TITLES.RENTAL.SUBMIT_CAR_CHECK}
        title={localize('rental.carCheck.button')}
        onClick={onClickSubmit}
        disabled={isSubmitDisabled}
      />
    </div>
  );
};

export default AllAroundView;
