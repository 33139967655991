import { Location } from '../../Types';

export type PlatformType = {
  name: 'ios' | 'android' | 'browser';
  version: number;
};
export type Language = string | 'de' | 'en';
export type DeviceState = {
  readonly status: {
    readonly cameraPermission: any;
    readonly locationPermission: any;
    readonly bluetoothPermission: any;
    readonly devicePaired: boolean;
    readonly deviceConnected: boolean;
  };
  readonly lastKnownLocation: Location | null | undefined;
  readonly deviceUUID: string;
  readonly platform: string;
  readonly model: string;
  readonly pushToken: string;
  readonly pushDeviceID: string;
  readonly language: Language;
  readonly userAgent: string;
  readonly isKeyboardOpened: boolean;
  readonly orientation: string;
  readonly totalMemory: any;
  readonly usedMemory: any;
};
enum DeviceTypes {
  SET_DEVICE_UUID = 'device/SET_DEVICE_UUID',
  SET_PLATFORM = 'appData/texts/SET_PLATFORM',
  SET_MODEL = 'device/SET_MODEL',
  SET_PUSH_TOKEN = 'device/SET_PUSH_TOKEN',
  SET_PUSH_DEVICE_ID = 'device/SET_PUSH_DEVICE_ID',
  SET_LANGUAGE = 'appData/texts/SET_LANGUAGE',
  SET_LAST_KNOWN_LOCATION = 'device/SET_LAST_KNOWN_LOCATION',
  SET_USER_AGENT = 'device/SET_USER_AGENT',
  SET_LOCATION_PERMISSION = 'device/SET_LOCATION_PERMISSION',
  SET_CAMERA_PERMISSION = 'device/SET_CAMERA_PERMISSION',
  SET_BLUETOOTH_PERMISSION = 'device/SET_BLUETOOTH_PERMISSION',
  SET_DEVICE_PAIRED = 'device/SET_DEVICE_PAIRED',
  SET_DEVICE_CONNECTED = 'device/SET_DEVICE_CONNECTED',
  SET_DEVICE_ORIENTATION = 'device/SET_DEVICE_ORIENTATION',
  SET_TOTAL_MEMORY = 'device/SET_TOTAL_MEMORY',
  SET_USED_MEMORY = 'device/SET_USED_MEMORY',
  SET_IS_KEYBOARD_OPENED = 'device/SET_IS_KEYBOARD_OPENED',
}
export default DeviceTypes;
export type SetTotalMemory = {
  type: DeviceTypes.SET_TOTAL_MEMORY;
  payload: {
    totalMemory: string | number;
  };
};
export type SetUsedMemory = {
  type: DeviceTypes.SET_USED_MEMORY;
  payload: {
    usedMemory: string | number;
  };
};
export type SetOrientation = {
  type: DeviceTypes.SET_DEVICE_ORIENTATION;
  payload: {
    orientation: string;
  };
};
export type SetDeviceUUID = {
  type: DeviceTypes.SET_DEVICE_UUID;
  payload: {
    deviceUUID: string;
  };
};
export type SetLanguage = {
  type: DeviceTypes.SET_LANGUAGE;
  payload: {
    language: Language;
  };
};
export type SetPlatform = {
  type: DeviceTypes.SET_PLATFORM;
  payload: {
    platform: string;
  };
};
export type SetModel = {
  type: DeviceTypes.SET_MODEL;
  payload: {
    model: string;
  };
};
export type SetPushToken = {
  type: DeviceTypes.SET_PUSH_TOKEN;
  payload: {
    pushToken: string;
  };
};
export type SetPushDeviceID = {
  type: DeviceTypes.SET_PUSH_DEVICE_ID;
  payload: {
    pushDeviceID: string;
  };
};
export type SetLastKnownLocation = {
  type: DeviceTypes.SET_LAST_KNOWN_LOCATION;
  payload: {
    lastKnownLocation: Location;
  };
};
export type SetUserAgent = {
  type: DeviceTypes.SET_USER_AGENT;
  payload: {
    userAgent: string;
  };
};
export type SetCameraPermission = {
  type: DeviceTypes.SET_CAMERA_PERMISSION;
  payload: {
    cameraPermission: PermissionStatus;
  };
};
export type SetLocationPermission = {
  type: DeviceTypes.SET_LOCATION_PERMISSION;
  payload: {
    locationPermission: PermissionStatus;
  };
};
export type SetBluetoothPermission = {
  type: DeviceTypes.SET_BLUETOOTH_PERMISSION;
  payload: {
    bluetoothPermission: PermissionStatus;
  };
};
export type SetDevicePaired = {
  type: DeviceTypes.SET_DEVICE_PAIRED;
  payload: {
    devicePaired: boolean;
  };
};
export type SetDeviceConnected = {
  type: DeviceTypes.SET_DEVICE_CONNECTED;
  payload: {
    deviceConnected: boolean;
  };
};

export type SetIsKeyboardOpened = {
  type: DeviceTypes.SET_IS_KEYBOARD_OPENED;
  payload: {
    isKeyboardOpened: boolean;
  };
};
export type DeviceAction =
  | SetOrientation
  | SetDeviceUUID
  | SetLanguage
  | SetPlatform
  | SetModel
  | SetPushToken
  | SetPushDeviceID
  | SetLastKnownLocation
  | SetUserAgent
  | SetLocationPermission
  | SetCameraPermission
  | SetBluetoothPermission
  | SetDevicePaired
  | SetDeviceConnected
  | SetTotalMemory
  | SetUsedMemory
  | SetIsKeyboardOpened;
