import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';

export type Props = {
  newDamages: Array<string>;
};

const withDamages: any = compose(
  connect(
    ({
      ui: {
        damages: { newDamages },
      },
    }: State) => ({
      newDamages,
    })
  )
);
export default withDamages;
