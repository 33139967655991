import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  verifyEmail,
  withTextLocalizer,
  LocalizePropType,
  selectUser,
  isKYCApp,
  selectIsVerifyingGDPREmail,
  setIsVerifyingGDPREmail,
  selectIsEditingPhone,
  setIsEditingPhone,
  selectBrandSettings,
  updateUser,
  setNotification,
  isMVV,
} from 'getaway-data-layer';
import {
  attachInputListeners,
  GA_TITLES,
  getEmailVerificationStatus,
  isDashboardScreen,
  isPhoneVerificationRequired,
  isTariffRequired,
  navigateTo,
  removeInputListeners,
  reportGAEvent,
  showAlert,
  TALLY_LINKS,
} from '../../../../utils';
import VerifyEmailView from './VerifyEmailView';
import { useQuery } from '../../../../hooks/useQuery';
import {
  deletePhone,
  getGDPR,
  reSendVerificationEmail,
} from 'getaway-data-layer/src/redux/store/userData/user/actions';

type Props = {
  localize: LocalizePropType;
};

const SKIP_AFTER_DURATION = 20;

const VerifyEmailContainer = ({ localize }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const user = useSelector(selectUser) as any;
  const brandSettings = useSelector(selectBrandSettings) as any;
  const isVerifyingGDPREmail = useSelector(selectIsVerifyingGDPREmail);
  const isEditingPhone = useSelector(selectIsEditingPhone);
  const isEmailVerified = getEmailVerificationStatus(user) === 'completed';
  const [skipCountdown, setSkipCountdown] = useState(
    SKIP_AFTER_DURATION
  ) as any;
  const [code, setCode] = useState('');
  const [marketingConsentGiven, setMarketingConsentGiven] = useState(
    brandSettings?.marketingConsentAutoSelected || user?.marketingConsentGiven
  );

  const resendCode = () => {
    showAlert(
      '',
      localize('profile.gdpr.resend'),
      localize('resend'),
      () => {
        dispatch(reSendVerificationEmail());
        setCode('');
      },
      localize('no'),
      () => {
        dispatch(setIsVerifyingGDPREmail(false));
        dispatch(setIsEditingPhone(false));
        navigateTo(history, '/settings');
      }
    );
  };

  const onResendPressed = () => {
    dispatch(
      reSendVerificationEmail(() => {
        dispatch(
          setNotification({ message: localize('profile.verify.email.sent') })
        );
      })
    );
    setCode('');
  };

  const onSubmit = (code: string) => {
    if (isVerifyingGDPREmail) {
      dispatch(
        getGDPR(code, resendCode, () => {
          dispatch(setIsVerifyingGDPREmail(false));
          navigateTo(history, '/settings');
        })
      );
    } else if (isEditingPhone) {
      dispatch(
        deletePhone(code, resendCode, () => {
          dispatch(setIsEditingPhone(false));
          navigateTo(history, '/dashboard/addPhone');
        })
      );
    } else {
      dispatch(
        verifyEmail(code.trim(), localize, () => {
          navigateTo(
            history,
            isDashboardScreen() || user?.phoneNumber
              ? '/settings'
              : isPhoneVerificationRequired(user)
              ? '/onboarding/addPhone'
              : isTariffRequired(user)
              ? isMVV()
                ? `/onboarding/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
                : '/onboarding/tariff'
              : '/onboarding/IDOnboarding'
          );

          if (marketingConsentGiven) {
            dispatch(updateUser({ marketingConsentGiven }));
          }
          reportGAEvent(GA_TITLES.ON_BOARDING.VERIFY_EMAIL_SUBMITTED);
        })
      );
    }
  };

  const onClose = () => {
    dispatch(setIsVerifyingGDPREmail(false));
    dispatch(setIsEditingPhone(false));
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, '/settings'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onPressSkip = () => {
    navigateTo(
      history,
      isDashboardScreen() || user?.phoneNumber
        ? '/settings'
        : '/onboarding/addPhone'
    );
  };

  const onPressEmail = () => {
    navigateTo(
      history,
      isDashboardScreen() ? '/dashboard/updateEmail' : '/onboarding/updateEmail'
    );
  };

  const onPressMarketingConsent = () => {
    setMarketingConsentGiven(!marketingConsentGiven);
  };

  useEffect(() => {
    let countdownUpdaterId: any;
    const code = query.get('verificationCode');
    if (code) {
      onSubmit(code);
    }
    attachInputListeners();
    countdownUpdaterId = setInterval(() => {
      setSkipCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;

        if (newCountdown <= 0) {
          clearInterval(countdownUpdaterId);
          return 0;
        }

        return newCountdown;
      });
    }, 1000);

    setTimeout(() => {
      const inputElement = document?.querySelector(
        'input[name="one-time-code"]'
      );
      // @ts-ignore
      inputElement && inputElement?.click();
    }, 300);

    return () => {
      if (countdownUpdaterId) {
        clearInterval(countdownUpdaterId);
      }
      removeInputListeners();
    };
  }, []);

  useEffect(() => {
    setMarketingConsentGiven(
      brandSettings?.marketingConsentAutoSelected || user?.marketingConsentGiven
    );
  }, [brandSettings]);

  return (
    <VerifyEmailView
      localize={localize}
      email={user?.email}
      isVerifyingGDPREmail={isVerifyingGDPREmail}
      isEditingPhone={isEditingPhone}
      marketingConsentGiven={marketingConsentGiven}
      marketingConsentRequired={brandSettings?.marketingConsentRequired}
      onPressMarketingConsent={onPressMarketingConsent}
      onClose={onClose}
      onSubmit={onSubmit}
      onPressSkip={onPressSkip}
      onPressEmail={onPressEmail}
      skipCountdown={skipCountdown}
      code={code}
      setCode={setCode}
      isEmailVerified={isEmailVerified}
      onResendPressed={onResendPressed}
    />
  );
};

export default withTextLocalizer(VerifyEmailContainer);
