import React from 'react';
import { format, isValid } from 'date-fns';
import './SchufaCheckView.less';
import { FormikValues } from 'formik';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import BrandButton from '../../../components/BrandButton/BrandButton';
import { isSafari } from '../../Map/MapFilter/MapFilterContainer';
import {
  getBrandColor,
  isLocalizeAdded,
  LocalizePropType,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../../utils';
import { ADDRESS_STREET_REGEX } from '../../../../validation';
import Hyperlink from 'react-native-hyperlink';
// @ts-ignore
import { Linking } from 'react-native-web';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  supportedCountries: Array<any>;
  formik: FormikValues;
  licenseStatus: string;
  customError: string;
  minDate: any;
  maxDate: any;
  onClose: () => void;
  country: string;
  onCountryChange: (value: any) => void;
  dateFormat: string;
};

const SchufaCheckView = ({
  localize,
  supportedCountries,
  formik,
  customError,
  minDate,
  maxDate,
  licenseStatus,
  onClose,
  onCountryChange,
  dateFormat,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('schufa.title')}
        description={localize('schufa.instruction')}
        showHelp
        showArrowBack
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'schufa.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'schufa.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <div className="schufa-header">
              {localize('schufa.personalInfo')}
            </div>
            <TextField
              required
              id="firstName"
              sx={{ width: 0.5, pr: 1 }}
              label={localize('signup.name.placeholder')}
              helperText={
                (formik.touched.firstName && formik.errors.firstName) || ''
              }
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              variant="standard"
              size="small"
              type="text"
              autoFocus
              disabled={licenseStatus === 'completed'}
              autoComplete="given-name"
              {...formik.getFieldProps('firstName')}
            />
            <TextField
              required
              id="lastName"
              sx={{ width: 0.5 }}
              label={localize('signup.facebook.lastname.placeholder')}
              helperText={
                (formik.touched.lastName && formik.errors.lastName) || ''
              }
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              variant="standard"
              size="small"
              type="text"
              disabled={licenseStatus === 'completed'}
              autoComplete="family-name"
              {...formik.getFieldProps('lastName')}
            />
            <div className="schufa-date-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  autoFocus={false}
                  showToolbar={false}
                  inputFormat={dateFormat}
                  label={localize('schufa.dateOfBirth.label')}
                  minDate={minDate}
                  maxDate={maxDate}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        width: '100%',
                        borderBottomStyle: 'solid',
                      }}
                      error={false}
                    />
                  )}
                  onChange={(value) => {
                    formik.setFieldValue('dateOfBirth', value);
                  }}
                  value={formik?.values?.dateOfBirth}
                />
              </LocalizationProvider>
              {customError && (
                <div className="date-of-birth-error">{customError}</div>
              )}
            </div>
            <div className="schufa-header-address">
              {localize('schufa.address')}
            </div>
            <div className="schufa-instructions">
              {localize('firstSteps.address.instructions')}
            </div>
            <TextField
              required
              sx={{ width: 0.7, pr: 1 }}
              label={localize('firstSteps.address.streetName.placeholder')}
              helperText={
                (formik.touched.streetName && formik.errors.streetName) || ' '
              }
              error={
                formik.touched.streetName && Boolean(formik.errors.streetName)
              }
              variant="standard"
              size="small"
              type="text"
              autoComplete="address-line1"
              {...formik.getFieldProps('streetName')}
              onChange={(e) => {
                const value = e?.target?.value;
                if (value?.length > 1) {
                  const street = ADDRESS_STREET_REGEX.exec(value);
                  formik.setFieldValue(
                    'streetName',
                    street?.[1] || street?.[0] || value
                  );
                } else {
                  formik.setFieldValue('streetName', value);
                }
              }}
            />
            <TextField
              required
              id="streetNumber"
              sx={{ width: 0.3 }}
              label={localize('firstSteps.address.streetNum.placeholder')}
              helperText={
                (formik.touched.streetNumber && formik.errors.streetNumber) ||
                ' '
              }
              error={
                formik.touched.streetNumber &&
                Boolean(formik.errors.streetNumber)
              }
              variant="standard"
              size="small"
              type="text"
              {...formik.getFieldProps('streetNumber')}
            />
            <TextField
              required
              id="postalCode"
              sx={{ width: 0.3 }}
              label={localize('firstSteps.address.postalCode.placeholder')}
              helperText={
                (formik.touched.postalCode && formik.errors.postalCode) || ' '
              }
              error={
                formik.touched.postalCode && Boolean(formik.errors.postalCode)
              }
              variant="standard"
              size="small"
              type="text"
              autoComplete="postal-code"
              {...formik.getFieldProps('postalCode')}
            />
            <TextField
              required
              id="city"
              sx={{ width: 0.7, pl: 1 }}
              label={localize('firstSteps.address.city.placeholder')}
              helperText={(formik.touched.city && formik.errors.city) || ' '}
              error={formik.touched.city && Boolean(formik.errors.city)}
              variant="standard"
              size="small"
              type="text"
              autoComplete="address-level2"
              {...formik.getFieldProps('city')}
            />
            <FormControl fullWidth required variant="standard">
              <InputLabel id="country-label">
                {localize('firstSteps.address.country.placeholder')}
              </InputLabel>
              <Select
                fullWidth
                required
                labelId="select-country"
                id="country"
                variant="standard"
                label={localize('firstSteps.address.country.placeholder')}
                {...formik.getFieldProps('country')}
                onChange={(event) => {
                  onCountryChange(event.target.value);
                  formik.handleChange(event);
                }}
              >
                {supportedCountries.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="add-sepa-footnote">
              <Hyperlink
                linkStyle={{ color: getBrandColor() }}
                linkText={(url) =>
                  url === localize('settings.payment.footnote.url')
                    ? localize('settings.payment.footnote.url.text')
                    : url
                }
                onPress={(url) => {
                  Linking.openURL(url);
                }}
              >
                {localize(
                  'settings.payment.footnote',
                  localize('settings.payment.footnote.url')
                )}
              </Hyperlink>
            </div>
            <BrandButton
              id={GA_TITLES.ON_BOARDING.SUBMIT_SCHUFA}
              title={localize('schufa.button.title')}
              disabled={!!(isSafari && customError)}
              formik={formik}
            />
          </Box>
        </div>
        {isLocalizeAdded(localize, 'schufa.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('schufa.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default SchufaCheckView;
