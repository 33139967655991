import * as yup from 'yup';

export const LoginModal = {
  email: '',
  password: '',
};

export const LoginSchema = (localize: any): any =>
  yup.object({
    email: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        localize('input.error.invalid')
      ),
    password: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^(?=(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[#?!@$%^&*-]))(?=.*?[a-z]).{8,}$/,
        localize('input.error.invalid')
      ),
  });
