import { setSelectedCar } from '../redux/store/ui/map/Actions';
import { setNotification } from '../redux/store/ui/notifications/Actions';
import {
  submitChallenge,
  setRecoverPaymentAuth,
  submitIdentityShopper,
  isPaymentVerified,
} from '../redux/store/userData/user/actions';
import { showAlert } from './showAlert';
import { APP } from '../enums';
import { isWeb } from './platformHelpers';
import { CardPaymentManager } from '../native/CardPaymentManager';
import { getFirstStepsChallenge } from './firstStepsHelper';
import { LocalizePropType } from '../enhancers';

export const handleRentalViolations = (
  violations: Record<string, any>,
  user: any,
  localize: LocalizePropType,
  displayNotification: typeof setNotification,
  devicePaired: boolean,
  navigation: any,
  features: Array<string>,
  sendCode: any,
  helpAction: () => void,
  mIsPaymentVerified: typeof isPaymentVerified,
  mSubmitIdentityShopper: typeof submitIdentityShopper,
  mSubmitChallenge: typeof submitChallenge,
  mSetRecoverPaymentAuth: typeof setRecoverPaymentAuth,
  dispatch: any = null,
  history: any = null
) => {
  const firstStepsViolations = Object.keys(violations).filter((violationType) =>
    [
      'phoneNumberVerified',
      'paymentMethodExists',
      'validLicence',
      'addressVerified',
    ].includes(violationType)
  );
  const requiredDeviceViolations = Object.keys(violations).filter(
    (violationType) =>
      ['hasPairedDevice', 'isCorrectDevice', 'isPaired'].includes(violationType)
  );
  const paymentVerifyViolation = Object.keys(violations).find(
    (violationType) => violationType === 'api_error.payment.3ds_required'
  );
  const firstStepsChallenge = getFirstStepsChallenge(user.challenges);
  const incompleteTasks = firstStepsChallenge
    ? firstStepsChallenge.required - firstStepsChallenge.achieved
    : 0;

  let action = () => {};

  let licenseStatus: string;

  const myProfileAction = () => showMyProfileAction(navigation, history);

  if (requiredDeviceViolations.length > 0) {
    const needAuthorization = user.verified.phoneNumber === false;
    const violationKey = requiredDeviceViolations[0];
    let alertTitle = localize('map.fillProfile.dialog.title');
    let alertMessage = violations[violationKey];
    let yesActionText = localize(
      needAuthorization
        ? 'map.fillProfile.dialog.support'
        : 'map.fillProfile.dialog.add'
    );

    let yesAction = () => {};

    let noActionText = localize('map.fillProfile.dialog.no');

    if (needAuthorization) {
      yesAction = () => {
        showUpdatePhoneViolationAction(navigation, history);
      };
    } else if (!devicePaired) {
      alertTitle = localize('insecuredevice.dialog.title');
      alertMessage = localize('insecuredevice.dialog.message');
      yesActionText = localize('insecuredevice.dialog.yes');

      yesAction = () => {
        showPairingPhoneViolationAction(navigation, history, sendCode);
      };

      noActionText = localize('insecuredevice.dialog.no');
    } else {
      yesAction = () => {
        helpAction();
      };
    }

    showAlert(alertTitle, alertMessage, yesActionText, yesAction, noActionText);
  } else if (firstStepsViolations.length > 1 || incompleteTasks > 1) {
    showAlert(
      localize('map.fillProfile.dialog.title'),
      localize('map.fillProfile.dialog.message'),
      localize('map.fillProfile.dialog.yes'),
      myProfileAction,
      localize('map.fillProfile.dialog.no')
    );
  } else if (firstStepsViolations.length === 1) {
    const violationKey = firstStepsViolations[0];

    switch (violationKey) {
      case 'phoneNumberVerified':
        action = () => {
          showUpdatePhoneViolationAction(navigation, history);
        };
        break;
      case 'paymentMethodExists':
        action = () => {
          showUpdatePaymentViolationAction(navigation, history);
        };
        break;
      case 'validLicence': {
        const { tasks } = user.challenges.find(
          (challenge: { type: string }) => challenge.type === 'firstSteps'
        );
        const { status } = tasks.find(
          (task: { screen: string }) =>
            task.screen === '/user/verify-drivers-license'
        );
        licenseStatus =
          status !== 'completed'
            ? status
            : user.verified.drivingLicense
            ? 'completed'
            : 'deleted';

        action = () => {
          if (user?.remainingLicenseSubmissionAttempts > 0) {
            showUpdateLicenseViolationAction(
              navigation,
              history,
              features,
              licenseStatus
            );
          } else {
            showDLResubmitInvalid(localize, () => {
              helpAction();
            });
          }
        };
        break;
      }
      case 'addressVerified':
        action = () => {
          showUpdateAddressViolationAction(navigation, history);
        };
        break;
      default:
        return;
    }

    // @ts-ignore
    if (licenseStatus === 'inProgress') {
      showAlert(
        '',
        localize('user.DL.inProgress.message'),
        null,
        () => {},
        localize('ok'),
        () => {}
      );
    } else {
      showAlert(
        localize('map.fillProfile.dialog.title'),
        violations[violationKey],
        localize('map.fillProfile.dialog.add'),
        () => {
          if (dispatch) {
            dispatch(setSelectedCar(null));
          }
          action();
        },
        localize('map.fillProfile.dialog.no')
      );
    }
  } else if (paymentVerifyViolation) {
    const callback = () => {
      navigation.navigate('MapStack', {
        screen: 'MapCards',
      });
    };

    action = () => {
      mIsPaymentVerified((userData) => {
        if (
          userData?.paymentMethods?.length &&
          userData?.paymentMethods[0]?.paymentData
        ) {
          handle3dsData(
            navigation,
            'BookingViolationsStack',
            userData?.paymentMethods[0]?.paymentData,
            mSubmitIdentityShopper,
            mSubmitChallenge,
            () => mSetRecoverPaymentAuth(true),
            callback,
            callback
          );
        }
      });
    };

    showAlert(
      localize('payment.3ds.title'),
      violations[paymentVerifyViolation],
      localize('continue'),
      action,
      localize('cancel')
    );
  } else {
    if (dispatch) {
      Object.values(violations).forEach((violation) =>
        dispatch(
          displayNotification({
            message: violation,
            type: APP.NOTIFICATION_TYPE.ERROR,
            localize: false,
          })
        )
      );
    } else {
      Object.values(violations).forEach((violation) =>
        displayNotification({
          message: violation,
          type: APP.NOTIFICATION_TYPE.ERROR,
          localize: false,
        })
      );
    }
  }
};

const showMyProfileAction = (navigation: any, history: any) => {
  if (isWeb()) {
    webNavigateTo(history, '/settings');
  } else {
    mobileNavigateTo(navigation, 'DashboardStack', 'Settings');
  }
};

const showUpdatePhoneViolationAction = (navigation: any, history: any) => {
  if (isWeb()) {
    webNavigateTo(history, '/dashboard/addPhone');
  } else {
    mobileNavigateTo(navigation, 'BookingViolationsStack', 'Phone');
  }
};

const showPairingPhoneViolationAction = (
  navigation: any,
  history: any,
  sendCode: any
) => {
  sendCode();
  if (isWeb()) {
    webNavigateTo(history, '/dashboard/verifyPhone');
  } else {
    mobileNavigateTo(
      navigation,
      'BookingViolationsStack',
      'PhonePairingContainer'
    );
  }
};

const showUpdatePaymentViolationAction = (navigation: any, history: any) => {
  if (isWeb()) {
    webNavigateTo(history, '/dashboard/selectPayment');
  } else {
    mobileNavigateTo(navigation, 'BookingViolationsStack', 'SelectPayment');
  }
};

const showUpdateLicenseViolationAction = (
  navigation: any,
  history: any,
  features: Array<string>,
  licenseStatus: string
) => {
  if (['open', 'deleted'].includes(licenseStatus)) {
    if (isWeb()) {
      webNavigateTo(history, '/dashboard/DLOnboarding');
    } else {
      mobileNavigateTo(navigation, 'BookingViolationsStack', 'DL');
    }
  }
};

const showUpdateAddressViolationAction = (navigation: any, history: any) => {
  if (isWeb()) {
    webNavigateTo(history, '/dashboard/addAddress');
  } else {
    mobileNavigateTo(navigation, 'BookingViolationsStack', 'Address');
  }
};

const mobileNavigateTo = (
  navigation: any,
  navigationNode: string,
  screen: string
) => {
  navigation.navigate(navigationNode, {
    screen,
  });
};

const webNavigateTo = (history: any, path: string) => {
  history.push(path);
};

export const showDLResubmitInvalid = (
  localize: LocalizePropType,
  positiveCallback: () => void = () => {}
): void => {
  showAlert(
    '',
    localize('user.DL.resubmission.message'),
    localize('user.action.support.text'),
    () => {
      positiveCallback();
    },
    localize('user.notNow')
  );
};

export const handle3dsData = (
  navigation: any,
  stack: string,
  paymentData: any,
  mSubmitIdentityShopper: typeof submitIdentityShopper,
  mSubmitChallenge: typeof submitChallenge,
  onRedirectChallengeStarted: () => void,
  successCallback: () => void,
  onPressBack: () => void
) => {
  const { threeDS1, identifyShopperData, challengeShopperData } = paymentData;

  if (threeDS1) {
    onRedirectChallengeStarted();
    handleRedirect(navigation, stack, threeDS1, onPressBack);
  } else if (identifyShopperData) {
    handleIdentifyShopper(
      identifyShopperData,
      mSubmitIdentityShopper,
      mSubmitChallenge,
      successCallback
    );
  } else if (challengeShopperData) {
    handleChallenge(challengeShopperData, mSubmitChallenge, successCallback);
  }
};

export const handleRedirect = (
  navigation: any,
  stack: string,
  data: any,
  onPressBack: () => void
) => {
  navigation.navigate(stack, {
    screen: 'adyen3DSLinkView',
    params: {
      issuerUrl: data?.issuerUrl,
      paRequest: data?.paRequest,
      md: data?.md,
      termUrl: data?.termUrl,
      onPressBack,
    },
  });
};

export const handleChallenge = (
  challengeData: any,
  mSubmitChallenge: typeof submitChallenge,
  successCallback: () => void
) => {
  const {
    threeDSServerTransID,
    acsTransID,
    acsReferenceNumber,
    acsSignedContent,
  } = challengeData;
  CardPaymentManager.performChallenge(
    threeDSServerTransID,
    acsTransID,
    acsReferenceNumber,
    acsSignedContent,
    (data) => {
      mSubmitChallenge(data, () => {
        successCallback();
      });
    }
  );
};

export const handleIdentifyShopper = (
  identifyShopperData: any,
  mSubmitIdentityShopper: typeof submitIdentityShopper,
  mSubmitChallenge: typeof submitChallenge,
  successCallback: () => void
) => {
  const {
    publicKey,
    directoryServerId,
    messageVersion,
    threeDSServerTransID,
    rootCertificates,
  } = identifyShopperData;
  CardPaymentManager.identifyShopper(
    publicKey,
    directoryServerId,
    rootCertificates,
    messageVersion,
    (response) => {
      const sdkEphemeralPublicKeyObj = JSON.parse(
        // @ts-ignore
        response.sdkEphemeralPublicKey
      );
      const dataObject = {
        ...response,
        threeDSServerTransID,
        sdkEphemeralPublicKey: sdkEphemeralPublicKeyObj,
      };
      mSubmitIdentityShopper(
        dataObject,
        () => {
          successCallback();
        },
        (challengeData) => {
          handleChallenge(challengeData, mSubmitChallenge, successCallback);
        }
      );
    }
  );
};
