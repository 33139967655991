import { isIOS } from './platformHelpers';

import { UIManager } from '../native/UIManager';
import { callPhoneNumber } from './phoneCallHelpers';
import { differenceInMinutes } from 'date-fns';
import { DriverBookingData, RentalData } from '../@types';
import { isRentalOngoing } from './rentalHelpers';
import { LocalizePropType } from '../enhancers';
import {
  injectedActionSheet,
  injectedDeviceEmitter,
  injectedIntercom,
  injectedLinking,
  injectedPermissionsManager,
} from '../redux/ConfigureGlobals';

export const getIsRentingUser = (
  xBrandId: string,
  rentalData: RentalData,
  bookings: Array<DriverBookingData>,
  lastFinishedRentalDate: Date
) =>
  isRentalOngoing(rentalData) ||
  bookings?.filter((booking) =>
    ['confirmed', 'offered'].includes(booking.status)
  ).length > 0 ||
  (lastFinishedRentalDate &&
    differenceInMinutes(new Date(), new Date(lastFinishedRentalDate)) < 15);

const displayMessenger = () => {
  injectedPermissionsManager?.requestNotificationPermission(() => {
    injectedIntercom.present();
  });
};

const displayIntercomByMode = (
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never',
  supportContacted: boolean,
  isAuthorizedUser: boolean
) => {
  if (intercomHandlingMode === 'always') {
    displayMessenger();
  } else if (intercomHandlingMode === 'onContactSupport') {
    if (!supportContacted) {
      injectedDeviceEmitter?.emit('setSupportContacted');
    }
    displayMessenger();
  } else if (intercomHandlingMode === 'onLogin' && isAuthorizedUser) {
    displayMessenger();
  }
};

// Customer support channels are:
// 1- Text support channels: whats app, intercom, email
// 2- Phone support
//
// Renting User = User has on going rental or Has an active booking or Its been 15min since last rental
//
// Business logic:
// If phoneSupportType == always
//   - open dialog with first option phone and second Text always
// If phoneSupportType == duringTrip
//   - If renting user, open dialog with first option phone and second Text
//   - If not renting user, open text support / no dialog
//
// Intercom handling modes:
//   - always: intercom always accessible, user always registered on new sessions
//   - onContactSupport:  intercom always accessible, register user to intercom on new sessions if he contacted support before
//   - onLogin: show/enable intercom when user has logged in only
//   - never: ...

export const helpAction = async (
  isRentingUser: boolean,
  localize: LocalizePropType,
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never',
  supportContacted: boolean,
  isAuthorizedUser: boolean,
  isVerifiedUser: boolean = false,
  isRentalStarted: boolean = false,
  brandSettings: any,
  onlyTextMode: boolean
) => {
  const phoneSupportType = brandSettings?.phoneSupportType;
  const isIntercom = brandSettings?.textSupportType === 'intercom';
  const isWhatsaap = brandSettings?.textSupportType === 'whatsapp';
  const formattedServiceWhatsapp = brandSettings?.serviceWhatsapp?.includes('+')
    ? brandSettings?.serviceWhatsapp?.replace(/\s/g, '').split('+')[1]
    : brandSettings?.serviceWhatsapp?.replace(/\s/g, '');

  const textModeAction = () => {
    if (isIntercom) {
      displayIntercomByMode(
        intercomHandlingMode,
        supportContacted,
        isAuthorizedUser
      );
    } else if (isWhatsaap) {
      injectedLinking
        .canOpenURL(`https://wa.me/${formattedServiceWhatsapp}`)
        .then((supported: any) => {
          if (supported) {
            injectedLinking.openURL(
              `https://wa.me/${formattedServiceWhatsapp}`
            );
          }
        });
    } else {
      injectedLinking
        .canOpenURL(`mailto:${brandSettings?.serviceEmail}`)
        .then((supported: any) => {
          if (supported) {
            injectedLinking.openURL(`mailto:${brandSettings?.serviceEmail}`);
          }
        });
    }
  };

  if (onlyTextMode || (phoneSupportType === 'duringTrip' && !isRentingUser)) {
    textModeAction();
  } else if (
    phoneSupportType === 'always' ||
    (phoneSupportType === 'duringTrip' && isRentingUser)
  ) {
    if (isIOS()) {
      injectedActionSheet.showActionSheetWithOptions(
        {
          title: '',
          message: localize('help.dialog.title'),
          options: [
            'cancel',
            brandSettings?.servicePhone || 'customerSupport.call',
            isIntercom
              ? localize('user.action.support.text')
              : isWhatsaap
              ? localize('support.whatsapp')
              : localize('support.email'),
          ].map((key) => localize(key as any as string)),
          cancelButtonIndex: 0,
        },
        (buttonIndex: any) => {
          if (buttonIndex === 1) {
            callPhoneNumber(
              localize(
                brandSettings?.servicePhone ||
                  localize('customerSupport.number')
              )
            );
          } else if (buttonIndex === 2) {
            textModeAction();
          }
        }
      );
    } else {
      UIManager.showListAlert(
        null,
        [
          localize(brandSettings?.servicePhone || 'customerSupport.call'),
          isIntercom
            ? localize('user.action.support.text')
            : isWhatsaap
            ? localize('support.whatsapp')
            : localize('support.email'),
        ],
        (response) => {
          if (response.buttonIndex) {
            const selectedIndex = parseInt(response.buttonIndex, 10);

            if (selectedIndex === 0) {
              callPhoneNumber(
                localize(
                  brandSettings?.servicePhone ||
                    localize('customerSupport.number')
                )
              );
            } else if (selectedIndex === 1) {
              textModeAction();
            }
          }
        }
      );
    }
  }
};
