import { isWithinInterval } from 'date-fns';
export const dateFromObjectId = (id: String) => {
  if (id) {
    const timestamp = id.toString().substring(0, 8);
    return new Date(parseInt(timestamp, 16) * 1000);
  }
  return '';
};

export const isValidDate = (dateString) => {
  if (!dateString) return false;
  const yearFromDateString = dateString.match(/(\d{4})/)?.[0];
  if (!yearFromDateString) {
    return false;
  }

  const date = new Date(dateString);
  // @ts-ignore
  if (isNaN(date)) {
    return false;
  }
  const yearFromDate = date.getFullYear().toString();
  return yearFromDateString === yearFromDate;
};

export const isDateInRange = (dateString, minDate, maxDate) => {
  const date = new Date(dateString);
  return isWithinInterval(date, { start: minDate, end: maxDate });
};

export const validateDateOfBirth = (dateOfBirth, minDate, maxDate) => {
  return (
    isValidDate(dateOfBirth) && isDateInRange(dateOfBirth, minDate, maxDate)
  );
};
