import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  selectJumioRedirectData,
  LocalizePropType,
  selectUser,
  initJumioForMobile,
} from 'getaway-data-layer';
import { isDashboardScreen, navigateTo } from '../../../../../utils';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';

type Props = {
  localize: LocalizePropType;
};

const JumioSdk = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const jumioRedirectData = useSelector(selectJumioRedirectData);
  const user = useSelector(selectUser);

  useEffect(() => {
    const jumioSDK = document.createElement('jumio-sdk');
    jumioSDK.setAttribute('dc', 'eu');

    const initJumioSDK = (sdk) => {
      jumioSDK.setAttribute(
        'base-url',
        'https://web-sdk.emea-1.jumio.link/websdk/v4/api'
      );
      jumioSDK.setAttribute('locale', 'de');
      jumioSDK.setAttribute('token', sdk.token || '');

      document.getElementById('jumio-container').appendChild(jumioSDK);
      window.location.hash = 'step=cross-device';
    };

    dispatch(
      initJumioForMobile('DRIVING_LICENSE', (res) => {
        initJumioSDK(res.sdk!);

        const jumioSdkElement = document.querySelector('jumio-sdk');

        console.log({
          // @ts-ignore
          accountId: jumioSdkElement?.accountId,
          // @ts-ignore
          workflowId: jumioSdkElement?.workflowId,
          // @ts-ignore
          workflowExecutionId: jumioSdkElement?.workflowExecutionId,
        });
      })
    );

    return () => {
      const container = document.getElementById('jumio-container');
      if (container) {
        container.innerHTML = '';
      }
    };
  }, [jumioRedirectData]);

  const onClose = () => {
    navigateTo(history, '/settings');
  };

  return (
    <div className="screen-container" style={{ width: '100%', height: '100%' }}>
      <NavigationBar
        title={localize('jumio.sdk')}
        description=" "
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <template></template>
      <div id="jumio-container" style={{ width: '100%', height: '100%' }}></div>
    </div>
  );
};

export default withTextLocalizer(JumioSdk);
