import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CarStatusData, State } from '../@types';

export type Props = {
  driverCarStatus: CarStatusData | null | undefined;
};

const withCarStatus: any = compose(
  connect(
    ({
      userData: {
        rental: { driverCarStatus },
      },
    }: State) => ({
      driverCarStatus,
    }),
    {}
  )
);
export default withCarStatus;
