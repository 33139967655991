import { DriverCarData } from '../../Types.api';
import { Location } from '../../Types';
export type MapCars = Record<string, DriverCarData>;
export type CarsSearchState = {
  readonly mapCenterLocation: Location;
  readonly radius: number;
  readonly mapZoom: number;
  readonly from: string | null | undefined;
  readonly backBy: string | null | undefined;
  readonly mileage: any;
  readonly availableCars: MapCars;
  readonly unavailableCars: MapCars;
  readonly searchCars: Array<DriverCarData>;
  readonly circleId: string | null | undefined;
  readonly categories: any;
};
enum CarsSearchTypes {
  SET_AVAILABLE_CARS = 'carsSearch/SET_AVAILABLE_CARS',
  CLEAR_AVAILABLE_CARS = 'carsSearch/CLEAR_AVAILABLE_CARS',
  SET_UNAVAILABLE_CARS = 'carsSearch/SET_UNAVAILABLE_CARS',
  CLEAR_UNAVAILABLE_CARS = 'carsSearch/CLEAR_UNAVAILABLE_CARS',
  SET_SEARCH_CARS = 'carsSearch/SET_SEARCH_CARS',
  SET_MAP_CENTER_LOCATION = 'carsSearch/SET_MAP_CENTER_LOCATION',
  SET_MAP_ZOOM = 'carsSearch/SET_MAP_ZOOM',
  SET_RADIUS = 'carsSearch/SET_RADIUS',
  SET_FROM = 'carsSearch/SET_FROM',
  CLEAR_FROM = 'carsSearch/CLEAR_FROM',
  SET_BACK_BY = 'carsSearch/SET_BACK_BY',
  CLEAR_BACK_BY = 'carsSearch/CLEAR_BACK_BY',
  SET_MAP_CIRCLE_ID_SEARCH = 'carsSearch/SET_MAP_CIRCLE_ID_SEARCH',
  CLEAR_MAP_CIRCLE_ID_SEARCH = 'carsSearch/CLEAR_MAP_CIRCLE_ID_SEARCH',
  SET_CATEGORIES = 'carsSearch/SET_CATEGORIES',
}
export default CarsSearchTypes;
export type SetAvailableCars = {
  type: CarsSearchTypes.SET_AVAILABLE_CARS;
  payload: {
    availableCars: MapCars;
  };
};
export type SetCategories = {
  type: CarsSearchTypes.SET_CATEGORIES;
  payload: {
    categories: any;
  };
};
export type SetUnavailableCars = {
  type: CarsSearchTypes.SET_UNAVAILABLE_CARS;
  payload: {
    unavailableCars: MapCars;
  };
};
export type ClearAvailableCars = {
  type: CarsSearchTypes.CLEAR_AVAILABLE_CARS;
};
export type ClearUnavailableCars = {
  type: CarsSearchTypes.CLEAR_UNAVAILABLE_CARS;
};
export type SetSearchCars = {
  type: CarsSearchTypes.SET_SEARCH_CARS;
  payload: {
    searchCars: Array<DriverCarData>;
  };
};
export type SetMapCenterLocation = {
  type: CarsSearchTypes.SET_MAP_CENTER_LOCATION;
  payload: {
    mapCenterLocation: Location;
  };
};
export type SetMapZoom = {
  type: CarsSearchTypes.SET_MAP_ZOOM;
  payload: {
    zoom: number;
  };
};
export type SetRadius = {
  type: CarsSearchTypes.SET_RADIUS;
  payload: {
    radius: number;
  };
};
export type SetFrom = {
  type: CarsSearchTypes.SET_FROM;
  payload: {
    from: string | null | undefined;
  };
};
export type ClearFrom = {
  type: CarsSearchTypes.CLEAR_FROM;
};
export type SetBackBy = {
  type: CarsSearchTypes.SET_BACK_BY;
  payload: {
    backBy: string;
  };
};

export type ClearBackBy = {
  type: CarsSearchTypes.CLEAR_BACK_BY;
};

export type SetMapCircleIdSearch = {
  type: CarsSearchTypes.SET_MAP_CIRCLE_ID_SEARCH;
  payload: {
    circleId: string;
  };
};

export type ClearMapCircleIdSearch = {
  type: CarsSearchTypes.CLEAR_MAP_CIRCLE_ID_SEARCH;
};

export type CarsSearchAction =
  | SetSearchCars
  | ClearAvailableCars
  | SetCategories
  | ClearUnavailableCars
  | SetAvailableCars
  | SetUnavailableCars
  | SetMapCenterLocation
  | SetRadius
  | SetFrom
  | ClearFrom
  | SetMapZoom
  | SetBackBy
  | ClearBackBy
  | SetMapCircleIdSearch
  | ClearMapCircleIdSearch;
