import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/UserTypes';
import { UserAction, UserState } from '../../../../@types';
export default (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case TYPES.SET_USER: {
      return {
        ...state,
        user: action.payload.user!,
        email: action.payload.user?.email!,
      };
    }

    case TYPES.SET_EMAIL: {
      return { ...state, email: action.payload.email };
    }

    case TYPES.SET_SIGN_UP_CIRCLE: {
      return { ...state, signUpCircle: action.payload.circleCode };
    }

    case TYPES.SET_TARIFFS: {
      return { ...state, tariffs: action.payload.tariffs };
    }

    case TYPES.SET_ACTIVE_PAYMENT_DATA: {
      return { ...state, activePaymentData: action.payload.activePaymentData };
    }

    case TYPES.SET_CHECKOUT_PAYMENT_METHODS: {
      return {
        ...state,
        checkoutPaymentMethods: action.payload.data,
      };
    }

    case TYPES.SET_PAYMENT_PUBLIC_KEY: {
      return { ...state, paymentPublicKey: action.payload.paymentPublicKey };
    }

    case TYPES.SET_JUMIO_REDIRECT_DATA: {
      return { ...state, jumioRedirectData: action.payload.jumioRedirectData };
    }

    case TYPES.SET_RECOVER_PAYMENT_AUTH: {
      return {
        ...state,
        recoverPaymentAuth: action.payload.recoverPaymentAuth,
      };
    }

    case TYPES.SET_AUTH: {
      return { ...state, basicAuth: action.payload.basicAuth };
    }

    case TYPES.CLEAR_AUTH: {
      return {
        ...initialState,
        email: state.email,
        paymentPublicKey: state.paymentPublicKey,
      };
    }

    case TYPES.SET_TRANSACTIONS: {
      return { ...state, transactions: action.payload.transactions };
    }

    case TYPES.APPEND_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          ...action.payload.transactions,
          transactions: [
            ...state.transactions.transactions, // This eliminates any duplicate transactions
            ...action.payload.transactions.transactions.filter(
              (actionItem: any) =>
                !state.transactions.transactions.filter(
                  (stateItem: any) => stateItem.id === actionItem.id
                ).length
            ),
          ],
        },
      };
    }

    case TYPES.SET_RENTAL_HISTORY: {
      return { ...state, rentalHistory: action.payload.rentalHistory };
    }

    case TYPES.APPEND_RENTAL_HISTORY: {
      return {
        ...state,
        rentalHistory: {
          ...state.rentalHistory,
          ...action.payload.rentalHistory,
          rentals: [
            ...state.rentalHistory.rentals, // This eliminates any duplicate transactions
            ...action.payload.rentalHistory.rentals.filter(
              (actionItem: any) =>
                !state.rentalHistory.rentals.filter(
                  (stateItem: any) => stateItem.tripId === actionItem.tripId
                ).length
            ),
          ],
        },
      };
    }
    // @ts-ignore
    case PURGE:
      return { ...initialState, email: state.email };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
