import { isAfter } from 'date-fns';

export const isRentalCard = (cardContentType: any): boolean =>
  [
    'reservedCar',
    'openedCar',
    'drivingCar',
    'parkingCar',
    'reservedOfflineService',
    'startedOfflineService',
    'mymTripStart',
    'mymTripStartLocation',
    'mymTripDestination',
    'mymTripOffer',
    'mymWaitingCar',
    'mymArrivedCar',
    'mymOpenedCar',
    'mymDrivingCar',
    'mymTransitCar',
    'mymExistCar',
  ].includes(cardContentType);

export const isRentalExpired = (rental: any | null | undefined): boolean => {
  if (rental && rental.access && rental.access.expires) {
    return isAfter(new Date(), new Date(rental.access.expires));
  }

  return false;
};

export const isRentalOngoing = (rental: any | null | undefined): boolean => {
  if (rental) {
    return (
      [
        'reserved',
        'opened',
        'driving',
        'parking',
        'offered',
        'readyToGo',
      ].includes(rental.carMode) && !isRentalExpired(rental)
    );
  }

  return false;
};

export const isRentalStarted = (rentalData: any | null | undefined): boolean =>
  ['opened', 'driving', 'parking'].includes(rentalData?.carMode);

export const isRentalMapContent = (
  mapContent: any | null | undefined
): boolean =>
  [
    'reservedCar',
    'openedCar',
    'drivingCar',
    'parkingCar',
    'rentalDriverCar',
    'rentalReturnLocation',
    'rentalNearestGasStation',
  ].includes(mapContent);

export const mapAnnotationFromCarMode = (
  carMode: any
): any | null | undefined => {
  switch (carMode) {
    case 'reserved':
      return 'reservedCar';

    case 'opened':
      return 'openedCar';

    case 'driving':
      return 'drivingCar';

    case 'parking':
      return 'parkingCar';

    case 'offered':
      return 'offeredCar';

    default:
      return null;
  }
};

export const mapCardFromCarMode = (
  carMode: any,
  isOfflineService: boolean = false
): any => {
  switch (carMode) {
    case 'reserved':
      return isOfflineService ? 'reservedOfflineService' : 'reservedCar';

    case 'opened':
      return isOfflineService ? 'startedOfflineService' : 'openedCar';

    case 'driving':
      return 'drivingCar';

    case 'parking':
      return 'parkingCar';

    case 'offered':
      return 'mymTripOffer';

    default:
      return 'none';
  }
};

export const mymMapCardFromCarMode = (
  rentalData: any,
  prevCardType: string
): any => {
  const isTripStarted = !!rentalData.readyToGoSince;
  const atPickupPoint =
    rentalData.pickupStopName === rentalData.currentStopName;
  const atDropPoint = rentalData.releaseStopName === rentalData.currentStopName;
  console.log('*******************************');
  console.log('carMode', rentalData.carMode);
  console.log('pickupStopName', rentalData.pickupStopName);
  console.log('releaseStopName', rentalData.releaseStopName);
  console.log('currentStopName', rentalData.currentStopName);
  switch (rentalData.carMode) {
    case 'offered':
      return 'mymTripOffer';

    case 'reserved':
      return atPickupPoint ? 'mymArrivedCar' : 'mymWaitingCar';

    case 'opened':
      return 'mymOpenedCar';

    case 'readyToGo':
      return 'mymDrivingCar';

    case 'driving':
      return isTripStarted ? 'mymDrivingCar' : 'mymWaitingCar';

    case 'parking':
      return isTripStarted
        ? atDropPoint
          ? 'mymExistCar'
          : 'mymTransitCar'
        : 'mymArrivedCar';

    default:
      return prevCardType;
  }
};
