import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import { LocalizePropType } from '../enhancers';

export const getSupportedCountriesCodesForCredit = (): Array<string> => [
  'DE',
  'AT',
];

export const getCountryList = (
  localize: LocalizePropType,
  allowedUserAddressCountries: Array<string> = [],
  isCreditWorthiness: boolean = false
) => {
  const userAddressCountries = allowedUserAddressCountries?.map((country) =>
    country?.toUpperCase()
  );
  const countriesCodes = isCreditWorthiness
    ? getSupportedCountriesCodesForCredit().filter((item) =>
        userAddressCountries.includes(item)
      )
    : userAddressCountries;

  return countriesCodes
    .map((code) => ({ label: localize(code), value: code }))
    .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};

export const getCountryListWeb = (
  localize: LocalizePropType,
  allowedUserAddressCountries: Array<string> = [],
  isCreditWorthiness: boolean = false
) => {
  const userAddressCountries = allowedUserAddressCountries?.map((country) =>
    country?.toUpperCase()
  );
  const countriesCodes = isCreditWorthiness
    ? getSupportedCountriesCodesForCredit().filter((item) =>
        userAddressCountries.includes(item)
      )
    : userAddressCountries;

  return countriesCodes
    .map((code) => ({ text: localize(code), value: code }))
    .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
};

export const postalCodePattern = (country: string, value: any): any =>
  postcodeValidatorExistsForCountry(country)
    ? postcodeValidator(value, country)
    : /.*/;
