import React, { useEffect, useRef } from 'react';

type Props = {
  threeDSData: string;
  threeDSMethodURL: string;
};

const IdentifyShopperView = ({ threeDSData, threeDSMethodURL }: Props) => {
  const formRef = useRef<any>(null);

  useEffect(() => {
    formRef?.current?.submit();
  }, []);

  return (
    <form
      ref={formRef}
      method="POST"
      action={threeDSMethodURL}
      id="3dform"
      target="identify-iframe"
    >
      <input type="hidden" name="threeDSMethodData" value={threeDSData} />
    </form>
  );
};

export default IdentifyShopperView;
