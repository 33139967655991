import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/CommonTypes';
import { CommonAction, CommonState } from '../../../../@types';

export default (
  state: CommonState = initialState,
  action: CommonAction
): CommonState => {
  switch (action.type) {
    case TYPES.SET_OPENED_MODAL: {
      return {
        ...state,
        openedModal: action.payload.modalName,
      };
    }

    case TYPES.CLEAR_OPENED_MODAL: {
      return { ...state, openedModal: null };
    }

    case TYPES.SET_DIALOG: {
      return { ...state, dialog: action.payload.dialog };
    }

    case TYPES.CLEAR_DIALOG: {
      return { ...state, dialog: null };
    }

    case TYPES.SET_APP_RATED: {
      return { ...state, appRated: action.payload.appRated };
    }

    case TYPES.SET_FIRST_STEPS: {
      return { ...state, firstSteps: action.payload.firstSteps };
    }

    case TYPES.SET_SUPPORT_CONTACTED: {
      return { ...state, supportContacted: action.payload.supportContacted };
    }

    case TYPES.SET_IS_INTERCOM_USER_REGISTERED: {
      return {
        ...state,
        isIntercomUserRegistered: action.payload.isIntercomUserRegistered,
      };
    }

    case TYPES.SET_BRAND_SETTINGS: {
      return { ...state, brandSettings: action.payload.brandSettings };
    }
    // @ts-ignore
    case PURGE:
      return {
        ...initialState,
        supportContacted: state.supportContacted,
        brandSettings: state.brandSettings,
      };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
