import React from 'react';
import './AccountTabView.less';
import ListItem from '../../../../components/ListItem/ListItem';
import {
  formatIDStatus,
  formatLicenseStatus,
  GA_TITLES,
  getEmailVerificationStatus,
  isAddressVerificationRequired,
  isDLVerificationRequired,
  isEmailVerificationRequired,
  isIDVerificationRequired,
  isPaymentRequired,
  isPhoneVerificationRequired,
  isTariffEnabled,
} from '../../../../../utils';
import { logo } from '../../../../../assets/brandImages';
import config from '../../../../../env.config';
import {
  LocalizePropType,
  UserDataOutput,
  getBrandName,
  isKYCApp,
  formatMoneyEuro,
  formattedAddress,
  isServicesTabEnabled,
  maskSepa,
  isLocalizeAdded,
  isMVV,
  getVersionHeaderColor,
} from 'getaway-data-layer';
import FileUploadItem from '../../../../components/FileUpload/FileUploadItem';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import Divider from '@mui/material/Divider';

type Props = {
  user: UserDataOutput;
  localize: LocalizePropType;
  phoneNumber: string;
  phoneStatus: string;
  onPressPhoneIncomplete: () => void;
  onPressPhoneCompleted: () => void;
  address: any;
  addressStatus: string;
  onPressAddress: () => void;
  onPressAddressInProgress: () => void;
  onPressAddressCompleted: () => void;
  license: any;
  licenseStatus: string;
  idStatus: string;
  onPressDrivingLicense: () => void;
  onPressDLInProgress: () => void;
  onPressDLCompleted: () => void;
  paymentMethods: any;
  onOpenPaymentProfile: (payment: any) => void;
  onPressEmail: () => void;
  onPressPassword: () => void;
  onTerminateUser: () => void;
  onLogout: () => void;
  onAddPayment: () => void;
  onClose: () => void;
  onPressCircle: (circle: any) => void;
  onPressJoinCircle: () => void;
  readOnlyMode: boolean;
  onPressID: () => void;
  onPressIDInProgress: () => void;
  onPressIDCompleted: () => void;
  onboardingOnly?: boolean;
  onDeletePayment: (payment: any) => void;
  onAddPaymentProfile: () => void;
  onAddDepositPayment: () => void;
  onDepositBalanceClick: () => void;
  onPressGDPR: () => void;
  blocked: boolean;
  onPressTariff: () => void;
  tariffStatus: string;
  onPressTariffCompleted: () => void;
  emailStatus: string;
  onPressConsents: () => void;
  onClickVersionHeader: () => void;
};

const AccountTabView = ({
  user,
  localize,
  phoneNumber,
  phoneStatus,
  onPressPhoneIncomplete,
  onPressPhoneCompleted,
  addressStatus,
  address,
  onPressAddress,
  onPressAddressInProgress,
  onPressAddressCompleted,
  license,
  blocked,
  licenseStatus,
  idStatus,
  onPressDrivingLicense,
  onPressDLInProgress,
  onPressDLCompleted,
  paymentMethods,
  onOpenPaymentProfile,
  onDeletePayment,
  onPressEmail,
  onPressPassword,
  onTerminateUser,
  onClose,
  onLogout,
  onAddPayment,
  onPressCircle,
  onPressJoinCircle,
  readOnlyMode,
  onPressID,
  onPressIDInProgress,
  onPressIDCompleted,
  onboardingOnly,
  onAddPaymentProfile,
  onAddDepositPayment,
  onDepositBalanceClick,
  onPressGDPR,
  onPressTariff,
  onPressTariffCompleted,
  tariffStatus,
  emailStatus,
  onPressConsents,
  onClickVersionHeader,
}: Props) => {
  const disableAddPayments =
    (isKYCApp() && user?.paymentMethods?.length > 0) ||
    readOnlyMode ||
    (isKYCApp() && user?.deposit?.toPayForSepaUnlock);
  const userPayments = paymentMethods?.filter((payment) =>
    user?.deposit?.toPayForSepaUnlock ? payment.status !== 'pending' : true
  );
  const isLowSchufaCredit =
    !user?.blocked &&
    user?.paymentMethods?.length > 0 &&
    !user?.supportedPaymentTypes?.includes('sepa') &&
    !user?.deposit?.balance &&
    !user?.deposit?.toPayForSepaUnlock &&
    !user?.deposit?.receiptNo;

  const isOrg = user?.userType === 'org';
  const tariffDisplayName = isLocalizeAdded(
    localize,
    `settings.tariff.${user?.tariff?.id}.name`
  )
    ? `"${localize(`settings.tariff.${user?.tariff?.id}.name`)}"`
    : `"${user?.tariff?.name}"`;

  const readOnlyDialog = (
    <div className="account-warning">
      <div className="account-warning-title">
        {localize(
          isOrg ? 'profile.completed.org.title' : 'profile.completed.title'
        )}
      </div>
      <div className="account-warning-msg">
        {localize(
          isOrg ? 'profile.completed.org.msg' : 'profile.completed.msg'
        )}
      </div>
      {!isServicesTabEnabled() && (
        <button
          className="account-warning-btn"
          onClick={() => {
            window.open(localize('profile.completed.url', '_blank'));
          }}
        >
          {localize('profile.completed.button')}
        </button>
      )}
    </div>
  );

  const orgSection = (
    <>
      <div className="account-header">{localize('settings.org.data')}</div>
      <div className="account-org-wrapper">
        <img
          src="/images/businessPayment.png"
          style={{
            width: 31,
            height: 20,
            marginRight: 25,
            marginTop: 5,
            objectFit: 'contain',
          }}
        />
        <div style={{ flex: 1 }}>
          <ListItem
            containerStyles={{ marginTop: 0, paddingTop: 0 }}
            title={`${user?.companyName} (${user?.legalType})`}
            readOnly
            disabled={blocked}
          />
          <ListItem
            title={`${user?.registerNr}, ${user?.registerLocation}`}
            readOnly={readOnlyMode}
            disabled={blocked}
            showSeparator={addressStatus === 'completed'}
          />
          {addressStatus === 'completed' && (
            <ListItem
              title={
                addressStatus === 'completed' || addressStatus === 'inProgress'
                  ? formattedAddress(address, localize)
                  : localize('settings.login.data.user.add.address.text')
              }
              readOnly={readOnlyMode}
              disabled={blocked}
              showSeparator={false}
            />
          )}
        </div>
      </div>
    </>
  );
  const personalSection = (
    <>
      <div className="account-header">
        {localize(
          isOrg ? 'settings.org.personal.data' : 'settings.personal.data'
        )}
      </div>
      <ListItem
        title={`${user?.firstName} ${user?.lastName}`}
        leftItem={
          <img
            style={{
              width: 20,
              height: 20,
              marginRight: 35,
              objectFit: 'contain',
            }}
            src="/images/user.png"
            alt="user"
          />
        }
        readOnly
        disabled={blocked}
      />
      {isOrg && (
        <ListItem
          title={
            user?.email?.length > 24
              ? `${user?.email?.slice(0, 27)}...`
              : user?.email || ''
          }
          leftIcon="email"
          rightIcon={
            !isEmailVerificationRequired(user)
              ? 'arrowRight'
              : getEmailVerificationStatus(user) === 'completed'
              ? 'arrowRight'
              : 'iconIncomplete'
          }
          rightIconSize={
            !isEmailVerificationRequired(user)
              ? 14
              : getEmailVerificationStatus(user) === 'completed'
              ? 14
              : 10
          }
          brandTextColor={
            isEmailVerificationRequired(user)
              ? ['open', 'inProgress'].includes(emailStatus)
              : false
          }
          onClick={onPressEmail}
          readOnly={readOnlyMode}
          disabled={blocked}
        />
      )}
      {isPhoneVerificationRequired(user) && (
        <ListItem
          title={
            phoneStatus === 'completed'
              ? phoneNumber
              : localize('settings.login.data.user.add.phone.text')
          }
          leftIcon="phone"
          rightIcon={
            phoneStatus === 'completed' ? 'arrowRight' : 'iconIncomplete'
          }
          rightIconSize={phoneStatus === 'completed' ? 14 : 10}
          onClick={
            phoneStatus === 'completed'
              ? onPressPhoneCompleted
              : onPressPhoneIncomplete
          }
          brandTextColor={['open', 'inProgress'].includes(phoneStatus)}
          readOnly={readOnlyMode}
          disabled={blocked}
        />
      )}
      {isAddressVerificationRequired(user) &&
        !isOrg &&
        !(isKYCApp() && addressStatus === 'open') && (
          <ListItem
            title={
              addressStatus === 'completed' || addressStatus === 'inProgress'
                ? formattedAddress(address, localize)
                : localize('settings.login.data.user.add.address.text')
            }
            leftIcon="home"
            rightIcon={
              addressStatus === 'completed'
                ? 'arrowRight'
                : addressStatus === 'inProgress'
                ? 'inProgress'
                : 'iconIncomplete'
            }
            rightIconSize={addressStatus === 'completed' ? 14 : 10}
            onClick={
              addressStatus === 'open'
                ? onPressAddress
                : addressStatus === 'inProgress'
                ? onPressAddressInProgress
                : onPressAddressCompleted
            }
            brandTextColor={addressStatus === 'open'}
            greyTextColor={addressStatus === 'inProgress'}
            readOnly={readOnlyMode}
            disabled={blocked}
          />
        )}
      {isIDVerificationRequired(user) && (
        <ListItem
          title={formatIDStatus(user?.identityDocument, localize)}
          leftIcon="license"
          rightIcon={
            idStatus === 'completed'
              ? 'arrowRight'
              : idStatus === 'inProgress'
              ? 'inProgress'
              : 'iconIncomplete'
          }
          rightIconSize={idStatus === 'completed' ? 14 : 10}
          onClick={
            ['open', 'deleted'].includes(idStatus) ||
            !user?.identityDocument?.status
              ? onPressID
              : idStatus === 'inProgress'
              ? onPressIDInProgress
              : onPressIDCompleted
          }
          brandTextColor={['open', 'deleted'].includes(idStatus)}
          greyTextColor={idStatus === 'inProgress'}
          readOnly={readOnlyMode}
          disabled={blocked || isLowSchufaCredit}
        />
      )}
      {isDLVerificationRequired(user) && (
        <ListItem
          title={formatLicenseStatus(license, licenseStatus, localize)}
          leftIcon="license"
          rightIcon={
            licenseStatus === 'completed'
              ? 'arrowRight'
              : licenseStatus === 'inProgress'
              ? 'inProgress'
              : 'iconIncomplete'
          }
          rightIconSize={licenseStatus === 'completed' ? 14 : 10}
          onClick={
            ['open', 'deleted'].includes(licenseStatus)
              ? onPressDrivingLicense
              : licenseStatus === 'inProgress'
              ? onPressDLInProgress
              : onPressDLCompleted
          }
          brandTextColor={['open', 'deleted'].includes(licenseStatus)}
          greyTextColor={licenseStatus === 'inProgress'}
          readOnly={readOnlyMode}
          disabled={blocked || isLowSchufaCredit}
        />
      )}
    </>
  );

  const payemntSection = (
    <>
      <div className="account-header">{localize('settings.payment.data')}</div>
      {userPayments &&
        userPayments.map((payment: any) => (
          <div key={payment.id}>
            {payment?.methodType === 'paypal' &&
              payment?.status === 'verified' && (
                <ListItem
                  title={
                    payment?.paypal?.name?.length > 27
                      ? `${payment?.paypal?.name?.slice(0, 27)}...`
                      : payment?.paypal?.name ||
                        localize(`settings.payment.paypal.title`)
                  }
                  leftIcon="pal"
                  leftIconSize={30}
                  rightIcon={
                    payment?.status == 'pending' ? 'inProgress' : 'trash'
                  }
                  rightIconSize={16}
                  onClick={() => onDeletePayment(payment)}
                  readOnly={readOnlyMode}
                />
              )}
            {payment.payIn && (
              <ListItem
                title={localize('settings.payment.payIn.title')}
                leftItem={
                  <img
                    style={{
                      width: 31,
                      height: 20,
                      marginRight: 27,
                      marginLeft: -2,
                      objectFit: 'contain',
                    }}
                    src="/images/paymentPayIn.png"
                    alt="payin"
                  />
                }
                rightIcon={
                  payment?.status == 'pending' ? 'inProgress' : 'trash'
                }
                rightIconSize={16}
                onClick={() => onDeletePayment(payment)}
                readOnly={readOnlyMode}
              />
            )}
            {payment.sepa && !isLowSchufaCredit && (
              <ListItem
                title={maskSepa(payment.sepa.iban)}
                leftIcon="paymentSepa"
                leftIconSize={27}
                rightIcon={
                  payment?.status == 'pending' ? 'inProgress' : 'trash'
                }
                rightIconSize={16}
                onClick={() => onDeletePayment(payment)}
                readOnly={readOnlyMode}
                showSeparator={user?.deposit?.balance === 0}
              />
            )}
            {payment.card && payment.status === 'verified' && (
              <ListItem
                title={`****${payment?.card?.number?.substr(-4)}`}
                leftIcon={
                  payment.card.variant === 'MasterCard'
                    ? 'paymentMastercard'
                    : 'paymentVisa'
                }
                leftIconSize={27}
                rightIcon={
                  payment?.status == 'pending' ? 'inProgress' : 'trash'
                }
                rightIconSize={16}
                onClick={() => onDeletePayment(payment)}
                readOnly={readOnlyMode}
              />
            )}
          </div>
        ))}
      {user?.deposit?.balance > 0 && (
        <>
          <ListItem
            containerStyles={{ paddingTop: 0, marginTop: 0 }}
            title={localize('deposit.balance.title')}
            leftItem={<div style={{ width: 56 }} />}
            value={
              <div className="deposit-balance">
                {formatMoneyEuro(user?.deposit?.balance)}
              </div>
            }
            rightIcon="arrowRight"
            rightIconSize={16}
            onClick={() => onDepositBalanceClick()}
            disabled={blocked}
          />
        </>
      )}
      {!disableAddPayments && (
        <ListItem
          title={localize('settings.payment.data.add')}
          brandTextColor
          leftIcon="plus"
          leftIconSize={14}
          rightIcon={
            paymentMethods?.length &&
            paymentMethods.find((payment: any) => payment.status === 'verified')
              ? 'arrowRight'
              : 'iconIncomplete'
          }
          rightIconSize={
            paymentMethods?.length &&
            paymentMethods.find((payment: any) => payment.status === 'verified')
              ? 14
              : 10
          }
          onClick={onAddPayment}
          disabled={blocked}
        />
      )}
      {user?.deposit?.toPayForSepaUnlock !== undefined && !blocked && (
        <>
          <div className="account-deposit-warning">
            <ListItem
              containerStyles={{ margin: 0 }}
              title={maskSepa(user.paymentMethods?.[0]?.sepa?.iban)}
              leftIcon="paymentSepa"
              leftIconSize={27}
              rightIcon="inProgress"
              rightIconSize={16}
              readOnly={readOnlyMode}
            />

            <div className="account-deposit-warning-msg">
              {localize('account-deposit.warning.desc')}
            </div>
            <div className="account-deposit-warning-container">
              <button
                className="account-deposit-warning-btn"
                onClick={onAddDepositPayment}
              >
                {localize('account-deposit.warning.btn')}
              </button>
              <div className="account-deposit-warning-value">
                {formatMoneyEuro(user?.deposit?.toPayForSepaUnlock)}
              </div>
            </div>
          </div>
        </>
      )}
      {isLowSchufaCredit && (
        <div className="account-deposit-warning">
          <ListItem
            containerStyles={{ margin: 0 }}
            title={maskSepa(user.paymentMethods?.[0]?.sepa?.iban)}
            leftIcon="paymentSepa"
            leftIconSize={27}
            rightIcon="inProgress"
            rightIconSize={16}
            readOnly={readOnlyMode}
          />

          <div className="account-deposit-warning-msg">
            {localize('account.low.schufa.desc')}
          </div>
        </div>
      )}
    </>
  );

  const payemntProfilesSection = (
    <>
      <div className="account-header">{localize('settings.payment.data')}</div>
      {userPayments &&
        userPayments.map((payment: any) => (
          <div key={payment.id}>
            {payment?.methodType === 'paypal' &&
              payment?.status === 'verified' && (
                <ListItem
                  title={localize(
                    payment?.billingInfo?.billingProfile
                      ? `profile.payment.type.${payment?.billingInfo?.billingProfile}`
                      : 'profile.payment.type.personal'
                  )}
                  descriptionComponent={
                    <div className="account-payment-description">
                      <BrandIcon
                        style={{
                          width: 33,
                          height: 25,
                          marginRight: 10,
                        }}
                        name="pal"
                      />
                      <div>
                        {payment?.paypal?.name?.length > 15
                          ? `${payment?.paypal?.name?.slice(0, 15)}...`
                          : payment?.paypal?.name ||
                            localize(`settings.payment.paypal.title`)}
                      </div>
                    </div>
                  }
                  leftItem={
                    <img
                      style={{
                        width: 31,
                        height: 20,
                        marginRight: 35,
                        objectFit: 'contain',
                      }}
                      src={
                        payment?.billingInfo?.billingProfile
                          ? payment?.billingInfo?.billingProfile === 'personal'
                            ? '/images/privatePayment.png'
                            : '/images/businessPayment.png'
                          : '/images/privatePayment.png'
                      }
                      alt="payin"
                    />
                  }
                  rightIcon={
                    payment?.status == 'pending' ? 'inProgress' : 'arrowRight'
                  }
                  rightIconSize={16}
                  showStandard={
                    !isOrg &&
                    (paymentMethods?.length === 1 ||
                      (payment?.defaultOne !== undefined &&
                        payment?.defaultOne))
                  }
                  onClick={() => onOpenPaymentProfile(payment)}
                  readOnly={readOnlyMode}
                />
              )}
            {payment.payIn && (
              <ListItem
                title={localize(
                  payment?.billingInfo?.billingProfile
                    ? `profile.payment.type.${payment?.billingInfo?.billingProfile}`
                    : 'profile.payment.type.personal'
                )}
                descriptionComponent={
                  <div className="account-payment-description">
                    <div>{localize('settings.payment.payIn.title')}</div>
                  </div>
                }
                leftItem={
                  <img
                    style={{
                      width: 31,
                      height: 20,
                      marginRight: 35,
                      objectFit: 'contain',
                    }}
                    src={
                      payment?.billingInfo?.billingProfile
                        ? payment?.billingInfo?.billingProfile === 'personal'
                          ? '/images/privatePayment.png'
                          : '/images/businessPayment.png'
                        : '/images/privatePayment.png'
                    }
                    alt="payin"
                  />
                }
                rightIcon={
                  payment?.status == 'pending' ? 'inProgress' : 'arrowRight'
                }
                rightIconSize={16}
                showStandard={
                  paymentMethods?.length === 1 ||
                  (payment?.defaultOne !== undefined && payment?.defaultOne)
                }
                onClick={() => onOpenPaymentProfile(payment)}
                readOnly={readOnlyMode}
              />
            )}
            {payment.sepa && !isLowSchufaCredit && (
              <ListItem
                title={localize(
                  isOrg
                    ? 'profile.payment.type.business'
                    : payment?.billingInfo?.billingProfile
                    ? `profile.payment.type.${payment?.billingInfo?.billingProfile}`
                    : 'profile.payment.type.personal'
                )}
                descriptionComponent={
                  <div className="account-payment-description">
                    <BrandIcon
                      style={{
                        width: 33,
                        height: 25,
                        marginRight: 10,
                      }}
                      name="paymentSepa"
                    />
                    <div>{maskSepa(payment.sepa.iban)}</div>
                  </div>
                }
                leftItem={
                  <img
                    style={{
                      width: 31,
                      height: 20,
                      marginRight: 35,
                      objectFit: 'contain',
                    }}
                    src={
                      isOrg
                        ? '/images/businessPayment.png'
                        : payment?.billingInfo?.billingProfile
                        ? payment?.billingInfo?.billingProfile === 'personal'
                          ? '/images/privatePayment.png'
                          : '/images/businessPayment.png'
                        : '/images/privatePayment.png'
                    }
                    alt="payin"
                  />
                }
                rightIcon={
                  isOrg
                    ? 'trash'
                    : payment?.status == 'pending'
                    ? 'inProgress'
                    : 'arrowRight'
                }
                rightIconSize={16}
                showStandard={
                  !isOrg &&
                  (paymentMethods?.length === 1 ||
                    (payment?.defaultOne !== undefined && payment?.defaultOne))
                }
                onClick={
                  isOrg
                    ? () => onDeletePayment(payment)
                    : () => onOpenPaymentProfile(payment)
                }
                readOnly={readOnlyMode}
              />
            )}
            {payment.card && payment.status === 'verified' && (
              <ListItem
                title={localize(
                  payment?.billingInfo?.billingProfile
                    ? `profile.payment.type.${payment?.billingInfo?.billingProfile}`
                    : 'profile.payment.type.personal'
                )}
                descriptionComponent={
                  <div className="account-payment-description">
                    <BrandIcon
                      style={{
                        width: 33,
                        height: 25,
                        marginRight: 10,
                      }}
                      name={
                        payment.card.variant === 'MasterCard'
                          ? 'paymentMastercard'
                          : 'paymentVisa'
                      }
                    />

                    <div>{`****${payment?.card?.number?.substr(-4)}`}</div>
                  </div>
                }
                leftItem={
                  <img
                    style={{
                      width: 31,
                      height: 20,
                      marginRight: 35,
                      objectFit: 'contain',
                    }}
                    src={
                      payment?.billingInfo?.billingProfile
                        ? payment?.billingInfo?.billingProfile === 'personal'
                          ? '/images/privatePayment.png'
                          : '/images/businessPayment.png'
                        : '/images/privatePayment.png'
                    }
                    alt="payin"
                  />
                }
                rightIcon={
                  payment?.status == 'pending' ? 'inProgress' : 'arrowRight'
                }
                rightIconSize={16}
                showStandard={
                  paymentMethods?.length === 1 ||
                  (payment?.defaultOne !== undefined && payment?.defaultOne)
                }
                onClick={() => onOpenPaymentProfile(payment)}
                readOnly={readOnlyMode}
              />
            )}
          </div>
        ))}
      {user?.deposit?.balance > 0 && (
        <ListItem
          title={localize('deposit.balance.title')}
          leftItem={
            <img
              style={{
                width: 31,
                height: 20,
                marginRight: 27,
                marginLeft: -2,
                objectFit: 'contain',
              }}
              src="/images/paymentPayIn.png"
              alt="payin"
            />
          }
          value={
            <div className="deposit-balance">
              {formatMoneyEuro(user?.deposit?.balance)}
            </div>
          }
          rightIcon="arrowRight"
          rightIconSize={16}
          onClick={() => onDepositBalanceClick()}
          disabled={blocked}
        />
      )}
      {user?.deposit?.toPayForSepaUnlock !== undefined && !blocked && (
        <>
          <div className="account-deposit-warning">
            <ListItem
              containerStyles={{ margin: 0 }}
              title={maskSepa(user.paymentMethods?.[0]?.sepa?.iban)}
              leftIcon="paymentSepa"
              leftIconSize={27}
              rightIcon="inProgress"
              rightIconSize={16}
              readOnly={readOnlyMode}
            />

            <div className="account-deposit-warning-msg">
              {localize('account-deposit.warning.desc')}
            </div>
            <div className="account-deposit-warning-container">
              <button
                className="account-deposit-warning-btn"
                onClick={onAddDepositPayment}
              >
                {localize('account-deposit.warning.btn')}
              </button>
              <div className="account-deposit-warning-value">
                {formatMoneyEuro(user?.deposit?.toPayForSepaUnlock)}
              </div>
            </div>
          </div>
        </>
      )}
      {isLowSchufaCredit && (
        <div className="account-deposit-warning">
          <ListItem
            containerStyles={{ margin: 0 }}
            title={maskSepa(user.paymentMethods?.[0]?.sepa?.iban)}
            leftIcon="paymentSepa"
            leftIconSize={27}
            rightIcon="inProgress"
            rightIconSize={16}
            readOnly={readOnlyMode}
          />

          <div className="account-deposit-warning-msg">
            {localize('account.low.schufa.desc')}
          </div>
        </div>
      )}
      {!disableAddPayments && (
        <ListItem
          title={localize('settings.payment.profile.add')}
          brandTextColor
          leftIcon="plus"
          leftIconSize={14}
          rightIcon={
            paymentMethods?.length &&
            paymentMethods.find((payment: any) => payment.status === 'verified')
              ? 'arrowRight'
              : 'iconIncomplete'
          }
          rightIconSize={
            paymentMethods?.length &&
            paymentMethods.find((payment: any) => payment.status === 'verified')
              ? 14
              : 10
          }
          onClick={isOrg ? onAddPayment : onAddPaymentProfile}
          disabled={blocked}
        />
      )}
    </>
  );

  const circleSection = (
    <>
      {!readOnlyMode && (
        <div className="account-header">
          {localize('settings.circles.data')}
        </div>
      )}
      {user?.circles &&
        user?.circles?.length > 0 &&
        user?.circles.map((circle: any) => {
          if (circle.circleType === 'brand') {
            return null;
          }
          return (
            <ListItem
              key={circle.id}
              title={
                circle.circleType === 'brand'
                  ? localize(
                      // @ts-ignore
                      `brand.${getBrandName(config.brand.xBrandId)}.name`
                    )
                  : circle.name
              }
              rightIcon="arrowRight"
              rightIconSize={14}
              leftItem={
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 16,
                    objectFit: 'contain',
                  }}
                  src={circle.circleType === 'brand' ? logo : circle?.logo}
                  alt="circle-logo"
                />
              }
              onClick={() => onPressCircle(circle)}
              disabled={blocked}
            />
          );
        })}
      {!readOnlyMode && (
        <ListItem
          title={localize('settings.circles.data.add')}
          brandTextColor
          leftIcon="plus"
          leftIconSize={14}
          rightIcon="arrowRightBrand"
          rightIconSize={14}
          onClick={onPressJoinCircle}
          disabled={blocked}
        />
      )}
    </>
  );

  const loginSection = (
    <>
      <div className="account-header">{localize('settings.login.data')}</div>
      {isTariffEnabled(user) && (
        <ListItem
          title={
            user?.tariff?.name
              ? localize('settings.tariff', tariffDisplayName)
              : localize('settings.select.tariff')
          }
          leftItem={
            <img
              style={{
                width: 20,
                height: 20,
                marginRight: 35,
                objectFit: 'contain',
              }}
              src="/images/docs.png"
              alt="tarif"
            />
          }
          rightIconSize={tariffStatus === 'completed' ? 14 : 10}
          rightIcon={
            tariffStatus === 'completed' ? 'arrowRight' : 'iconIncomplete'
          }
          onClick={
            tariffStatus === 'completed' && !!(isMVV() ? user?.extraData : true)
              ? onPressTariffCompleted
              : onPressTariff
          }
          brandTextColor={
            ['open', 'inProgress'].includes(tariffStatus) ||
            (isMVV() && !user?.extraData)
          }
          disabled={blocked}
          readOnly={readOnlyMode}
        />
      )}
      {!isOrg && (
        <ListItem
          title={
            user?.email?.length > 24
              ? `${user?.email?.slice(0, 27)}...`
              : user?.email || ''
          }
          leftIcon="email"
          rightIcon={
            !isEmailVerificationRequired(user)
              ? 'arrowRight'
              : getEmailVerificationStatus(user) === 'completed'
              ? 'arrowRight'
              : 'iconIncomplete'
          }
          rightIconSize={
            !isEmailVerificationRequired(user)
              ? 14
              : getEmailVerificationStatus(user) === 'completed'
              ? 14
              : 10
          }
          brandTextColor={
            isEmailVerificationRequired(user)
              ? ['open', 'inProgress'].includes(emailStatus)
              : false
          }
          onClick={onPressEmail}
          readOnly={readOnlyMode}
          disabled={blocked}
        />
      )}
      {!readOnlyMode && (
        <ListItem
          title={localize('settings.login.data.password')}
          leftIcon="key"
          rightIcon="arrowRight"
          rightIconSize={14}
          onClick={onPressPassword}
          readOnly={readOnlyMode}
        />
      )}
      <ListItem
        title={localize('settings.gdpr')}
        leftItem={
          <img
            style={{
              width: 20,
              height: 20,
              marginRight: 35,
              objectFit: 'contain',
            }}
            src="/images/gdpr.png"
            alt="user"
          />
        }
        rightIcon="arrowRight"
        rightIconSize={14}
        onClick={onPressGDPR}
        disabled={blocked}
      />
      {isKYCApp() && (
        <ListItem
          title={localize('settings.consents')}
          leftItem={
            <img
              style={{
                width: 20,
                height: 20,
                marginRight: 35,
                objectFit: 'contain',
              }}
              src="/images/rule.png"
              alt="user"
            />
          }
          rightIcon="arrowRight"
          rightIconSize={14}
          onClick={onPressConsents}
          disabled={blocked}
        />
      )}
      {!readOnlyMode && <FileUploadItem localize={localize} />}
    </>
  );
  return (
    <>
      {readOnlyMode && readOnlyDialog}
      {isOrg && orgSection}
      {personalSection}
      {isPaymentRequired(user) &&
        (isKYCApp() && !isOrg ? payemntSection : payemntProfilesSection)}
      {!onboardingOnly && circleSection}
      {loginSection}
      <>
        <div
          id={GA_TITLES.AUTH.LOGOUT}
          className="account-item"
          onClick={onLogout}
        >
          {localize('settings.logout')}
        </div>
        <Divider />
        {!readOnlyMode && (
          <>
            <div className="account-item-terminate" onClick={onTerminateUser}>
              {localize('settings.login.data.terminate')}
            </div>
            <Divider />
          </>
        )}
        <div
          className="user-version-header"
          onClick={onClickVersionHeader}
          style={{ color: 'rgba(0, 0, 0, 0.53)' }}
        >
          {localize('home.about')}
        </div>
      </>
    </>
  );
};

export default AccountTabView;
