import * as yup from 'yup';

export const ForgotPasswordModal = {
  email: '',
};

export const ForgotPasswordSchema = (localize: any): any =>
  yup.object({
    email: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        localize('input.error.invalid')
      ),
  });
