import React, { useEffect, useRef } from 'react';

type Props = {
  issuerUrl: string;
  paRequest: string;
  md: string;
  termUrl: string;
};

const RedirectView = ({ issuerUrl, paRequest, md, termUrl }: Props) => {
  const formRef = useRef<any>(null);

  useEffect(() => {
    formRef?.current?.submit();
  }, []);

  return (
    <form
      ref={formRef}
      method="POST"
      action={issuerUrl}
      id="3dform"
      target="redirect-iframe"
    >
      <input type="hidden" name="PaReq" value={paRequest} />
      <input type="hidden" name="MD" value={md} />
      <input type="hidden" name="TermUrl" value={termUrl} />
      <noscript>
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          <h1>Processing your 3D Secure Transaction</h1>
          <p>
            Please click continue to continue the processing of your 3D Secure
            transaction.
          </p>
          <input type="submit" className="button" value="continue" />
        </div>
      </noscript>
    </form>
  );
};

export default RedirectView;
