import * as yup from 'yup';
import { format } from 'date-fns';
import { postalCodePattern } from 'getaway-data-layer';
import { validateDateOfBirth } from '../utils';
import { maxBirthDate, minBirthDate } from './addSepa.schema';

export const SchufaModal = (user: any) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  dateOfBirth: user?.dateOfBirth
    ? format(new Date(user?.dateOfBirth), 'yyyy-MM-dd')
    : '',
  streetName: user?.address?.streetName || '',
  streetNumber: user?.address?.streetNumber || '',
  postalCode: user?.address?.postalCode || '',
  city: user?.address?.city || '',
  country: user?.address?.country || 'DE',
});

export const SchufaSchema = (localize: any, country: string): any =>
  yup.object({
    firstName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z-.]+/,
        localize('input.error.invalid')
      ),
    lastName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z-.]+/,
        localize('input.error.invalid')
      ),
    dateOfBirth: yup
      .string()
      .required(localize('validation.required'))
      .test('is-date-in-range', localize('input.error.invalid'), (value) => {
        return validateDateOfBirth(value, minBirthDate, maxBirthDate);
      }),
    streetName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^([A-ZÄÖÜ|a-zäöüß .\-0-9]{4,}|[A-z]+[1-9][0-9]?[A-z]*)$/,
        localize('input.error.invalid')
      ),
    streetNumber: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^(([a-zA-Z]\s?)?[1-9][0-9]{0,2}(\s?[a-zA-Z])?(([/,-]([a-zA-Z]\s?)?[1-9,a-z][0-9]{0,2}(\s?[a-zA-Z])?){0,3}))$/,
        localize('input.error.invalid')
      ),
    postalCode: yup
      .string()
      .required(localize('validation.required'))
      .test('isValidPostalCode', localize('input.error.invalid'), (value) =>
        postalCodePattern(country, value)
      ),
    city: yup.string().required(localize('validation.required')),
    country: yup.string().required(localize('validation.required')),
  });
