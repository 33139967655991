import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import withTextLocalizer from './withTextLocalizer';
import { State } from '../@types';

export type Props = {
  name: string;
  code: string;
  intercomHandlingMode: 'always' | 'onLogin' | 'onContactSupport' | 'never';
  defaultInsurance: any;
  payInOnboardingType: 'idDocument' | 'schufaCheck';
  addressOnboardingType: 'document' | 'creditCheck' | 'noAddressCheck';
  onboardingType: 'onSignup' | 'onBooking';
  enable3ds: boolean;
  maxLicenseSubmissionsPerUser: number;
  defaultCountryCode: string;
  allroundPhotosMandatory: boolean;
  merchantAccount: string;
  adyenClientKey: string;
  adyenEnvironment: string;
  passwordRegex: string | undefined;
  bookingDuration: string | undefined;
  brandSettings: any;
  allowedSEPACountries: string[] | null | undefined;
};

const withBrandSettings: any = compose(
  withTextLocalizer,
  connect(
    ({
      appData: {
        common: { brandSettings },
      },
    }: State) => ({
      brandSettings,
      name: brandSettings?.name,
      code: brandSettings?.code,
      bookingDuration: brandSettings?.bookingDuration,
      intercomHandlingMode: brandSettings?.intercomHandlingMode,
      defaultInsurance: brandSettings?.defaultInsurance,
      payInOnboardingType: brandSettings?.payInOnboardingType,
      addressOnboardingType: brandSettings?.addressOnboardingType,
      onboardingType: brandSettings?.onboardingType,
      enable3ds: brandSettings?.enable3ds,
      maxLicenseSubmissionsPerUser: brandSettings?.maxLicenseSubmissionsPerUser,
      defaultCountryCode:
        brandSettings?.defaultCountryCode?.toUpperCase() || 'DE',
      allroundPhotosMandatory: brandSettings?.allroundPhotosMandatory || true,
      merchantAccount: brandSettings?.merchantAccount,
      adyenClientKey: brandSettings?.adyenClientKey,
      adyenEnvironment: brandSettings?.adyenEnvironment,
      passwordRegex: brandSettings?.passwordRegex,
      allowedSEPACountries: brandSettings?.allowedSEPACountries,
    })
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ dispatch, ...props }: any) => ({ ...props }))
);
export default withBrandSettings;
