import React from 'react';
import { Divider, Box } from '@mui/material';
import './IDOnboardingView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import { GA_TITLES, isDashboardScreen } from '../../../../../utils';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import { FormikValues } from 'formik';
import ToggleTab from '../../../../components/ToggleTab/ToggleTab';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  onClose: () => void;
  formik: FormikValues;
  scanEnabled: boolean;
  onClickDisabled: () => void;
};

const IDOnboardingView = ({
  localize,
  onClose,
  formik,
  scanEnabled,
  onClickDisabled,
}: Props) => {
  const steps = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'stepFive'];

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('ID.title')}
        description={localize('ID.desr')}
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <div
        className={
          isLocalizeAdded(localize, 'ID.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'ID.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <ToggleTab
            localize={localize}
            optionOne={localize(`id.tab.card`)}
            optionTwo={localize(`id.tab.passport`)}
            isOptionOneSelected={formik.values?.documentType === 'ID_CARD'}
            onToggleValueChange={() => {
              if (formik.values?.documentType === 'ID_CARD') {
                formik.setFieldValue('documentType', 'PASSPORT');
              } else {
                formik.setFieldValue('documentType', 'ID_CARD');
              }
            }}
          />
          <div className="add-ID-img-container">
            <img
              alt="ID"
              src={
                formik.values?.documentType === 'ID_CARD'
                  ? '/images/ID-de.png'
                  : '/images/passport.png'
              }
              className="add-ID-img"
            />
          </div>
          {steps.map((step, index) => {
            const localizedText = localize(
              `ID.${formik.values?.documentType?.toLowerCase()}.${step}`
            );
            if (
              localizedText.includes('.step') ||
              localizedText.trim().length === 0
            ) {
              return null;
            }

            return (
              <>
                <div key={index} className="add-ID-item-container">
                  <BrandIcon
                    name="selected"
                    style={{ width: 24, height: 24, marginRight: 18 }}
                  />
                  <div className="add-ID-item-text">{localizedText}</div>
                  {index < steps.length - 1 && <Divider />}
                </div>
                <Divider />
              </>
            );
          })}
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <BrandButton
              id={GA_TITLES.ON_BOARDING.ADD_ID}
              title={localize('ID.button.text')}
              disabled={
                !scanEnabled || !formik.getFieldProps('documentType')?.value
              }
              enableKeyboardEffect={false}
              formik={formik}
              onClickDisabled={onClickDisabled}
            />
          </Box>
        </div>
        {isLocalizeAdded(localize, 'ID.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('ID.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default IDOnboardingView;
