// @ts-ignore
import adyenEncrypt from 'adyen-cse-web';

export const encryptCreditCardData = (
  publicKey: any,
  cardNumber: string,
  cardExpiry: string,
  cardCVC: string,
  holderName: string
) => {
  const cseInstance = adyenEncrypt.createEncryption(publicKey, {
    enableValidations: false,
  });
  const [expiryMonth, expiryYear] = cardExpiry.replace(/\s/g, '').split('/');
  const isFullYear = expiryYear?.length > 2;
  const cardData = {
    number: cardNumber.replace(/\s/g, ''),
    cvc: cardCVC,
    holderName,
    expiryMonth: parseInt(expiryMonth, 10),
    expiryYear: parseInt(expiryYear, 10) + (isFullYear ? 0 : 2000),
    generationtime: new Date().toISOString(),
  };
  return cseInstance.encrypt(cardData);
};

export const ibanPattern = (iban: string): RegExp => {
  // Each pattern accounts for spaces added every 4 characters
  // The digit count in each pattern includes these spaces, Excluding country code
  const patterns = {
    // Austria (20 total): 18 digits + 4 spaces = 22
    AT: /^AT[0-9^\s]{22}$/,
    // Belgium (16 total): 14 digits + 3 spaces = 17
    BE: /^BE[0-9^\s]{17}$/,
    // Bulgaria (22 total): 20 characters + 5 spaces = 25
    BG: /^BG[0-9A-Z^\s]{25}$/,
    // Cyprus (28 total): 26 digits + 6 spaces = 32
    CY: /^CY[0-9^\s]{32}$/,
    // Czech Republic (24 total): 22 digits + 5 spaces = 27
    CZ: /^CZ[0-9^\s]{27}$/,
    // Germany (22 total): 20 digits + 5 spaces = 25
    DE: /^DE[0-9^\s]{25}$/,
    // Denmark (18 total): 16 digits + 4 spaces = 20
    DK: /^DK[0-9^\s]{20}$/,
    // Estonia (20 total): 18 digits + 4 spaces = 22
    EE: /^EE[0-9^\s]{22}$/,
    // Spain (24 total): 22 digits + 5 spaces = 27
    ES: /^ES[0-9^\s]{27}$/,
    // Finland (18 total): 16 digits + 4 spaces = 20
    FI: /^FI[0-9^\s]{20}$/,
    // France (27 total): 25 digits + 6 spaces = 31
    FR: /^FR[0-9A-Z^\s]{31}$/,
    // Greece (27 total): 25 digits + 6 spaces = 31
    GR: /^GR[0-9^\s]{31}$/,
    // Croatia (21 total): 19 digits + 5 spaces = 24
    HR: /^HR[0-9^\s]{24}$/,
    // Hungary (28 total): 26 digits + 6 spaces = 32
    HU: /^HU[0-9^\s]{32}$/,
    // Ireland (20 total): 18 digits + 4 spaces = 22
    IE: /^IE[0-9A-Z^\s]{22}$/,
    // Italy (27 total): 25 digits + 6 spaces = 31
    IT: /^IT[0-9A-Z^\s]{31}$/,
    // Lithuania (20 total): 18 digits + 4 spaces = 22
    LT: /^LT[0-9^\s]{22}$/,
    // Luxembourg (20 total): 18 digits + 4 spaces = 22
    LU: /^LU[0-9^\s]{22}$/,
    // Latvia (21 total): 19 digits + 5 spaces = 24
    LV: /^LV[0-9^\s]{24}$/,
    // Malta (31 total): 29 digits + 7 spaces = 36
    MT: /^MT[0-9A-Z^\s]{36}$/,
    // Netherlands (18 total): 16 digits + 4 spaces = 20
    NL: /^NL[0-9A-Z^\s]{20}$/,
    // Poland (28 total): 26 digits + 6 spaces = 32
    PL: /^PL[0-9^\s]{32}$/,
    // Portugal (25 total): 23 digits + 6 spaces = 29
    PT: /^PT[0-9^\s]{29}$/,
    // Romania (24 total): 22 digits + 5 spaces = 27
    RO: /^RO[0-9A-Z^\s]{27}$/,
    // Sweden (24 total): 22 digits + 5 spaces = 27
    SE: /^SE[0-9^\s]{27}$/,
    // Slovenia (19 total): 17 digits + 4 spaces = 21
    SI: /^SI[0-9^\s]{21}$/,
    // Slovakia (24 total): 22 digits + 5 spaces = 27
    SK: /^SK[0-9^\s]{27}$/,
    // United Kingdom (22 total): 20 digits + 5 spaces = 25
    GB: /^GB[0-9A-Z^\s]{25}$/,
  };

  if (!iban || iban.length < 2) return patterns.DE; // Default to Germany if the IBAN is too short

  const countryCode = iban.substring(0, 2).toUpperCase();
  return patterns[countryCode] || patterns.DE; // Default to Germany if country code is not found
};

// BIC validation pattern, BIC is typically either 8 or 11 characters long, consisting of uppercase letters and digits
export const bicPattern =
  /^[A-Z]{4}[A-Z]{2}[A-Z2-9][A-NP-Z0-9](?:[A-Z0-9]{3})?$/;

export const maskSepa = (str: string): string => {
  if (!str) return '';
  const start: string = str.slice(0, 2);
  const end: string = str.slice(-4);
  return `${start}****${end}`;
};
