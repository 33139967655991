import React, { useEffect } from 'react';
import './CarCheckPhotoWrapper.less';
import { isCameraPermissionGranted, showAlert } from '../../../utils';
import {
  CarCheckImages,
  WithTextLocalizer,
  withTextLocalizer,
} from 'getaway-data-layer';

type Props = WithTextLocalizer & {
  carCheckImages: CarCheckImages;
  onPressRemoveImage: (index: number) => void;
};

const CarCheckPhotoWrapper = ({
  localize,
  carCheckImages,
  onPressRemoveImage,
}: Props) => {
  useEffect(() => {
    isCameraPermissionGranted().then((granted) => {
      if (!granted) {
        showAlert(
          localize('dialog.camera.permission.title'),
          localize('dialog.camera.permission.desc'),
          'ok',
          () => {}
        );
      }
    });
  }, []);

  return (
    <div
      className="car-check-photos-container"
      style={{ height: window.innerHeight * 0.43 }}
    >
      <div className="layout">
        <input
          id="car-check-image-1"
          type="file"
          accept="image/*"
          capture="environment"
          className="addImageOne invisible"
          style={carCheckImages?.imageOne ? { visibility: 'hidden' } : {}}
        />
        <div className="addImageOne">
          <img
            src={
              carCheckImages.imageOne
                ? carCheckImages.imageOne.uri
                : '/images/addImageOne.png'
            }
            className="car-check-image"
            style={
              carCheckImages.imageOne
                ? { objectFit: 'cover' }
                : { objectFit: 'contain' }
            }
          />
          {carCheckImages.imageOne && (
            <div
              onClick={() => {
                onPressRemoveImage(1);
              }}
              className="trashTouchable"
            >
              <img src="/images/trash.png" className="trash" />
            </div>
          )}
        </div>
        <input
          id="car-check-image-2"
          type="file"
          accept="image/*"
          capture="environment"
          className="addImageTwo invisible"
          style={carCheckImages?.imageTwo ? { visibility: 'hidden' } : {}}
        />
        <div className="addImageTwo">
          <img
            src={
              carCheckImages.imageTwo
                ? carCheckImages.imageTwo.uri
                : '/images/addImageTwo.png'
            }
            className="car-check-image"
            style={
              carCheckImages.imageTwo
                ? { objectFit: 'cover' }
                : { objectFit: 'contain' }
            }
          />
          {carCheckImages.imageTwo && (
            <div
              onClick={() => {
                onPressRemoveImage(2);
              }}
              className="trashTouchable"
            >
              <img src="/images/trash.png" className="trash" />
            </div>
          )}
        </div>
        <img src="/images/carCheck.png" className="carCheck" />
        <input
          id="car-check-image-3"
          type="file"
          accept="image/*"
          capture="environment"
          className="addImageThree invisible"
          style={carCheckImages?.imageThree ? { visibility: 'hidden' } : {}}
        />
        <div className="addImageThree">
          <img
            src={
              carCheckImages.imageThree
                ? carCheckImages.imageThree.uri
                : '/images/addImageThree.png'
            }
            className="car-check-image"
            style={
              carCheckImages.imageThree
                ? { objectFit: 'cover' }
                : { objectFit: 'contain' }
            }
          />
          {carCheckImages.imageThree && (
            <div
              onClick={() => {
                onPressRemoveImage(3);
              }}
              className="trashTouchable"
            >
              <img src="/images/trash.png" className="trash" />
            </div>
          )}
        </div>
        <input
          id="car-check-image-4"
          type="file"
          accept="image/*"
          capture="environment"
          className="addImageFour invisible"
          style={carCheckImages?.imageFour ? { visibility: 'hidden' } : {}}
        />
        <div className="addImageFour">
          <img
            src={
              carCheckImages.imageFour
                ? carCheckImages.imageFour.uri
                : '/images/addImageFour.png'
            }
            className="car-check-image"
            style={
              carCheckImages.imageFour
                ? { objectFit: 'cover' }
                : { objectFit: 'contain' }
            }
          />
          {carCheckImages.imageFour && (
            <div
              onClick={() => {
                onPressRemoveImage(4);
              }}
              className="trashTouchable"
            >
              <img src="/images/trash.png" className="trash" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CarCheckPhotoWrapper.defaultProps = {
  // containerStyles: {},
};

export default withTextLocalizer(CarCheckPhotoWrapper);
