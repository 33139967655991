import React from 'react';
import './IDScanningView.less';

type Props = {
  jumioRedirectUrl: string;
  onClose: () => void;
};

const IDScanningView = ({ jumioRedirectUrl, onClose }: Props) => {
  return (
    <div className="screen-container">
      <div className="jumio-iframe-container">
        <iframe
          src={jumioRedirectUrl}
          width="100%"
          height="100%"
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default IDScanningView;
