import React from 'react';
import './MdPlaygroundView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { LocalizePropType } from 'getaway-data-layer';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
};

const MdPlaygroundView = ({ localize, onClose }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title=" "
        description=""
        showHelp
        onClose={onClose}
        showLogo
      />
      <div className="screen-body">
        <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
          {localize('md.demo')}
        </Markdown>
      </div>
    </div>
  );
};

export default MdPlaygroundView;
