import React, { useEffect } from 'react';
import {
  selectSelectedCar,
  selectOpenedModal,
  clearOpenedModal,
  withTextLocalizer,
  getCarPriceDetails,
  selectRentalData,
  locationFromString,
  getCarBookings,
  selectDefaultTab,
  setDefaultTab,
  selectFleetStyle,
  getBrandColor,
  parseAddress,
} from 'getaway-data-layer';
import { addDays } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import CarProfileView from './CarProfileView';
import { openRouteDialog } from '../../../utils/dialogsHelpers';

type Props = { localize: any };

const CarProfileContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const rentalData = useSelector(selectRentalData) as any;
  const selectedMapCar = useSelector(selectSelectedCar) as any;
  const defaultTab = useSelector(selectDefaultTab);
  const selectedCar = rentalData?.carData
    ? rentalData?.carData
    : selectedMapCar;
  const isOpened = useSelector(selectOpenedModal) === 'CarProfileModal';
  const [selectedTab, setSelectedTab] = React.useState('0');
  const [carBookings, setCarBookings] = React.useState([]);
  const fleetStyle = useSelector(selectFleetStyle);
  const fleetColor = getBrandColor(fleetStyle);
  const carAddress = parseAddress(selectedCar?.releaseAddress);

  const handleClose = () => {
    dispatch(clearOpenedModal());
  };

  const onClickShare = async () => {
    let shareMessage = null;
    if (selectedCar) {
      const { brand, model, driverPrice } = selectedCar;
      shareMessage = localize(
        'web.share.message',
        brand,
        model,
        driverPrice.description.split('+')[0],
        selectedCar.id
      );
    }
    if (shareMessage && navigator?.share) {
      await navigator?.share({ text: shareMessage });
    }
  };

  const onChangeTab = (e: any, index: any) => {
    setSelectedTab(index);
  };

  const onClickAddress = () => {
    openRouteDialog(
      localize,
      locationFromString(selectedCar?.position),
      carAddress
    );
  };

  useEffect(() => {
    if (selectedCar && isOpened) {
      const from = new Date();
      const to = new Date(addDays(new Date(), 90));
      dispatch(
        getCarPriceDetails(selectedCar?.driverPrice?.pricingId, selectedCar?.id)
      );
      dispatch(
        getCarBookings(selectedCar?.id, from, to, (bookings: any) => {
          setCarBookings(bookings);
        })
      );
    }
    if (!isOpened) {
      dispatch(setDefaultTab(0));
      setSelectedTab('0');
    }
  }, [isOpened]);

  useEffect(() => {
    if (defaultTab) {
      setSelectedTab(defaultTab.toString());
    }
  }, [defaultTab]);

  return (
    <CarProfileView
      localize={localize}
      carAddress={carAddress}
      carBookings={carBookings}
      selectedCar={selectedCar}
      selectedTab={selectedTab}
      isOpened={isOpened}
      fleetStyle={fleetStyle}
      fleetColor={fleetColor}
      handleClose={handleClose}
      onClickShare={onClickShare}
      onClickAddress={onClickAddress}
      onChangeTab={onChangeTab}
    />
  );
};

export default withTextLocalizer(CarProfileContainer);
