import { setIsKeyboardOpened } from 'getaway-data-layer';
import { store } from '../redux/config';

export const attachInputListeners = () => {
  const inputs = document?.getElementsByTagName('input');
  if (inputs) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputs?.length; i++) {
      inputs[i].addEventListener('focus', () => {
        store.dispatch(setIsKeyboardOpened(true));
      });
      inputs[i].addEventListener('blur', () => {
        store.dispatch(setIsKeyboardOpened(false));
      });
    }
  }
};

export const removeInputListeners = () => {
  store.dispatch(setIsKeyboardOpened(false));
  const inputs = document?.getElementsByTagName('input');
  if (inputs) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < inputs?.length; i++) {
      inputs[i].removeEventListener('focus', () => {});
      inputs[i].removeEventListener('blur', () => {});
    }
  }
};
