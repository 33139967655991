import React, { useEffect } from 'react';
import {
  withTextLocalizer,
  getBookings,
  getRentalHistory,
  getMoreRentalHistory,
  selectUserRentals,
  selectBookings,
  dateAndTimeStringFromDate,
  LocalizePropType,
  BookingData,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserRentalsView from './UserRentalsView';
import { isMobileBrowser, navigateTo, showAlert } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const UserRentalsContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentals = useSelector(selectUserRentals) as any;
  const bookings = useSelector(selectBookings) as any;

  const onClose = () => {
    navigateTo(history, '/map');
  };

  const onLoadMoreRentals = () => {
    dispatch(getMoreRentalHistory());
  };

  const onClickOfferedBooking = (booking: any) => {
    if (booking?.bookingType !== 'express') {
      navigateTo(history, `/booking/consent/${booking.id}`);
    }
  };

  const onClickConfirmedBooking = (bookingId: string) => {
    navigateTo(history, `/booking/${bookingId}`);
  };

  const onClickStartedBooking = () => {
    if (isMobileBrowser()) {
      navigateTo(history, '/map');
    } else {
      navigateTo(history, '/mobile');
    }
  };

  const onClickCancelledBooking = (booking: any) => {
    const formattedCancelledAt = dateAndTimeStringFromDate(
      new Date(booking?.cancelledAt),
      localize
    );
    showAlert(
      localize('booking.cancelled.at', formattedCancelledAt),
      booking?.cancellationReason
        ? localize(`booking.${booking?.cancellationReason}`)
        : '',
      null,
      () => {},
      localize('ok'),
      () => {}
    );
  };

  const onClickRentalItem = (tripId) => {
    if (tripId) navigateTo(history, `/rentalDetails/${tripId}`);
  };

  useEffect(() => {
    dispatch(getBookings());
    dispatch(getRentalHistory());
  }, []);

  return (
    <UserRentalsView
      localize={localize}
      rentals={rentals}
      bookings={bookings}
      onLoadMoreRentals={onLoadMoreRentals}
      onClickRentalItem={onClickRentalItem}
      onClickCancelledBooking={onClickCancelledBooking}
      onClickOfferedBooking={onClickOfferedBooking}
      onClickStartedBooking={onClickStartedBooking}
      onClickConfirmedBooking={onClickConfirmedBooking}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(UserRentalsContainer);
