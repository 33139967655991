import React from 'react';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { isDashboardScreen } from '../../../utils';
import { LocalizePropType } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  onClose: () => void;
};

const CCAdyenComponentView = ({ localize, onClose }: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('Adyen CC component')}
        description={localize('...')}
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <div className="screen-body">
        <div id="card-div" />
      </div>
    </div>
  );
};

export default CCAdyenComponentView;
