import { isWeb } from './isWeb';
import { isAndroid } from './isAndroid';
import {
  injectedReactNativeFS,
  injectedReactNativeShare,
} from '../redux/ConfigureGlobals';

export async function downloadZip(blob, responseHeaders) {
  const contentDisposition = responseHeaders['content-disposition'];
  let filename = 'GDPR.zip';
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="([^"]+)/);
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1];
    }
  }

  if (isWeb()) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } else {
    try {
      const blobReader = new FileReader();
      blobReader.onload = async () => {
        const base64data = (blobReader.result as string).split(',')[1];
        const downloadsPath = `${injectedReactNativeFS.ExternalStorageDirectoryPath}/Download/${filename}`;
        const filePath = `${injectedReactNativeFS?.DocumentDirectoryPath}/${filename}`;

        if (isAndroid()) {
          await injectedReactNativeFS.writeFile(
            downloadsPath,
            base64data,
            'base64'
          );
        } else {
          await injectedReactNativeFS.writeFile(filePath, base64data, 'base64');
          // @ts-ignore
          await injectedReactNativeShare.open({
            url: `file://${filePath}`,
            type: 'application/zip',
          });
        }
      };
      blobReader.onerror = (e) => {
        console.error('Error during Blob to base64 conversion:', e);
      };
      blobReader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error while handling the zip file:', error);
    }
  }
}
