import React, { useRef, useEffect } from 'react';
import {
  getInsuranceShortName,
  isFreeInsurance,
  getInsuranceDescription,
  LocalizePropType,
  InsuranceData,
  getBrandColor,
} from 'getaway-data-layer';
import Hyperlink from 'react-native-hyperlink';
// @ts-ignore
import { Linking } from 'react-native-web';
import ListItem from '../../components/ListItem/ListItem';
import InsuranceItem from '../InsuranceItem/insuranceItem';
import './ExpandableInsuranceItem.style.less';

type Props = {
  localize: LocalizePropType;
  insurancesData: Array<InsuranceData>;
  onInsuranceSelected: (id: string) => void;
  selectedInsurance: string;
  initiallyOpened?: boolean;
};

const ExpandableInsuranceItem = ({
  localize,
  insurancesData,
  onInsuranceSelected,
  selectedInsurance,
  initiallyOpened,
}: Props) => {
  const [insurancesOpened, setInsurancesOpened] =
    React.useState(initiallyOpened);
  const selectedInsuranceData = insurancesData.find(
    (item) => item.id === selectedInsurance
  );

  useEffect(() => {
    if (insurancesOpened) {
      const selectedElement = document.querySelector(
        `[id='${selectedInsurance}']`
      );
      if (selectedElement) {
        selectedElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [selectedInsurance, insurancesOpened]);

  return (
    <div>
      {!!selectedInsurance && (
        <>
          <ListItem
            title={localize('rental.insurance.label')}
            greyTextColor
            value={getInsuranceShortName(
              selectedInsuranceData?.deductibleAmount!
            )}
            brandValueColor
            onClick={() => setInsurancesOpened(!insurancesOpened)}
            rightIcon={insurancesOpened ? 'arrowUp' : 'arrowDown'}
            rightIconSize={12}
            showSeparator={false}
          />
        </>
      )}
      {insurancesOpened && (
        <>
          <div className="expandable-reserved-insuranceScroll">
            {insurancesData.map((insurance: any) => {
              const checked = insurance.id === selectedInsurance;
              return insurance.deductibleAmount ? (
                <div id={insurance.id} key={insurance.id}>
                  <InsuranceItem
                    title={localize(
                      isFreeInsurance(insurance?.dailyRate)
                        ? 'insurance.description.free'
                        : 'insurance.description'
                    )}
                    description={getInsuranceDescription(
                      insurance.deductibleAmount
                    )}
                    dailyRate={insurance?.dailyRate}
                    value={
                      isFreeInsurance(insurance?.dailyRate)
                        ? localize('insurance.value.free')
                        : `+${insurance?.dailyRate / 100} ${localize(
                            'insurance.value'
                          )}`
                    }
                    onPress={() => {
                      onInsuranceSelected(insurance.id);
                    }}
                    checked={checked}
                  />
                </div>
              ) : null;
            })}
          </div>
          <div className="expandable-reserved-card-linkContainer">
            <Hyperlink
              linkStyle={{ color: getBrandColor() }}
              linkText={(url: string) =>
                url === localize('rental.insurance.settings.more.url')
                  ? localize('rental.insurance.settings.more.url.text')
                  : url
              }
              onPress={(url: string) => {
                Linking.canOpenURL(url).then((supported: any) => {
                  if (supported) {
                    Linking.openURL(url);
                  }
                });
              }}
            >
              <div>
                {localize(
                  'rental.insurance.settings.footnote',
                  localize('rental.insurance.settings.more.url')
                )}
              </div>
            </Hyperlink>
          </div>
        </>
      )}
      {!!selectedInsurance && (
        <div className="expandable-reserved-card-separator" />
      )}
    </div>
  );
};

ExpandableInsuranceItem.defaultProps = { initiallyOpened: false };

export default ExpandableInsuranceItem;
