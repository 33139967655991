import React from 'react';
import './WarningTopBar.less';
import { useSelector } from 'react-redux';
import {
  isRentalStarted,
  LocalizePropType,
  selectRentalData,
  selectUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useCustomerSupport } from '../../../hooks/useCustomerSupport';

type Props = {
  localize: LocalizePropType;
};

const WarningTopBar = ({ localize }: Props) => {
  const { helpAction } = useCustomerSupport(localize);
  const user = useSelector(selectUser) as any;
  const rentalData = useSelector(selectRentalData);
  return (
    (user?.blocked ||
      (isRentalStarted(rentalData) && rentalData.userBlocked)) && (
      <div className="warning-top-bar-container" onClick={() => helpAction()}>
        {localize(
          rentalData?.userBlocked ? 'map.rental.blocked' : 'map.user.blocked'
        )}
      </div>
    )
  );
};

WarningTopBar.defaultProps = {
  id: '',
  style: {},
};

export default withTextLocalizer(WarningTopBar);
