import { CommonState } from '../../../../@types';

const initialState: CommonState = {
  appRated: false,
  firstSteps: false,
  supportContacted: false,
  brandSettings: null,
  dialog: null,
  openedModal: null,
  isIntercomUserRegistered: false,
};

export default initialState;
