import * as React from 'react';
import { withUser, isValidAuthString, EventsClient } from 'getaway-data-layer';

type Props = {
  basicAuth: string;
};

class Subscriber extends React.Component<Props> {
  subscriptions = [];

  eventsClient: any;

  componentDidMount() {
    const { basicAuth } = this.props;
    if (basicAuth && isValidAuthString(basicAuth)) {
      this.startEventClient();
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    const { basicAuth } = this.props;
    return nextProps.basicAuth !== basicAuth;
  }

  componentDidUpdate(prevProps: Props) {
    const { basicAuth } = this.props;
    if (prevProps.basicAuth !== basicAuth) {
      if (basicAuth === null) {
        this.stopEventClient();
      } else if (isValidAuthString(basicAuth)) {
        if (prevProps.basicAuth) {
          this.restartEventClient();
        } else {
          this.startEventClient();
        }
      }
    }
  }

  startEventClient = async () => {
    this.eventsClient = new EventsClient();
    await this.eventsClient.initialize();
    this.eventsClient.start();
  };

  stopEventClient = () => {
    if (this.eventsClient) {
      this.eventsClient.stop();
    }
  };

  restartEventClient = () => {
    this.stopEventClient();
    this.eventsClient = new EventsClient();
    this.startEventClient();
  };

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withUser(Subscriber);
