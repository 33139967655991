import { IntercomManager } from '../native/intercomManager';
import { getFirstStepsChallenge } from './firstStepsHelper';
import { LocalizePropType } from '../enhancers';

export const anyFirstStepsOpen = (user: any): boolean => {
  const { challenges } = user;
  const challenge = getFirstStepsChallenge(challenges);

  if (challenge) {
    return challenge.tasks.some(
      (it: { status: string }) => it.status === 'open'
    );
  }

  return true;
};

export const isFirstStepsCompleted = (user: any): boolean => {
  const { challenges } = user;
  const challenge = getFirstStepsChallenge(challenges);

  if (challenge) {
    return challenge.tasks.every(
      (it: { status: string }) => it.status === 'completed'
    );
  }

  return true;
};

export const isDocumentAddressProof = (addressOnBoardingType: any): boolean =>
  addressOnBoardingType && addressOnBoardingType === 'document';

export const registerIntercomUser = (
  intercomHandlingMode: string | null | undefined,
  user: any | null | undefined,
  basicAuth: string | null | undefined,
  supportContacted: boolean,
  isIntercomUserRegistered: boolean,
  setIsIntercomUserRegistered: (isIntercomUserRegistered: boolean) => void
) => {
  switch (intercomHandlingMode) {
    case 'always':
      if (!isIntercomUserRegistered) {
        IntercomManager.registerIntercomUser(user).then((result) => {
          setIsIntercomUserRegistered(result);
        });
      }
      break;

    case 'onLogin':
      if (user && basicAuth && !isIntercomUserRegistered) {
        IntercomManager.registerIntercomUser(user).then((result) => {
          setIsIntercomUserRegistered(result);
        });
      }

      break;

    case 'onContactSupport':
      if (supportContacted && !isIntercomUserRegistered) {
        IntercomManager.registerIntercomUser(user).then((result) => {
          setIsIntercomUserRegistered(result);
        });
      }

      break;

    default:
      break;
  }
};

export const showTariffRequiredDialog = async (
  localize: LocalizePropType,
  yesAction: () => void,
  violation: string
) => {
  const { showAlert } = await import('./showAlert');

  showAlert(
    '',
    localize(violation),
    localize('add.tariff'),
    () => yesAction(),
    localize('cancel'),
    () => {}
  );
};
