import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import { State } from '../@types';
import { addressFromCoordinates } from '../helpers/addressFromCoordinates';

export type Props = {
  addressFromCoordinates: (
    coordinates: any,
    callback: (arg0: string) => void
  ) => string;
};

const withGeoLocation: any = compose(
  connect(({ config: { googleApiAccessToken } }: State) => ({
    googleApiAccessToken,
  })),
  withHandlers({
    addressFromCoordinates:
      ({ googleApiAccessToken }: any) =>
      (coordinates: Location, callback: (address: string) => void) =>
        addressFromCoordinates(coordinates, callback, googleApiAccessToken),
  } as any),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ googleApiAccessToken, ...props }: any) => ({ ...props }))
);
export default withGeoLocation;
