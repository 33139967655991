import React from 'react';
// @ts-ignore
import { FormikValues } from 'formik';
import './BillingAddressView.less';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  // @ts-ignore
} from '@mui/material';
// @ts-ignore
import Box from '@mui/material/Box';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import { GA_TITLES, isDashboardScreen } from '../../../../../utils';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import {
  ADDRESS_STREET_NUMBER_REGEX,
  ADDRESS_STREET_REGEX,
} from '../../../../../validation';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import Modal from 'react-modal';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  isPrivate: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  formik: FormikValues;
  supportedCountries: Array<any>;
  onCountryChange: (value: any) => void;
  onSearchAddressClicked: () => void;
};

const BillingAddressView = ({
  localize,
  isPrivate,
  isModalOpen,
  onCloseModal,
  supportedCountries,
  formik,
  onCountryChange,
  onSearchAddressClicked,
}: Props) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      className="address-modal"
      overlayClassName="address-modal-overlay"
    >
      <div className="screen-container">
        <NavigationBar
          title={localize('payment.billing.address.title')}
          description={localize('payment.billing.address.description')}
          showHelp
          showArrowBack={isDashboardScreen()}
          onClose={onCloseModal}
        />
        <div
          className={
            isLocalizeAdded(localize, 'address.billing.faq')
              ? 'screen-body-faq'
              : 'screen-body'
          }
        >
          <div
            className={
              isLocalizeAdded(localize, 'address.billing.faq')
                ? 'screen-body-content-faq'
                : 'screen-body-content'
            }
          >
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              {!isPrivate && (
                <>
                  <div className="billing-address-header">
                    {localize('payment.billing.company.headline')}
                  </div>
                  <TextField
                    required
                    className="form-input"
                    sx={{ width: 0.5, pr: 1 }}
                    label={localize('payment.billing.address.company.name')}
                    helperText={
                      (formik.touched.companyName &&
                        formik.errors.companyName) ||
                      ' '
                    }
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    variant="standard"
                    size="small"
                    type="text"
                    // autoFocus
                    {...formik.getFieldProps('companyName')}
                  />
                  <TextField
                    required
                    className="form-input"
                    sx={{ width: 0.5, pr: 1 }}
                    label={localize('payment.billing.address.company.contact')}
                    helperText={
                      (formik.touched.companyContact &&
                        formik.errors.companyContact) ||
                      ' '
                    }
                    error={
                      formik.touched.companyContact &&
                      Boolean(formik.errors.companyContact)
                    }
                    variant="standard"
                    size="small"
                    type="text"
                    {...formik.getFieldProps('companyContact')}
                  />
                </>
              )}
              {!isPrivate && (
                <>
                  <div className="billing-address-header">
                    {localize('payment.billing.address.headline')}
                  </div>
                </>
              )}
              <div
                className="billing-address-search-container"
                onClick={onSearchAddressClicked}
              >
                <BrandIcon
                  style={{
                    width: 14,
                    height: 14,
                    marginRight: 16,
                    marginLeft: 8,
                  }}
                  name="search"
                />
                <div className="billing-address-search-text">
                  {localize('firstSteps.address.search.text')}
                </div>
              </div>

              <TextField
                required
                className="form-input"
                sx={{ width: 0.7, pr: 1 }}
                label={localize('firstSteps.address.streetName.placeholder')}
                helperText={
                  (formik.touched.streetName && formik.errors.streetName) || ' '
                }
                error={
                  formik.touched.streetName && Boolean(formik.errors.streetName)
                }
                variant="standard"
                size="small"
                type="text"
                autoFocus={isPrivate}
                autoComplete="address-line1"
                {...formik.getFieldProps('streetName')}
                onChange={(e) => {
                  const value = e?.target?.value;
                  if (value?.length > 1) {
                    const street = ADDRESS_STREET_REGEX.exec(value);
                    const number = ADDRESS_STREET_NUMBER_REGEX.exec(value);
                    formik.setFieldValue(
                      'streetName',
                      street?.[1] || street?.[0] || value
                    );
                    if (number?.length > 1) {
                      formik.setFieldValue('streetNumber', number?.[2]);
                    }
                  } else {
                    formik.setFieldValue('streetName', value);
                  }
                }}
              />
              <TextField
                required
                id="streetNumber"
                sx={{ width: 0.3 }}
                label={localize('firstSteps.address.streetNum.placeholder')}
                helperText={
                  (formik.touched.streetNumber && formik.errors.streetNumber) ||
                  ' '
                }
                error={
                  formik.touched.streetNumber &&
                  Boolean(formik.errors.streetNumber)
                }
                variant="standard"
                size="small"
                type="text"
                {...formik.getFieldProps('streetNumber')}
              />
              <TextField
                required
                id="postalCode"
                sx={{ width: 0.3 }}
                label={localize('firstSteps.address.postalCode.placeholder')}
                helperText={
                  (formik.touched.postalCode && formik.errors.postalCode) || ' '
                }
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                variant="standard"
                size="small"
                type="text"
                autoComplete="postal-code"
                {...formik.getFieldProps('postalCode')}
              />
              <TextField
                required
                id="city"
                sx={{ width: 0.7, pl: 1 }}
                label={localize('firstSteps.address.city.placeholder')}
                helperText={(formik.touched.city && formik.errors.city) || ' '}
                error={formik.touched.city && Boolean(formik.errors.city)}
                variant="standard"
                size="small"
                type="text"
                autoComplete="address-level2"
                {...formik.getFieldProps('city')}
              />
              <FormControl fullWidth required variant="standard">
                <InputLabel id="country-label">
                  {localize('firstSteps.address.country.placeholder')}
                </InputLabel>
                <Select
                  fullWidth
                  required
                  labelId="select-country"
                  id="country"
                  variant="standard"
                  label={localize('firstSteps.address.country.placeholder')}
                  {...formik.getFieldProps('country')}
                  onChange={(event: any) => {
                    onCountryChange(event.target.value);
                    formik.handleChange(event);
                  }}
                >
                  {supportedCountries.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <img
                className="address-trust-logos"
                src="/images/trustLogos.png"
                alt="trustLogos"
              />

              <BrandButton
                id={GA_TITLES.ON_BOARDING.ADD_ADDRESS}
                title={localize('firstSteps.address.submitBtn.text')}
                formik={formik}
              />
            </Box>
          </div>
          {isLocalizeAdded(localize, 'address.billing.faq') && (
            <div className="faq">
              <Markdown rehypePlugins={[rehypeRaw]}>
                {localize('address.billing.faq')}
              </Markdown>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BillingAddressView;
