import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  withTextLocalizer,
  selectCameraImages,
  removeCameraImage,
  setCameraImages,
  clearCameraImages,
  addDamage,
  selectRentalData,
  LocalizePropType,
  addDamageImageBlob,
  getCarDamages,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import AddDamageView from './AddDamageView';
import { isCameraPermissionGranted, showAlert } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const AddDamageContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const rentalData = useSelector(selectRentalData);
  const images = useSelector(selectCameraImages);

  const [freeText, setFreeText] = useState('');
  const [error, setError] = useState(false);
  const [cameraEnabled, setCameraEnabled] = useState(true);

  const onClose = () => {
    history.goBack();
  };

  const onPressRemoveImage = (index: number) => {
    dispatch(removeCameraImage(index));
  };

  const onChangeImage = () => {
    // @ts-ignore
    const imageFile = document?.getElementById('add-damage-image')?.files[0];
    if (imageFile) {
      dispatch(setCameraImages(URL.createObjectURL(imageFile)));
    }
  };

  const handleChangeFreeText = (event: any) => {
    setError(false);
    setFreeText(event.target.value);
  };

  const uploadImagesSequentially = (images, carId, damageId, index = 0) => {
    if (index >= images.length) {
      dispatch(clearCameraImages());
      dispatch(getCarDamages(rentalData?.carData?.id!));
      return;
    }

    const image = images[index];
    dispatch(
      addDamageImageBlob(carId, damageId, image.uri, () => {
        uploadImagesSequentially(images, carId, damageId, index + 1);
      })
    );
  };

  const onSubmit = () => {
    if (images?.length < 2) {
      showAlert('', localize('damage.add.noImages.error'), 'ok', () => {});
    } else if (!freeText) {
      setError(true);
    } else {
      const damage = {
        where: 'interior',
        component: 'other',
        characteristics: 'freeText',
        characteristicsFreeText: freeText,
      };
      dispatch(
        addDamage(rentalData?.carData?.id!, damage, (damageId: string) => {
          if (damageId) {
            images.forEach((image: any) => {
              dispatch(
                addDamageImageBlob(
                  rentalData?.carData?.id!,
                  damageId,
                  image.uri
                )
              );
            });
          }
        })
      );
    }
  };

  const checkCameraPermission = () => {
    isCameraPermissionGranted().then((granted) => {
      setCameraEnabled(granted);
      if (!granted) {
        showAlert(
          'Camera permission denied',
          'Please enable camera access from browser settings',
          'ok',
          () => {}
        );
      }
    });
  };

  useEffect(() => {
    dispatch(clearCameraImages());
    // @ts-ignore
    document
      .getElementById('add-damage-image')
      ?.addEventListener('change', onChangeImage, true);

    isCameraPermissionGranted().then((granted) => {
      setCameraEnabled(granted);
      if (!granted) {
        showAlert(
          'Camera permission denied',
          'Please enable camera access from browser settings',
          'ok',
          () => {}
        );
      }
    });
  }, []);

  return (
    <AddDamageView
      localize={localize}
      images={images}
      freeText={freeText}
      error={error}
      cameraEnabled={cameraEnabled}
      checkCameraPermission={checkCameraPermission}
      handleChangeFreeText={handleChangeFreeText}
      onPressRemoveImage={onPressRemoveImage}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};

export default withTextLocalizer(AddDamageContainer);
