import React from 'react';
import { $PropertyType } from 'utility-types';
import './ActiveBookingItem.less';
import { Divider, ListItemButton } from '@mui/material';
import BrandIcon from '../BrandIcon/BrandIcon';
import { CarMode, RentalData } from 'getaway-data-layer';

type Props = {
  title: string;
  description: string;
  value: string;
  status: any;
  carMode: CarMode;
  showSeparator?: boolean;
  onClick: () => void;
};

const ActiveBookingItem = ({
  title,
  description,
  value,
  status,
  carMode,
  showSeparator,
  onClick,
}: Props) => {
  return (
    <ListItemButton className="active-booking-item-container" onClick={onClick}>
      <div className="active-booking-item-title-container">
        <div className="active-booking-item-title">{title}</div>
        <div className="active-booking-item-description">{description}</div>
      </div>
      <div
        className="active-booking-item-value"
        style={{
          color:
            status === 'started'
              ? carMode !== 'waitingForCheckin'
                ? '#02ac00'
                : '#F2994A'
              : 'black',
        }}
      >
        {value}
      </div>
      <BrandIcon
        name="arrowRight"
        style={{
          width: 14,
          height: 14,
          marginLeft: '10px',
        }}
      />
      {showSeparator && <Divider />}
    </ListItemButton>
  );
};

ActiveBookingItem.defaultProps = {
  showSeparator: true,
};

export default ActiveBookingItem;
