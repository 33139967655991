import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setDeeplinkUrl } from '../redux/store/ui/deeplink';
import { State } from '../@types';

export type Props = {
  deeplink: {
    deeplinkUrl: string;
  };
  setDeeplinkUrl: typeof setDeeplinkUrl;
};

const withDeeplink: any = compose(
  connect(
    ({ ui: { deeplink } }: State) => ({
      deeplink,
    }),
    {
      setDeeplinkUrl,
    }
  )
);
export default withDeeplink;
