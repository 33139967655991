import React, { useState, useEffect } from 'react';
import './ExternalBrowser.less';
import NavigationBar from '../NavigationBar/NavigationBar';
import {
  GA_TITLES,
  getEmailVerificationStatus,
  isDashboardScreen,
  navigateTo,
} from '../../../utils';
import {
  isIOSBrowser,
  LocalizePropType,
  selectUser,
  setNotification,
  truncateText,
} from 'getaway-data-layer';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '../ListItem/ListItem';
import { useHistory } from 'react-router-dom';
import BrandButton from '../BrandButton/BrandButton';
import { generateMagicLink } from 'getaway-data-layer/src/redux/store/userData/user/actions';

type Props = {
  localize: LocalizePropType;
  onClose?: () => void;
};

const ExternalBrowser = ({ localize, onClose }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser) as any;
  const email = user?.email;
  const isEmailVerified = getEmailVerificationStatus(user) === 'completed';
  const [magicLink, setMagicLink] = useState('');

  const onPressEditEmail = () => {
    navigateTo(
      history,
      isDashboardScreen() ? '/dashboard/updateEmail' : '/onboarding/updateEmail'
    );
  };

  const onPressCopyLink = () => {
    if (magicLink) {
      navigator.clipboard.writeText(magicLink).then(() => {
        dispatch(setNotification({ message: localize('wj.link.copied') }));
      });
    }
  };

  useEffect(() => {
    dispatch(
      generateMagicLink(true, (res) => {
        setMagicLink(res.value);
      })
    );
  }, []);

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('web.external.browser.title')}
        description=" "
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <div className={'screen-body'}>
        <div className="external-browser-section">
          {localize('web.external.email.section')}
        </div>
        <div className="external-browser-section-desc">
          {localize('web.external.email.section.desc')}
        </div>
        <ListItem
          title={truncateText(email, 25) || ''}
          leftIcon="email"
          rightIcon={isEmailVerified ? undefined : 'edit'}
          value={isEmailVerified ? undefined : localize('web.edit')}
          brandValueColor
          rightIconSize={17}
          onClick={isEmailVerified ? undefined : onPressEditEmail}
        />

        <div className="external-browser-section" style={{ marginTop: 30 }}>
          {localize('web.external.link.section')}
        </div>
        <div className="external-browser-section-desc">
          {localize('web.external.link.section.desc')}
        </div>
        <ListItem
          title={
            truncateText(magicLink, window.innerWidth < 360 ? 17 : 25) || ''
          }
          leftIcon="link"
          leftIconSize={22}
          rightIcon="copy"
          value={localize('web.copy')}
          brandValueColor
          rightIconSize={17}
          onClick={onPressCopyLink}
        />
      </div>
    </div>
  );
};

ExternalBrowser.defaultProps = {};

export default ExternalBrowser;
