import React from 'react';
import './Info.less';
import {
  withTextLocalizer,
  formatMmToCm,
  formatKgToTone,
  LocalizePropType,
  DriverCarData,
  isMSM,
} from 'getaway-data-layer';
import BrandIcon from '../BrandIcon/BrandIcon';
import { carSize } from '../../../assets/brandImages';

type Props = {
  localize: LocalizePropType;
  selectedCar: DriverCarData;
  fleetStyle: string;
};

const carSizeImage = {
  spp: '/images/carSizeSPP.png',
  expert: '/images/carSizeOBI.png',
  obi: '/images/carSizeOBI.png',
};

const Info = ({ localize, selectedCar, fleetStyle }: Props) => {
  const { height, width, length, seats, loadCapacity } = selectedCar;
  return (
    <div>
      <div className="info-specs">
        <div className="info-spec">
          <BrandIcon name="profileGear" style={{ width: 30, height: 30 }} />
          <div className="info-spec-text">
            {localize(`car.details.gear.${selectedCar.gear}`)}
          </div>
        </div>
        <div className="info-spec">
          <BrandIcon name="profileSeat" style={{ width: 30, height: 30 }} />
          <div className="info-spec-text">{selectedCar.seats}</div>
        </div>
        <div className="info-spec">
          <BrandIcon name="mileage" style={{ width: 30, height: 30 }} />
          <div className="info-spec-text">{`${selectedCar.enginePower} ps`}</div>
        </div>
        <div className="info-spec">
          <BrandIcon name="fuelType" style={{ width: 30, height: 30 }} />
          <div className="info-spec-text">
            {localize(`car.details.fuel.${selectedCar.fuel}`)}
          </div>
        </div>
      </div>
      {!isMSM() && (
        <>
          <div className="info-dimensions-wrapper">
            <img
              className="info-dimensions-img"
              src={carSizeImage?.[fleetStyle] || carSize}
              alt="info-dimensions"
            />
            {height !== 0 && height && (
              <div className="info-height">
                {`${localize('car.details.height')} \n`}
                <div className="info-value">{formatMmToCm(height)}</div>
              </div>
            )}
            {width !== 0 && width && (
              <div className="info-width">
                {`${localize('car.details.width')} \n`}
                <div className="info-value">{formatMmToCm(width)}</div>
              </div>
            )}
            {length !== 0 && length && (
              <div className="info-length">
                {`${localize('car.details.length')} \n`}
                <div className="info-value">{formatMmToCm(length)}</div>
              </div>
            )}
            {loadCapacity !== 0 && loadCapacity && (
              <div className="info-capacity">
                {`${localize('car.details.loadCapacity')} \n`}
                <div className="info-value">{formatKgToTone(loadCapacity)}</div>
              </div>
            )}
            {seats !== 0 && seats && (
              <div className="info-seat">
                <BrandIcon
                  name="profileSeat"
                  style={{ width: 16, height: 16, marginRight: 3 }}
                />
                <div className="info-value">{seats}</div>
              </div>
            )}
          </div>
          <div className="info-footnote">
            {localize('car.profile.dimensions.note')}
          </div>
          <div className="screen-vertical-space" />
        </>
      )}
    </div>
  );
};

export default withTextLocalizer(Info);
