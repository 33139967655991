import { carsClient } from '../../../clients/carsClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import {
  SetCarEquipments,
  Dispatch,
  CarEquipmentData,
} from '../../../../@types';
import TYPES from '../../../../@types/redux/store/CarEquipmentsTypes';
import { CLIENT_TYPE } from '../../../../@types';

export const setCarEquipments = (
  carEquipments: CarEquipmentData[]
): SetCarEquipments => ({
  type: TYPES.SET_CAR_EQUIPMENTS,
  payload: {
    carEquipments,
  },
});

export const getCarEquipments = () => async (dispatch: Dispatch) => {
  dispatch(setNetworkActivity(CLIENT_TYPE.CARS_CLIENT.GET_CAR_EQUIPMENTS_DATA));
  // $FlowFixMe
  const { notModified, data, error } = await carsClient.getCarEquipmentsData();

  if (error) {
    dispatch(
      setNetworkError(CLIENT_TYPE.CARS_CLIENT.GET_CAR_EQUIPMENTS_DATA, error)
    );
  } else {
    if (!!data?.carEquipments && !notModified) {
      dispatch(setCarEquipments(data.carEquipments));
    }

    dispatch(
      setNetworkSuccess(CLIENT_TYPE.CARS_CLIENT.GET_CAR_EQUIPMENTS_DATA)
    );
  }
};
