import React from 'react';
import Plate from '../../../components/Plate/Plate';
import SwipeIndicator from '../../../components/SwitchIndicator/SwipeIndicator';
import './CarCheckCardView.less';
import { GA_TITLES } from '../../../../utils';
import BrandButton from '../../../components/BrandButton/BrandButton';
import CarCheckPhotoWrapper from '../../../components/CarCheckPhotoWrapper/CarCheckPhotoWrapper';
import { LocalizePropType, getSecondaryBrandColor } from 'getaway-data-layer';
import ListItem from '../../../components/ListItem/ListItem';

type Props = {
  localize: LocalizePropType;
  carPlate: string;
  onClickSubmit: () => void;
  toggleCard: () => void;
  onRentalCancel: () => void;
  carCheckImages: any;
  onPressRemoveImage: (index: number) => void;
  isSubmitDisabled: boolean;
  immobilizerLocked: boolean;
};

const CarCheckCardView = ({
  localize,
  carPlate,
  toggleCard,
  onClickSubmit,
  onRentalCancel,
  carCheckImages,
  onPressRemoveImage,
  isSubmitDisabled,
  immobilizerLocked,
}: Props) => {
  return (
    <>
      <div onClick={toggleCard}>
        <strong className="cursor">
          <div className="check-card-header-wrapper">
            <div className="check-card-header-title">{`${localize(
              'rental.carCheck.header.title'
            )}`}</div>
            <div className="check-card-header-description-wrapper">
              <Plate
                carPlate={carPlate}
                titleWrapperStyles={{ backgroundColor: 'white' }}
              />
              <div className="check-card-header-description">
                {`${localize('rental.carCheck.header.desc')}`}
              </div>
            </div>
            <SwipeIndicator
              opacity={1}
              height={3}
              width={40}
              color={getSecondaryBrandColor()}
            />
          </div>
        </strong>
      </div>
      <div
        className="screen-body check-card-content"
        style={{ paddingTop: '85px', maxHeight: window?.innerHeight - 100 }}
      >
        {immobilizerLocked && (
          <>
            <ListItem
              containerStyles={{ padding: '10px 16px', margin: 0 }}
              title={localize('rental.immobilizer.title')}
              leftItem={
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 12,
                    objectFit: 'contain',
                  }}
                  src="/images/malfunction.png"
                  alt="malfunction"
                />
              }
              value={
                <div className="immobilizer-container">
                  <div className="orange-dot" />
                  <div className="immobilizer-text">
                    {localize('rental.immobilizer.blocked')}
                  </div>
                </div>
              }
              showSeparator={false}
            />
            <div className="reserved-card-separator" />
            <div className="imobilizer-warning">
              {localize('rental.immobilizer.allaround.msg')}
            </div>
          </>
        )}
        <div className="check-card-photo-container">
          <CarCheckPhotoWrapper
            carCheckImages={carCheckImages}
            onPressRemoveImage={onPressRemoveImage}
          />
          <div className="check-card-footnote">
            {localize('rental.carCheck.footnote')}
          </div>
        </div>
      </div>
      <div className="check-card-bottom">
        <img
          className="check-card-bottom-gradiant"
          src="/images/gradientBackground50.png"
          alt="gradiant"
        />
        <BrandButton
          id={GA_TITLES.RENTAL.SUBMIT_CAR_CHECK}
          containerStyles={{
            position: 'unset',
            width: '100%',
            marginBottom: '20px',
          }}
          title={localize('rental.carCheck.button')}
          onClick={onClickSubmit}
          disabled={isSubmitDisabled}
        />
        <div className="check-card-cancelContainer" onClick={onRentalCancel}>
          <img
            alt="redClose"
            src="/images/redClose.png"
            className="check-card-closeIcon"
          />
          <div className="check-card-close">
            {localize('rental.carCheck.cancel')}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarCheckCardView;
