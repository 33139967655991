export const truncateText = (text: string, maxChar = 20) =>
  text?.length > maxChar ? `${text?.slice(0, maxChar)}...` : text;

export const isLocalizeAdded = (localize, key) =>
  localize(key) && localize(key) !== '' && localize(key) !== key;

export const isDuplicateViolation = (violation: string): boolean => {
  const duplicateViolations = [
    'email',
    'phoneNumber',
    'api_error.user.iban.duplicate',
  ];
  return duplicateViolations.includes(violation);
};
