import * as yup from 'yup';
import { postalCodePattern } from 'getaway-data-layer';

export const BillingAddressModal = (billingInfo) => ({
  companyName: billingInfo?.companyName || '',
  companyContact: billingInfo?.companyContact || '',
  streetName: billingInfo?.streetName || '',
  streetNumber: billingInfo?.streetNumber || '',
  postalCode: billingInfo?.postalCode || '',
  city: billingInfo?.city || '',
  country: billingInfo?.country || 'DE',
});

export const BillingAddressSchema = (
  localize: any,
  country: string,
  isPrivate: boolean = false
): any =>
  yup.object({
    companyName: yup.string().when([], {
      is: () => !isPrivate,
      then: yup.string().required(localize('validation.required')),
      otherwise: yup.string().notRequired(),
    }),
    companyContact: yup.string().when([], {
      is: () => !isPrivate,
      then: yup.string().required(localize('validation.required')),
      otherwise: yup.string().notRequired(),
    }),
    streetName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^([A-ZÄÖÜ|a-zäöüß .\-0-9]{4,}|[A-z]+[1-9][0-9]?[A-z]*)$/,
        localize('input.error.invalid')
      ),
    streetNumber: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^(([a-zA-Z]\s?)?[1-9][0-9]{0,2}(\s?[a-zA-Z])?(([/,-]([a-zA-Z]\s?)?[1-9,a-z][0-9]{0,2}(\s?[a-zA-Z])?){0,3}))$/,
        localize('input.error.invalid')
      ),
    postalCode: yup
      .string()
      .required(localize('validation.required'))
      .test('isValidPostalCode', localize('input.error.invalid'), (value) =>
        postalCodePattern(country, value)
      ),
    city: yup.string().required(localize('validation.required')),
    country: yup.string().required(localize('validation.required')),
  });
