import { BluetoothState } from '../../../@types';

const initialState: BluetoothState = {
  status: 'disabled',
  connectedDevice: {
    deviceQnr: null,
    carId: null,
    accessToken: null,
    carStatus: null,
  },
};

export default initialState;
