import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/TextsTypes';
import { TextsAction, TextsState } from '../../../../@types';

export default (
  state: TextsState = initialState,
  action: TextsAction
): TextsState => {
  switch (action.type) {
    case TYPES.SET_TEXTS: {
      const { group, language, texts } = action.payload;
      return {
        ...state,
        [group]: { ...state[group], [language]: texts },
      };
    }

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
