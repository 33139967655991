import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFRM,
  isMVV,
  loadUserTariffs,
  LocalizePropType,
  selectTariffs,
  selectUser,
  updateUser,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import UpsellingsView from './UpsellingsView';
import {
  getDLVerificationStatus,
  getIDVerificationStatus,
  isDashboardScreen,
  isDLVerificationRequired,
  isIDVerificationRequired,
  navigateTo,
  TALLY_LINKS,
} from '../../../../utils';

interface Values {}

type Props = {
  localize: LocalizePropType;
};

const UpsellingsContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tariffs = useSelector(selectTariffs);
  const selectedUser = useSelector(selectUser);
  const [upsellingSelected, setUpsellingSelected] = useState('free');
  const selectedTariffUpsellings = tariffs?.find(
    (tariff) => tariff?.id === selectedUser?.tariff?.id
  )?.upsellings;
  const upsellings = [...(selectedTariffUpsellings || []), { code: 'free' }];

  const onSuccess = (user = selectedUser) => {
    const extraData = user?.extraData ? JSON.parse(user?.extraData) : {};
    if (
      !isFRM() &&
      getIDVerificationStatus(user) === 'completed' &&
      getDLVerificationStatus(user) === 'completed'
    ) {
      navigateTo(
        history,
        isDashboardScreen() ? '/settings' : '/onboarding/optimizedSepa'
      );
    } else if (isFRM()) {
      if (extraData?.referral) {
        navigateTo(
          history,
          isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
        );
      } else {
        navigateTo(
          history,
          isDashboardScreen()
            ? `/dashboard/dynamicForm?url=${
                extraData?.partner
                  ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                  : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
              }`
            : `/onboarding/dynamicForm?url=${
                extraData?.partner
                  ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                  : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
              }`
        );
      }
    } else if (isMVV()) {
      navigateTo(
        history,
        isDashboardScreen()
          ? `/dashboard/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
          : `/onboarding/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
      );
    } else if (
      !isDLVerificationRequired(user) &&
      !isIDVerificationRequired(user)
    ) {
      navigateTo(
        history,
        isDashboardScreen() ? '/settings' : '/onboarding/optimizedSepa'
      );
    } else {
      navigateTo(
        history,
        isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
      );
    }
  };

  const onSubmit = () => {
    if (upsellingSelected === 'free') {
      onSuccess();
    } else {
      dispatch(
        // @ts-ignore
        updateUser(
          {
            tariff: {
              upsellings: [
                {
                  code: upsellingSelected,
                },
              ],
            },
          },
          (user) => {
            onSuccess();
          }
        )
      );
    }
  };

  const onClose = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(loadUserTariffs());
  }, []);

  return (
    <UpsellingsView
      localize={localize}
      upsellings={upsellings}
      onClose={onClose}
      onSubmit={onSubmit}
      upsellingSelected={upsellingSelected}
      setUpsellingSelected={setUpsellingSelected}
    />
  );
};

export default withTextLocalizer(UpsellingsContainer);
