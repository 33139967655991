/* eslint-disable no-undef */
import { isWeb, isIOS } from './platformHelpers';
import { injectedLinking } from '../redux/ConfigureGlobals';

export const callPhoneNumber = async (phoneNumber: string) => {
  if (isWeb()) {
    window.open(`tel:${phoneNumber}`);
  } else {
    const clippedPhoneNumber = phoneNumber.replace(/[/\s.]/g, '');
    const url = `${isIOS() ? 'telprompt' : 'tel'}:${clippedPhoneNumber}`;
    const supported = await injectedLinking?.canOpenURL(url);

    if (supported) {
      await injectedLinking?.openURL(url);
    } else {
      // @ts-ignore
      if (__DEV__) {
        console.log(`Can't Open the URL: ${url}`);
      }
    }
  }
};
