import React from 'react';
import './DepositPaymentView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import { LocalizePropType } from 'getaway-data-layer';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
  paymentLinkUrl: string;
};

const DepositPaymentView = ({ localize, onClose, paymentLinkUrl }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('payment.deposit.title')}
        description={localize('payment.deposit.description')}
        onClose={onClose}
      />
      <div className="screen-body payment-link-content">
        <iframe
          src={paymentLinkUrl}
          width="100%"
          height="100%"
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default DepositPaymentView;
