import { APIResponse, AreaData, FeatureData } from '../../@types';

export const dataClient = {
  getAreas: async (): Promise<APIResponse<Array<AreaData>>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/areas',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  getFeatures: async (internal: boolean): Promise<APIResponse<FeatureData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/features',
      params: {
        internal,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
