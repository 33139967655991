import * as React from 'react';
import './ToggleItem.less';

type Props = {
  onToggleValueChange: () => void;
  toggleValue: boolean;
  optionOne: string;
  OptionTwo: string;
};

const ToggleItem = ({
  toggleValue,
  optionOne,
  OptionTwo,
  onToggleValueChange,
}: Props) => (
  <div className="toggle-container" onClick={onToggleValueChange}>
    <div
      className={
        toggleValue ? 'toggle-option-left-active' : 'toggle-option-left'
      }
    >
      {optionOne}
    </div>
    <div
      className={
        !toggleValue ? 'toggle-option-right-active' : 'toggle-option-right'
      }
    >
      {OptionTwo}
    </div>
  </div>
);

ToggleItem.defaultProps = {};

export default ToggleItem;
