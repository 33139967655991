import Intl from 'intl';
import 'intl/locale-data/jsonp/de-DE';
import 'intl/locale-data/jsonp/en-DE';

export const formatMmToCm = (value: number = 0) => {
  const unitFormatter = new Intl.NumberFormat([], {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  let formattedUnit = `${unitFormatter.format(value / 1000)} m`;

  if (formattedUnit.includes('.')) {
    formattedUnit = formattedUnit.replace('.', ',');
  }

  return formattedUnit;
};

export const formatKgToTone = (value: number = 0) => {
  const unitFormatter = new Intl.NumberFormat([], {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  let formattedUnit = `${unitFormatter.format(value / 1000)} t`;

  if (formattedUnit.includes('.')) {
    formattedUnit = formattedUnit.replace('.', ',');
  }

  return formattedUnit;
};
