import React from 'react';
import { LocalizePropType, abbreviateText } from 'getaway-data-layer';
import './FileUploadComponent.less';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BrandIcon from '../BrandIcon/BrandIcon';

type Props = {
  localize: LocalizePropType;
  openFileSelector: any;
  filesContent: any;
  loading: any;
  clearFile: () => void;
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const FileUploadItem = ({
  localize,
  openFileSelector,
  filesContent,
  loading,
  clearFile,
}: Props) => {
  return (
    <>
      <div className="file-upload-label noselect">
        {localize('wj.upload.label')}
      </div>
      <div className="file-upload-wrapper" onClick={openFileSelector}>
        <div className="file-upload-content">
          {loading ? (
            <Spin indicator={loadingIcon} />
          ) : filesContent ? (
            <div
              className="file-uploaded"
              onClick={(e) => {
                e.stopPropagation();
                clearFile();
              }}
            >
              <img
                style={{
                  width: 100,
                  height: 100,
                  objectFit: 'cover',
                  borderTopRightRadius: 5,
                  borderTopLeftRadius: 5,
                }}
                src={
                  typeof filesContent === 'string'
                    ? filesContent
                    : URL.createObjectURL(filesContent)
                }
                alt="file"
              />
              <div className="file-uploaded-bottom">
                <p className="file-uploaded-name">
                  {abbreviateText(filesContent?.name, 15)}
                </p>
                <BrandIcon
                  name="trash"
                  style={{
                    width: 12,
                    height: 12,
                    margin: '3px',
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <img
                style={{
                  width: 25,
                  height: 25,
                  objectFit: 'contain',
                }}
                src="/images/upload.png"
                alt="user"
              />
              <div className="file-upload-text noselect">
                {localize('wj.upload')}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

FileUploadItem.defaultProps = {};

export default FileUploadItem;
