import { CarStatusData, RentalData, UserDataOutput } from '../@types';
import { isAndroid } from '../helpers/platformHelpers';

export const DataNativeBridge = {
  nativeManager: null as any,

  setRentalData(rentalData: RentalData) {
    this.nativeManager?.setRentalData(rentalData);
  },

  clearRentalData() {
    this.nativeManager?.clearRentalData();
  },

  setUser(user: UserDataOutput) {
    this.nativeManager?.setUser(user);
  },

  resetAuth() {
    this.nativeManager?.resetAuth();
  },

  setAuth(auth: string) {
    if (auth) {
      this.nativeManager?.setAuth(auth);
    }
  },

  setCarStatusData(carStatusData: CarStatusData) {
    if (isAndroid()) {
      this.nativeManager?.setCarStatusData(carStatusData);
    }
  },
};
