import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  getCountryListWeb,
  LocalizePropType,
  selectBrandSettings,
  withTextLocalizer,
} from 'getaway-data-layer';
import BillingAddressView from './BillingAddressView';
import {
  BillingAddressModal,
  BillingAddressSchema,
} from '../../../../../validation';
import AddressAutoCompleteModal from '../../UpdateAddress/AddressSearchModal';

interface Values {
  companyName?: string;
  companyContact?: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

type Props = {
  localize: LocalizePropType;
  billingInfo: any;
  isAddressModalOpen: boolean;
  onCloseAddressModal: () => void;
  onUpdateBillingInfo: (billingInfo: any) => void;
};

const BillingAddressContainer = ({
  localize,
  billingInfo,
  isAddressModalOpen,
  onCloseAddressModal,
  onUpdateBillingInfo,
}: Props) => {
  const brandSettings = useSelector(selectBrandSettings);

  const [country, setCountry] = useState(
    billingInfo?.country ||
      brandSettings?.defaultCountryCode?.toUpperCase() ||
      'DE'
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const supportedCountries = useMemo(
    () =>
      getCountryListWeb(localize, brandSettings?.allowedUserAddressCountries),
    []
  );

  const onCountryChange = (value: any) => {
    setCountry(value);
  };

  const onSubmit = (values: Values) => {
    onUpdateBillingInfo({
      companyName: values?.companyName?.trim(),
      companyContact: values?.companyContact?.trim(),
      streetName: values?.streetName.trim(),
      streetNumber: values?.streetNumber.trim(),
      city: values?.city.trim(),
      postalCode: values?.postalCode.trim(),
      country: values?.country.trim(),
    });
    onCloseAddressModal();
  };

  const AddressFormik = useFormik({
    initialValues: BillingAddressModal(billingInfo),
    validationSchema: BillingAddressSchema(
      localize,
      country,
      billingInfo?.isPrivate
    ),
    onSubmit,
  });

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onLocationSelected = (location: any) => {
    AddressFormik.setValues(location);
    setIsModalOpen(false);
  };

  const onSearchAddressClicked = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    AddressFormik.resetForm();
  }, [isAddressModalOpen]);

  return (
    <>
      <AddressAutoCompleteModal
        countryRestriction={AddressFormik.values.country}
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        onLocationSelected={onLocationSelected}
      />
      <BillingAddressView
        isModalOpen={isAddressModalOpen}
        onCloseModal={onCloseAddressModal}
        localize={localize}
        isPrivate={billingInfo?.isPrivate}
        formik={AddressFormik}
        supportedCountries={supportedCountries}
        onCountryChange={onCountryChange}
        onSearchAddressClicked={onSearchAddressClicked}
      />
    </>
  );
};

export default withTextLocalizer(BillingAddressContainer);
