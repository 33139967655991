import React from 'react';
import Plate from '../../../components/Plate/Plate';
import SwipeIndicator from '../../../components/SwitchIndicator/SwipeIndicator';
import './OpenedCardView.less';
import { GA_TITLES } from '../../../../utils';
import ListItem from '../../../components/ListItem/ListItem';
import BrandButton from '../../../components/BrandButton/BrandButton';
import {
  LocalizePropType,
  getBrandImagePath,
  getSecondaryBrandColor,
} from 'getaway-data-layer';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';

type Props = {
  localize: LocalizePropType;
  carPlate: string;
  damages: any;
  newDamages: any;
  toggleCard: any;
  onCancelRental: any;
  onCLickKeyItem: () => void;
  onAddDamage: () => void;
  onPressUnlockOption: () => void;
  onPressUnblockOption: () => void;
  immobilizerLocked: boolean;
  carCheckImagesSubmitted: boolean;
  onPressAddAllAround: () => void;
};

const OpenedCardView = ({
  localize,
  carPlate,
  damages,
  toggleCard,
  newDamages,
  onCancelRental,
  onCLickKeyItem,
  onAddDamage,
  onPressUnlockOption,
  onPressUnblockOption,
  immobilizerLocked,
  carCheckImagesSubmitted,
  onPressAddAllAround,
}: Props) => {
  return (
    <>
      <div onClick={toggleCard}>
        <strong className="cursor">
          <div className="opened-card-header-wrapper">
            <div className="opened-card-header-title">{`${localize(
              'rental.opened.header.title'
            )}`}</div>
            <div className="opened-card-header-description-wrapper">
              <Plate
                carPlate={carPlate}
                titleWrapperStyles={{ backgroundColor: 'white' }}
              />
              <div className="opened-card-header-description">
                {`${localize('rental.opened.header.desc')}`}
              </div>
            </div>
            <SwipeIndicator
              opacity={1}
              height={3}
              width={40}
              color={getSecondaryBrandColor()}
            />
          </div>
        </strong>
      </div>
      <div
        className="screen-body opened-card-content"
        style={{ paddingTop: '75px', maxHeight: window?.innerHeight - 100 }}
      >
        {immobilizerLocked && (
          <>
            <ListItem
              containerStyles={{ padding: '10px 16px', margin: 0 }}
              title={localize('rental.immobilizer.title')}
              leftItem={
                <img
                  style={{
                    width: 28,
                    height: 28,
                    marginRight: 12,
                    objectFit: 'contain',
                  }}
                  src="/images/malfunction.png"
                  alt="malfunction"
                />
              }
              value={
                <div className="immobilizer-container">
                  <div className="orange-dot" />
                  <div className="immobilizer-text">
                    {localize('rental.immobilizer.blocked')}
                  </div>
                </div>
              }
              showSeparator={false}
            />
            <div className="reserved-card-separator" />
            <div className="imobilizer-warning">
              {localize('rental.immobilizer.allaround.msg')}
            </div>
            {!carCheckImagesSubmitted && (
              <>
                <ListItem
                  containerStyles={{ padding: '10px 16px', margin: 0 }}
                  title={localize('rental.allaround.add')}
                  leftItem={
                    <img
                      style={{
                        width: 28,
                        height: 24,
                        marginRight: 12,
                        objectFit: 'fill',
                      }}
                      src="/images/cameraPlus.png"
                      alt="cameraPlus"
                    />
                  }
                  brandTextColor
                  rightIconSize={10}
                  rightIcon="iconIncomplete"
                  showSeparator={false}
                  onClick={onPressAddAllAround}
                />
                <div className="reserved-card-separator" />
              </>
            )}
          </>
        )}
        <ExpandableItem
          title={localize('rental.opened.troubleshoot.title')}
          value={localize('rental.opened.troubleshoot.options')}
          initiallyOpened={false}
          showSeparator={false}
        >
          <ListItem
            containerStyles={{ padding: '0px 16px 5px 24px' }}
            title={localize('rental.opened.troubleshoot.door')}
            value={localize('rental.opened.troubleshoot.unlock')}
            rightIconSize={16}
            showSeparator={false}
            brandValueColor
            onClick={onPressUnlockOption}
          />
          <div className="separator" />
          <ListItem
            containerStyles={{ padding: '0px 16px 5px 24px' }}
            title={localize('rental.opened.troubleshoot.engine')}
            value={localize('rental.opened.troubleshoot.unblock')}
            rightIconSize={16}
            showSeparator={false}
            brandValueColor
            onClick={onPressUnblockOption}
          />
          <div className="separator" />
          <ListItem
            containerStyles={{ padding: '5px 16px 5px 24px' }}
            title={localize('rental.opened.troubleshoot.key')}
            rightIcon="info"
            rightIconSize={16}
            showSeparator={false}
            onClick={onCLickKeyItem}
          />
        </ExpandableItem>
        <div className="opened-card-damagesTitle">
          {localize('rental.opened.damages.title')}
        </div>
        <div className="opened-card-damage-wrapper">
          {damages.length > 0 &&
            damages.map((damage: any, index: number) => (
              <div key={damage.id}>
                <div className="opened-card-damage-container">
                  <img
                    className="opened-card-damage-image"
                    src={damage.images[0]}
                    alt="damage"
                  />
                  <div>
                    <div className="opened-card-damage-title">
                      {`#${damages.length - index}. ${damage.title}`}
                      <span className="opened-card-damage-new">
                        {newDamages.includes(damage.id)
                          ? ` (${localize('damage.new')})`
                          : ''}
                      </span>
                    </div>
                    <div className="opened-card-damage-description">
                      {damage.description}
                    </div>
                  </div>
                </div>
                <div className="separator" />
              </div>
            ))}
          {damages.length === 0 && (
            <p className="opened-card-empty-txt">
              {localize('damages.noRecord.text')}
            </p>
          )}
        </div>
      </div>
      <div onClick={onAddDamage}>
        <img
          alt="addDamage"
          src={getBrandImagePath('addDamage')}
          style={{
            position: 'absolute',
            right: '16px',
            bottom: '90px',
            width: '45px',
            height: '45px',
            borderRadius: 50,
            boxShadow: '0 0 8px rgb(0 0 0 / 40%)',
          }}
        />
      </div>
      <div>
        <img
          className="opened-card-bottom-gradiant"
          src="/images/gradientBackground50.png"
          alt="gradiant"
        />
        <BrandButton
          id={GA_TITLES.RENTAL.FINISH_BEFORE_DRIVING}
          title={localize('rental.finish.button')}
          onClick={onCancelRental}
        />
      </div>
    </>
  );
};

export default OpenedCardView;
