import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  encryptCreditCardData,
  addPayment,
  getPaymentPublicKey,
  getPaymentData,
  selectPaymentPublicKey,
  selectActivePaymentData,
  selectUser,
  selectNetworkActivities,
  CLIENT_TYPE,
  LocalizePropType,
  getUser,
} from 'getaway-data-layer';
import { useFormik } from 'formik';
import { differenceInMinutes } from 'date-fns';
import {
  GA_TITLES,
  isDashboardScreen,
  isIDVerificationRequired,
  navigateTo,
  reportGAEvent,
} from '../../../../../utils';
import AddCCPaymentView from './AddCCPaymentView';
import { AddCCModal, AddCCSchema } from '../../../../../validation';

type InputItem = {
  value: string;
  error: boolean;
};

type Values = {
  name: string;
};

const initialInput = {
  value: '',
  error: false,
};

type Props = {
  localize: LocalizePropType;
};

const PAYMENT_UPDATE_PERIOD = 5000;

const AddCCPaymentContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const paymentPublicKey = useSelector(selectPaymentPublicKey);
  const activePayment: any = useSelector(selectActivePaymentData);
  const networkActivities: any = useSelector(selectNetworkActivities);
  const isAddingPayment = networkActivities.includes(
    CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT
  );

  const paymentReqId = useRef(
    user?.paymentMethods.length > 0 &&
      user?.paymentMethods[0]?.paymentRequestId &&
      differenceInMinutes(new Date(), new Date(activePayment?.created)) < 5
      ? user?.paymentMethods[0]?.paymentRequestId
      : null
  );

  const [cardNumber, setCardNumber] = useState<InputItem>(initialInput);
  const [cardExpiry, setCardExpiry] = useState<InputItem>(initialInput);
  const [cardCVC, setCardCVC] = useState<InputItem>(initialInput);

  const onChangeNumber = (e: any) => {
    setCardNumber({ value: e.target.value, error: false });
  };

  const onChangeExpiry = (e: any) => {
    setCardExpiry({ value: e.target.value, error: false });
  };

  const onChangeCVC = (e: any) => {
    setCardCVC({ value: e.target.value, error: false });
  };

  const onNumberError = (error: string) => {
    setCardNumber({ ...cardNumber, error: Boolean(error) });
  };

  const onExpiryError = (error: string) => {
    setCardExpiry({ ...cardExpiry, error: Boolean(error) });
  };

  const onCVCError = (error: string) => {
    setCardCVC({ ...cardCVC, error: Boolean(error) });
  };

  const validateInput = (inputItem: InputItem) =>
    inputItem.value && !inputItem.error;

  const getPublicKey = async () => {
    let key = paymentPublicKey;
    if (key && key?.length > 0) return new Promise((resolve) => resolve(key));
    await dispatch(
      getPaymentPublicKey((publicKey: string) => {
        key = publicKey;
      })
    );
    return new Promise((resolve, reject) =>
      key && key.length > 0 ? resolve(key) : reject()
    );
  };

  const encryptCardData = (name: string) =>
    // @ts-ignore
    getPublicKey().then((key: string) => {
      return encryptCreditCardData(
        key,
        cardNumber.value,
        cardExpiry.value,
        cardCVC.value,
        name
      );
    });

  const successCallback = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      if (isIDVerificationRequired(user)) {
        navigateTo(history, `/onboarding/IDOnboarding`);
      } else {
        navigateTo(history, '/onboarding/DLOnboarding');
      }
    }
  };

  const onSubmit = (values: Values) => {
    if (
      validateInput(cardNumber) &&
      validateInput(cardExpiry) &&
      validateInput(cardCVC)
    ) {
      encryptCardData(values.name).then((data: any) => {
        if (data) {
          dispatch(
            addPayment(
              {
                card: {
                  cardEncryptedJson: data,
                  accountHolder: values.name.trim(),
                },
              },
              (userData: any) => {
                if (userData?.paymentMethods[0]?.paymentRequestId) {
                  paymentReqId.current =
                    userData?.paymentMethods[0]?.paymentRequestId;
                }
                // navigate if cc with no 3ds added
                if (userData?.paymentMethods[0]?.status === 'verified') {
                  successCallback();
                }

                reportGAEvent(GA_TITLES.ON_BOARDING.CC_SUBMITTED);
              }
            )
          );
        }
      });
    }
  };

  const onClose = () => {
    history.goBack();
  };

  const AddCCFormik = useFormik({
    initialValues: AddCCModal,
    validationSchema: AddCCSchema(localize),
    onSubmit,
  });

  useEffect(() => {
    const paymentDataUpdaterId = setInterval(() => {
      if (paymentReqId.current) {
        dispatch(getPaymentData(paymentReqId.current));
        dispatch(
          getUser((user) => {
            // @ts-ignore
            if (user?.paymentMethods[0]?.status === 'verified') {
              successCallback();
            }
          })
        );
      }
    }, PAYMENT_UPDATE_PERIOD);
    return () => {
      if (paymentDataUpdaterId) {
        clearInterval(paymentDataUpdaterId);
      }
    };
  }, []);

  useEffect(() => {
    if (activePayment?.status === 'confirmed' && paymentReqId.current) {
      successCallback();
    }
  }, [activePayment?.status]);

  return (
    <>
      <AddCCPaymentView
        localize={localize}
        formik={AddCCFormik}
        cardNumber={cardNumber.value}
        cardExpiry={cardExpiry.value}
        cardCVC={cardCVC.value}
        redirectData={activePayment?.paymentData?.threeDS1}
        identifyShopperData={activePayment?.paymentData?.identifyShopperData}
        challengeShopperData={activePayment?.paymentData?.challengeShopperData}
        isAddingPayment={isAddingPayment}
        onChangeNumber={onChangeNumber}
        onChangeExpiry={onChangeExpiry}
        onChangeCVC={onChangeCVC}
        onClose={onClose}
        onNumberError={onNumberError}
        onExpiryError={onExpiryError}
        onCVCError={onCVCError}
      />
    </>
  );
};

export default withTextLocalizer(AddCCPaymentContainer);
