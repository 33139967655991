import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectUser,
  schufaCheck,
  withTextLocalizer,
  LocalizePropType,
  getCountryListWeb,
  selectBrandSettings,
  UserDataOutput,
} from 'getaway-data-layer';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import SchufaCheckView from './SchufaCheckView';
import {
  maxBirthDate,
  minBirthDate,
  SchufaModal,
  SchufaSchema,
} from '../../../../validation';
import {
  GA_TITLES,
  getLicenseStatus,
  isDashboardScreen,
  navigateTo,
  reportGAEvent,
} from '../../../../utils';

interface Values {
  firstName: string;
  lastName: string;
  dateOfBirth: any;
  streetName: string;
  streetNumber: string;
  city: string;
  postalCode: string;
  country: string;
}

type Props = {
  localize: LocalizePropType;
};

const SchufaCheckContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user: any = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings);
  const licenseStatus = getLicenseStatus(user);
  const [country, setCountry] = useState(user?.address?.country || 'DE');
  const [customError, setCustomError] = useState('') as any;
  const supportedCountries = useMemo(
    () =>
      getCountryListWeb(
        localize,
        brandSettings?.allowedUserAddressCountries,
        true
      ),
    []
  );
  const dateFormat = 'dd/MM/yyyy';

  const onCountryChange = (value: any) => {
    setCountry(value);
  };

  const onSubmit = (values: Values) => {
    const formattedBirth = format(new Date(values.dateOfBirth), 'yyyy-MM-dd');
    dispatch(
      schufaCheck(
        {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          dateOfBirth: formattedBirth.trim(),
          address: {
            streetName: values.streetName.trim(),
            streetNumber: values.streetNumber.trim(),
            city: values.city.trim(),
            postalCode: values.postalCode.trim(),
            country: values.country.trim(),
          },
        },
        () => {
          navigateTo(
            history,
            isDashboardScreen() ? '/settings' : '/onboarding/DLOnboarding'
          );

          reportGAEvent(GA_TITLES.ON_BOARDING.SCHUFA_SUBMITTED);
        },
        () => {}
      )
    );
  };

  const onClose = () => {
    history.goBack();
  };

  const SchufaFormik = useFormik({
    initialValues: SchufaModal(user),
    validationSchema: SchufaSchema(localize, country),
    onSubmit,
  });

  return (
    <>
      <SchufaCheckView
        localize={localize}
        supportedCountries={supportedCountries}
        formik={SchufaFormik}
        licenseStatus={licenseStatus}
        minDate={minBirthDate}
        maxDate={maxBirthDate}
        onClose={onClose}
        country={country}
        dateFormat={dateFormat}
        customError={customError || SchufaFormik.errors.dateOfBirth}
        onCountryChange={onCountryChange}
      />
    </>
  );
};

export default withTextLocalizer(SchufaCheckContainer);
