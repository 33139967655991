import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceUUID } from 'device-uuid';
import {
  setApiBaseURL,
  setApiVersion,
  setXBrandId,
  setDeviceUUID,
  setPlatform,
  selectDeviceUUID,
  setCardState,
  setIsKeyboardOpened,
} from 'getaway-data-layer';
import config from '../env.config';

type props = {
  children: React.ReactElement;
};

const Setup = ({ children }: props) => {
  const dispatch = useDispatch();
  const deviceUUID = useSelector(selectDeviceUUID);

  useEffect(() => {
    dispatch(setIsKeyboardOpened(false));
    dispatch(setPlatform('browser'));
    dispatch(setApiVersion(config.api.apiVersion));
    dispatch(setXBrandId(config.brand.xBrandId!));
    dispatch(setApiBaseURL(config.api.baseURL));
    dispatch(setCardState('Expanded'));
    if (!deviceUUID) {
      dispatch(setDeviceUUID(new DeviceUUID().get()));
    }
  }, []);

  return children;
};

export default Setup;
