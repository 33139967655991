import React from 'react';
import './DesktopWTView.less';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import SlideItem from './SlideItem';
import { homeWalkthrogh1, homeWalkthrogh2 } from '../../../assets/brandImages';
import {
  getVersionHeaderColor,
  LocalizePropType,
  isKYCApp,
  UserDataOutput,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../utils';

type Props = {
  localize: LocalizePropType;
  user: UserDataOutput;
  sliderIndex: number;
  sliderRef: any;
  afterSlide: (index: number) => void;
  onClickVersionHeader: () => void;
  onClickLogin: () => void;
  onClickSignup: () => void;
};

const DesktopWTView = ({
  localize,
  sliderRef,
  afterSlide,
  onClickVersionHeader,
  onClickLogin,
  onClickSignup,
}: Props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots WT-slick-dots',
    autoplay: true,
    arrows: true,
  };
  return (
    <>
      {isKYCApp() && (
        <button
          id={GA_TITLES.AUTH.OPEN_LOGIN}
          className="desktop-WT-login-btn"
          type="submit"
          style={
            process.env.REACT_APP_X_BRAND_ID === '000000000000000000000003'
              ? {
                  color: '#0077BE',
                  backgroundColor: 'white',
                  fontFamily: 'AvenirNext-Medium, serif',
                }
              : {}
          }
          onClick={onClickLogin}
          name="login-btn"
        >
          {localize('home.walkthrough.login')}
        </button>
      )}
      {isKYCApp() && (
        <div
          id={GA_TITLES.AUTH.OPEN_SIGNUP}
          className="desktop-WT-signup-bar"
          onClick={onClickSignup}
        >
          {localize('signup.header')}
        </div>
      )}
      <div
        className="screen-container"
        style={{
          height: 'calc(100% - 150px)',
          top: '52%',
          transform: 'translateY(-50%)',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      >
        <div
          className="screen-body"
          style={{
            padding: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <div
            className="version-header"
            onClick={onClickVersionHeader}
            style={{ color: getVersionHeaderColor() }}
          >
            {localize('home.about')}
          </div>
          <Slider ref={sliderRef} {...settings} afterChange={afterSlide}>
            <SlideItem
              title={localize(`home.walkthrough.page1.title`)}
              description={localize(`home.walkthrough.page1.description`)}
              imageSrc={homeWalkthrogh1}
            />
            <SlideItem
              title={localize(`home.walkthrough.page2.title`)}
              description={localize(`home.walkthrough.page2.description`)}
              imageSrc={homeWalkthrogh2}
            />
          </Slider>
        </div>
      </div>
    </>
  );
};

export default DesktopWTView;
