import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/DeeplinkTypes';
import { DeeplinkAction, DeeplinkState } from '../../../../@types';

export default (
  state: DeeplinkState = initialState,
  action: DeeplinkAction
): DeeplinkState => {
  switch (action.type) {
    case TYPES.SET_DEEPLINK_URL: {
      return { ...state, deeplinkUrl: action.payload.deeplinkUrl };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions
      return state;
    }
  }
};
