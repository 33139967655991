import { isMobileBrowser, navigateTo } from '../../../utils';
import { DriverCarData, isMBA, isUBQ } from 'getaway-data-layer';

export const annotationToMapContent = (
  id: string,
  type: string,
  currentCardType: string
) => {
  switch (type) {
    case 'reservedCar':
    case 'openedCar':
    case 'drivingCar':
    case 'parkingCar':
      return {
        mapType: type,
        cardType: type,
        id,
      };
    case 'availableCar':
    case 'selectedAvailableCar':
      return {
        mapType: type,
        cardType: currentCardType,
        id,
      };
    case 'unavailableCar':
    case 'selectedUnavailableCar':
      return {
        mapType: type,
        cardType: 'none',
        id,
      };
    case 'gasStation':
    case 'selectedGasStation':
      return {
        mapType: type,
        cardType: currentCardType,
        id,
      };
    case 'offlineService':
    case 'selectedOfflineService':
      return {
        mapType: type,
        cardType: 'offlineService',
        id,
      };
    case 'poiStation':
    case 'selectedPoiStation':
      return {
        mapType: type,
        cardType: currentCardType,
        id,
      };
    default:
      return {
        mapType: type,
        cardType: 'none',
        id,
      };
  }
};

export const openNav = (history: any) => {
  if (isMobileBrowser()) {
    // @ts-ignore
    document.getElementById('sidebar').style.width = isMobileBrowser()
      ? `${window.innerWidth * 0.7}px`
      : '350px';
    // @ts-ignore
    // document.getElementById('main').style.marginLeft = isMobileBrowser()
    //   ? `${window.innerWidth * 0.7}px`
    //   : '350px';
  } else {
    navigateTo(history, '/dashboard');
  }
};

export const closeNav = () => {
  if (document?.getElementById('sidebar')) {
    // @ts-ignore
    document.getElementById('sidebar').style.width = '0';
    // @ts-ignore
    // document.getElementById('main').style.marginLeft = '0';
  }
};

export const onSwipeLeft = () => {
  // @ts-ignore
  let start = null;
  // eslint-disable-next-line func-names
  window.addEventListener('touchstart', function (event) {
    if (event.touches.length === 1) {
      // just one finger touched
      // @ts-ignore
      start = event.touches.item(0).clientX;
    } else {
      // a second finger hit the screen, abort the touch
      start = null;
    }
  });
  // eslint-disable-next-line func-names
  window.addEventListener('touchend', function (event) {
    const offset = 60; // at least 100px are a swipe
    // @ts-ignore
    if (start) {
      // the only finger that hit the screen left it
      // @ts-ignore
      const end = event.changedTouches.item(0).clientX;
      // @ts-ignore
      if (end < start - offset) {
        // @ts-ignore
        if (document.getElementById('sidebar')?.style?.width !== '0px') {
          closeNav();
        }
      }
    }
  });
};

export const mapButtonShadow = {
  borderRadius: '50px',
  webkitBoxShadow: 'rgb(0 0 0 / 15%) 0px 0px 20px 5px',
  mozBoxShadow: 'rgb(0 0 0 / 15%) 0px 0px 20px 5px',
  boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 20px 5px',
};

export const getInCompleteIconStyles = (selectedCar: DriverCarData) =>
  selectedCar
    ? {
        position: isMobileBrowser() ? 'fixed' : 'absolute',
        top: '23px',
        left: isMobileBrowser() ? '42px' : '442px',
        zIndex: 3,
        width: '12px',
        height: '12px',
      }
    : {
        position: isMobileBrowser() ? 'fixed' : 'absolute',
        top: '23px',
        left: '42px',
        zIndex: 3,
        width: '12px',
        height: '12px',
      };

export const getProfileIconStyles = (selectedCar: DriverCarData) =>
  selectedCar
    ? {
        position: isMobileBrowser() ? 'fixed' : 'absolute',
        top: '20px',
        left: isMobileBrowser() ? '10px' : '410px',
        zIndex: 2,
        width: '55px',
        height: '55px',
        cursor: 'pointer',
      }
    : {
        position: isMobileBrowser() ? 'fixed' : 'absolute',
        top: '20px',
        left: '10px',
        zIndex: 2,
        width: '55px',
        height: '55px',
        cursor: 'pointer',
      };

export const helpIconStyles = {
  position: isMobileBrowser() ? 'fixed' : 'absolute',
  top: '20px',
  right: '10px',
  zIndex: 1,
  width: '55px',
  height: '55px',
};

export const navigateIconStyles = {
  position: isMobileBrowser() ? 'fixed' : 'absolute',
  top: '70px',
  right: isMobileBrowser() ? '10px' : '15px',
  zIndex: 1,
  width: '55px',
  height: '55px',
};

export const mapFuelIconStyles = {
  position: isMobileBrowser() ? 'fixed' : 'absolute',
  top: '124px',
  right: '17px',
  zIndex: 1,
  width: '40px',
  height: '40px',
  ...mapButtonShadow,
};

export const mapFlagIconStyles = {
  position: isMobileBrowser() ? 'fixed' : 'absolute',
  top: '174px',
  right: '17px',
  zIndex: 1,
  width: '40px',
  height: '40px',
  ...mapButtonShadow,
};

export const getMapDefaultCenter = (userAddress) => {
  if (userAddress?.includes('Spain')) {
    return { lat: 39.5696, lng: 2.6502 };
  } else {
    if (isUBQ()) {
      return { lat: 48.18026139, lng: 16.33216349 };
    } else if (isMBA()) {
      return { lat: 47.428712352412, lng: 9.740950091786 };
    } else {
      return { lat: 52.52, lng: 13.388 };
    }
  }
};
