export default [
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:17:09.122Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0850838 53.5118434',
              '10.0889596 53.5141082',
              '10.0719815 53.524518',
              '10.0675504 53.5229521',
              '10.0850838 53.5118434',
            ],
          },
        },
      ],
    },
    id: '5d3f8c8590e8b12127c0b5c3',
    lastUpdatedAt: '2019-07-30T00:17:17.567Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_01_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:18:07.451Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.9347487 53.5663095',
              '9.9357852 53.5647031',
              '9.9383804 53.5637257',
              '9.9384876 53.5641399',
              '9.9367871 53.5649045',
              '9.9357303 53.5657138',
              '9.9353065 53.5663637',
              '9.9347487 53.5663095',
            ],
          },
        },
      ],
    },
    id: '5d3f8cbf90e8b12127c0b5ca',
    lastUpdatedAt: '2019-07-30T00:19:16.535Z',
    version: 7,
    kmlFilename: 'planned_Hamburg_02_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:19:55.469Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.9764453 53.5626854',
              '9.9761396 53.5620131',
              '9.9817132 53.5598879',
              '9.9812251 53.5605635',
              '9.9764453 53.5626854',
            ],
          },
        },
      ],
    },
    id: '5d3f8d2b90e8b12127c0b5d4',
    lastUpdatedAt: '2019-07-30T00:20:14.72Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_03_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:21:12.423Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.9833619 53.4329382',
              '9.9824956 53.4316941',
              '9.9825921 53.430486',
              '9.9829676 53.4304349',
              '9.9840767 53.4314636',
              '9.9842846 53.4327992',
              '9.9833619 53.4329382',
            ],
          },
        },
      ],
    },
    id: '5d3f8d7890e8b12127c0b5db',
    lastUpdatedAt: '2019-07-30T00:21:31.263Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_04_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:22:19.398Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.9712223 53.4673482',
              '9.9640179 53.4693792',
              '9.9640957 53.4690982',
              '9.970482 53.4664557',
              '9.9712223 53.4673482',
            ],
          },
        },
      ],
    },
    id: '5d3f8dbb90e8b12127c0b5e2',
    lastUpdatedAt: '2019-07-30T00:22:26.172Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_05_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:27:18.16Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.757111 50.1327128',
              '8.7568106 50.1332905',
              '8.7411036 50.1323002',
              '8.7416829 50.1304983',
              '8.757111 50.1327128',
            ],
          },
        },
      ],
    },
    id: '5d3f8ee690e8b12127c0b5f0',
    lastUpdatedAt: '2019-07-30T00:27:30.614Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_01_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:34:52.97Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7612337 50.1146578',
              '8.7642378 50.1164465',
              '8.7569851 50.1212345',
              '8.7446901 50.1236091',
              '8.7404844 50.1221921',
              '8.7415787 50.121119',
              '8.7612337 50.1146578',
            ],
          },
        },
      ],
    },
    id: '5d3f90ac90e8b12127c0b5f9',
    lastUpdatedAt: '2019-07-30T00:35:17.879Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_02_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:36:19.482Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7612337 50.1146578',
              '8.7642378 50.1164465',
              '8.7569851 50.1212345',
              '8.7446901 50.1236091',
              '8.7404844 50.1221921',
              '8.7415787 50.121119',
              '8.7612337 50.1146578',
            ],
          },
        },
      ],
    },
    id: '5d3f910390e8b12127c0b600',
    lastUpdatedAt: '2019-07-30T00:36:37.369Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_02_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:48:40.658Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7516344 50.1444891',
              '8.753587 50.1432927',
              '8.7561619 50.1411337',
              '8.7578785 50.1418763',
              '8.7548315 50.1441866',
              '8.7526643 50.1446679',
              '8.7516344 50.1444891',
            ],
          },
        },
      ],
    },
    id: '5d3f93e890e8b12127c0b609',
    lastUpdatedAt: '2019-07-30T00:48:55.601Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_03_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:50:03.843Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6330474 50.1751307',
              '8.6376769 50.1810705',
              '8.635955 50.1816304',
              '8.6280478 50.1789922',
              '8.6299039 50.1766218',
              '8.6325405 50.175007',
              '8.6330474 50.1751307',
            ],
          },
        },
      ],
    },
    id: '5d3f943b90e8b12127c0b610',
    lastUpdatedAt: '2019-07-30T00:50:14.664Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_04_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:51:46.686Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5792698 50.1432696',
              '8.5784732 50.1402613',
              '8.5812171 50.1414922',
              '8.5792698 50.1432696',
            ],
          },
        },
      ],
    },
    id: '5d3f94a290e8b12127c0b617',
    lastUpdatedAt: '2019-07-30T00:51:53.108Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_05_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:53:52.873Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6295395 50.0844685',
              '8.6288382 50.0844674',
              '8.6236201 50.0818526',
              '8.6243145 50.0778127',
              '8.6269805 50.0779401',
              '8.6292738 50.0781871',
              '8.629915 50.0822724',
              '8.6295395 50.0844685',
            ],
          },
        },
      ],
    },
    id: '5d3f952090e8b12127c0b61e',
    lastUpdatedAt: '2019-07-30T00:54:12.389Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_06_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:55:13.531Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.632735 50.1236308',
              '8.6276913 50.122156',
              '8.6215866 50.1213683',
              '8.6216027 50.1209624',
              '8.6253953 50.1207664',
              '8.6337166 50.1222207',
              '8.632735 50.1236308',
            ],
          },
        },
      ],
    },
    id: '5d3f957190e8b12127c0b625',
    lastUpdatedAt: '2019-07-30T00:55:23.992Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_07_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:57:47.67Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5948021 50.096639',
              '8.592582 50.0999657',
              '8.5915413 50.0999865',
              '8.5925008 50.0965529',
              '8.5948021 50.096639',
            ],
          },
        },
      ],
    },
    id: '5d3f960b90e8b12127c0b62e',
    lastUpdatedAt: '2019-07-30T00:58:06.116Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_08_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T00:59:46.466Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7232524 50.1056585',
              '8.7218308 50.1055622',
              '8.721804 50.1046745',
              '8.7235689 50.1046917',
              '8.7232524 50.1056585',
            ],
          },
        },
      ],
    },
    id: '5d3f968290e8b12127c0b635',
    lastUpdatedAt: '2019-07-30T01:00:00.494Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_09_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_09',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:00:59.392Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6690049 50.0891627',
              '8.6700563 50.0880889',
              '8.6792831 50.0936368',
              '8.6778669 50.0946004',
              '8.6690049 50.0891627',
            ],
          },
        },
      ],
    },
    id: '5d3f96cb90e8b12127c0b63c',
    lastUpdatedAt: '2019-07-30T01:01:09.302Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_10_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_10',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:02:13.218Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5383282 50.1113854',
              '8.5353885 50.1141031',
              '8.5343371 50.1136697',
              '8.5372339 50.111007',
              '8.5383282 50.1113854',
            ],
          },
        },
      ],
    },
    id: '5d3f971590e8b12127c0b643',
    lastUpdatedAt: '2019-07-30T01:02:29.398Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_11_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_11',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:03:23.927Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.649809 50.1200506',
              '8.648307 50.1170718',
              '8.6496051 50.117003',
              '8.65056 50.1200437',
              '8.649809 50.1200506',
            ],
          },
        },
      ],
    },
    id: '5d3f975b90e8b12127c0b64a',
    lastUpdatedAt: '2019-07-30T01:03:32.768Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_12_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_12',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:08:21.363Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6198788 50.1009914',
              '8.6245674 50.0943982',
              '8.6438363 50.1027737',
              '8.6423772 50.1029871',
              '8.6198788 50.1009914',
            ],
          },
        },
      ],
    },
    id: '5d3f988590e8b12127c0b65c',
    lastUpdatedAt: '2019-07-30T01:08:44.859Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_13_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_13',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:09:49.462Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6765267 50.1686584',
              '8.6825992 50.162693',
              '8.6839886 50.1632428',
              '8.6778463 50.1688508',
              '8.6765267 50.1686584',
            ],
          },
        },
      ],
    },
    id: '5d3f98dd90e8b12127c0b663',
    lastUpdatedAt: '2019-07-30T01:09:59.952Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_14_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_14',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:10:50.33Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.6591277 50.1019015',
              '8.6505446 50.0998094',
              '8.6522827 50.0970015',
              '8.6602221 50.1006215',
              '8.6591277 50.1019015',
            ],
          },
        },
      ],
    },
    id: '5d3f991a90e8b12127c0b66a',
    lastUpdatedAt: '2019-07-30T01:11:14.36Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_15_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_15',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T01:11:59.971Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7088219 50.1112895',
              '8.7102461 50.1112396',
              '8.7116757 50.1112448',
              '8.7128237 50.1115957',
              '8.7126976 50.1130182',
              '8.7109569 50.1131369',
              '8.7091813 50.1124093',
              '8.7085295 50.1120532',
              '8.7088219 50.1112895',
            ],
          },
        },
      ],
    },
    id: '5d3f995f90e8b12127c0b671',
    lastUpdatedAt: '2019-07-30T01:12:22.416Z',
    version: 6,
    kmlFilename: 'planned_Frankfurt_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Frankfurt_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T13:12:39.427Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0257883 53.5512159',
              '10.0280414 53.5480542',
              '10.0314854 53.5488319',
              '10.0289319 53.5519871',
              '10.0257883 53.5512159',
            ],
          },
        },
      ],
    },
    id: '5d40424790e8b12127c0b69f',
    lastUpdatedAt: '2019-07-30T13:13:45.078Z',
    version: 6,
    kmlFilename: 'test_Hamburg_55-190730.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Test_Hamburg_55',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T15:02:17.562Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5d405bf990e8b1055e82819b',
    lastUpdatedAt: '2019-08-22T08:45:16.613Z',
    version: 7,
    kmlFilename: 'Unbenannte Karte.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Test_Frankfurt_55',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-12T16:19:21.811Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.3279933 51.3086419',
              '12.3277572 51.3093797',
              '12.3264751 51.3092674',
              '12.3265771 51.3088465',
              '12.3271001 51.3088063',
              '12.3272691 51.3089287',
              '12.3279933 51.3086419',
            ],
          },
        },
      ],
    },
    id: '5d51918990e8b16356070d53',
    lastUpdatedAt: '2019-08-12T16:20:57.353Z',
    version: 6,
    kmlFilename: 'planned_Leipzig_01_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:08:19.424Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.8211219 51.2187306',
              '6.8208624 51.2185565',
              '6.826283 51.2165359',
              '6.8263473 51.2166098',
              '6.8278655 51.2160755',
              '6.8281589 51.2158877',
              '6.8331264 51.2153569',
              '6.8360178 51.2150376',
              '6.8381796 51.2147654',
              '6.8427824 51.2142379',
              '6.847149 51.2137035',
              '6.8472455 51.2140731',
              '6.8426113 51.2145471',
              '6.838052 51.2150815',
              '6.8331222 51.2156159',
              '6.8282352 51.2161635',
              '6.824684 51.2174202',
              '6.8211219 51.2187306',
            ],
          },
        },
      ],
    },
    id: '5d5253d390e8b16356070def',
    lastUpdatedAt: '2019-10-09T12:05:04.631Z',
    version: 12,
    kmlFilename: 'DuÌsseldorf_01.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Düsseldorf_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:10:49.884Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.8537858 51.186996',
              '6.851833 51.188494',
              '6.8494509 51.1898575',
              '6.8487427 51.1891514',
              '6.8511572 51.1880695',
              '6.8525144 51.1864564',
              '6.8537858 51.186996',
            ],
          },
        },
      ],
    },
    id: '5d52546990e8b16356070e07',
    lastUpdatedAt: '2019-08-13T06:11:13.6Z',
    version: 6,
    kmlFilename: 'planned_Düsseldorf_02_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Düsseldorf_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:12:15.69Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7961618 51.1926763',
              '6.792321 51.1916896',
              '6.7895905 51.1912693',
              '6.7897414 51.1904351',
              '6.7899653 51.1893728',
              '6.7937243 51.1863082',
              '6.7987561 51.1840251',
              '6.802905 51.1826276',
              '6.8030873 51.1838986',
              '6.7969908 51.1852739',
              '6.7947525 51.1863247',
              '6.7935226 51.1871669',
              '6.792675 51.188569',
              '6.7917232 51.1890946',
              '6.7905193 51.1898051',
              '6.7903764 51.190351',
              '6.790593 51.1909978',
              '6.7912515 51.1911785',
              '6.7926127 51.191371',
              '6.7945319 51.1918459',
              '6.7964631 51.1921618',
              '6.7961618 51.1926763',
            ],
          },
        },
      ],
    },
    id: '5d5254bf90e8b16356070e10',
    lastUpdatedAt: '2019-08-13T06:12:50.063Z',
    version: 10,
    kmlFilename: 'planned_Düsseldorf_03_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Düsseldorf_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:13:03.392Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.8504643 51.1805113',
              '6.8503355 51.179792',
              '6.8515264 51.1799589',
              '6.8565528 51.1817126',
              '6.855885 51.1823019',
              '6.8504643 51.1805113',
            ],
          },
        },
      ],
    },
    id: '5d5254ef90e8b16356070e25',
    lastUpdatedAt: '2019-08-13T06:13:25.285Z',
    version: 6,
    kmlFilename: 'planned_Düsseldorf_04_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Düsseldorf_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:13:39.421Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7091391 51.2317757',
              '6.701692 51.2298525',
              '6.7019777 51.2266268',
              '6.7037405 51.2266969',
              '6.7050902 51.2294276',
              '6.70977 51.230904',
              '6.7091391 51.2317757',
            ],
          },
        },
      ],
    },
    id: '5d52551390e8b16356070e2e',
    lastUpdatedAt: '2019-09-13T13:47:35.732Z',
    version: 7,
    kmlFilename: 'planned_Düsseldorf_05_190913.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Düsseldorf_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:14:30.939Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.8119324 51.1984157',
              '6.8115569 51.1982006',
              '6.8115891 51.1977501',
              '6.8136169 51.1943817',
              '6.8165887 51.1971921',
              '6.8144215 51.1984359',
              '6.8119324 51.1984157',
            ],
          },
        },
      ],
    },
    id: '5d52554690e8b16356070e35',
    lastUpdatedAt: '2019-08-13T06:15:07.69Z',
    version: 6,
    kmlFilename: 'planned_Düsseldorf_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Düsseldorf_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T06:19:54.047Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0081847 51.4686346',
              '7.0083291 51.4763345',
              '7.006747 51.4761595',
              '7.0060711 51.4708667',
              '7.0069401 51.468247',
              '7.0081847 51.4686346',
            ],
          },
        },
      ],
    },
    id: '5d52568a90e8b16356070e3e',
    lastUpdatedAt: '2019-08-13T07:48:05.27Z',
    version: 8,
    kmlFilename: 'planned_Essen_01_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:48:29.059Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0618367 51.405943',
              '7.0635157 51.4072379',
              '7.0608926 51.4082686',
              '7.0587682 51.4075793',
              '7.0618367 51.405943',
            ],
          },
        },
      ],
    },
    id: '5d526b4d90e8b16356070e69',
    lastUpdatedAt: '2019-08-13T07:53:28.887Z',
    version: 6,
    kmlFilename: 'planned_Essen_02_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:54:06.228Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0070571 51.5073273',
              '7.0062417 51.5025592',
              '7.0102972 51.5027729',
              '7.0085591 51.507314',
              '7.0070571 51.5073273',
            ],
          },
        },
      ],
    },
    id: '5d526c9e90e8b16356070ef9',
    lastUpdatedAt: '2019-08-13T07:55:38.813Z',
    version: 5,
    kmlFilename: 'planned_Essen_03_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:56:21.118Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0438688 51.471042',
              '7.0446842 51.4675165',
              '7.0547049 51.4697287',
              '7.0544689 51.4712191',
              '7.0525591 51.4732841',
              '7.0438688 51.471042',
            ],
          },
        },
      ],
    },
    id: '5d526d2590e8b16356070eff',
    lastUpdatedAt: '2019-08-13T07:57:07.671Z',
    version: 5,
    kmlFilename: 'planned_Essen_04_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:57:28.263Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9936217 51.4141544',
              '6.9907249 51.4143953',
              '6.9881178 51.413773',
              '6.9878442 51.4128528',
              '6.9882358 51.4120398',
              '6.9930316 51.4130637',
              '6.9936217 51.4141544',
            ],
          },
        },
      ],
    },
    id: '5d526d6890e8b16356070f05',
    lastUpdatedAt: '2019-08-13T07:58:13.39Z',
    version: 5,
    kmlFilename: 'planned_Essen_05_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:58:52.108Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0554696 51.4307362',
              '7.0534634 51.4296459',
              '7.0517897 51.4281108',
              '7.0559739 51.4284552',
              '7.0562314 51.429666',
              '7.0554696 51.4307362',
            ],
          },
        },
      ],
    },
    id: '5d526dbc90e8b16356070f0b',
    lastUpdatedAt: '2019-08-13T07:59:21.076Z',
    version: 5,
    kmlFilename: 'planned_Essen_06_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T07:59:50.059Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9299891 51.4641331',
              '6.9258048 51.461152',
              '6.9276073 51.4589059',
              '6.9326069 51.4623418',
              '6.9299891 51.4641331',
            ],
          },
        },
      ],
    },
    id: '5d526df690e8b16356070f11',
    lastUpdatedAt: '2019-08-13T08:00:53.775Z',
    version: 6,
    kmlFilename: 'planned_Essen_07_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T08:01:16.357Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0249515 51.4590541',
              '7.0248039 51.4588272',
              '7.0249112 51.4583546',
              '7.0253832 51.4575692',
              '7.0266117 51.4575324',
              '7.0267699 51.4591952',
              '7.0249413 51.4592079',
              '7.0249515 51.4590541',
            ],
          },
        },
      ],
    },
    id: '5d526e4c90e8b16356070f1a',
    lastUpdatedAt: '2019-08-13T08:01:39.69Z',
    version: 5,
    kmlFilename: 'planned_Essen_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Essen_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T08:08:09.091Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.7011964 52.4085176',
              '9.7038464 52.4053709',
              '9.7050346 52.4056164',
              '9.7016765 52.4086566',
              '9.7011964 52.4085176',
            ],
          },
        },
      ],
    },
    id: '5d526fe990e8b16356070f23',
    lastUpdatedAt: '2019-08-13T08:08:38.634Z',
    version: 6,
    kmlFilename: 'planned_Hannover_01_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hannover_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T08:09:07.527Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.8235958 52.3148',
              '9.8180705 52.3138752',
              '9.8183387 52.3129767',
              '9.8240894 52.3139146',
              '9.8235958 52.3148',
            ],
          },
        },
      ],
    },
    id: '5d52702390e8b16356070f2c',
    lastUpdatedAt: '2019-09-16T18:06:43.489Z',
    version: 6,
    kmlFilename: 'planned_Hannover_02_190916.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Hannover_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T08:11:03.687Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.7608311 52.342094',
              '9.760528 52.3425496',
              '9.7591654 52.3430936',
              '9.7578029 52.342661',
              '9.7580255 52.3418089',
              '9.7593505 52.3420432',
              '9.7594015 52.3418056',
              '9.7608311 52.342094',
            ],
          },
        },
      ],
    },
    id: '5d52709790e8b16356070f33',
    lastUpdatedAt: '2019-08-13T08:11:30.03Z',
    version: 5,
    kmlFilename: 'planned_Hannover_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hannover_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:00:48.28Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9144185 50.9507627',
              '6.9065972 50.9501949',
              '6.9074555 50.9372359',
              '6.9251366 50.9368302',
              '6.9286557 50.944429',
              '6.9144185 50.9507627',
            ],
          },
        },
      ],
    },
    id: '5d527c4090e8b166a899f864',
    lastUpdatedAt: '2019-08-13T09:01:25.585Z',
    version: 5,
    kmlFilename: 'planned_Köln_01_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Köln_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:05:02.223Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9626522 50.9035629',
              '6.9635979 50.9001821',
              '6.9691003 50.900234',
              '6.9673193 50.9036915',
              '6.9626522 50.9035629',
            ],
          },
        },
      ],
    },
    id: '5d527d3e90e8b166a899f86a',
    lastUpdatedAt: '2019-08-13T09:05:30.902Z',
    version: 5,
    kmlFilename: 'planned_Köln_03_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Köln_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:08:58.861Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9695799 50.8625558',
              '6.9692581 50.858208',
              '6.9782703 50.8582215',
              '6.9776051 50.8631246',
              '6.9695799 50.8625558',
            ],
          },
        },
      ],
    },
    id: '5d527e2a90e8b166a899f870',
    lastUpdatedAt: '2019-08-13T09:10:36.949Z',
    version: 5,
    kmlFilename: 'planned_Köln_04_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Köln_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:11:24.873Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9036123 50.9868727',
              '6.8910273 50.9869876',
              '6.890963 50.9816991',
              '6.9042238 50.9816451',
              '6.9036123 50.9868727',
            ],
          },
        },
      ],
    },
    id: '5d527ebc90e8b166a899f876',
    lastUpdatedAt: '2019-08-13T09:11:51.06Z',
    version: 5,
    kmlFilename: 'planned_Köln_05_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Köln_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:14:47.503Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0169348 50.9491272',
              '7.0169215 50.9494027',
              '7.0155239 50.9495447',
              '7.0154182 50.9505512',
              '7.0157247 50.9512167',
              '7.0159883 50.951203',
              '7.0201188 50.9482245',
              '7.0208106 50.9483637',
              '7.0161862 50.951748',
              '7.0156331 50.952038',
              '7.0150687 50.9518541',
              '7.014381 50.9506474',
              '7.0146134 50.9495269',
              '7.0155341 50.9485227',
              '7.0164145 50.948039',
              '7.0170824 50.9480559',
              '7.0168185 50.9485312',
              '7.0163079 50.9486549',
              '7.0156836 50.9491593',
              '7.0169348 50.9491272',
            ],
          },
        },
      ],
    },
    id: '5d527f8790e8b166a899f87c',
    lastUpdatedAt: '2019-09-19T13:53:01.013Z',
    version: 7,
    kmlFilename: 'planned_KoÌln_06_190917.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Köln_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:15:35.324Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.9077957 50.9439316',
              '6.9050961 50.943515',
              '6.9084971 50.9418689',
              '6.9095592 50.9418892',
              '6.9082865 50.9440804',
              '6.9077957 50.9439316',
            ],
          },
        },
      ],
    },
    id: '5d527fb790e8b166a899f882',
    lastUpdatedAt: '2019-08-13T09:15:55.999Z',
    version: 5,
    kmlFilename: 'planned_Köln_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Köln_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:17:21.791Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.4017509 51.3216771',
              '12.3952598 51.3187068',
              '12.3949327 51.3165894',
              '12.39699 51.3154436',
              '12.399884 51.3145257',
              '12.4060531 51.319223',
              '12.4038162 51.3212983',
              '12.4017509 51.3216771',
            ],
          },
        },
      ],
    },
    id: '5d52802190e8b166a899f888',
    lastUpdatedAt: '2019-09-09T07:24:57.717Z',
    version: 7,
    kmlFilename: 'planned_Leipzig_02_190908.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:24:14.572Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.3703086 51.3039216',
              '12.3729908 51.3057259',
              '12.3699975 51.3091062',
              '12.3660707 51.3086904',
              '12.3675568 51.3060378',
              '12.3703086 51.3039216',
            ],
          },
        },
      ],
    },
    id: '5d5281be90e8b166a899f905',
    lastUpdatedAt: '2019-08-13T09:25:11.608Z',
    version: 6,
    kmlFilename: 'planned_Leipzig_03_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:25:30.313Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.4674361 51.3501114',
              '12.4706526 51.3445876',
              '12.480384 51.3447485',
              '12.4799782 51.3521015',
              '12.4734175 51.3514483',
              '12.4674361 51.3501114',
            ],
          },
        },
      ],
    },
    id: '5d52820a90e8b166a899f90e',
    lastUpdatedAt: '2019-09-09T07:25:11.326Z',
    version: 7,
    kmlFilename: 'planned_Leipzig_04_190907.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:26:26.38Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.9672068 49.5769632',
              '10.9660897 49.5769632',
              '10.9633123 49.5767719',
              '10.9634223 49.5765388',
              '10.9653186 49.576605',
              '10.9672673 49.5767285',
              '10.9672068 49.5769632',
            ],
          },
        },
      ],
    },
    id: '5d52824290e8b166a899f917',
    lastUpdatedAt: '2019-08-13T09:27:59.474Z',
    version: 6,
    kmlFilename: 'planned_Erlangen_01_190722.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Erlangen_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:28:18.428Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.9749037 49.5871446',
              '10.9777898 49.5866021',
              '10.9778219 49.5870021',
              '10.9774947 49.5872698',
              '10.977087 49.5873811',
              '10.9751612 49.5875689',
              '10.9749037 49.5871446',
            ],
          },
        },
      ],
    },
    id: '5d5282b290e8b166a899f920',
    lastUpdatedAt: '2019-08-13T09:31:07.001Z',
    version: 5,
    kmlFilename: 'planned_Erlangen_03_190620.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Erlangen_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:31:39.612Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0336035 49.5890874',
              '11.0334962 49.5883676',
              '11.0331207 49.5884023',
              '11.0330563 49.588065',
              '11.0335284 49.5880059',
              '11.033743 49.5880755',
              '11.0346871 49.5880476',
              '11.0348749 49.5889727',
              '11.0336035 49.5890874',
            ],
          },
        },
      ],
    },
    id: '5d52837b90e8b166a899f926',
    lastUpdatedAt: '2019-08-13T09:32:05.897Z',
    version: 5,
    kmlFilename: 'planned_Erlangen_02_190620.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Erlangen_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:33:43.64Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.6349637 48.128828',
              '11.6339874 48.1267011',
              '11.667987 48.1197828',
              '11.6787051 48.1177702',
              '11.689847 48.1146456',
              '11.7015452 48.1123713',
              '11.7030395 48.1150105',
              '11.6941521 48.118928',
              '11.6861405 48.1207464',
              '11.6810335 48.1218278',
              '11.6790592 48.1206996',
              '11.6349637 48.128828',
            ],
          },
        },
      ],
    },
    id: '5d5283f790e8b166a899f92c',
    lastUpdatedAt: '2019-08-13T09:34:19.18Z',
    version: 5,
    kmlFilename: 'planned_München_01_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'München_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:34:43.298Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.4461456 48.1599124',
              '11.4234706 48.1540718',
              '11.4282309 48.1514384',
              '11.4367671 48.1494345',
              '11.452379 48.1466851',
              '11.4574108 48.1469285',
              '11.4574108 48.1525119',
              '11.447015 48.1601916',
              '11.4461456 48.1599124',
            ],
          },
        },
      ],
    },
    id: '5d52843390e8b166a899f932',
    lastUpdatedAt: '2019-08-13T09:36:14.576Z',
    version: 7,
    kmlFilename: 'planned_München_03_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'München_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:36:42.139Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0939189 49.4702142',
              '11.0925885 49.4691022',
              '11.096923 49.4655741',
              '11.1019762 49.4708871',
              '11.1000236 49.4720165',
              '11.0939189 49.4702142',
            ],
          },
        },
      ],
    },
    id: '5d5284aa90e8b166a899f93c',
    lastUpdatedAt: '2019-08-13T09:38:13.51Z',
    version: 6,
    kmlFilename: 'planned_Nürnberg_01_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:40:09.828Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.1291859 49.4026324',
              '11.1360523 49.3972981',
              '11.1441204 49.4014316',
              '11.1366102 49.4061231',
              '11.1291859 49.4026324',
            ],
          },
        },
      ],
    },
    id: '5d52857990e8b166a899f947',
    lastUpdatedAt: '2019-08-13T09:41:02.286Z',
    version: 6,
    kmlFilename: 'planned_Nürnberg_04_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:41:26.428Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0361031 49.4361504',
              '11.0399901 49.4314335',
              '11.0508477 49.435871',
              '11.0425221 49.4408384',
              '11.0346686 49.4382431',
              '11.0361031 49.4361504',
            ],
          },
        },
      ],
    },
    id: '5d5285c690e8b166a899f94e',
    lastUpdatedAt: '2019-08-13T09:41:54.229Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_02_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:42:47.992Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.1475121 49.4661475',
              '11.1321358 49.4632971',
              '11.1349682 49.4593642',
              '11.1495828 49.463442',
              '11.1475121 49.4661475',
            ],
          },
        },
      ],
    },
    id: '5d52861790e8b166a899f954',
    lastUpdatedAt: '2019-08-13T09:43:07.87Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_06_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:43:37.389Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0092586 49.4543088',
              '11.0070699 49.4503542',
              '11.0167477 49.4477738',
              '11.0186462 49.4473479',
              '11.0198264 49.454539',
              '11.0092586 49.4543088',
            ],
          },
        },
      ],
    },
    id: '5d52864990e8b166a899f95a',
    lastUpdatedAt: '2019-08-13T09:44:03.634Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_07_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:44:24.848Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.1228809 49.486508',
              '11.1151561 49.4813222',
              '11.1185894 49.4766099',
              '11.1285886 49.4774744',
              '11.1317214 49.4831903',
              '11.1228809 49.486508',
            ],
          },
        },
      ],
    },
    id: '5d52867890e8b166a899f961',
    lastUpdatedAt: '2019-08-13T09:44:56.933Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_09_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_09',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:45:19.229Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0697635 49.4702519',
              '11.0692888 49.4698039',
              '11.0700465 49.4697002',
              '11.0708311 49.4696871',
              '11.0707694 49.4700671',
              '11.0697635 49.4702519',
            ],
          },
        },
      ],
    },
    id: '5d5286af90e8b166a899f967',
    lastUpdatedAt: '2019-08-13T09:45:47.285Z',
    version: 6,
    kmlFilename: 'planned_Nürnberg_10_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_10',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:46:14.743Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0169804 49.4308427',
              '11.0086119 49.4258744',
              '11.011101 49.4233063',
              '11.0257781 49.4278562',
              '11.0169804 49.4308427',
            ],
          },
        },
      ],
    },
    id: '5d5286e690e8b166a899f970',
    lastUpdatedAt: '2019-08-13T09:46:38.984Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_13_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_13',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:46:59.148Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.1029075 49.4312844',
              '11.0984512 49.4312381',
              '11.0967379 49.4282626',
              '11.1034094 49.428867',
              '11.1029075 49.4312844',
            ],
          },
        },
      ],
    },
    id: '5d52871390e8b166a899f976',
    lastUpdatedAt: '2019-08-13T09:47:22.927Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_16_190621.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_16',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:47:45.256Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.0341172 49.4336651',
              '11.0239771 49.4331148',
              '11.0253517 49.4315858',
              '11.0267465 49.4311393',
              '11.0285597 49.4315754',
              '11.0289874 49.4310181',
              '11.0302012 49.4313905',
              '11.0296755 49.4321179',
              '11.0309844 49.4324162',
              '11.034321 49.4333163',
              '11.0341172 49.4336651',
            ],
          },
        },
      ],
    },
    id: '5d52874190e8b166a899f97c',
    lastUpdatedAt: '2019-08-13T09:48:21.865Z',
    version: 5,
    kmlFilename: 'planned_Nürnberg_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Nürnberg_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:48:47.192Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7417692 50.1125194',
              '8.7421983 50.1111983',
              '8.7561458 50.1102076',
              '8.7673896 50.1065194',
              '8.7835687 50.1046753',
              '8.8016541 50.1102225',
              '8.8144329 50.1150588',
              '8.8205188 50.1203347',
              '8.8170836 50.1201573',
              '8.7803501 50.1109506',
              '8.7417692 50.1125194',
            ],
          },
        },
      ],
    },
    id: '5d52877f90e8b166a899f982',
    lastUpdatedAt: '2019-08-13T09:49:53.144Z',
    version: 5,
    kmlFilename: 'planned_Offenbach_01_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Offenbach_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:50:21.23Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.798428 50.0982907',
              '8.7921837 50.0964876',
              '8.7989536 50.0935693',
              '8.8028697 50.0964737',
              '8.798428 50.0982907',
            ],
          },
        },
      ],
    },
    id: '5d5287dd90e8b166a899f988',
    lastUpdatedAt: '2019-08-13T09:50:43.567Z',
    version: 5,
    kmlFilename: 'planned_Offenbach_02_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Offenbach_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:51:04.129Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.75466 50.0966077',
              '8.7502505 50.0917416',
              '8.7514521 50.0912873',
              '8.7551536 50.0964425',
              '8.75466 50.0966077',
            ],
          },
        },
      ],
    },
    id: '5d52880890e8b166a899f98e',
    lastUpdatedAt: '2019-08-13T09:51:27.484Z',
    version: 5,
    kmlFilename: 'planned_Offenbach_03_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Offenbach_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:51:58.52Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.1522751 48.8353083',
              '9.1553221 48.8298844',
              '9.1667375 48.836297',
              '9.1751489 48.8346868',
              '9.1749773 48.838133',
              '9.1624457 48.837498',
              '9.1522751 48.8353083',
            ],
          },
        },
      ],
    },
    id: '5d52883e90e8b166a899f994',
    lastUpdatedAt: '2019-08-13T09:54:08.911Z',
    version: 5,
    kmlFilename: 'planned_Stuttgart_01_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Stuttgart_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T09:55:19.358Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.1692676 48.8151659',
              '9.1716495 48.8128627',
              '9.1760911 48.8112942',
              '9.1784086 48.8139507',
              '9.174117 48.8200262',
              '9.1692676 48.8151659',
            ],
          },
        },
      ],
    },
    id: '5d52890790e8b166a899f99a',
    lastUpdatedAt: '2019-08-13T10:01:12.287Z',
    version: 5,
    kmlFilename: 'planned_Stuttgart_02_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Stuttgart_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:04:48.102Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '9.0902549 48.8253912',
              '9.0940744 48.8199098',
              '9.1010052 48.8189493',
              '9.1091806 48.8203619',
              '9.1102106 48.8243599',
              '9.1087086 48.8292195',
              '9.1007691 48.8275105',
              '9.0902549 48.8253912',
            ],
          },
        },
      ],
    },
    id: '5d528b4090e8b17a336ece05',
    lastUpdatedAt: '2019-08-13T10:05:22.088Z',
    version: 5,
    kmlFilename: 'planned_Stuttgart_03_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Stuttgart_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:05:53.703Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.4698252 48.2609375',
              '16.4671859 48.2565946',
              '16.4650616 48.251437',
              '16.4723143 48.2513084',
              '16.4734945 48.2530229',
              '16.4752325 48.2554803',
              '16.4739879 48.2557803',
              '16.4730438 48.2547231',
              '16.4714989 48.2551802',
              '16.4720139 48.2564517',
              '16.475254 48.2555231',
              '16.4766487 48.2592232',
              '16.4698252 48.2609375',
            ],
          },
        },
      ],
    },
    id: '5d528b8190e8b17a336ece0b',
    lastUpdatedAt: '2019-08-13T10:07:16.381Z',
    version: 5,
    kmlFilename: 'planned_Wien_01_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:07:46.385Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.4600558 48.245948',
              '16.4637278 48.244773',
              '16.466878 48.247411',
              '16.4669491 48.2476913',
              '16.4668096 48.2478699',
              '16.4625234 48.2494524',
              '16.4600558 48.245948',
            ],
          },
        },
      ],
    },
    id: '5d528bf290e8b17a336ece11',
    lastUpdatedAt: '2019-08-13T10:08:17.65Z',
    version: 5,
    kmlFilename: 'planned_Wien_02_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:08:42.399Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.4633945 48.2704113',
              '16.45479 48.2645694',
              '16.4648322 48.2611839',
              '16.46848 48.2664263',
              '16.4633945 48.2704113',
            ],
          },
        },
      ],
    },
    id: '5d528c2a90e8b17a336ece17',
    lastUpdatedAt: '2019-08-13T10:09:09.392Z',
    version: 5,
    kmlFilename: 'planned_Wien_03_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:09:40.123Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.3558289 48.145431',
              '16.3539433 48.1406989',
              '16.3559174 48.140323',
              '16.3582375 48.1448548',
              '16.3558289 48.145431',
            ],
          },
        },
      ],
    },
    id: '5d528c6490e8b17a336ece1d',
    lastUpdatedAt: '2019-08-13T10:10:05.965Z',
    version: 5,
    kmlFilename: 'planned_Wien_04_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:10:28.018Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.2998437 48.1293733',
              '16.303749 48.1282275',
              '16.3068013 48.1364335',
              '16.3031911 48.1366483',
              '16.2998437 48.1293733',
            ],
          },
        },
      ],
    },
    id: '5d528c9490e8b17a336ece23',
    lastUpdatedAt: '2019-08-13T10:11:13.787Z',
    version: 5,
    kmlFilename: 'planned_Wien_05_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:11:38.097Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.3630798 48.143712',
              '16.3601428 48.1391265',
              '16.3647375 48.1382529',
              '16.3678729 48.142171',
              '16.3630798 48.143712',
            ],
          },
        },
      ],
    },
    id: '5d528cda90e8b17a336ece29',
    lastUpdatedAt: '2019-08-13T10:12:10.346Z',
    version: 5,
    kmlFilename: 'planned_Wien_06_19-08-01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:13:24.257Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1505724 51.2245204',
              '7.1505402 51.2227801',
              '7.1537803 51.2227734',
              '7.1536301 51.2246414',
              '7.1505724 51.2245204',
            ],
          },
        },
      ],
    },
    id: '5d528d4490e8b17a336ece2f',
    lastUpdatedAt: '2019-08-13T10:13:44.158Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_01-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:14:04.23Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1374794 51.2505771',
              '7.1379085 51.2509397',
              '7.1337887 51.2534915',
              '7.1319274 51.2529878',
              '7.1286715 51.2514633',
              '7.1315714 51.249797',
              '7.1374794 51.2505771',
            ],
          },
        },
      ],
    },
    id: '5d528d6c90e8b17a336ece35',
    lastUpdatedAt: '2019-08-13T10:14:47.577Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_02_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:15:26.788Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2838629 51.2928002',
              '7.2610319 51.2837151',
              '7.2635639 51.2791249',
              '7.2854078 51.2834198',
              '7.2838629 51.2928002',
            ],
          },
        },
      ],
    },
    id: '5d528dbe90e8b17a336ece3b',
    lastUpdatedAt: '2019-08-13T10:16:07.133Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_03-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:16:27.871Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1177567 51.2490159',
              '7.1173919 51.249862',
              '7.1089805 51.249721',
              '7.1088732 51.2480555',
              '7.1114534 51.2482134',
              '7.1177567 51.2490159',
            ],
          },
        },
      ],
    },
    id: '5d528dfb90e8b17a336ece41',
    lastUpdatedAt: '2019-08-13T10:16:51.112Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_04-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:17:11.902Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1451063 51.2469217',
              '7.1474455 51.242865',
              '7.1532173 51.2388619',
              '7.1568651 51.2399635',
              '7.1478529 51.2481305',
              '7.1451063 51.2469217',
            ],
          },
        },
      ],
    },
    id: '5d528e2790e8b17a336ece47',
    lastUpdatedAt: '2019-08-13T10:17:39.404Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_05-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:18:03.15Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1127836 51.261875',
              '7.1059922 51.2603376',
              '7.1065394 51.2568798',
              '7.1090606 51.2560539',
              '7.1111205 51.2572222',
              '7.1135882 51.260163',
              '7.1127836 51.261875',
            ],
          },
        },
      ],
    },
    id: '5d528e5b90e8b17a336ece4d',
    lastUpdatedAt: '2019-08-13T10:18:23.276Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_06-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:18:47.412Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1911143 51.2479833',
              '7.1901595 51.2459484',
              '7.1927666 51.2455522',
              '7.1935713 51.2477013',
              '7.1911143 51.2479833',
            ],
          },
        },
      ],
    },
    id: '5d528e8790e8b17a336ece53',
    lastUpdatedAt: '2019-08-13T10:19:11.197Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_07-190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-13T10:19:31.554Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5d528eb390e8b17a336ece59',
    lastUpdatedAt: '2019-08-13T10:19:52.141Z',
    version: 5,
    kmlFilename: 'planned_Wuppertal_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wuppertal_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:05:44.613Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5691031 52.0394945',
              '8.5721072 52.0408507',
              '8.5784023 52.0444967',
              '8.5770853 52.0451532',
              '8.5711201 52.0433716',
              '8.5669144 52.0415502',
              '8.5691031 52.0394945',
            ],
          },
        },
      ],
    },
    id: '5d53eb0890e8b15ba9a3b522',
    lastUpdatedAt: '2019-08-14T11:06:40.021Z',
    version: 5,
    kmlFilename: 'planned_Bielefeld_01_190618.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bielefeld_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:07:23.823Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5783025 52.0386416',
              '8.5742255 52.0369389',
              '8.5735737 52.0363433',
              '8.5724231 52.0348665',
              '8.5725947 52.0345761',
              '8.5770579 52.0365561',
              '8.577766 52.0371369',
              '8.5784741 52.0379685',
              '8.5786672 52.0386284',
              '8.5783025 52.0386416',
            ],
          },
        },
      ],
    },
    id: '5d53eb6b90e8b15ba9a3b527',
    lastUpdatedAt: '2019-08-14T11:07:44.847Z',
    version: 6,
    kmlFilename: 'planned_Bielefeld_02_190618.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bielefeld_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:08:52.366Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5583096 51.9982533',
              '8.5598601 52.0040959',
              '8.5579959 52.0044336',
              '8.556133 51.9988544',
              '8.5583096 51.9982533',
            ],
          },
        },
      ],
    },
    id: '5d53ebc490e8b15ba9a3b52f',
    lastUpdatedAt: '2019-08-14T11:09:15.906Z',
    version: 5,
    kmlFilename: 'planned_Bielefeld_03_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bielefeld_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:09:35.305Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.4949348 51.9807306',
              '8.4932772 51.9804201',
              '8.489388 51.9798022',
              '8.4895436 51.9790522',
              '8.4895275 51.9781071',
              '8.4926925 51.9784574',
              '8.4941033 51.9783219',
              '8.4949348 51.9807306',
            ],
          },
        },
      ],
    },
    id: '5d53ebef90e8b15ba9a3b535',
    lastUpdatedAt: '2019-08-14T11:09:59.608Z',
    version: 5,
    kmlFilename: 'planned_Bielefeld_04_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bielefeld_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:10:39.703Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5540249 52.0370043',
              '8.5534332 52.0374128',
              '8.5516898 52.0375976',
              '8.5532738 52.0365819',
              '8.5540249 52.0370043',
            ],
          },
        },
      ],
    },
    id: '5d53ec2f90e8b15ba9a3b53b',
    lastUpdatedAt: '2019-08-14T11:11:08.834Z',
    version: 5,
    kmlFilename: 'planned_Bielefeld_99_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bielefeld_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:16:26.898Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2642333 51.4564981',
              '7.2615725 51.4581426',
              '7.2567123 51.4553348',
              '7.2592551 51.4529681',
              '7.2642333 51.4564981',
            ],
          },
        },
      ],
    },
    id: '5d53ed8a90e8b15ba9a3b541',
    lastUpdatedAt: '2019-08-14T11:17:46.037Z',
    version: 5,
    kmlFilename: 'planned_Bochum_01_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:21:40.763Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1984068 51.4910467',
              '7.1920554 51.4896839',
              '7.193214 51.4801701',
              '7.1948234 51.4794084',
              '7.2001664 51.481052',
              '7.1984068 51.4910467',
            ],
          },
        },
      ],
    },
    id: '5d53eec490e8b15ba9a3b547',
    lastUpdatedAt: '2019-08-14T11:22:08.342Z',
    version: 5,
    kmlFilename: 'planned_Bochum_02_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:22:30.413Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2668398 51.4720731',
              '7.271981 51.4737398',
              '7.2736708 51.4742577',
              '7.2739014 51.4748825',
              '7.2754572 51.4763326',
              '7.2752987 51.4769015',
              '7.2663785 51.4741047',
              '7.2668398 51.4720731',
            ],
          },
        },
      ],
    },
    id: '5d53eef690e8b15ba9a3b54d',
    lastUpdatedAt: '2019-08-14T11:22:51.643Z',
    version: 5,
    kmlFilename: 'planned_Bochum_03_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:23:12.98Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1260903 51.4851426',
              '7.1259723 51.4864722',
              '7.1198247 51.486098',
              '7.118387 51.4841806',
              '7.1260903 51.4851426',
            ],
          },
        },
      ],
    },
    id: '5d53ef2090e8b15ba9a3b553',
    lastUpdatedAt: '2019-08-14T11:23:41.291Z',
    version: 5,
    kmlFilename: 'planned_Bochum_04_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:23:57.371Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2623193 51.4962222',
              '7.2626144 51.4956378',
              '7.2697542 51.4969237',
              '7.274105 51.4991444',
              '7.2719055 51.4993381',
              '7.2690517 51.4990042',
              '7.2654039 51.496947',
              '7.2623193 51.4962222',
            ],
          },
        },
      ],
    },
    id: '5d53ef4d90e8b15ba9a3b559',
    lastUpdatedAt: '2019-08-14T11:24:19.684Z',
    version: 5,
    kmlFilename: 'planned_Bochum_05_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:24:29.916Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2126389 51.4812149',
              '7.2125155 51.4815189',
              '7.2113354 51.4812282',
              '7.2110457 51.4815088',
              '7.2100667 51.4814905',
              '7.2082133 51.4806102',
              '7.2084439 51.4801491',
              '7.2126389 51.4812149',
            ],
          },
        },
      ],
    },
    id: '5d53ef6d90e8b15ba9a3b55f',
    lastUpdatedAt: '2019-08-14T11:24:51.422Z',
    version: 5,
    kmlFilename: 'planned_Bochum_99_190625.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bochum_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:25:02.717Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1238576 50.7444922',
              '7.1239541 50.7438778',
              '7.1253382 50.743944',
              '7.1261831 50.7435243',
              '7.1260302 50.7443089',
              '7.1253789 50.7443038',
              '7.1253285 50.7444209',
              '7.1252034 50.7445515',
              '7.1238576 50.7444922',
            ],
          },
        },
      ],
    },
    id: '5d53ef8e90e8b15ba9a3b565',
    lastUpdatedAt: '2019-08-14T11:25:31.031Z',
    version: 5,
    kmlFilename: 'planned_Bonn_01_190619.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:25:38.96Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1541545 50.7511358',
              '7.1547955 50.7515261',
              '7.1556297 50.7517637',
              '7.1554527 50.7519571',
              '7.1536985 50.7518434',
              '7.1533498 50.7516814',
              '7.1541545 50.7511358',
            ],
          },
        },
      ],
    },
    id: '5d53efb290e8b15ba9a3b56b',
    lastUpdatedAt: '2019-08-14T11:26:26.184Z',
    version: 5,
    kmlFilename: 'planned_Bonn_02_190619.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:26:49.279Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0645842 50.7533139',
              '7.061336 50.7550235',
              '7.0587651 50.753174',
              '7.0619839 50.7514083',
              '7.0645842 50.7533139',
            ],
          },
        },
      ],
    },
    id: '5d53eff990e8b15ba9a3b570',
    lastUpdatedAt: '2019-08-14T11:29:22.63Z',
    version: 6,
    kmlFilename: 'planned_Bonn_03_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:28:46.492Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0423148 50.7473243',
              '7.0412527 50.7456611',
              '7.0498894 50.7436312',
              '7.0508389 50.7465368',
              '7.0480656 50.7464215',
              '7.0423148 50.7473243',
            ],
          },
        },
      ],
    },
    id: '5d53f06e90e8b15ba9a3b577',
    lastUpdatedAt: '2019-08-14T11:29:10.414Z',
    version: 5,
    kmlFilename: 'planned_Bonn_04_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:29:53.766Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1328495 50.7470186',
              '7.1323426 50.7491884',
              '7.1302813 50.7488957',
              '7.1291857 50.7471596',
              '7.1328495 50.7470186',
            ],
          },
        },
      ],
    },
    id: '5d53f0b190e8b15ba9a3b580',
    lastUpdatedAt: '2019-08-14T11:30:16.866Z',
    version: 5,
    kmlFilename: 'planned_Bonn_05_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:30:24.745Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0889417 50.7527407',
              '7.0863078 50.7519261',
              '7.0866994 50.7507161',
              '7.090028 50.7516003',
              '7.0889417 50.7527407',
            ],
          },
        },
      ],
    },
    id: '5d53f0d090e8b15ba9a3b586',
    lastUpdatedAt: '2019-08-14T11:30:45.167Z',
    version: 6,
    kmlFilename: 'planned_Bonn_06_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:31:51.813Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0390682 50.7134977',
              '7.0419746 50.7131217',
              '7.0426344 50.7147334',
              '7.0397898 50.7149074',
              '7.0390682 50.7134977',
            ],
          },
        },
      ],
    },
    id: '5d53f12790e8b15ba9a3b58d',
    lastUpdatedAt: '2019-08-14T11:32:15.273Z',
    version: 5,
    kmlFilename: 'planned_Bonn_07_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:32:23.727Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0693033 50.7562129',
              '7.0675451 50.7549436',
              '7.0682417 50.7544283',
              '7.0700952 50.7558685',
              '7.0693033 50.7562129',
            ],
          },
        },
      ],
    },
    id: '5d53f14790e8b15ba9a3b593',
    lastUpdatedAt: '2019-08-14T11:32:43.384Z',
    version: 5,
    kmlFilename: 'planned_Bonn_08_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:32:56.788Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1542147 50.7224913',
              '7.1553038 50.7214487',
              '7.1565537 50.7220531',
              '7.156264 50.7221738',
              '7.1556471 50.7226781',
              '7.1542147 50.7224913',
            ],
          },
        },
      ],
    },
    id: '5d53f16890e8b15ba9a3b599',
    lastUpdatedAt: '2019-08-14T11:33:18.429Z',
    version: 5,
    kmlFilename: 'planned_Bonn_09_190628.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_09',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:33:30.707Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0712898 50.7097182',
              '7.0717404 50.7117564',
              '7.0725019 50.7144645',
              '7.0695681 50.7137576',
              '7.0705548 50.7096877',
              '7.0712898 50.7097182',
            ],
          },
        },
      ],
    },
    id: '5d53f18a90e8b15ba9a3b59f',
    lastUpdatedAt: '2019-08-14T11:33:48.601Z',
    version: 5,
    kmlFilename: 'planned_Bonn_10_100629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_10',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:33:57.151Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1448399 50.6926534',
              '7.1391054 50.6959734',
              '7.1351626 50.6941742',
              '7.1415195 50.6909679',
              '7.1448399 50.6926534',
            ],
          },
        },
      ],
    },
    id: '5d53f1a590e8b15ba9a3b5a5',
    lastUpdatedAt: '2019-08-14T11:35:54.152Z',
    version: 5,
    kmlFilename: 'planned_Bonn_11_190629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_11',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:36:06.177Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1759473 50.672425',
              '7.1719401 50.6718164',
              '7.1741529 50.6666774',
              '7.1744533 50.6658716',
              '7.1748502 50.6659872',
              '7.1774895 50.6683536',
              '7.1760627 50.6711824',
              '7.1759473 50.672425',
            ],
          },
        },
      ],
    },
    id: '5d53f22690e8b15ba9a3b5ab',
    lastUpdatedAt: '2019-08-14T11:36:29.216Z',
    version: 5,
    kmlFilename: 'planned_Bonn_12_190629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_12',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:37:24.81Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.2033039 50.6537955',
              '7.1873321 50.6699335',
              '7.1836038 50.6686058',
              '7.1943128 50.6513215',
              '7.2033039 50.6537955',
            ],
          },
        },
      ],
    },
    id: '5d53f27490e8b15ba9a3b5b1',
    lastUpdatedAt: '2019-08-14T11:37:44.613Z',
    version: 5,
    kmlFilename: 'planned_Bonn_13_190629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_13',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:37:54.615Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.159823 50.7047391',
              '7.1532598 50.7043602',
              '7.1442046 50.6993155',
              '7.1414311 50.6973499',
              '7.1415167 50.6971137',
              '7.1441721 50.696966',
              '7.1617731 50.7026089',
              '7.159823 50.7047391',
            ],
          },
        },
      ],
    },
    id: '5d53f29290e8b15ba9a3b5b7',
    lastUpdatedAt: '2019-08-14T11:38:26.472Z',
    version: 5,
    kmlFilename: 'planned_Bonn_14_100629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_14',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:39:50.41Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.051802 50.7399642',
              '7.0516733 50.7394381',
              '7.0605139 50.7391124',
              '7.0648376 50.7387117',
              '7.0661357 50.7388541',
              '7.0650575 50.7398149',
              '7.0606158 50.7399',
              '7.051802 50.7399642',
            ],
          },
        },
      ],
    },
    id: '5d53f30690e8b15ba9a3b5bd',
    lastUpdatedAt: '2019-08-14T11:40:15.55Z',
    version: 5,
    kmlFilename: 'planned_Bonn_98_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn_98',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:40:34.15Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1317123 50.7345616',
              '7.1320878 50.7327248',
              '7.1355854 50.7338622',
              '7.1346305 50.7353221',
              '7.1317123 50.7345616',
            ],
          },
        },
      ],
    },
    id: '5d53f33290e8b15ba9a3b5c3',
    lastUpdatedAt: '2019-08-14T11:41:00.959Z',
    version: 5,
    kmlFilename: 'planned_Bonn2_98_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bonn2_98',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:41:22.603Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0198673 50.7581366',
              '7.0232536 50.7560106',
              '7.0257883 50.7571152',
              '7.0228847 50.7595856',
              '7.0198673 50.7581366',
            ],
          },
        },
      ],
    },
    id: '5d53f36290e8b15ba9a3b5c9',
    lastUpdatedAt: '2019-08-14T11:44:06.968Z',
    version: 6,
    kmlFilename: 'planned_Bornheim_01_190629.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bornheim_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:44:21.972Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7907062 53.0788461',
              '8.7890378 53.0793181',
              '8.7874626 53.0776887',
              '8.7865741 53.0757499',
              '8.7847436 53.0731934',
              '8.7861974 53.0732739',
              '8.788205 53.0752963',
              '8.7907062 53.0788461',
            ],
          },
        },
      ],
    },
    id: '5d53f41590e8b15ba9a3b5d2',
    lastUpdatedAt: '2019-09-09T07:23:30.81Z',
    version: 6,
    kmlFilename: 'planned_Bremen_01_190907.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bremen_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:45:06.051Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7834007 53.0613853',
              '8.7845648 53.0620703',
              '8.7820383 53.0635387',
              '8.7794686 53.0649651',
              '8.7784976 53.0642608',
              '8.7807588 53.0629737',
              '8.7834007 53.0613853',
            ],
          },
        },
      ],
    },
    id: '5d53f44290e8b15ba9a3b5d8',
    lastUpdatedAt: '2019-09-09T07:23:59.313Z',
    version: 6,
    kmlFilename: 'planned_Bremen_02_190907.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bremen_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:45:46.115Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.8328893 53.0481868',
              '8.8346813 53.0483837',
              '8.8373448 53.0476848',
              '8.8401799 53.0464698',
              '8.8474805 53.0452522',
              '8.8491878 53.0453134',
              '8.8492642 53.0454005',
              '8.8471841 53.0454747',
              '8.8462192 53.0456214',
              '8.845265 53.0458584',
              '8.8482852 53.0496895',
              '8.8510425 53.0538749',
              '8.8507099 53.0539716',
              '8.8500019 53.0531914',
              '8.8491435 53.0524916',
              '8.8484998 53.0517596',
              '8.8480492 53.0512469',
              '8.8476737 53.0507084',
              '8.8459463 53.0479868',
              '8.8447714 53.045952',
              '8.8403072 53.0468064',
              '8.8390401 53.047364',
              '8.8358191 53.0483631',
              '8.8348059 53.0485683',
              '8.8327149 53.0484109',
              '8.8328893 53.0481868',
            ],
          },
        },
      ],
    },
    id: '5d53f46a90e8b15ba9a3b5de',
    lastUpdatedAt: '2019-10-21T08:18:59.573Z',
    version: 6,
    kmlFilename: 'Bremen_03.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Bremen_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:46:17.203Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.8404313 53.1047589',
              '8.8247969 53.0849547',
              '8.8256553 53.0843876',
              '8.8264647 53.085163',
              '8.8268267 53.0855057',
              '8.8300387 53.0897398',
              '8.8339573 53.0949528',
              '8.8381059 53.1007642',
              '8.8407318 53.1046945',
              '8.8404313 53.1047589',
            ],
          },
        },
      ],
    },
    id: '5d53f48990e8b15ba9a3b5e4',
    lastUpdatedAt: '2019-08-14T11:46:38.588Z',
    version: 5,
    kmlFilename: 'planned_Bremen_05_100619.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bremen_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:46:53.676Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.9525903 53.0541562',
              '8.9492859 53.0429854',
              '8.9515603 53.0422245',
              '8.9607013 53.0388185',
              '8.9645208 53.0530599',
              '8.9525903 53.0541562',
            ],
          },
        },
      ],
    },
    id: '5d53f4ad90e8b15ba9a3b5ea',
    lastUpdatedAt: '2019-09-13T13:44:47.64Z',
    version: 6,
    kmlFilename: 'planned_Bremen_07_190913.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bremen_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:47:30.807Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.8465585 53.0490483',
              '8.8466658 53.0491644',
              '8.8459737 53.0493321',
              '8.8434847 53.0493901',
              '8.8431628 53.0492612',
              '8.8429804 53.0480648',
              '8.8421328 53.0462331',
              '8.8424332 53.0461782',
              '8.843018 53.0475262',
              '8.8431413 53.0479809',
              '8.8449545 53.0478616',
              '8.8458665 53.0491838',
              '8.8465585 53.0490483',
            ],
          },
        },
      ],
    },
    id: '5d53f4d290e8b15ba9a3b5f0',
    lastUpdatedAt: '2019-10-21T08:19:15.484Z',
    version: 6,
    kmlFilename: 'Bremen_99.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Bremen_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:48:16.029Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.4190759 51.5036865',
              '7.4142007 51.4970573',
              '7.416215 51.4970205',
              '7.420283 51.5035612',
              '7.4190759 51.5036865',
            ],
          },
        },
      ],
    },
    id: '5d53f50090e8b15ba9a3b5f6',
    lastUpdatedAt: '2019-08-14T11:48:38.39Z',
    version: 5,
    kmlFilename: 'planned_Dortmund_01_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:49:00.968Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.375431 51.493922',
              '7.3748167 51.4953196',
              '7.3736043 51.4951927',
              '7.3745807 51.4938068',
              '7.375431 51.493922',
            ],
          },
        },
      ],
    },
    id: '5d53f52c90e8b15ba9a3b5fc',
    lastUpdatedAt: '2019-08-14T11:49:22.879Z',
    version: 5,
    kmlFilename: 'planned_Dortmund_02_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:49:31.349Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.5702568 51.5017662',
              '7.5695246 51.5065996',
              '7.5695031 51.5067465',
              '7.5650641 51.5071755',
              '7.5666664 51.5020545',
              '7.5702568 51.5017662',
            ],
          },
        },
      ],
    },
    id: '5d53f54b90e8b15ba9a3b602',
    lastUpdatedAt: '2019-08-14T11:50:00.418Z',
    version: 5,
    kmlFilename: 'planned_Dortmund_03_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:50:21.026Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.5180664 51.5264638',
              '7.5305143 51.5284414',
              '7.5331137 51.5294442',
              '7.5311315 51.5333018',
              '7.5297501 51.5343362',
              '7.5178519 51.5288968',
              '7.5180664 51.5264638',
            ],
          },
        },
      ],
    },
    id: '5d53f57d90e8b15ba9a3b608',
    lastUpdatedAt: '2019-09-13T13:45:48.805Z',
    version: 8,
    kmlFilename: 'planned_Dortmund_04_190913.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:52:12.97Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.4933126 51.519465',
              '7.4949446 51.5174067',
              '7.502522 51.5173927',
              '7.5115789 51.5239894',
              '7.5112883 51.524685',
              '7.498657 51.5225182',
              '7.4933126 51.519465',
            ],
          },
        },
      ],
    },
    id: '5d53f5ec90e8b15ba9a3b60e',
    lastUpdatedAt: '2019-09-13T13:47:08.972Z',
    version: 6,
    kmlFilename: 'planned_Dortmund_05_190913.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:52:53.12Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.4589291 51.5424064',
              '7.458736 51.5424064',
              '7.4579528 51.5418326',
              '7.4567511 51.5424331',
              '7.4557105 51.5397906',
              '7.4544445 51.5372148',
              '7.4557534 51.5367076',
              '7.4602166 51.541926',
              '7.4589291 51.5424064',
            ],
          },
        },
      ],
    },
    id: '5d53f61590e8b15ba9a3b614',
    lastUpdatedAt: '2019-08-14T11:53:18.072Z',
    version: 5,
    kmlFilename: 'planned_Dortmund_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dortmund_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:57:17.18Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.6940918 51.0858343',
              '13.6928956 51.0856793',
              '13.6932711 51.084483',
              '13.6923377 51.0843078',
              '13.6926703 51.0833979',
              '13.6936573 51.0836372',
              '13.6947248 51.0837787',
              '13.6940918 51.0858343',
            ],
          },
        },
      ],
    },
    id: '5d53f71d90e8b15ba9a3b61a',
    lastUpdatedAt: '2019-08-14T11:57:44.788Z',
    version: 5,
    kmlFilename: 'planned_Dresden_01_190620.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:57:54.662Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7051941 51.0341053',
              '13.7046831 51.0327592',
              '13.7037175 51.0317472',
              '13.7045544 51.0314031',
              '13.7055199 51.0323477',
              '13.7069589 51.033547',
              '13.7051941 51.0341053',
            ],
          },
        },
      ],
    },
    id: '5d53f74290e8b15ba9a3b620',
    lastUpdatedAt: '2019-08-14T11:58:17.124Z',
    version: 5,
    kmlFilename: 'planned_Dresden_02_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:58:31.533Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7058831 51.0454819',
              '13.7041825 51.0453908',
              '13.7042844 51.0447095',
              '13.7049067 51.0444836',
              '13.7052554 51.0447163',
              '13.705584 51.0444743',
              '13.705816 51.044718',
              '13.7059515 51.0452247',
              '13.7058831 51.0454819',
            ],
          },
        },
      ],
    },
    id: '5d53f76790e8b15ba9a3b626',
    lastUpdatedAt: '2019-08-14T11:58:55.972Z',
    version: 5,
    kmlFilename: 'planned_Dresden_03_190619.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:59:06.547Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7801065 51.044006',
              '13.7773465 51.040537',
              '13.7792522 51.0398042',
              '13.7812008 51.0392063',
              '13.7827619 51.0433247',
              '13.7801065 51.044006',
            ],
          },
        },
      ],
    },
    id: '5d53f78a90e8b15ba9a3b62c',
    lastUpdatedAt: '2019-08-14T11:59:26.795Z',
    version: 5,
    kmlFilename: 'planned_Dresden_04_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T11:59:40.466Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7387593 51.0738785',
              '13.7367999 51.0733081',
              '13.7363493 51.073271',
              '13.7353891 51.0729777',
              '13.7345362 51.0724991',
              '13.7346113 51.0724182',
              '13.7349653 51.0725429',
              '13.7356573 51.0725193',
              '13.7368429 51.0728733',
              '13.7370521 51.073126',
              '13.7388974 51.0737395',
              '13.7387593 51.0738785',
            ],
          },
        },
      ],
    },
    id: '5d53f7ac90e8b15ba9a3b632',
    lastUpdatedAt: '2019-08-14T12:00:02.15Z',
    version: 5,
    kmlFilename: 'planned_Dresden_05_190619.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:00:16.901Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7831093 51.0033366',
              '13.783566 51.0027551',
              '13.7839825 51.0021854',
              '13.790578 51.0039493',
              '13.7878193 51.0056117',
              '13.7863709 51.0047881',
              '13.7830637 51.0034749',
              '13.7831093 51.0033366',
            ],
          },
        },
      ],
    },
    id: '5d53f7d090e8b15ba9a3b638',
    lastUpdatedAt: '2019-08-14T12:00:41.035Z',
    version: 5,
    kmlFilename: 'planned_Dresden_06_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:00:51.913Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.8845812 51.0610461',
              '13.8849836 51.0606786',
              '13.8865124 51.0612214',
              '13.8903212 51.0648744',
              '13.8879179 51.0670184',
              '13.8845812 51.0610461',
            ],
          },
        },
      ],
    },
    id: '5d53f7f390e8b15ba9a3b63e',
    lastUpdatedAt: '2019-08-14T12:01:23.576Z',
    version: 5,
    kmlFilename: 'planned_Dresden_07_190624.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:01:35.25Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7756566 51.0221377',
              '13.771601 51.0186216',
              '13.7749002 51.0167183',
              '13.7758168 51.0167786',
              '13.7767227 51.0168761',
              '13.7784648 51.017152',
              '13.7819195 51.0199359',
              '13.7756566 51.0221377',
            ],
          },
        },
      ],
    },
    id: '5d53f81f90e8b15ba9a3b644',
    lastUpdatedAt: '2019-09-09T07:25:54.292Z',
    version: 6,
    kmlFilename: 'planned_Dresden_99_190907.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:02:19.03Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7580291 51.388521',
              '6.7577957 51.387716',
              '6.7599764 51.387721',
              '6.7600326 51.3885009',
              '6.7580291 51.388521',
            ],
          },
        },
      ],
    },
    id: '5d53f84b90e8b15ba9a3b64a',
    lastUpdatedAt: '2019-08-14T12:02:46.589Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_01_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:02:58.155Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.773113 51.3769452',
              '6.7727161 51.3751671',
              '6.7728234 51.3737305',
              '6.7700446 51.3707971',
              '6.7702752 51.3707033',
              '6.7720241 51.3725685',
              '6.7729628 51.3732014',
              '6.7736575 51.3736435',
              '6.7737622 51.3744873',
              '6.7744381 51.3768213',
              '6.773113 51.3769452',
            ],
          },
        },
      ],
    },
    id: '5d53f87290e8b15ba9a3b650',
    lastUpdatedAt: '2019-08-14T12:03:22.195Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_02_190620.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:03:32.189Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7504714 51.3971205',
              '6.748087 51.3944378',
              '6.7531832 51.3936328',
              '6.7532609 51.3957833',
              '6.7509288 51.3972904',
              '6.7504714 51.3971205',
            ],
          },
        },
      ],
    },
    id: '5d53f89490e8b15ba9a3b656',
    lastUpdatedAt: '2019-08-14T12:04:30.994Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_03_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:04:03.575Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7779684 51.4166495',
              '6.7765501 51.4156367',
              '6.7788515 51.4145292',
              '6.780326 51.4158671',
              '6.7779684 51.4166495',
            ],
          },
        },
      ],
    },
    id: '5d53f8b390e8b15ba9a3b658',
    lastUpdatedAt: '2019-08-14T12:04:43.873Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_04_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:04:53.339Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7586939 51.4422021',
              '6.7589486 51.4414949',
              '6.7599504 51.4416471',
              '6.7609361 51.441836',
              '6.7607912 51.4425398',
              '6.7586939 51.4422021',
            ],
          },
        },
      ],
    },
    id: '5d53f8e590e8b15ba9a3b662',
    lastUpdatedAt: '2019-08-14T12:05:24.902Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_05_190704.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:05:38.126Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7665281 51.4842065',
              '6.7678746 51.4801058',
              '6.7684003 51.4799337',
              '6.7671236 51.4842633',
              '6.7665281 51.4842065',
            ],
          },
        },
      ],
    },
    id: '5d53f91290e8b15ba9a3b668',
    lastUpdatedAt: '2019-08-14T12:05:56.262Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_06_190806.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:06:08.531Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7542404 51.5298363',
              '6.7538059 51.5311211',
              '6.7522127 51.5306105',
              '6.7530066 51.5293157',
              '6.7542404 51.5298363',
            ],
          },
        },
      ],
    },
    id: '5d53f93090e8b15ba9a3b66e',
    lastUpdatedAt: '2019-08-14T12:06:39.411Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_07_190806.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:06:49.609Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.7366604 51.4879447',
              '6.734992 51.4861309',
              '6.7361883 51.4856532',
              '6.7381248 51.4876842',
              '6.7366604 51.4879447',
            ],
          },
        },
      ],
    },
    id: '5d53f95990e8b15ba9a3b674',
    lastUpdatedAt: '2019-08-14T12:07:09.485Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_08_190806.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-08-14T12:08:44.639Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '6.804775 51.489868',
              '6.8049145 51.4894772',
              '6.8103432 51.4903757',
              '6.8100697 51.490713',
              '6.804775 51.489868',
            ],
          },
        },
      ],
    },
    id: '5d53f9cc90e8b113922eb630',
    lastUpdatedAt: '2019-08-14T12:09:17.203Z',
    version: 5,
    kmlFilename: 'planned_Duisburg_99_190806.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Duisburg_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5da0cb62e10cc87f8424587c',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3825568 52.5429309',
              '13.3737162 52.5373452',
              '13.3831576 52.5297225',
              '13.4107093 52.534526',
              '13.41337 52.5483071',
              '13.3909682 52.5455408',
              '13.3825568 52.5429309',
            ],
          },
        },
      ],
    },
    id: '5d5e572890e8b10557d089ab',
    lastUpdatedAt: '2019-12-06T16:40:20.323Z',
    createdAt: '2019-08-22T08:49:44.882Z',
    version: 10,
    kmlFilename: 'Unbenannte Ebene (2).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Germany_Test01',
    fileLastUpdatedAt: '2019-12-06T16:40:20.323Z',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-09-05T13:26:15.937Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0437447 53.6085858',
              '10.0432726 53.6072618',
              '10.0603959 53.6078729',
              '10.0714251 53.6075419',
              '10.0724551 53.6090187',
              '10.0578639 53.6090187',
              '10.0528857 53.608955',
              '10.0516197 53.6097952',
              '10.0439593 53.6093496',
              '10.0437447 53.6085858',
            ],
          },
        },
      ],
    },
    id: '5d710cf790e8b139cdc5e74e',
    lastUpdatedAt: '2019-09-05T13:27:35.648Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_06_190904.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T08:47:02.657Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '14.3704187 46.6411051',
              '14.3685741 46.6407708',
              '14.3670084 46.6404053',
              '14.3671962 46.639444',
              '14.3680102 46.6392277',
              '14.3689315 46.639247',
              '14.3697167 46.6389749',
              '14.3701683 46.6391114',
              '14.3703625 46.6398372',
              '14.370636 46.6403248',
              '14.3707688 46.6408586',
              '14.3704187 46.6411051',
            ],
          },
        },
      ],
    },
    id: '5d91c106e10cc80b5243a2e8',
    lastUpdatedAt: '2019-10-15T15:10:12.187Z',
    version: 6,
    kmlFilename: 'Klagenfurt_01 (1).kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Klagenfurt_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e62e10cc80b5243a2cf',
    createdAt: '2019-09-30T09:05:35.707Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.4303884 47.2611722',
              '11.4323266 47.2605983',
              '11.4328363 47.2614072',
              '11.4318856 47.26168',
              '11.4322089 47.2621707',
              '11.4318763 47.262274',
              '11.4314122 47.2620847',
              '11.4305687 47.261756',
              '11.4304088 47.2614678',
              '11.4303884 47.2611722',
            ],
          },
        },
      ],
    },
    id: '5d91c55fe10cc80b5243a2f1',
    lastUpdatedAt: '2019-09-30T12:21:14.753Z',
    version: 3,
    kmlFilename: 'Innsbruck_01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Innsbruck_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T09:06:14.201Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.3170666 48.1055038',
              '16.3170639 48.1052243',
              '16.3175118 48.1052207',
              '16.317493 48.1054984',
              '16.3170666 48.1055038',
            ],
          },
        },
      ],
    },
    id: '5d91c586e10cc80b5243a2f3',
    lastUpdatedAt: '2019-10-01T08:36:45.039Z',
    version: 5,
    kmlFilename: 'Unbenannte Ebene (1).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Vösendorf_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T09:07:39.852Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '15.6368343 48.2242273',
              '15.6367391 48.2240414',
              '15.6370555 48.2239798',
              '15.6371468 48.224171',
              '15.6368343 48.2242273',
            ],
          },
        },
      ],
    },
    id: '5d91c5dbe10cc80b5243a2f8',
    lastUpdatedAt: '2019-10-01T14:24:14.239Z',
    version: 4,
    kmlFilename: 'St.Pölten_01 (1).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'St.Pölten',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T09:09:03.53Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '15.4225329 47.0331139',
              '15.4224899 47.0321596',
              '15.423713 47.0318123',
              '15.4240188 47.0332017',
              '15.4225329 47.0331139',
            ],
          },
        },
      ],
    },
    id: '5d91c62fe10cc80b5243a2fe',
    lastUpdatedAt: '2019-10-09T13:39:56.395Z',
    version: 6,
    kmlFilename: 'Graz_01 (2).kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Graz_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T09:16:28.202Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '14.2518759 48.19679',
              '14.2517653 48.1967631',
              '14.2519034 48.1965249',
              '14.2520074 48.1965544',
              '14.2518759 48.19679',
            ],
          },
        },
      ],
    },
    id: '5d91c7ece10cc80b5243a308',
    lastUpdatedAt: '2019-09-30T09:16:46.573Z',
    version: 3,
    kmlFilename: 'Haid_01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Haid_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T11:38:30.282Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '16.4722516 48.2544149',
              '16.4721899 48.2543149',
              '16.4724554 48.2542434',
              '16.4725225 48.2543426',
              '16.4722516 48.2544149',
            ],
          },
        },
      ],
    },
    id: '5d91e936e10cc80b5243a332',
    lastUpdatedAt: '2019-09-30T11:40:20.632Z',
    version: 3,
    kmlFilename: 'Wien_Nord.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Wien_Nord',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e43e10cc80b5243a2cd',
    createdAt: '2019-09-30T12:22:26.504Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.0054666 47.8172477',
              '13.0054277 47.817182',
              '13.0052359 47.817245',
              '13.0053151 47.8172927',
              '13.0052467 47.8173179',
              '13.0049248 47.8171018',
              '13.0048953 47.8170478',
              '13.0049033 47.8170082',
              '13.0049677 47.8169487',
              '13.0050106 47.8169415',
              '13.0069713 47.8162742',
              '13.0070518 47.8164426',
              '13.0069016 47.8165777',
              '13.0057791 47.8170109',
              '13.0058515 47.8171036',
              '13.0054666 47.8172477',
            ],
          },
        },
      ],
    },
    id: '5d91f382e10cc80b5243a3f1',
    lastUpdatedAt: '2019-09-30T12:22:48.248Z',
    version: 3,
    kmlFilename: 'Salzburg_01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Salzburg_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e7fe10cc80b5243a2d1',
    createdAt: '2019-10-04T08:51:47.881Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.7529366 48.3794448',
              '11.7537533 48.3795384',
              '11.7535226 48.3805993',
              '11.7533228 48.3811088',
              '11.7526321 48.3807436',
              '11.7529366 48.3794448',
            ],
          },
        },
      ],
    },
    id: '5d970823e10cc816736fce91',
    lastUpdatedAt: '2019-10-24T08:05:13.096Z',
    version: 5,
    kmlFilename: 'Freising_01 (1).kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Freising_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '5d911e7fe10cc80b5243a2d1',
    createdAt: '2019-10-04T13:20:46.949Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.6322688 48.3079342',
              '11.6326899 48.3072188',
              '11.633508 48.3074346',
              '11.6330735 48.3081429',
              '11.6322688 48.3079342',
            ],
          },
        },
      ],
    },
    id: '5d97472ee10cc816736fcf1d',
    lastUpdatedAt: '2019-10-04T13:21:25.548Z',
    version: 3,
    kmlFilename: 'Eching-Ost_01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Eching_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-11T14:23:29.433Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.2925882 51.3256398',
              '12.2755937 51.3198469',
              '12.2807435 51.3102708',
              '12.3057632 51.3136776',
              '12.2925882 51.3256398',
            ],
          },
        },
      ],
    },
    id: '5da09061e10cc8379706619a',
    lastUpdatedAt: '2019-10-11T14:23:54.187Z',
    version: 3,
    kmlFilename: 'Leipzig_05_191011.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-11T14:24:27.72Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.384155 51.376647',
              '12.385013 51.367539',
              '12.398703 51.368342',
              '12.396171 51.376862',
              '12.384155 51.376647',
            ],
          },
        },
      ],
    },
    id: '5da0909be10cc837970661a0',
    lastUpdatedAt: '2019-10-15T11:57:38.903Z',
    version: 6,
    kmlFilename: 'Leipzig_06_191015 (1).kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Leipzig_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-11T14:25:32.689Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.253453 51.346511',
              '12.262122 51.342303',
              '12.283194 51.340426',
              '12.283065 51.344957',
              '12.253711 51.34745',
              '12.253453 51.346511',
            ],
          },
        },
      ],
    },
    id: '5da090dce10cc837970661a8',
    lastUpdatedAt: '2019-10-15T11:56:08.969Z',
    version: 8,
    kmlFilename: 'Leipzig_07_191015 (2).kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Leipzig_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-11T14:27:10.749Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.3401598 51.333227',
              '12.340577 51.336486',
              '12.3279533 51.3361581',
              '12.3040036 51.3273546',
              '12.3076943 51.3197917',
              '12.3307828 51.3195905',
              '12.3322419 51.3215619',
              '12.3401598 51.333227',
            ],
          },
        },
      ],
    },
    id: '5da0913ee10cc837970661af',
    lastUpdatedAt: '2019-10-11T14:27:29.337Z',
    version: 3,
    kmlFilename: 'Leipzig_08_191011.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Leipzig_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-11T14:29:35.064Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.7956235 50.9959422',
              '13.795602 50.993545',
              '13.8023398 50.9935314',
              '13.8022325 50.9960029',
              '13.7956235 50.9959422',
            ],
          },
        },
      ],
    },
    id: '5da091cfe10cc837970661b6',
    lastUpdatedAt: '2019-10-11T14:30:03.987Z',
    version: 3,
    kmlFilename: 'Dresden_08_191011.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Dresden_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-10-21T08:19:58.32Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7992061 53.028797',
              '8.7990023 53.0288679',
              '8.7964059 53.0251641',
              '8.7961591 53.0249834',
              '8.7979937 53.0244607',
              '8.79838 53.0244542',
              '8.8013841 53.0282098',
              '8.8011266 53.0282743',
              '8.7984658 53.0248866',
              '8.7983585 53.0247317',
              '8.7981118 53.0246285',
              '8.7966419 53.025035',
              '8.7992061 53.028797',
            ],
          },
        },
      ],
    },
    id: '5dad6a2e4dcf2f72b32d915c',
    lastUpdatedAt: '2019-10-21T08:20:29.232Z',
    version: 3,
    kmlFilename: 'Bremen_08.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Bremen_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1269692 49.6007253',
              '7.0859126 49.6010378',
              '7.0316676 49.6384051',
              '6.9183711 49.6175008',
              '6.8325404 49.58635',
              '6.6897181 49.5395865',
              '6.6423396 49.5400321',
              '6.5929011 49.5164109',
              '6.380041 49.5467153',
              '6.3608149 49.5284458',
              '6.3697413 49.5132902',
              '6.3759211 49.4704729',
              '6.4027003 49.466903',
              '6.4123134 49.4767194',
              '6.4253596 49.4793962',
              '6.4651851 49.4709191',
              '6.4782313 49.4628867',
              '6.5613154 49.4285112',
              '6.5578822 49.4155583',
              '6.5915278 49.3820437',
              '6.6080073 49.3663957',
              '6.587408 49.3476115',
              '6.5702418 49.3556627',
              '6.5674952 49.3480588',
              '6.6018275 49.3319525',
              '6.5942744 49.324345',
              '6.6746119 49.2818104',
              '6.6732387 49.2603053',
              '6.6952113 49.2499974',
              '6.6945247 49.2441703',
              '6.6986445 49.2266847',
              '6.7274836 49.2217518',
              '6.7350367 49.2056042',
              '6.7219905 49.1867587',
              '6.7350367 49.1719464',
              '6.7762355 49.1723954',
              '6.8400935 49.1584769',
              '6.8304805 49.1732932',
              '6.8558864 49.1800264',
              '6.8291072 49.2118845',
              '6.8586329 49.2262363',
              '6.8922786 49.2132301',
              '6.9341639 49.2325139',
              '6.9595698 49.2082958',
              '6.9739894 49.2150243',
              '7.0035151 49.1993232',
              '7.0419673 49.1952849',
              '7.040594 49.1692528',
              '7.0591334 49.1216416',
              '7.083166 49.1315269',
              '7.071493 49.1454529',
              '7.0907191 49.1638652',
              '7.1305446 49.1454529',
              '7.1998958 49.1319762',
              '7.2850398 49.1328748',
              '7.3564509 49.159375',
              '7.3604287 49.2622311',
              '7.4057473 49.3651873',
              '7.2574318 49.4375751',
              '7.3192299 49.5267957',
              '7.1269692 49.6007253',
            ],
          },
        },
      ],
    },
    id: '5dd7c06b9a7b9e0b1645bf7b',
    lastUpdatedAt: '2019-11-22T11:04:23.382Z',
    createdAt: '2019-11-22T11:03:07.367Z',
    version: 4,
    kmlFilename: 'Saarland_01.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Saarland_01',
    fileLastUpdatedAt: '2019-11-22T11:04:23.382Z',
  },
  {
    fleetId: '5d911e7fe10cc80b5243a2d1',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '11.8389846 47.7942776',
              '11.8383623 47.7943641',
              '11.8383892 47.793456',
              '11.8393574 47.793456',
              '11.8393762 47.7933677',
              '11.8397759 47.7933695',
              '11.8397759 47.7937587',
              '11.8390275 47.7937425',
              '11.8389846 47.7942776',
            ],
          },
        },
      ],
    },
    id: '5e5e5df880b6990c3abf7150',
    lastUpdatedAt: '2020-03-03T13:39:23.356Z',
    createdAt: '2020-03-03T13:39:04.776Z',
    version: 3,
    kmlFilename: 'Unbenannte Ebene (3).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Miesbach_01',
    fileLastUpdatedAt: '2020-03-03T13:39:23.356Z',
  },
  {
    fleetId: '5da0cb62e10cc87f8424587c',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3913828 52.5563529',
              '13.3765562 52.5577111',
              '13.3642395 52.5582591',
              '13.3440783 52.5485093',
              '13.33721 52.5530519',
              '13.3362014 52.5528692',
              '13.3384599 52.5502938',
              '13.3356274 52.5486498',
              '13.3398332 52.5461966',
              '13.3355416 52.5424384',
              '13.3308495 52.5405196',
              '13.3284891 52.5400889',
              '13.3302057 52.5379094',
              '13.3347548 52.5398997',
              '13.345312 52.5447543',
              '13.3497323 52.5413613',
              '13.3526934 52.5423009',
              '13.3570279 52.5437886',
              '13.359474 52.5459026',
              '13.3575459 52.5471004',
              '13.3490916 52.5475441',
              '13.3672162 52.5559679',
              '13.3910395 52.5544285',
              '13.3913828 52.5563529',
            ],
          },
        },
      ],
    },
    id: '5e68c9e2c6410b0c2647ad7b',
    lastUpdatedAt: '2020-03-11T11:22:37.117Z',
    createdAt: '2020-03-11T11:22:10.076Z',
    version: 2,
    kmlFilename: 'planned_Berlin_28_190916.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'GET_Berlin_28',
    fileLastUpdatedAt: '2020-03-11T11:22:37.117Z',
  },
];
