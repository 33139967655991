import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './BalanceView.less';
import {
  formatMoneyEuro,
  formatMonthFromDate,
  LocalizePropType,
  TransactionData,
} from 'getaway-data-layer';
import { format } from 'date-fns';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import TransactionItem from '../../../components/TransactionItem/TransactionItem';

type Props = {
  localize: LocalizePropType;
  balance?: number;
  transactions?: Array<TransactionData>;
  transactionsCount?: number;
  onLoadMoreTransactions: () => void;
  onClose: () => void;
};

const BalanceView = ({
  localize,
  balance,
  transactions,
  transactionsCount,
  onLoadMoreTransactions,
  onClose,
}: Props) => {
  const dataWithKeys: Array<any> = [{ key: 'balance' }];
  let openTransactions = [];
  if (transactions) {
    openTransactions = [
      ...transactions.filter(
        (transaction: any) => transaction.status === 'open'
      ),
    ];
    dataWithKeys.push(
      ...openTransactions.map((transaction) => ({
        key: `open-${transaction.id}`,
        ...transaction,
      })),
      ...transactions.map((transaction: any) => ({
        key: `others-${transaction.id}`,
        ...transaction,
      }))
    );
  }

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('dashboard.balance')}
        description=""
        showHelp
        onClose={onClose}
      />
      <div id="transactions" className="screen-body" style={{ paddingTop: 10 }}>
        <InfiniteScroll
          dataLength={transactionsCount || 0}
          next={onLoadMoreTransactions}
          hasMore
          scrollableTarget="transactions"
          loader={<h4> </h4>}
        >
          {dataWithKeys.map((item, index) => {
            const date = item.date ? new Date(item.date) : '';
            if (item.key === 'balance') {
              return (
                <div key={item.key} className="balance-header-container">
                  <div className="balance-header">{localize('balance')}</div>
                  <div
                    style={{ backgroundColor: balance! < 0 ? 'red' : 'black' }}
                    className="balance-header-value"
                  >
                    {formatMoneyEuro(balance)}
                  </div>
                </div>
              );
            }
            return (
              <div key={item.key}>
                {item.key.startsWith('others-') && (
                  <div>
                    {(index === openTransactions.length + 1 ||
                      (index < dataWithKeys.length - 1 &&
                        !(
                          new Date(
                            dataWithKeys[index - 1].date
                          ).getUTCMonth() ===
                          new Date(dataWithKeys[index].date).getUTCMonth()
                        ))) &&
                      dataWithKeys[index].date && (
                        <div className="balance-date-header">
                          {formatMonthFromDate(
                            new Date(dataWithKeys[index].date),
                            false
                          )}
                        </div>
                      )}
                    <TransactionItem
                      title={item.title}
                      subTitle={item.description}
                      value={formatMoneyEuro(item.amount)}
                      isPositiveValue={item.amount > 0}
                      leftValue={date ? format(date, 'dd') : ''}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default BalanceView;
