import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setBluetoothStatus, setCarStatus } from '../redux/store/bluetooth';
import { State, BluetoothState } from '../@types';

export type Props = {
  bluetooth: BluetoothState;
  setBluetoothStatus: typeof setBluetoothStatus;
  setCarStatus: typeof setCarStatus;
};

const withBluetooth: any = compose(
  connect(
    ({ bluetooth }: State) => ({
      bluetooth,
    }),
    {
      setBluetoothStatus,
      setCarStatus,
    }
  )
);
export default withBluetooth;
