import * as yup from 'yup';

export const AddCircleModal = {
  circleCode: '',
};

export const AddCircleSchema = (localize: any): any =>
  yup.object({
    circleCode: yup.string().required(localize('validation.required')),
  });
