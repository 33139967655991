export let injectedActionSheet: any;
export let injectedDeviceEmitter: any;
export let injectedPermissionsManager: any;
export let injectedSentry: any;
export let injectedIntercom: any;
export let injectedDimensions: any;
export let injectedLinking: any;
export let injectedPlatform: any;
export let injectedRate: any;
export let injectedFetchBlob: any;
export let injectedReactNativeShare: any;
export let injectedReactNativeFS: any;
export let injectedAlert: any;

export const injectAlert = (alert: any) => {
  injectedAlert = alert;
};

export const injectActionSheet = (actionSheet: any) => {
  injectedActionSheet = actionSheet;
};
export const injectDeviceEmitter = (deviceEmitter: any) => {
  injectedDeviceEmitter = deviceEmitter;
};
export const injectPermissionsManager = (permissionsManager: any) => {
  injectedPermissionsManager = permissionsManager;
};
export const injectSentry = (sentry: any) => {
  injectedSentry = sentry;
};
export const injectIntercom = (intercom: any) => {
  injectedIntercom = intercom;
};
export const injectLinking = (linking: any) => {
  injectedLinking = linking;
};
export const injectPlatform = (platform: any) => {
  injectedPlatform = platform;
};
export const injectDimensions = (dimensions: any) => {
  injectedDimensions = dimensions;
};
export const injectRate = (rate: any) => {
  injectedRate = rate;
};
export const injectFetchBlob = (fetchBlob: any) => {
  injectedFetchBlob = fetchBlob;
};
export const injectReactNativeShare = (reactNativeShare: any) => {
  injectedReactNativeShare = reactNativeShare;
};
export const injectReactNativeFS = (reactNativeFS: any) => {
  injectedReactNativeFS = reactNativeFS;
};
