import * as yup from 'yup';

export const AddCCModal = {
  name: '',
};

export const AddCCSchema = (localize: any): any =>
  yup.object({
    name: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^([ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z\-./]+\s+[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z\-.]+(\s+[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z\-.]+)*)$/,
        localize('input.error.invalid')
      ),
  });
