import React from 'react';
import {
  getInsuranceShortName,
  getSecondaryBrandColor,
} from 'getaway-data-layer';
import Plate from '../../../components/Plate/Plate';
import SwipeIndicator from '../../../components/SwitchIndicator/SwipeIndicator';
import './DrivingParkingCardView.less';
import { GA_TITLES } from '../../../../utils';
import BrandButton from '../../../components/BrandButton/BrandButton';
import ListItem from '../../../components/ListItem/ListItem';
import PointItem from '../../../components/PointItem/PointItem';
import BrandIcon from '../../../components/BrandIcon/BrandIcon';

type Props = {
  localize: any;
  carPlate: string;
  address: string;
  tripId: string;
  from: string;
  until: string;
  isDongleRental: boolean;
  formattedUnlock: string;
  isOriginallyBooking: boolean;
  formattedRequestedAt: string;
  formattedReservedAt: string;
  onClickCarProfile: () => void;
  onEndRental: any;
  insurance: any;
  fuelLevel: string;
  onClickFuelItem: () => void;
  car: any;
  duration: any;
  distance: any;
  rentalBackBy: string;
  negativeRentalBackBy: boolean;
  redRentalBackBy: boolean;
  rentalBackByCounter: string;
  showNotification: boolean;
  onHideNotification: () => void;
  onClickDamages: () => void;
  onAddressClicked: () => void;
  toggleCard: () => void;
  fuelLevelStart: string;
  immobilizerLocked: boolean;
  carCheckImagesSubmitted: boolean;
  onPressAddAllAround: () => void;
  onPressBackBy: () => void;
};

const DrivingParkingCardView = ({
  localize,
  carPlate,
  address,
  isDongleRental,
  formattedUnlock,
  isOriginallyBooking,
  formattedRequestedAt,
  formattedReservedAt,
  tripId,
  from,
  until,
  insurance,
  fuelLevel,
  car,
  duration,
  distance,
  rentalBackBy,
  redRentalBackBy,
  negativeRentalBackBy,
  rentalBackByCounter,
  showNotification,
  onHideNotification,
  onClickCarProfile,
  onEndRental,
  onClickFuelItem,
  onClickDamages,
  onAddressClicked,
  toggleCard,
  fuelLevelStart,
  immobilizerLocked,
  carCheckImagesSubmitted,
  onPressAddAllAround,
  onPressBackBy,
}: Props) => {
  return (
    <>
      <div onClick={toggleCard}>
        <strong className="cursor">
          <div className="driving-header-wrapper">
            <div className="driving-header-title">{`${duration} | ${distance}`}</div>
            <div className="driving-header-description-wrapper">
              <Plate
                carPlate={carPlate}
                titleWrapperStyles={{ backgroundColor: 'white' }}
              />
              <div className="driving-header-description">
                {`${localize('rental.driving.greeting')}`}
              </div>
            </div>
            <SwipeIndicator
              opacity={1}
              height={3}
              width={40}
              color={getSecondaryBrandColor()}
            />
          </div>
        </strong>
      </div>
      <div
        className="screen-body driving-content"
        style={{ paddingTop: '35px', maxHeight: window?.innerHeight - 100 }}
      >
        <div className="driving-card-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="driving-card-dashed"
          />
          {immobilizerLocked && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('rental.immobilizer.title')}
                leftItem={
                  <img
                    style={{
                      width: 28,
                      height: 28,
                      marginRight: 12,
                      objectFit: 'contain',
                    }}
                    src="/images/malfunction.png"
                    alt="malfunction"
                  />
                }
                value={
                  <div className="immobilizer-container">
                    <div className="orange-dot" />
                    <div className="immobilizer-text">
                      {localize('rental.immobilizer.blocked')}
                    </div>
                  </div>
                }
                showSeparator={false}
              />
              <div className="reserved-card-separator" />
              <div className="imobilizer-warning">
                {localize(
                  carCheckImagesSubmitted
                    ? 'rental.immobilizer.payment.msg'
                    : 'rental.immobilizer.allaround.msg'
                )}
              </div>
              {!carCheckImagesSubmitted && (
                <>
                  <ListItem
                    containerStyles={{ padding: '10px 16px', margin: 0 }}
                    title={localize('rental.allaround.add')}
                    leftItem={
                      <img
                        style={{
                          width: 28,
                          height: 24,
                          marginRight: 12,
                          objectFit: 'fill',
                        }}
                        src="/images/cameraPlus.png"
                        alt="cameraPlus"
                      />
                    }
                    brandTextColor
                    rightIconSize={10}
                    rightIcon="iconIncomplete"
                    showSeparator={false}
                    onClick={onPressAddAllAround}
                  />
                  <div className="reserved-card-separator" />
                </>
              )}
            </>
          )}
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={fuelLevel}
            value={localize('rental.action.fuel')}
            brandValueColor
            showSeparator={false}
            leftIcon="carFuel"
            leftIconSize={18}
            rightIcon="arrowRightBrand"
            rightIconSize={14}
            leftIconStyles={{ minWidth: 'unset', marginRight: '12px' }}
            onClick={onClickFuelItem}
          />
          <div className="driving-card-separator" />
          {car?.refuelBonusApplies && (
            <div className="refuel-warning">
              {localize('rental.refuel.warning')}
            </div>
          )}
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={`${car?.damages?.length || ''} ${localize('car.damages')}`}
            value={localize('rental.driving.damages.add')}
            brandValueColor
            leftIcon="warning"
            leftIconSize={18}
            rightIcon="arrowRightBrand"
            rightIconSize={14}
            showSeparator={false}
            leftIconStyles={{ minWidth: 'unset', marginRight: '12px' }}
            onClick={onClickDamages}
          />
          <div className="driving-card-separator" />
          <div className="driving-card-verticalSpace" />
        </div>
        <PointItem title={localize('rental.section.return')} />
        <div className="driving-card-section">
          <div className="driving-card-section-line" />
          {!!rentalBackBy && (
            <>
              <div
                className="driving-card-backBy-container"
                onClick={onPressBackBy}
              >
                <div style={{ display: 'flex' }}>
                  <BrandIcon
                    name={redRentalBackBy ? 'sandClockRed' : 'sandClock'}
                    style={{ width: 18, height: 24 }}
                  />
                  <div
                    className={`driving-card-backBy-title ${
                      redRentalBackBy ? 'red-title' : ''
                    }`}
                  >{`${rentalBackBy} Uhr`}</div>
                </div>
                <div className="item-value">
                  {localize('rental.backby.update')}
                </div>
              </div>
              {!(redRentalBackBy && showNotification) && (
                <div className="separator" />
              )}
            </>
          )}
          {redRentalBackBy && showNotification && (
            <div className="driving-card-backBy-notification">
              <div className="driving-card-backBy-notification-title">
                {negativeRentalBackBy
                  ? localize(
                      'notification.backby.title.late',
                      rentalBackByCounter
                    )
                  : localize('notification.backby.title', rentalBackByCounter)}
              </div>
              <div className="driving-card-backBy-notification-description">
                {localize('notification.backby.desc')}
              </div>
              <div
                className="driving-card-backBy-notification-button"
                onClick={onHideNotification}
              >
                {localize('notification.backby.hide')}
              </div>
            </div>
          )}
          <div className="driving-card-verticalSpace" />
        </div>
        <PointItem
          title={
            isDongleRental
              ? localize('dongle.rental.section.unlocked')
              : localize('rental.section.unlocked')
          }
          value={isDongleRental ? formattedReservedAt : formattedUnlock}
        />
        <div className="driving-card-section">
          <div className="driving-card-section-line" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('rental.fuelSettings.where')}
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator={false}
            onClick={onAddressClicked}
          />
          <div className="driving-card-separator" />
          {fuelLevelStart && (
            <ListItem
              containerStyles={{ padding: '10px 16px', margin: 0 }}
              title={localize('rental.driving.tank')}
              showSeparator={false}
              value={`${fuelLevelStart}%`}
              leftIconStyles={{ minWidth: 'unset', marginRight: '12px' }}
            />
          )}
          <div className="driving-card-separator" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('insurance.charterline.headline')}
            value={getInsuranceShortName(insurance?.deductibleAmount)}
            showSeparator={false}
            onClick={() => {}}
          />
          <div className="driving-card-separator" />
          <div className="driving-card-verticalSpace" />
        </div>
        <PointItem
          title={localize('rental.section.reserved')}
          value={
            isOriginallyBooking ? formattedRequestedAt : formattedReservedAt
          }
        />
        <div className="driving-card-section">
          <div className="driving-card-section-line" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('booking.confirm.profile')}
            carPlate={carPlate}
            rightIcon="arrowRightBrand"
            rightIconSize={12}
            showSeparator={false}
            onClick={onClickCarProfile}
          />
          <div className="driving-card-separator" />
          {!!from && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.from')}
                value={from}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="driving-card-separator" />
            </>
          )}
          {!!until && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.to')}
                value={until}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="driving-card-separator" />
            </>
          )}
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('station.booking.code')}
            code={tripId}
            showSeparator={false}
            onClick={() => {}}
          />
          <div className="driving-card-separator" />
        </div>
      </div>
      <div>
        <img
          className="driving-card-bottom-gradiant"
          src="/images/gradientBackground50.png"
          alt="gradiant"
        />
        <BrandButton
          id={GA_TITLES.RENTAL.OPEN_HANDOVER}
          title={localize('rental.finish.button')}
          onClick={onEndRental}
        />
      </div>
    </>
  );
};

export default DrivingParkingCardView;
