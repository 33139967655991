import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  selectRentalData,
  getRentalData,
  getDriverCarStatus,
  canFinishRental,
  setOpenedModal,
  timeStringFromDate,
  dateAndTimeStringFromDate,
  selectDriverCarStatus,
  msToHMS,
  formatDistance,
  locationFromString,
  setCardState,
  selectCardState,
  LocalizePropType,
  parseAddress,
} from 'getaway-data-layer';
import { isAfter, isToday, subHours } from 'date-fns';
import DrivingParkingCardView from './DrivingParkingCardView';
import { dateFromObjectId, navigateTo, showAlert } from '../../../../utils';
import { openRouteDialog } from '../../../../utils/dialogsHelpers';

export const RENTAL_UPDATER_DURATION = 300000;
export const RENTAL_UI_UPDATER_DURATION = 15000;

type Props = {
  localize: LocalizePropType;
};

const DrivingParkingCardContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentalData = useSelector(selectRentalData) as any;
  const driverCarStatus = useSelector(selectDriverCarStatus) as any;
  const cardState = useSelector(selectCardState);
  const isOriginallyBooking = !!rentalData.bookingId;
  const reservedAt = rentalData?.id ? dateFromObjectId(rentalData.id) : null;
  const rentalBackBy = rentalData?.backBy
    ? dateAndTimeStringFromDate(new Date(rentalData.backBy), localize)
    : null;
  const formattedReservedAt = reservedAt
    ? isToday(reservedAt)
      ? timeStringFromDate(reservedAt)
      : dateAndTimeStringFromDate(reservedAt, localize)
    : ' ';
  const requestedAt = isOriginallyBooking
    ? dateFromObjectId(rentalData.bookingId)
    : null;
  const formattedRequestedAt = requestedAt
    ? isToday(requestedAt)
      ? timeStringFromDate(requestedAt)
      : dateAndTimeStringFromDate(requestedAt, localize)
    : ' ';
  const startDate = isOriginallyBooking
    ? new Date(rentalData.bookingFrom)
    : null;
  const backByDate = isOriginallyBooking
    ? new Date(rentalData.bookingTo)
    : null;
  const formattedFrom = startDate
    ? dateAndTimeStringFromDate(startDate, localize)
    : '';
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';
  const unlockDate = rentalData?.initialUnlock
    ? new Date(rentalData.initialUnlock)
    : null;
  const formattedUnlock = unlockDate
    ? dateAndTimeStringFromDate(unlockDate, localize)
    : '';
  const unlockTime =
    rentalData && rentalData.initialUnlock
      ? new Date(rentalData.initialUnlock)
      : null;

  const [distance, setDistance] = React.useState(0) as any;
  const [duration, setDuration] = React.useState() as any;
  const [rentalBackByCounter, setRentalBackByCounter] = React.useState('');
  const [redRentalBackBy, setRedRentalBackBy] = React.useState(false);
  const [negativeRentalBackBy, setNegativeRentalBackBy] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(true);
  const address = parseAddress(rentalData?.startAddress);
  const onEndRental = () => {
    dispatch(
      getDriverCarStatus(rentalData?.id, true, () => {
        dispatch(
          getRentalData(rentalData?.id, (data: any) => {
            if (data?.carData?.vehicleConnection === 'geoTab') {
              if (data?.id) {
                canFinishRental(data.id);
              }
              navigateTo(history, '/rental/handover');
            } else if (data?.carMode !== 'parking') {
              showAlert(
                localize('driving.finish.rental.dialog.title'),
                localize('driving.finish.rental.dialog.message'),
                localize('driving.finish.rental.dialog.retry'),
                () => {
                  onEndRental();
                },
                localize('ok'),
                () => {}
              );
            } else {
              if (data?.id) {
                dispatch(canFinishRental(data.id));
              }
              navigateTo(history, '/rental/handover');
            }
          })
        );
      })
    );
  };

  const onClickCarProfile = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  const toggleCard = () => {
    if (cardState === 'Hidden') {
      dispatch(setCardState('Expanded'));
    } else {
      dispatch(setCardState('Hidden'));
    }
  };

  const onClickFuelItem = () => {
    navigateTo(history, '/rental/fuelSettings');
  };

  const onClickDamages = () => {
    navigateTo(history, '/rental/browseDamages');
  };

  const onAddressClicked = () => {
    openRouteDialog(
      localize,
      locationFromString(rentalData?.pickupLocation),
      address
    );
  };

  const onHideNotification = () => {
    setShowNotification(false);
  };

  const updateUI = () => {
    setDuration(
      msToHMS(Math.abs((new Date() as any) - (unlockTime as any)), localize)
    );
    setRentalBackByCounter(
      msToHMS(
        Math.abs((new Date(rentalData.backBy) as any) - (new Date() as any)),
        localize
      )
    );
    if (isAfter(new Date(), subHours(new Date(rentalData.backBy), 1))) {
      setRedRentalBackBy(true);
    }
    if (isAfter(new Date(), new Date(rentalData.backBy))) {
      setNegativeRentalBackBy(true);
    }
  };

  const onPressAddAllAround = () => {
    navigateTo(history, '/rental/allAround');
  };

  const onPressBackBy = () => {
    dispatch(setOpenedModal('BackByUpdateModal'));
  };

  useEffect(() => {
    updateUI();
    setDistance(
      formatDistance(rentalData.distance === 0 ? 1 : rentalData.distance)
    );
    dispatch(getRentalData(rentalData?.id));
    dispatch(getDriverCarStatus(rentalData.id));
    const rentalUpdaterId = setInterval(() => {
      dispatch(getRentalData(rentalData?.id));
      dispatch(getDriverCarStatus(rentalData.id));
    }, RENTAL_UPDATER_DURATION);

    const uiUpdaterId = setInterval(() => {
      updateUI();
    }, RENTAL_UI_UPDATER_DURATION);

    return () => {
      if (rentalUpdaterId) clearInterval(rentalUpdaterId);
      if (uiUpdaterId) clearInterval(uiUpdaterId);
    };
  }, []);

  return (
    <DrivingParkingCardView
      localize={localize}
      carPlate={rentalData?.carData?.plate}
      address={address}
      onEndRental={onEndRental}
      insurance={rentalData?.insuranceData}
      isDongleRental={rentalData?.carData?.vehicleConnection === 'geoTab'}
      formattedUnlock={formattedUnlock}
      isOriginallyBooking={isOriginallyBooking}
      formattedReservedAt={formattedReservedAt}
      formattedRequestedAt={formattedRequestedAt}
      from={formattedFrom}
      until={formattedBackBy}
      fuelLevel={
        driverCarStatus?.fuelLevel ? `${driverCarStatus.fuelLevel}%` : ''
      }
      fuelLevelStart={rentalData?.fuelLevelStart}
      distance={distance}
      duration={duration}
      car={rentalData?.carData}
      tripId={rentalData?.presentableId}
      rentalBackBy={rentalBackBy!}
      redRentalBackBy={redRentalBackBy}
      negativeRentalBackBy={negativeRentalBackBy}
      rentalBackByCounter={rentalBackByCounter}
      showNotification={showNotification}
      onHideNotification={onHideNotification}
      onClickCarProfile={onClickCarProfile}
      onClickFuelItem={onClickFuelItem}
      onClickDamages={onClickDamages}
      onAddressClicked={onAddressClicked}
      toggleCard={toggleCard}
      immobilizerLocked={driverCarStatus?.immobilizerLocked}
      carCheckImagesSubmitted={rentalData?.startCheckImages?.length >= 4}
      onPressAddAllAround={onPressAddAllAround}
      onPressBackBy={onPressBackBy}
    />
  );
};

export default withTextLocalizer(DrivingParkingCardContainer);
