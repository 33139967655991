import TYPES from '../../../@types/redux/store/ConfigStoreTypes';
import {
  SetApiBaseURL,
  SetApiVersion,
  SetAppleAppId,
  SetBundleId,
  SetGoogleApiAccessToken,
  SetXBrandId,
} from '../../../@types';

export const setApiBaseURL = (apiBaseURL: string): SetApiBaseURL => ({
  type: TYPES.SET_API_BASE_URL,
  payload: {
    apiBaseURL,
  },
});

export const setApiVersion = (apiVersion: string): SetApiVersion => ({
  type: TYPES.SET_API_VERSION,
  payload: {
    apiVersion,
  },
});

export const setGoogleApiAccessToken = (
  googleApiAccessToken: string
): SetGoogleApiAccessToken => ({
  type: TYPES.SET_GOOGLE_API_ACCESS_TOKEN,
  payload: {
    googleApiAccessToken,
  },
});

export const setBundleId = (bundleId: string): SetBundleId => ({
  type: TYPES.SET_BUNDLE_ID,
  payload: {
    bundleId,
  },
});

export const setAppleAppId = (appleAppId: string): SetAppleAppId => ({
  type: TYPES.SET_APPLE_APP_ID,
  payload: {
    appleAppId,
  },
});

export const setXBrandId = (xBrandId: string): SetXBrandId => ({
  type: TYPES.SET_X_BRAND_ID,
  payload: {
    xBrandId,
  },
});
