import React from 'react';
import Modal from '@mui/material/Modal';
import './CarProfileView.less';
import {
  BookingData,
  getBrandColor,
  DriverCarData,
  withTextLocalizer,
} from 'getaway-data-layer';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import BrandIcon from '../BrandIcon/BrandIcon';
import PhotoAlbum from '../PhotoAlbum/PhotoAlbum';
import Plate from '../Plate/Plate';
import { isMobileBrowser, titleForDriverCar } from '../../../utils';
import Info from './Info';
import PricingTable from './PricingTable';
import BookingTable from './BookingTable';
import { ThemeProvider, createTheme } from '@mui/material/styles';

type Props = {
  carAddress: string;
  selectedCar: DriverCarData;
  selectedTab: number;
  carBookings: Array<BookingData>;
  isOpened: boolean;
  handleClose: () => void;
  onClickShare: () => void;
  onClickAddress: () => void;
  onChangeTab: (e: any, index: any) => void;
  fleetStyle: string;
  fleetColor: string;
};

const CarProfileView = ({
  carAddress,
  selectedCar,
  selectedTab,
  isOpened,
  carBookings,
  fleetStyle,
  handleClose,
  onChangeTab,
  onClickShare,
  onClickAddress,
  fleetColor,
}: Props) => {
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: fleetColor,
            height: '3px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: fleetColor,
            },
          },
        },
      },
    },
  });

  return (
    <Modal open={isOpened} onClose={handleClose}>
      <div
        className="screen-container"
        style={
          isMobileBrowser()
            ? {}
            : {
                backgroundColor: 'white',
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                top: 0,
                left: 0,
                bottom: 0,
              }
        }
      >
        <img
          className="car-profile-header"
          src="/images/headerGradiant.png"
          alt="headerGradiant"
        />
        <div className="car-profile-close-container" onClick={handleClose}>
          <BrandIcon
            name="whiteCloseIcon"
            style={{
              width: '20px',
              height: '20px',
              zIndex: 2,
              cursor: 'pointer',
            }}
          />
        </div>
        <div onClick={onClickShare}>
          <BrandIcon
            name="share"
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              width: '22px',
              height: '22px',
              zIndex: 2,
              cursor: 'pointer',
            }}
          />
        </div>
        {selectedCar && <PhotoAlbum images={selectedCar?.images} showArrows />}
        {selectedCar && (
          <div className="screen-body" style={{ padding: 0 }}>
            <div className="car-profile-title">
              {titleForDriverCar(selectedCar)}
            </div>
            <div className="car-profile-row">
              <Plate carPlate={selectedCar?.plate!} />
              <div
                className="car-profile-address"
                onClick={onClickAddress}
                style={{ color: getBrandColor(fleetStyle) }}
              >
                {carAddress}
              </div>
            </div>
            <div className="car-profile-tab-wrapper">
              <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <TabContext value={selectedTab}>
                  {/* @ts-ignore */}
                  <TabList
                    centered
                    value={selectedTab}
                    onChange={onChangeTab}
                    variant="fullWidth"
                  >
                    <Tab label="Info" value="0" />
                    <Tab label="Verfügbarkeit" value="1" />
                    <Tab label="Preise" value="2" />
                  </TabList>
                  {/* @ts-ignore */}
                  <TabPanel value="0" style={{ padding: 0 }}>
                    <Info selectedCar={selectedCar} fleetStyle={fleetStyle} />
                  </TabPanel>
                  <TabPanel value="1" style={{ padding: 0, paddingBottom: 70 }}>
                    <BookingTable
                      carBookings={carBookings}
                      car={selectedCar}
                      fleetColor={fleetColor}
                    />
                  </TabPanel>
                  {/* @ts-ignore */}
                  <TabPanel value="2" style={{ padding: 0, paddingBottom: 20 }}>
                    <PricingTable selectedCar={selectedCar} />
                  </TabPanel>
                </TabContext>
              </ThemeProvider>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default withTextLocalizer(CarProfileView);
