import { Dispatch } from '../../../../@types';
import TYPES from '../../../../@types/redux/store/BookingsTypes';

const clearBookingViolations = () => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_BOOKING_VIOLATIONS,
  });
};

export const setBookingViolations =
  (bookingViolations: Record<string, any>) => (dispatch: Dispatch) => {
    const updatedBookingViolations = { ...bookingViolations };

    if (updatedBookingViolations['api_error.block_payment_parameter_missing']) {
      delete updatedBookingViolations[
        'api_error.block_payment_parameter_missing'
      ];
    }

    if (
      updatedBookingViolations[
        'api_error.block_payment_parameter_missing.paypal'
      ]
    ) {
      delete updatedBookingViolations[
        'api_error.block_payment_parameter_missing.paypal'
      ];
    }

    dispatch({
      type: TYPES.SET_BOOKING_VIOLATIONS,
      payload: {
        bookingViolations: updatedBookingViolations,
      },
    });
    setTimeout(() => dispatch(clearBookingViolations()), 1000);
  };
