import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CLIENT_TYPE, State } from '../@types';
import { getCarPriceDetails } from '../redux/store/pricing';
import { estimateCost } from '../redux/store/userData/bookings/actions';
import { updatePayment } from '../redux/store/userData/user/actions';

export type Props = {
  pricing: any;
  getCarPriceDetails: typeof getCarPriceDetails;
  estimateCost: typeof estimateCost;
  updatePayment: typeof updatePayment;
};

const withCarProfile: any = compose(
  connect(
    ({ pricing, networkStatus: { activities } }: State) => ({
      pricing,
      reservingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
      ),
      bookingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      getCarPriceDetails,
      estimateCost,
      updatePayment,
    }
  )
);
export default withCarProfile;
