import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/AdminTypes';
import { AdminAction, AdminState } from '../../../../@types';
export default (
  state: AdminState = initialState,
  action: AdminAction
): AdminState => {
  switch (action.type) {
    case TYPES.SET_ADMIN: {
      return {
        ...state,
        admin: action.payload.admin!,
        email: action.payload.admin?.email!,
      };
    }

    case TYPES.SET_EMAIL: {
      return { ...state, email: action.payload.email };
    }

    case TYPES.SET_AUTH: {
      return { ...state, basicAuth: action.payload.basicAuth };
    }

    // @ts-ignore
    case PURGE:
      return { ...initialState, email: state.email };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
