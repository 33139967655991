import { DriverCarData } from '../../Types.api';
import { Location } from '../../Types';
export type CardContentType =
  | 'reservedCar'
  | 'openedCar'
  | 'drivingCar'
  | 'parkingCar'
  | 'signUp'
  | 'offlineService'
  | 'reservedOfflineService'
  | 'startedOfflineService'
  | 'mymTripStart'
  | 'mymTripDestination'
  | 'mymTripStartLocation'
  | 'none';
export type MapRegion = {
  latitude: number;
  latitudeDelta: number;
  longitude: number;
  longitudeDelta: number;
};
export type CardState = 'Expanded' | 'Collapsed' | 'Header' | 'Hidden';
export type MapContent = {
  cardType: string;
  mapType: any | null | undefined;
  id?: string[] | string | null | undefined;
};
export type MapFilterType = 'all' | 'today' | 'tomorrow' | 'thisWeek' | '';
export type MapFilter = {
  type: MapFilterType | null | undefined;
};
export type MapState = {
  readonly visibleRegion: MapRegion;
  readonly mapBoundaries: Array<Location>;
  readonly content: MapContent;
  readonly cardState: CardState;
  readonly carsSearchAllowed: boolean;
  readonly isFirstRental: boolean;
  readonly opened: boolean;
  readonly selectedCar: DriverCarData | null | undefined;
  readonly activeAlert: any | null | undefined;
  readonly alertBaseMode: boolean;
  readonly mapFilter: MapFilter;
  readonly showUserLocation: boolean;
  readonly defaultTab: number;
  readonly showHomeWT: boolean;
  readonly userAddress: null | string;
  readonly regionChangeNo: number;
  readonly isMapDragging: boolean;
  readonly isEmbedded: boolean;
};
enum MapTypes {
  SET_MAP_CONTENT = 'ui/card/SET_MAP_CONTENT',
  SET_MAP_REGION = 'ui/card/SET_MAP_REGION',
  SET_MAP_BOUNDARIES = 'ui/card/SET_MAP_BOUNDARIES',
  SET_CARD_STATE = 'ui/card/SET_CARD_STATE',
  SET_CARS_SEARCH_ALLOWED = 'ui/card/SET_CARS_SEARCH_ALLOWED',
  SET_OPENED = 'ui/SET_OPENED',
  SET_IS_FIRST_RENTAL = 'ui/SET_IS_FIRST_RENTAL',
  SET_SELECTED_CAR = 'ui/SET_SELECTED_CAR',
  SET_ACTIVE_ALERT = 'ui/SET_ACTIVE_ALERT',
  SET_ALERT_BASE_MODE = 'ui/SET_ALERT_BASE_MODE',
  SET_MAP_FILTER = 'ui/SET_MAP_FILTER',
  CLEAR_MAP_FILTER = 'ui/CLEAR_MAP_FILTER',
  SET_USER_LOCATION = 'ui/SET_USER_LOCATION',
  SET_DEFAULT_TAB = 'ui/SET_DEFAULT_TAB',
  SET_SHOW_HOME_WT = 'ui/SET_SHOW_HOME_WT',
  SET_USER_ADDRESS = 'ui/SET_USER_ADDRESS',
  SET_IS_MAP_DRAGGING = 'ui/SET_IS_MAP_DRAGGING',
  SET_IS_EMBEDDED = 'ui/SET_IS_EMBEDDED',
  SET_REGION_CHANGE_NO = 'ui/SET_REGION_CHANGE_NO',
  RESET_STORE = 'RESET_STORE',
}
export default MapTypes;
export type SetMapContent = {
  type: MapTypes.SET_MAP_CONTENT;
  payload: {
    mapType: any | null | undefined;
    cardType: string;
    id: string[] | string | null | undefined;
  };
};
export type SetMapFilter = {
  type: MapTypes.SET_MAP_FILTER;
  payload: {
    type: MapFilterType | null | undefined;
  };
};
export type SetShowUserLocation = {
  type: MapTypes.SET_USER_LOCATION;
  payload: {
    showUserLocation: boolean;
  };
};
export type ClearMapFilter = {
  type: MapTypes.CLEAR_MAP_FILTER;
};
export type SetMapRegion = {
  type: MapTypes.SET_MAP_REGION;
  payload: {
    region: MapRegion;
  };
};
export type SetMapBoundaries = {
  type: MapTypes.SET_MAP_BOUNDARIES;
  payload: {
    mapBoundaries: Array<Location>;
  };
};
export type SetCardState = {
  type: MapTypes.SET_CARD_STATE;
  payload: {
    cardState: CardState;
  };
};
export type SetCarsSearchAllowed = {
  type: MapTypes.SET_CARS_SEARCH_ALLOWED;
  payload: {
    carsSearchAllowed: boolean;
  };
};
export type SetOpened = {
  type: MapTypes.SET_OPENED;
  payload: {
    opened: boolean;
  };
};
export type SetIsEmbedded = {
  type: MapTypes.SET_IS_EMBEDDED;
  payload: {
    isEmbedded: boolean;
  };
};
export type SetIsFirstRental = {
  type: MapTypes.SET_IS_FIRST_RENTAL;
  payload: {
    isFirstRental: boolean;
  };
};
export type SetSelectedCar = {
  type: MapTypes.SET_SELECTED_CAR;
  payload: {
    selectedCar: DriverCarData | null | undefined;
  };
};
export type SetActiveAlert = {
  type: MapTypes.SET_ACTIVE_ALERT;
  payload: {
    activeAlert: any | null | undefined;
  };
};
export type SetAlertBaseMode = {
  type: MapTypes.SET_ALERT_BASE_MODE;
  payload: {
    alertBaseMode: boolean;
  };
};
export type SetDefaultTab = {
  type: MapTypes.SET_DEFAULT_TAB;
  payload: {
    defaultTab: number;
  };
};
export type SetShowHomeWT = {
  type: MapTypes.SET_SHOW_HOME_WT;
  payload: {
    showHomeWT: boolean;
  };
};
export type SetUserAddress = {
  type: MapTypes.SET_USER_ADDRESS;
  payload: {
    address: string;
  };
};
export type SetIsMapDragging = {
  type: MapTypes.SET_IS_MAP_DRAGGING;
  payload: {
    value: boolean;
  };
};

export type SetRegionChangeNo = {
  type: MapTypes.SET_REGION_CHANGE_NO;
  payload: {
    regionChangeNo: number;
  };
};

export type MapAction =
  | SetMapContent
  | SetMapRegion
  | SetMapBoundaries
  | SetCardState
  | SetCarsSearchAllowed
  | SetOpened
  | SetIsFirstRental
  | SetSelectedCar
  | SetActiveAlert
  | SetAlertBaseMode
  | SetShowUserLocation
  | SetMapFilter
  | SetDefaultTab
  | ClearMapFilter
  | SetShowHomeWT
  | SetUserAddress
  | SetIsMapDragging
  | SetRegionChangeNo
  | SetIsEmbedded;
