import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizePropType,
  login,
  isKYCApp,
  selectDeeplink,
  withTextLocalizer,
  selectPreSignupBooking,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import LoginView from './LoginView';
import { GA_TITLES, navigateTo, reportGAEvent } from '../../../utils';
import { LoginModal, LoginSchema } from '../../../validation';

interface Values {
  email: string;
  password: string;
}

type Props = {
  localize: LocalizePropType;
};

const LoginContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const deeplink = useSelector(selectDeeplink);
  const preSignupBooking = useSelector(selectPreSignupBooking);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = ({ email, password }: Values) => {
    dispatch(
      login(email.trim(), password.trim(), undefined, () => {
        navigateTo(
          history,
          isKYCApp() ? deeplink || '/settings' : deeplink || '/map'
        );
        reportGAEvent(GA_TITLES.AUTH.LOGIN_SUBMITTED);
      })
    );
  };

  const onPressForgotPassword = () => {
    navigateTo(history, '/forgot');
  };

  const onPressSignup = () => {
    navigateTo(history, '/signup');
  };

  const loginFormik = useFormik({
    initialValues: LoginModal,
    validationSchema: LoginSchema(localize),
    onSubmit,
  });

  const onClose = () => {
    navigateTo(history, isKYCApp() ? '/' : '/map');
  };

  return (
    <LoginView
      localize={localize}
      formik={loginFormik}
      showPassword={showPassword}
      showSignupLink={true}
      onClose={onClose}
      onPressForgotPassword={onPressForgotPassword}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      onPressSignup={onPressSignup}
    />
  );
};

export default withTextLocalizer(LoginContainer);
