import React from 'react';
import {
  clearAuth,
  deletePayment,
  selectUser,
  terminateUser,
  withTextLocalizer,
  forgetPassword,
  selectRentalData,
  removeCircle,
  LocalizePropType,
  selectCircleId,
  isKYCApp,
  isFRM,
  selectBrandSettings,
  setIsVerifyingGDPREmail,
  isMVV,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountTabView from './AccountTabView';
import {
  GA_TITLES,
  getAddressStatus,
  getEmailVerificationStatus,
  getFrmTariffStatus,
  getIDStatus,
  getLicenseStatus,
  getPhoneStatus,
  getTariffStatus,
  hasCompletedFrmProductOptions,
  isDashboardScreen,
  isEmailVerificationRequired,
  isIDVerificationRequired,
  isIncompleteProfile,
  isMobileBrowser,
  navigateTo,
  reportGAEvent,
  showAlert,
  showAlertWithOptions,
  TALLY_LINKS,
} from '../../../../../utils';
import { useCustomerSupport } from '../../../../../hooks/useCustomerSupport';
import { generateDepositPaymentLink } from 'getaway-data-layer/src/redux/store/userData/user/actions';
import { setIsEditingPhone } from 'getaway-data-layer/src/redux/store/ui/firstSteps/Actions';
import { showAboutDialog } from '../../../../../utils/dialogsHelpers';
import config from '../../../../../env.config';
import packageInfo from '../../../../../../package.json';

type Props = {
  localize: LocalizePropType;
};

const AccountTabContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { helpAction } = useCustomerSupport(localize);
  const user = useSelector(selectUser);
  const brandSettings = useSelector(selectBrandSettings) as any;
  const selectedCircleId = useSelector(selectCircleId);
  const isServiceHero =
    user?.serviceHero ||
    !!user?.circles?.find(
      (circle) =>
        circle.id === selectedCircleId && circle?.circleType === 'service'
    );
  const inCompleteProfile = isFRM()
    ? isIncompleteProfile(user) || !hasCompletedFrmProductOptions(user)
    : isIncompleteProfile(user);
  let onboardingOnlyProduct = isKYCApp();
  const readOnlyMode =
    !inCompleteProfile &&
    onboardingOnlyProduct &&
    !!(isMVV() ? user?.extraData : true);
  const rentalData = useSelector(selectRentalData) as any;

  const phoneStatus = getPhoneStatus(user);
  const addressStatus = getAddressStatus(user);
  const licenseStatus = getLicenseStatus(user);
  const idStatus = getIDStatus(user);
  const tariffStatus = isFRM()
    ? getFrmTariffStatus(user)
    : getTariffStatus(user);
  const emailStatus = getEmailVerificationStatus(user);

  const onPressPhoneIncomplete = () => {
    navigateTo(history, '/dashboard/addPhone');
  };

  const showHelpDialog = () => {
    showAlert(
      '',
      localize('user.phone.completed.message'),
      localize('user.action.support.text'),
      () => helpAction(true),
      localize('user.notNow'),
      () => {}
    );
  };

  const onPressPhoneCompleted = () => {
    showAlert(
      '',
      localize('user.phone.edit.desc'),
      localize('continue'),
      () => {
        dispatch(setIsEditingPhone(true));
        navigateTo(history, '/dashboard/email');
      },
      localize('cancel'),
      () => {}
    );
  };

  const onPressAddress = () => {
    navigateTo(history, '/dashboard/addAddress');
  };

  const onPressAddressInProgress = () => {
    showAlert(
      '',
      localize('user.address.inProgress.message'),
      null,
      () => {},
      localize('ok'),
      () => {}
    );
  };

  const onPressAddressCompleted = () => {
    if (isIDVerificationRequired(user)) {
      showAlert(
        '',
        localize('user.ID.completed.message'),
        localize('user.action.support.text'),
        () => helpAction(true),
        localize('user.notNow'),
        () => {}
      );
    } else {
      onPressAddress();
    }
  };

  const onPressDrivingLicense = () => {
    if (
      brandSettings?.paymentRequiredForDocumentVerification &&
      !user?.paymentMethods?.find(
        (payment: any) => payment.status === 'verified'
      ) &&
      !isServiceHero
    ) {
      showAlert(
        '',
        localize('user.noPayment.message'),
        localize('user.noPayment.action'),
        () => onAddPayment(),
        localize('cancel'),
        () => {}
      );
    } else {
      if (
        !user?.remainingLicenseSubmissionAttempts ||
        user?.remainingLicenseSubmissionAttempts > 0
      ) {
        navigateTo(history, '/dashboard/DLOnboarding');
      } else {
        showAlert(
          '',
          localize('user.DL.resubmission.message'),
          localize('user.action.support.text'),
          () => showHelpDialog(),
          localize('user.notNow'),
          () => {}
        );
      }
    }
  };

  const onPressDLInProgress = () => {
    showAlert(
      '',
      localize('user.DL.inProgress.message'),
      null,
      () => {},
      localize('ok'),
      () => {}
    );
  };

  const onPressDLCompleted = () => {
    showAlert(
      '',
      localize('user.DL.completed.message'),
      localize('user.action.support.text'),
      () => helpAction(true),
      localize('user.notNow'),
      () => {}
    );
  };

  const onPressID = () => {
    if (
      brandSettings?.paymentRequiredForDocumentVerification &&
      !user?.paymentMethods?.find(
        (payment: any) => payment.status === 'verified'
      ) &&
      !isServiceHero
    ) {
      showAlert(
        '',
        localize('user.noPayment.message'),
        localize('user.noPayment.action'),
        () => onAddPayment(),
        localize('cancel'),
        () => {}
      );
    } else {
      if (
        !user?.remainingIDSubmissionAttempts ||
        user?.remainingIDSubmissionAttempts > 0
      ) {
        navigateTo(history, '/dashboard/IDOnboarding');
      } else {
        showAlert(
          '',
          localize('user.ID.resubmission.message'),
          localize('user.action.support.text'),
          () => showHelpDialog(),
          localize('user.notNow'),
          () => {}
        );
      }
    }
  };

  const onPressIDInProgress = () => {
    showAlert(
      '',
      localize('user.ID.inProgress.message'),
      null,
      () => {},
      localize('ok'),
      () => {}
    );
  };

  const onPressIDCompleted = () => {
    showAlert(
      '',
      localize('user.ID.completed.message'),
      localize('user.action.support.text'),
      () => helpAction(true),
      localize('user.notNow'),
      () => {}
    );
  };

  const onDeletePayment = (payment) => {
    if (payment?.status === 'pending') {
      showAlert(
        '',
        localize(
          user?.userType === 'org'
            ? 'user.profile.payment.org.pending'
            : 'user.profile.payment.pending'
        ),
        '',
        () => {},
        localize('ok'),
        () => {}
      );
    } else if (rentalData?.carMode) {
      showAlert(
        '',
        localize('user.logout.cancelReservation.message'),
        '',
        () => {},
        localize('user.logout.cancelReservation.ok'),
        () => {}
      );
    } else {
      showAlert(
        localize('user.deletePayment.dialog.title'),
        localize('user.deletePayment.dialog.message'),
        localize('user.deletePayment.dialog.yes'),
        () => dispatch(deletePayment(payment.id)),
        localize('user.deletePayment.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onOpenPaymentProfile = (payment) => {
    if (payment?.status === 'pending') {
      showAlert(
        '',
        localize(
          user?.userType === 'org'
            ? 'user.profile.payment.org.pending'
            : 'user.profile.payment.pending'
        ),
        '',
        () => {},
        localize('ok'),
        () => {}
      );
    } else {
      navigateTo(history, `/dashboard/paymentProfile/${payment.id}`);
    }
  };

  const onPressEmail = () => {
    if (
      isEmailVerificationRequired(user) &&
      getEmailVerificationStatus(user) !== 'completed'
    ) {
      navigateTo(history, '/dashboard/email');
    } else {
      showAlert(
        '',
        localize('user.email.completed.message'),
        localize('user.action.support.text'),
        () => helpAction(true),
        localize('user.notNow'),
        () => {}
      );
    }
  };

  const onPressPassword = () => {
    dispatch(forgetPassword(user?.email!!, localize));
  };

  const onLogout = () => {
    if (rentalData?.carMode) {
      showAlert(
        '',
        localize('user.logout.cancelReservation.message'),
        '',
        () => {},
        localize('user.logout.cancelReservation.ok'),
        () => {}
      );
    } else {
      showAlert(
        localize('user.logout.dialog.title'),
        localize('user.logout.dialog.message'),
        localize('user.logout.dialog.yes'),
        () => {
          localStorage?.clear();
          dispatch(clearAuth());
          navigateTo(history, onboardingOnlyProduct ? '/' : '/map');
          reportGAEvent(GA_TITLES.AUTH.LOGOUT_SUBMITTED);
        },
        localize('user.logout.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onTerminateUser = () => {
    if (rentalData?.carMode) {
      showAlert(
        '',
        localize('user.logout.cancelReservation.message'),
        '',
        () => {},
        localize('user.logout.cancelReservation.ok'),
        () => {}
      );
    } else {
      showAlert(
        localize('user.terminate.dialog.title'),
        localize('user.terminate.dialog.message'),
        localize('user.terminate.dialog.yes'),
        () => {
          dispatch(
            terminateUser(() => {
              navigateTo(history, onboardingOnlyProduct ? '/' : '/map');
            })
          );
        },
        localize('user.terminate.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onAddPayment = () => {
    if (isKYCApp()) {
      navigateTo(history, '/dashboard/optimizedSepa');
    } else {
      navigateTo(history, '/dashboard/selectPayment');
    }
  };

  const onClose = () => {
    navigateTo(history, isMobileBrowser() ? '/map' : '/');
  };

  const onPressConsents = () => {
    navigateTo(history, '/dashboard/consents');
  };

  const onPressCircle = (circle: any) => {
    showAlert(
      '',
      localize('user.circle.description'),
      localize('user.circle.leave'),
      () =>
        setTimeout(
          () =>
            showAlert(
              '',
              localize('user.circle.leave.description'),
              localize('user.circle.leave'),
              () => dispatch(removeCircle(circle.circleCode)),
              localize('cancel'),
              () => {},
              true
            ),
          100
        ),
      localize('cancel'),
      () => {},
      true
    );
  };

  const onAddDepositPayment = () => {
    const newWindow = window.open('', '_blank');
    dispatch(
      generateDepositPaymentLink(
        user?.deposit?.toPayForSepaUnlock,
        undefined,
        (paymentLink: string) => {
          if (newWindow) {
            // Redirect the new window to the payment link
            newWindow.location.href = paymentLink;
          }
        }
      )
    );
  };

  const onDepositBalanceClick = () => {
    if (user?.deposit?.invoiceUrl) {
      const options = [
        {
          title: localize('deposit.dialog.receipt'),
          action: () => {
            window.open(user?.deposit?.invoiceUrl, '_blank');
          },
        },
      ];
      showAlertWithOptions(localize('deposit.dialog.title'), '', options);
    } else {
      showAlert(
        '',
        localize('user.deposit.dialog.msg'),
        localize('user.deposit.dialog.yes'),
        () => helpAction(true),
        localize('user.deposit.dialog.no'),
        () => {}
      );
    }
  };

  const onPressGDPR = () => {
    const options = [
      {
        title: localize('profile.gdpr.dialog.request'),
        action: () => {
          dispatch(setIsVerifyingGDPREmail(true));
          navigateTo(history, '/dashboard/email');
        },
      },
      {
        title: localize('profile.gdpr.dialog.more'),
        action: () => {
          window.open(localize('profile.gdpr.dialog.more.url'), '_blank');
        },
      },
      { title: localize('cancel') },
    ];

    showAlertWithOptions(
      localize('profile.gdpr.dialog.title'),
      localize(
        'profile.gdpr.dialog.msg',
        brandSettings?.name,
        brandSettings?.merchantName
      ),
      options
    );
  };

  const onPressJoinCircle = () => {
    navigateTo(history, '/dashboard/joinCircle');
  };

  const onPressTariff = () => {
    if (isMVV() && !user?.extraData) {
      navigateTo(
        history,
        isDashboardScreen()
          ? `/dashboard/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
          : `/onboarding/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
      );
    } else if (isFRM()) {
      navigateTo(
        history,
        user?.tariff?.id
          ? `/dashboard/tariff`
          : `/dashboard/dynamicForm?url=${TALLY_LINKS.FRM_PARTNER_AREA}`
      );
    } else {
      navigateTo(history, `/dashboard/tariff`);
    }
  };

  const onPressTariffCompleted = () => {
    showAlert(
      '',
      localize('user.ID.completed.message'),
      localize('user.action.support.text'),
      () => helpAction(true),
      localize('user.notNow'),
      () => {}
    );
  };

  const onAddPaymentProfile = () => {
    navigateTo(history, `/dashboard/paymentProfile`);
  };

  const onClickVersionHeader = () => {
    showAboutDialog(
      config.brand.xBrandId!!,
      localize,
      `© GETAWAY・v${packageInfo.version}`
    );
  };

  return (
    <AccountTabView
      user={user!!}
      localize={localize}
      readOnlyMode={readOnlyMode}
      phoneNumber={user?.phoneNumber!!}
      phoneStatus={phoneStatus}
      blocked={user?.blocked}
      onPressPhoneIncomplete={onPressPhoneIncomplete}
      onPressPhoneCompleted={onPressPhoneCompleted}
      address={user?.address}
      addressStatus={addressStatus}
      tariffStatus={tariffStatus}
      emailStatus={emailStatus}
      onPressAddress={onPressAddress}
      onPressAddressInProgress={onPressAddressInProgress}
      onPressAddressCompleted={onPressAddressCompleted}
      license={user?.license}
      licenseStatus={licenseStatus}
      idStatus={idStatus}
      onPressDrivingLicense={onPressDrivingLicense}
      onPressDLInProgress={onPressDLInProgress}
      onPressDLCompleted={onPressDLCompleted}
      paymentMethods={user?.paymentMethods}
      onOpenPaymentProfile={onOpenPaymentProfile}
      onPressEmail={onPressEmail}
      onPressPassword={onPressPassword}
      onTerminateUser={onTerminateUser}
      onClose={onClose}
      onLogout={onLogout}
      onAddPayment={onAddPayment}
      onPressCircle={onPressCircle}
      onPressJoinCircle={onPressJoinCircle}
      onPressID={onPressID}
      onPressIDInProgress={onPressIDInProgress}
      onPressIDCompleted={onPressIDCompleted}
      onboardingOnly={onboardingOnlyProduct}
      onDeletePayment={onDeletePayment}
      onAddPaymentProfile={onAddPaymentProfile}
      onAddDepositPayment={onAddDepositPayment}
      onDepositBalanceClick={onDepositBalanceClick}
      onPressGDPR={onPressGDPR}
      onPressTariff={onPressTariff}
      onPressTariffCompleted={onPressTariffCompleted}
      onPressConsents={onPressConsents}
      onClickVersionHeader={onClickVersionHeader}
    />
  );
};

export default withTextLocalizer(AccountTabContainer);
