export const locationFromString = (
  position?: string,
  separator: string = ',',
  latLong: boolean = true,
  short: boolean = false
): any => {
  if (position) {
    const numbers = position.split(separator);

    if (latLong) {
      return short
        ? {
            lat: Number(numbers[0]),
            lng: Number(numbers[1]),
          }
        : {
            latitude: Number(numbers[0]),
            longitude: Number(numbers[1]),
          };
    }

    return short
      ? {
          lat: Number(numbers[1]),
          lng: Number(numbers[0]),
        }
      : {
          latitude: Number(numbers[1]),
          longitude: Number(numbers[0]),
        };
  }

  return null;
};
