import axios from 'axios';
import { isWeb } from '../../../helpers/platformHelpers';
import { injectedStore } from '../../ConfigureStore';
import { injectedFetchBlob } from '../../ConfigureGlobals';

export const uploadFileFromStorage = async (
  url: string,
  fileUri: string,
  method: 'POST' | 'PUT' = 'POST'
): Promise<any> => {
  const state = injectedStore.getState();
  const { apiBaseURL, apiVersion, xBrandId } = state.config;
  const { deviceUUID, platform, model, pushToken, pushDeviceID, userAgent } =
    state.device;

  const headers = {
    Authorization: 'Bearer access-token...',
    'Content-Type': 'application/octet-stream',
    Accept: 'application/json; charset=utf-8',
    'X-API-Version': apiVersion,
    'X-Application-Client': 'mobile',
    'Cache-Control': 'no-cache',
  };

  if (xBrandId) {
    // @ts-ignore
    headers['X-Brand-ID'] = xBrandId;
  }

  if (deviceUUID) {
    // @ts-ignore
    headers['X-Device-UUID'] = deviceUUID;
  }

  if (platform) {
    // @ts-ignore
    headers['X-Device-Platform'] = `${platform.name} ${platform.version}`;
  }

  if (model) {
    // @ts-ignore
    headers['X-Device-Model'] = model;
  }

  if (pushToken) {
    // @ts-ignore
    headers['X-Push-Token'] = pushToken;
  }

  if (pushDeviceID) {
    // @ts-ignore
    headers['X-Push-Device-ID'] = pushDeviceID;
  }

  if (userAgent) {
    // @ts-ignore
    headers['User-Agent'] = `${userAgent} React-Native`;
  }

  if (state.userData.user.basicAuth) {
    headers.Authorization = state.userData.user.basicAuth;
  }

  if (!fileUri) {
    return { error: 'No fileUri provided' };
  }

  if (isWeb()) {
    try {
      let data = null;
      if (fileUri.startsWith('blob:')) {
        const response = await fetch(fileUri);
        data = await response.blob();
      }

      const axiosResponse = await axios({
        method: method,
        url: `${apiBaseURL}/${url}`,
        headers: headers,
        data: data,
      });
      URL.revokeObjectURL(fileUri);
      return { data: axiosResponse.data };
    } catch (error) {
      return { error: error.message };
    }
  } else {
    let wrappedPath: string;
    if (fileUri.startsWith('file://')) {
      wrappedPath = 'RNFetchBlob-' + fileUri;
    } else {
      wrappedPath = injectedFetchBlob.wrap(fileUri);
    }
    try {
      const response = await injectedFetchBlob.fetch(
        method,
        `${apiBaseURL}${url}`,
        headers,
        wrappedPath
      );

      if (response.respInfo.status.toString().startsWith('2')) {
        return { data: response.data };
      }
      return { error: response.respInfo.status };
    } catch (error) {
      return { error: error.message };
    }
  }
};
