import React, { useEffect, useState } from 'react';
import {
  generateStartCheckImageUrl,
  getCarDamages,
  getRentalData,
  removeCarCheckCameraImage,
  selectCarCheckImages,
  selectRentalData,
  setCarCheckCameraImage,
  uploadStartCheckImage,
  uploadStartCheckImageS3,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import AllAroundView from './AllAroundView';
import { navigateTo } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { RENTAL_UPDATER_DURATION } from '../CarCheckCard/CarCheckCardContainer';

const AllAroundContainer = ({ localize }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentalData = useSelector(selectRentalData) as any;
  const carCheckImages = useSelector(selectCarCheckImages) as any;

  const [disabledButton, setDisableButton] = useState(false) as any;

  const onClose = () => {
    navigateTo(history, '/map');
  };

  const onClickSubmit = async () => {
    setDisableButton(true);
    const { imageOne, imageTwo, imageThree, imageFour } = carCheckImages;
    setTimeout(() => {
      const upload = (image: any, resolvePromise: () => void = () => {}) => {
        dispatch(
          generateStartCheckImageUrl(
            rentalData.id,
            (url: any) => {
              dispatch(
                uploadStartCheckImageS3(url, image.file, resolvePromise, () => {
                  // Fallback to api upload id s3 upload fails
                  dispatch(
                    uploadStartCheckImage(
                      rentalData.id,
                      image.file,
                      resolvePromise
                    )
                  );
                })
              );
            },
            () => {
              // Fallback to api upload id s3 url generations fails
              dispatch(
                uploadStartCheckImage(rentalData.id, image.file, resolvePromise)
              );
            }
          )
        );
      };

      upload(imageOne, () => {
        dispatch(removeCarCheckCameraImage(1));
      });
      upload(imageTwo, () => {
        dispatch(removeCarCheckCameraImage(2));
      });
      upload(imageThree, () => {
        dispatch(removeCarCheckCameraImage(3));
      });
      upload(imageFour, () => {
        dispatch(removeCarCheckCameraImage(4));
        dispatch(getRentalData(rentalData?.id));
      });
      navigateTo(history, '/map');
    }, 300);
  };

  const onPressRemoveImage = (index: number) => {
    dispatch(removeCarCheckCameraImage(index));
  };

  const onChangeFirstImage = async () => {
    // @ts-ignore
    const file = document.getElementById('car-check-image-1').files;
    const imageFile = file[file.length - 1];
    dispatch(
      setCarCheckCameraImage(1, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeSecondImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-2').files[0];
    dispatch(
      setCarCheckCameraImage(2, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeThirdImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-3').files[0];
    dispatch(
      setCarCheckCameraImage(3, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeForthImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-4').files[0];
    dispatch(
      setCarCheckCameraImage(4, URL.createObjectURL(imageFile), imageFile)
    );
  };

  useEffect(() => {
    const rentalUpdaterId = setInterval(() => {
      dispatch(getRentalData(rentalData?.id));
    }, RENTAL_UPDATER_DURATION);
    dispatch(getCarDamages(rentalData?.carData?.id));

    // @ts-ignore
    document
      .getElementById('car-check-image-1')
      ?.addEventListener('change', onChangeFirstImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-2')
      ?.addEventListener('change', onChangeSecondImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-3')
      ?.addEventListener('change', onChangeThirdImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-4')
      ?.addEventListener('change', onChangeForthImage, true);

    dispatch(removeCarCheckCameraImage(1));
    dispatch(removeCarCheckCameraImage(2));
    dispatch(removeCarCheckCameraImage(3));
    dispatch(removeCarCheckCameraImage(4));
    return () => {
      if (rentalUpdaterId) clearInterval(rentalUpdaterId);
    };
  }, []);

  return (
    <AllAroundView
      localize={localize}
      onClickSubmit={onClickSubmit}
      carCheckImages={carCheckImages}
      onPressRemoveImage={onPressRemoveImage}
      isSubmitDisabled={
        Object.keys(carCheckImages).length < 4 || disabledButton
      }
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(AllAroundContainer);
