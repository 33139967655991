import React from 'react';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../../utils';
import { TextField } from '@mui/material';
import { FormikValues } from 'formik';
import Box from '@mui/material/Box';
import BrandButton from '../../../components/BrandButton/BrandButton';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  email: string;
  onClose: () => void;
  formik: FormikValues;
};

const UpdateEmailView = ({ localize, email, onClose, formik }: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('email.update.title')}
        description={localize('email.update.desc', email)}
        showHelp
        showArrowBack
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'email.update.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'email.update.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <TextField
              required
              fullWidth
              id="email"
              className="form-input"
              label={localize('signup.update.placeholder')}
              helperText={(formik.touched.email && formik.errors.email) || ''}
              error={formik.touched.email && Boolean(formik.errors.email)}
              variant="standard"
              size="small"
              type="email"
              autoComplete="email"
              {...formik.getFieldProps('email')}
            />

            <BrandButton
              id={GA_TITLES.ON_BOARDING.UPDATE_EMAIL}
              title={localize('email.update.button')}
              containerStyles={{ bottom: '40px' }}
              formik={formik}
            />
          </Box>
        </div>
        {isLocalizeAdded(localize, 'email.update.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('email.update.faq')}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateEmailView;
