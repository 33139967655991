import { v4 as uuidv4 } from 'uuid';
import TYPES from '../../../@types/redux/store/CameraTypes';
import {
  ClearCameraImages,
  RemoveCameraImage,
  RemoveCarCheckCameraImage,
} from '../../../@types';

export const clearCameraImages = (): ClearCameraImages => ({
  type: TYPES.CLEAR_CAMERA_IMAGES,
});

export const setCameraImages =
  (uri: String, base64: String | null = null) =>
  (dispatch: any) => {
    const id = uuidv4();
    dispatch({
      type: TYPES.SET_CAMERA_IMAGES,
      payload: {
        id,
        uri,
        base64,
      },
    });
  };

export const removeCameraImage = (id: string | number): RemoveCameraImage => ({
  type: TYPES.REMOVE_CAMERA_IMAGE,
  payload: {
    id,
  },
});

export const setCarCheckCameraImage =
  (index: Number, uri: String, file?: any) => (dispatch: any) => {
    dispatch({
      type: TYPES.SET_CAR_CHECK_CAMERA_IMAGE,
      payload: {
        index,
        uri,
        file,
      },
    });
  };

export const removeCarCheckCameraImage = (
  index: number
): RemoveCarCheckCameraImage => ({
  type: TYPES.REMOVE_CAR_CHECK_CAMERA_IMAGE,
  payload: {
    index,
  },
});
