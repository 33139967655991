import { MapState } from '../../../../@types';

const initialState: MapState = {
  content: {
    mapType: null,
    cardType: 'signUp',
    id: '',
  },
  visibleRegion: {
    latitude: 52.519783,
    longitude: 13.416321,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  },
  mapBoundaries: [],
  cardState: 'Hidden',
  carsSearchAllowed: true,
  opened: false,
  isFirstRental: true,
  selectedCar: null,
  activeAlert: null,
  alertBaseMode: false,
  mapFilter: {
    type: '',
  },
  showUserLocation: false,
  defaultTab: 0,
  showHomeWT: true,
  userAddress: null,
  isMapDragging: false,
  regionChangeNo: 0,
  isEmbedded: false,
};
export default initialState;
