import {
  ConnectCarData,
  ConnectDeviceData,
  ConnectFuelCardData,
  ConnectInstallationData,
} from '../../Types.api';
export type ConnectAppDataState = {
  readonly cars: Array<ConnectCarData>;
  readonly devices: Array<ConnectDeviceData>;
  readonly fuelCards: Array<ConnectFuelCardData>;
  readonly installation: ConnectInstallationData | null;
};
enum ConnectAppDataTypes {
  SET_CARS = 'connect/appData/SET_CARS',
  SET_DEVICES = 'connect/appData/SET_DEVICES',
  SET_FUEL_CARDS = 'connect/appData/SET_FUEL_CARDS',
  SET_INSTALLATION = 'connect/appData/SET_INSTALLATION',
}
export default ConnectAppDataTypes;
export type SetConnectCars = {
  type: ConnectAppDataTypes.SET_CARS;
  payload: {
    cars: Array<ConnectCarData>;
  };
};

export type SetConnectDevices = {
  type: ConnectAppDataTypes.SET_DEVICES;
  payload: {
    devices: Array<ConnectDeviceData>;
  };
};

export type SetConnectFuelCards = {
  type: ConnectAppDataTypes.SET_FUEL_CARDS;
  payload: {
    fuelCards: Array<ConnectFuelCardData>;
  };
};

export type SetConnectInstallation = {
  type: ConnectAppDataTypes.SET_INSTALLATION;
  payload: {
    installation: ConnectInstallationData | null;
  };
};

export type ConnectAppDataAction =
  | SetConnectCars
  | SetConnectDevices
  | SetConnectFuelCards
  | SetConnectInstallation;
