import React from 'react';
import { withTextLocalizer } from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import KeyVideoView from './KeyVideoView';

const KeyVideoContainer = () => {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
  };

  return <KeyVideoView onClose={onClose} />;
};

export default withTextLocalizer(KeyVideoContainer);
