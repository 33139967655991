import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setCarsSearchAllowed,
  setSelectedCar,
  setMapContent,
} from '../redux/store/ui/map';
import { reserveCar } from '../redux/store/userData/rental';
import { bookCar } from '../redux/store/userData/bookings/actions';
import { CLIENT_TYPE } from '../@types';
import { MapContent, State } from '../@types';
import { DriverCarData } from '../@types';
import { isMymBrand } from '../helpers/brandHelpers';

export type Props = {
  content: MapContent;
  selectedCar: DriverCarData | null | undefined;
  setMapContent: typeof setMapContent;
  searchCars: Array<DriverCarData>;
  from?: string;
  backBy: string;
  mileage?: number;
  reserveCar: typeof reserveCar;
  bookCar: typeof bookCar;
  setCarsSearchAllowed: typeof setCarsSearchAllowed;
  reservingCar: boolean;
  bookingCar: boolean;
  setSelectedCar: typeof setSelectedCar;
};

const withCarousel: any = compose(
  connect(
    ({
      ui: {
        map: { content },
      },
      userData: {
        user: { user },
      },
      carsSearch: { availableCars, from, backBy, mileage, circleId },
      networkStatus: { activities },
      config: { xBrandId },
    }: State) => ({
      content,
      selectedCar:
        isMymBrand(xBrandId) &&
        user?.circles?.find((circle) => circle?.id === circleId)?.circleType !==
          'service' &&
        !user?.serviceHero
          ? null
          : Object.values(availableCars)?.find(
              (item: any) => item?.id === content?.id
            ),
      searchCars: Object.values(availableCars),
      from,
      backBy,
      mileage,
      reservingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR].includes(activityType)
      ),
      bookingCar: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
    }),
    {
      setCarsSearchAllowed,
      setMapContent,
      reserveCar,
      bookCar,
      setSelectedCar,
    }
  )
);
export default withCarousel;
