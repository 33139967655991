import React, { useEffect } from 'react';
import CSS from 'csstype';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import './BrandButton.less';
import {
  selectFleetStyle,
  selectIsEmbedded,
  selectIsExpert,
  selectIsKeyboardOpened,
  selectIsOBI,
  selectIsSPP,
  selectRentalData,
} from 'getaway-data-layer';
import {
  attachInputListeners,
  isMobileBrowser,
  removeInputListeners,
} from '../../../utils';

type Props = {
  id: string;
  title: string;
  onClick?: () => void;
  containerStyles?: CSS.Properties;
  classStyle?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClickDisabled?: () => void;
  enableKeyboardEffect?: boolean;
  formik?: any;
};

const BrandButton = ({
  id,
  title,
  onClick,
  onClickDisabled,
  containerStyles,
  classStyle,
  disabled,
  isLoading,
  enableKeyboardEffect,
  formik,
}: Props) => {
  const isEmbedded = useSelector(selectIsEmbedded);
  const isKeyboardOpened = useSelector(selectIsKeyboardOpened) as any;
  const rentalData = useSelector(selectRentalData) as any;
  const isOBI = useSelector(selectIsOBI);
  const isExpert = useSelector(selectIsExpert);
  const isSPP = useSelector(selectIsSPP);
  let backgroundColor = null;

  if (!rentalData && !isEmbedded) {
    if (isOBI) {
      backgroundColor = '#00AB33';
    } else if (isExpert) {
      backgroundColor = '#004F9F';
    } else if (isSPP) {
      backgroundColor = '#f3be19';
    }
  }

  const handleTouch = (e) => {
    if (isKeyboardOpened && enableKeyboardEffect && isMobileBrowser()) {
      e.preventDefault();
      if (disabled) {
        if (onClickDisabled) onClickDisabled();
      } else {
        if (formik) {
          formik.handleSubmit();
        } else if (onClick) {
          onClick();
        }
      }
    }
  };

  useEffect(() => {
    if (enableKeyboardEffect) {
      attachInputListeners();
    }
    return () => {
      removeInputListeners();
    };
  }, []);

  return (
    <button
      id={id}
      data-testid="cta"
      type="submit"
      className={
        classStyle ||
        (disabled ? 'disabled-brand-btn noselect' : 'brand-btn noselect')
      }
      onClick={disabled ? onClickDisabled : onClick}
      onTouchStart={handleTouch}
      style={
        isKeyboardOpened && enableKeyboardEffect && isMobileBrowser()
          ? {
              ...containerStyles,
              position: 'unset',
              width: '100%',
              marginTop: 20,
              backgroundColor,
            }
          : { ...containerStyles, ...(backgroundColor && { backgroundColor }) }
      }
      disabled={!onClickDisabled && disabled}
    >
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={18}
          style={{ marginRight: 16 }}
        />
      )}
      {title}
    </button>
  );
};

BrandButton.defaultProps = {
  onClick: null,
  containerStyles: {},
  disabled: false,
  classStyle: '',
  onClickDisabled: null,
  isLoading: false,
  enableKeyboardEffect: true,
  formik: null,
};

export default BrandButton;
