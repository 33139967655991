import * as React from 'react';
import './StatusIndicator.less';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import {
  selectNetworkActivities,
  CLIENT_TYPE,
  selectIsEmbedded,
} from 'getaway-data-layer';

const StatusIndicator = () => {
  const networkActivities: any = useSelector(selectNetworkActivities);
  const isEmbedded = useSelector(selectIsEmbedded);
  const show = networkActivities.some((activityType: any) =>
    [
      ...(isEmbedded ? [CLIENT_TYPE.CARS_CLIENT.GET] : []),
      CLIENT_TYPE.AUTH_CLIENT.SIGNUP,
      CLIENT_TYPE.AUTH_CLIENT.LOGIN,
      CLIENT_TYPE.AUTH_CLIENT.CHECK_REFERRAL,
      CLIENT_TYPE.AUTH_CLIENT.FORGET_PASSWORD,
      CLIENT_TYPE.USER_CLIENT.UPDATE_USER,
      CLIENT_TYPE.USER_CLIENT.DELETE_PHONE,
      CLIENT_TYPE.USER_CLIENT.TERMINATE_USER,
      CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT,
      CLIENT_TYPE.USER_CLIENT.ADD_PAYMENT_DETAILS,
      CLIENT_TYPE.USER_CLIENT.UPDATE_PAYMENT,
      CLIENT_TYPE.USER_CLIENT.DELETE_PAYMENT,
      CLIENT_TYPE.USER_CLIENT.LOAD_TARIFFS,
      CLIENT_TYPE.USER_CLIENT.GET_JUMIO_CALLBACK_URL,
      CLIENT_TYPE.USER_CLIENT.SCHUFA_CHECK,
      CLIENT_TYPE.USER_CLIENT.ADDRESS_CHECK,
      CLIENT_TYPE.USER_CLIENT.REFUND_DEPOSIT,
      CLIENT_TYPE.USER_CLIENT.SEND_VERIFICATION_CODE,
      CLIENT_TYPE.USER_CLIENT.GET_TRANSACTION_PAYMENT_LINK,
      CLIENT_TYPE.USER_CLIENT.GET_TRANSACTIONS,
      CLIENT_TYPE.USER_CLIENT.GET_MORE_TRANSACTIONS,
      CLIENT_TYPE.USER_CLIENT.GET_RENTAL_HISTORY,
      CLIENT_TYPE.USER_CLIENT.GET_MORE_RENTAL_HISTORY,
      CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PUBLIC_KEY,
      CLIENT_TYPE.USER_CLIENT.DOWNLOAD_GDPR,
      CLIENT_TYPE.USER_CLIENT.GET_TRANSACTION_PAYMENT_LINK,
      CLIENT_TYPE.RENTAL_CLIENT.CANCEL_RESERVATION,
      CLIENT_TYPE.RENTAL_CLIENT.RESERVE_CAR,
      CLIENT_TYPE.RENTAL_CLIENT.FINISH_SERVICE_TYPE,
      CLIENT_TYPE.RENTAL_CLIENT.GET_FUEL_INFO,
      CLIENT_TYPE.RENTAL_CLIENT.RATE_CAR,
      CLIENT_TYPE.RENTAL_CLIENT.GET_MYM_TRIP_OFFER,
      CLIENT_TYPE.RENTAL_CLIENT.LOCK_CAR,
      CLIENT_TYPE.RENTAL_CLIENT.ADD_DAMAGE,
      CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE,
      CLIENT_TYPE.RENTAL_CLIENT.DELETE_DAMAGE_IMAGE,
      CLIENT_TYPE.RENTAL_CLIENT.UPDATE_RENTAL,
      CLIENT_TYPE.RENTAL_CLIENT.TAKE_SERVICE,
      CLIENT_TYPE.RENTAL_CLIENT.START_SERVICE,
      CLIENT_TYPE.RENTAL_CLIENT.FINISH_RENTAL,
      CLIENT_TYPE.RENTAL_CLIENT.UPDATE_RETURN_TIME,
      CLIENT_TYPE.RENTAL_CLIENT.GENERATE_SERVICE_IMAGE_URL,
      CLIENT_TYPE.RENTAL_CLIENT.GET_DRIVER_CAR_STATUS_LOADER,
      CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR,
      CLIENT_TYPE.BOOKING_CLIENT.ACCEPT_BOOKING_OFFER,
      CLIENT_TYPE.BOOKING_CLIENT.CANCEL_BOOKING_OFFER,
      CLIENT_TYPE.BOOKING_CLIENT.ACCEPT_BOOKING_REQUEST,
      CLIENT_TYPE.BOOKING_CLIENT.CANCEL_BOOKING_REQUEST,
      CLIENT_TYPE.SERVICE_CLIENT.GET_SERVICES,
      CLIENT_TYPE.CARS_CLIENT.GET_BY_ID,
      CLIENT_TYPE.CARS_CLIENT.GET_WITH_LOADER,
      CLIENT_TYPE.RENTAL_CLIENT.GET_SERVICE_TYPES,
      CLIENT_TYPE.PAYMENT_CLIENT.SUBMIT_IDENTITY_SHOPPER,
      CLIENT_TYPE.PAYMENT_CLIENT.SUBMIT_CHALLENGE,
      CLIENT_TYPE.PAYMENT_CLIENT.IS_PAYMENT_VERIFIED,
      CLIENT_TYPE.BOOKING_CLIENT.GET_CAR_BOOKINGS,
      CLIENT_TYPE.BOOKING_CLIENT.BOOK_POI,
      CLIENT_TYPE.BOOKING_CLIENT.UPDATE_BOOKING,
      CLIENT_TYPE.BOOKING_CLIENT.GET_BOOKING_HANDOVER,
      CLIENT_TYPE.BOOKING_CLIENT.GET_FLEET_INSURANCES,
      CLIENT_TYPE.BOOKING_CLIENT.CONFIRM_BOOKING,
      CLIENT_TYPE.USER_CLIENT.GET_PAYMENT_PAYMENTS,
      CLIENT_TYPE.USER_CLIENT.VERIFY_EMAIL,
      CLIENT_TYPE.USER_CLIENT.INIT_JUMIO_FOR_WEB,
    ].includes(activityType)
  );
  return show ? (
    <LoadingOverlay
      active
      spinner
      className="loading-overlay"
      // @ts-ignore
      styles={
        isEmbedded
          ? {
              content: {
                // @ts-ignore
                position: 'absolute',
                bottom: '100px',
                left: '50%',
                transform: 'translateX(-50%)',
              },
            }
          : undefined
      }
    />
  ) : null;
};

export default StatusIndicator;
