import * as React from 'react';
import {
  selectRentalData,
  selectRental,
  withTextLocalizer,
  LocalizePropType,
} from 'getaway-data-layer';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import LockContainer from './Lock/LockContainer';

type Props = {
  localize: LocalizePropType;
};

const RentalHandoverContainer = ({ localize }: Props) => {
  const history = useHistory();
  const rental = useSelector(selectRental) as any;
  const rentalData = useSelector(selectRentalData) as any;

  const showServiceReport =
    rentalData !== null &&
    rentalData.type === 'serviceTrip' &&
    !rental?.isServiceReportSubmitted;

  const onClose = () => {
    history.goBack();
  };

  return (
    <div className="screen-container">
      <NavigationBar
        title={
          showServiceReport
            ? localize('rental.service.report.title')
            : localize('rental.handover.lock.title')
        }
        description={
          rentalData?.carData?.vehicleConnection === 'geoTab'
            ? localize('rental.dongle.handover.description')
            : localize('rental.handover.description')
        }
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body" style={{ padding: 8 }}>
        {(showServiceReport && null) ||
          (rentalData?.carData?.vehicleConnection === 'geoTab' ? null : (
            <LockContainer />
          ))}
      </div>
    </div>
  );
};

export default withTextLocalizer(RentalHandoverContainer);
