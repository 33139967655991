import React from 'react';
import './PaymentProfileView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import {
  LocalizePropType,
  formattedAddress,
  isLocalizeAdded,
  maskSepa,
} from 'getaway-data-layer';
import ListItem from '../../../../components/ListItem/ListItem';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  onPressBack: () => void;
  onPressDeletePayment: (id: string) => void;
  onPressAddPayment: () => void;
  onPressAddBillingAddress: () => void;
  onPressReset: () => void;
  onChangeDefault: () => void;
  isDefault: boolean;
  isPrivate: boolean;
  onChangeIsPrivate: () => void;
  selectedPayment: any;
  billingInfo: any;
  paymentMethods: any;
};

const PaymentProfileView = ({
  localize,
  onPressBack,
  onPressDeletePayment,
  onPressAddPayment,
  onPressAddBillingAddress,
  onPressReset,
  onChangeDefault,
  isDefault,
  isPrivate,
  onChangeIsPrivate,
  selectedPayment,
  billingInfo,
  paymentMethods,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('payment.profile.title')}
        description=""
        showHelp
        showArrowBack
        onClose={onPressBack}
      />
      <div
        className={
          isLocalizeAdded(localize, 'payment.profile.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'payment.profile.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <ListItem
            title={localize('payment.profile.type')}
            toggleItem
            toggleValue={isPrivate}
            onToggleValueChange={onChangeIsPrivate}
            toggleOptionOne={localize('payment.profile.option.personal')}
            toggleOptionTwo={localize('payment.profile.option.business')}
          />
          {selectedPayment ? (
            <>
              {selectedPayment?.methodType === 'paypal' &&
                selectedPayment?.status === 'verified' && (
                  <ListItem
                    title={
                      selectedPayment?.paypal?.name?.length > 27
                        ? `${selectedPayment?.paypal?.name?.slice(0, 27)}...`
                        : selectedPayment?.paypal?.name ||
                          localize(`settings.selectedPayment.paypal.title`)
                    }
                    leftIcon="pal"
                    leftIconSize={30}
                    rightIconSize={16}
                  />
                )}
              {selectedPayment.payIn && (
                <ListItem
                  title={localize('settings.selectedPayment.payIn.title')}
                  leftItem={
                    <img
                      style={{
                        width: 31,
                        height: 20,
                        marginRight: 27,
                        marginLeft: -2,
                        objectFit: 'contain',
                      }}
                      src="/images/paymentPayIn.png"
                      alt="payin"
                    />
                  }
                  rightIconSize={16}
                />
              )}
              {selectedPayment.sepa && (
                <ListItem
                  title={maskSepa(selectedPayment.sepa.iban)}
                  leftIcon="paymentSepa"
                  leftIconSize={27}
                  rightIconSize={16}
                />
              )}
              {selectedPayment.card &&
                selectedPayment.status === 'verified' && (
                  <ListItem
                    title={`****${selectedPayment.card.number.substr(-4)}`}
                    leftIcon={
                      selectedPayment.card.variant === 'MasterCard'
                        ? 'paymentMastercard'
                        : 'paymentVisa'
                    }
                    leftIconSize={27}
                    rightIconSize={16}
                  />
                )}
            </>
          ) : (
            <ListItem
              title={localize('settings.payment.data.add')}
              brandTextColor
              leftIcon="plus"
              leftIconSize={14}
              rightIcon={
                paymentMethods.length &&
                paymentMethods.find(
                  (payment: any) => payment.status === 'verified'
                )
                  ? 'arrowRight'
                  : 'iconIncomplete'
              }
              rightIconSize={
                paymentMethods.length &&
                paymentMethods.find(
                  (payment: any) => payment.status === 'verified'
                )
                  ? 14
                  : 10
              }
              onClick={onPressAddPayment}
            />
          )}
          <ListItem
            title={localize('payment.profile.default')}
            switchItem
            switchValue={isDefault}
            onSwitchValueChange={onChangeDefault}
            disableSwitch={
              selectedPayment &&
              (paymentMethods.length === 1 || selectedPayment?.defaultOne)
            }
          />
          <div className="payment-profile-header">
            {localize('payment.profile.billing.title')}
          </div>
          {billingInfo?.city ? (
            <>
              {!isPrivate && (
                <ListItem
                  title={
                    billingInfo?.companyName ||
                    localize('payment.profile.company.add')
                  }
                  rightIcon="arrowRight"
                  rightIconSize={14}
                  leftItem={
                    <img
                      style={{
                        width: 31,
                        height: 20,
                        marginRight: 35,
                        objectFit: 'contain',
                      }}
                      src="/images/businessPayment.png"
                      alt="payin"
                    />
                  }
                  brandTextColor={!billingInfo?.companyName}
                  onClick={onPressAddBillingAddress}
                />
              )}
              <ListItem
                title={formattedAddress(billingInfo, localize)}
                rightIcon="arrowRight"
                rightIconSize={14}
                leftIcon="home"
                onClick={onPressAddBillingAddress}
              />
            </>
          ) : (
            <ListItem
              title={localize('payment.profile.billing.add')}
              rightIcon="arrowRight"
              rightIconSize={14}
              leftIcon="home"
              brandTextColor
              onClick={onPressAddBillingAddress}
            />
          )}
          <div className="payment-profile-item" onClick={onPressReset}>
            {localize('payment.profile.reset')}
          </div>
          {selectedPayment && (
            <div
              className="payment-profile-item-terminate"
              onClick={() => onPressDeletePayment(selectedPayment.id)}
            >
              {localize('payment.profile.delete')}
            </div>
          )}
        </div>
        {isLocalizeAdded(localize, 'payment.profile.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('payment.profile.faq')}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentProfileView;
