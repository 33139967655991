import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/NetworkStatusTypes';
import { NetworkStatusAction, NetworkStatusState } from '../../../@types';

export default (
  state: NetworkStatusState = initialState,
  action: NetworkStatusAction
): NetworkStatusState => {
  switch (action.type) {
    case TYPES.SET_GET_HISTORY: {
      return {
        ...state,
        getHistory: {
          ...state.getHistory,
          [action.payload.requestHash]: action.payload.lastFetchedAt,
        },
      };
    }

    case TYPES.SET_NETWORK_ACTIVITY: {
      return {
        ...state,
        activities: [...state.activities, action.payload.type],
      };
    }

    case TYPES.SET_NETWORK_SUCCESS: {
      return {
        ...state,
        activities: state.activities.filter(
          (running, ix) =>
            ix !==
            state.activities.findIndex(
              // $FlowFixMe
              (r) => r === action.payload.type
            )
        ),
      };
    }

    case TYPES.SET_NETWORK_ERROR: {
      return {
        ...state,
        activities: state.activities.filter(
          (running, ix) =>
            ix !==
            state.activities.findIndex(
              // $FlowFixMe
              (r) => r === action.payload.type
            )
        ),
        errors: [...state.errors, action.payload],
        errorHistory: [...state.errorHistory, action.payload],
      };
    }

    case TYPES.DISMISS_NETWORK_ERROR: {
      return {
        ...state,
        errors: state.errors.filter(
          // $FlowFixMe
          (e) => e.id !== action.payload.id
        ),
      };
    }

    case TYPES.CLEAR_NETWORK_ERRORS: {
      return { ...state, errors: [] };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
