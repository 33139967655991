import React from 'react';
import {
  LocalizePropType,
  isKYCApp,
  withTextLocalizer,
} from 'getaway-data-layer';
import MdPlaygroundView from './MdPlaygroundView';
import { navigateTo } from '../../../utils';

type Props = {
  localize: LocalizePropType;
};

const MdPlaygroundContainer = ({ localize }: Props) => {
  const onClose = () => {
    navigateTo(history, isKYCApp() ? '/' : '/map');
  };

  return <MdPlaygroundView localize={localize} onClose={onClose} />;
};

export default withTextLocalizer(MdPlaygroundContainer);
