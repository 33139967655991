export const revokeObjectURL = (imageData) => {
  if (Array.isArray(imageData)) {
    imageData.forEach((image) => {
      if (image && image.uri && image.uri.startsWith('blob:')) {
        URL.revokeObjectURL(image.uri);
      }
    });
  } else if (imageData && imageData.uri && imageData.uri.startsWith('blob:')) {
    URL.revokeObjectURL(imageData.uri);
  }
};
