import { DriverBookingData } from '../../Types.api';

export type PreSignupBooking = any;

export type BookingsState = {
  readonly bookings: Array<DriverBookingData>;
  readonly bookingViolations: any;
  readonly preSignupBooking: PreSignupBooking;
};
enum BookingsTypes {
  SET_BOOKINGS = 'userData/bookings/SET_BOOKINGS',
  SET_PRE_SIGNUP_BOOKING = 'userData/bookings/SET_PRE_SIGNUP_BOOKING',
  APPEND_BOOKINGS = 'userData/APPEND_BOOKINGS',
  UPDATE_BOOKING = 'userData/bookings/UPDATE_BOOKING',
  SET_BOOKING_VIOLATIONS = 'userData/bookings/SET_BOOKING_VIOLATIONS',
  CLEAR_BOOKING_VIOLATIONS = 'userData/bookings/CLEAR_BOOKING_VIOLATIONS',
  RESET_STORE = 'RESET_STORE',
}
export default BookingsTypes;
export type SetBookings = {
  type: BookingsTypes.SET_BOOKINGS;
  payload: {
    bookings: Array<DriverBookingData>;
  };
};

export type SetPreSignupBooking = {
  type: BookingsTypes.SET_PRE_SIGNUP_BOOKING;
  payload: {
    booking: PreSignupBooking;
  };
};

export type UpdateBooking = {
  type: BookingsTypes.UPDATE_BOOKING;
  payload: {
    booking: DriverBookingData;
  };
};
export type SetBookingViolations = {
  type: BookingsTypes.SET_BOOKING_VIOLATIONS;
  payload: {
    bookingViolations: Record<string, any>;
  };
};

export type AppendBookings = {
  type: BookingsTypes.APPEND_BOOKINGS;
  payload: {
    bookings: Array<DriverBookingData>;
  };
};

export type ClearBookingViolations = {
  type: BookingsTypes.CLEAR_BOOKING_VIOLATIONS;
};
export type BookingsAction =
  | SetBookings
  | SetPreSignupBooking
  | UpdateBooking
  | SetBookingViolations
  | ClearBookingViolations
  | AppendBookings;
