// @ts-ignore
import base64UTF8 from 'base64-utf8-transfer';

export default (username: string, password: string): string =>
  `Basic ${base64UTF8.encode(`${username}:${password}`)}`;

export const generateNewAuthString = (
  oldAuthResult: string,
  oldUsername: string,
  newUsername: string
): string => {
  const base64Encoded = oldAuthResult?.replace('Basic ', '');
  const decodedString = base64UTF8?.decode(base64Encoded);
  const parts = decodedString?.split(':');
  if (parts.length !== 2 || parts[0] !== oldUsername) {
    throw new Error('Invalid oldAuthResult or oldUsername');
  }
  const extractedPassword = parts[1];

  return `Basic ${base64UTF8.encode(`${newUsername}:${extractedPassword}`)}`;
};
