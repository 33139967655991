import { dataClient } from '../../../clients/dataClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import TYPES from '../../../../@types/redux/store/AreasTypes';
import { CLIENT_TYPE, Dispatch, GetState, SetAreas } from '../../../../@types';
import rwProdAreas from './InitialState.rw.prod';
import ubqProdAreas from './InitialState.ubq.prod';
import rwStagingAreas from './InitialState.rw.staging';
import ubqStagingAreas from './InitialState.ubq.staging';
import { getBrandName } from '../../../../helpers/brandHelpers';
import { extractPois } from '../pois/Actions';

export const setAreas = (areas: Array<any>): SetAreas => ({
  type: TYPES.SET_AREAS,
  payload: {
    areas,
  },
});

export const setInitialAreas =
  () => async (dispatch: Dispatch, getState: GetState) => {
    const { xBrandId, apiBaseURL } = getState().config;
    const isRob = getBrandName(xBrandId) === 'rw';
    const isStaging = apiBaseURL.includes('staging');
    let initialAreas = null;

    if (isStaging) {
      if (isRob) {
        initialAreas = rwStagingAreas;
      } else {
        initialAreas = ubqStagingAreas;
      }
    } else if (isRob) {
      initialAreas = rwProdAreas;
    } else {
      initialAreas = ubqProdAreas;
    }

    dispatch(setAreas(initialAreas));
  };

export const getAreas = () => async (dispatch: Dispatch) => {
  dispatch(setNetworkActivity(CLIENT_TYPE.DATA_CLIENT.GET_AREAS));
  // $FlowFixMe
  const { notModified, data, error } = await dataClient.getAreas();

  if (error) {
    dispatch(setNetworkError(CLIENT_TYPE.DATA_CLIENT.GET_AREAS, error));
  } else {
    if (!!data && !notModified) {
      dispatch(setAreas(data));
      dispatch(extractPois(data));
    }

    dispatch(setNetworkSuccess(CLIENT_TYPE.DATA_CLIENT.GET_AREAS));
  }
};
