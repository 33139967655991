import React from 'react';
import RedirectView from './RedirectView';

type Props = {
  redirectData: any;
};

const RedirectContainer = ({ redirectData }: Props) => {
  const { issuerUrl, paRequest, md, termUrl } = redirectData;

  return (
    <>
      <RedirectView
        issuerUrl={issuerUrl}
        paRequest={paRequest}
        md={md}
        termUrl={termUrl}
      />
    </>
  );
};

export default RedirectContainer;
