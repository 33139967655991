export const GA_TITLES = {
  ERROR: {
    RESET: 'crash-reset',
  },
  AUTH: {
    OPEN_LOGIN: 'open-login', // when screen is opened
    LOGIN: 'login-submit', // when submit button is clicked
    LOGIN_SUBMITTED: 'login-submitted', // when submit action is successful

    OPEN_SIGNUP: 'open-signup',
    SIGN_UP: 'signup-submit',
    SIGN_UP_SUBMITTED: 'signup-submitted',
    SIGN_UP_VIA_COMPANY_CLICKED: 'signup-via-company-clicked',
    SIGN_UP_VIA_TLA_CLICKED: 'signup-via-tla-clicked',
    SIGN_UP_VIA_BND_CLICKED: 'signup-via-bnd-clicked',
    SIGN_UP_VIA_CF_CLICKED: 'signup-via-cityFlitzer-clicked',
    SIGN_UP_AS_CO_USER: 'signup-as-co-user',
    SIGN_UP_AS_CARGO_BIKE: 'signup-as-cargo-bike',
    SIGN_UP_AS_ORG: 'signup-as-org',
    SIGN_UP_AS_PARTNER: 'signup-as-partner',

    FORGOT: 'forget-password-submit',
    FORGOT_SUBMITTED: 'forget-password-submitted',

    LOGOUT: 'logout-submit',
    LOGOUT_SUBMITTED: 'logout-submitted',

    RESET_PASSWORD: 'reset-password',
    RESET_PASSWORD_SUBMITTED: 'reset-password-submitted',
  },
  ON_BOARDING: {
    UPDATE_EMAIL: 'update-email',
    UPDATE_EMAIL_SUBMITTED: 'update-email-submitted',

    VERIFY_EMAIL: 'verify-email',
    VERIFY_EMAIL_SUBMITTED: 'verify-email-submitted',

    ADD_PHONE: 'add-phone',
    PHONE_SUBMITTED: 'phone-submitted',

    ADD_PHONE_CODE: 'add-phone-code',
    PHONE_CODE_SUBMITTED: 'phone-code-submitted',

    TARIFF_SUBMITTED: 'tariff-submitted',
    UPSELLING_SUBMITTED: 'upselling-submitted',

    EXTERNAL_BROWSER_VIA_BUTTON: 'external-browser-via-button',

    ADD_ADDRESS: 'add-address',
    ADDRESS_SUBMITTED: 'address-submitted',

    ADD_CC: 'add-CC',
    SELECT_CC: 'select-payment-CC',
    CC_SUBMITTED: 'CC-submitted',
    VALIDATE_CC: 'validate-CC',
    VALIDATE_CC_SUBMITTED: 'validate-CC-submitted',

    ADD_SEPA: 'add-sepa',
    SELECT_SEPA: 'select-payment-sepa',
    SEPA_SUBMITTED: 'sepa-submitted',

    ADD_PAYIN: 'add-payin',
    SELECT_PAYIN: 'select-payment-payin',
    PAYIN_SUBMITTED: 'payin-submitted',

    ADD_PAYPAL: 'add-paypal',
    SELECT_PAYPAL: 'select-payment-paypal',
    PAYPAL_SUBMITTED: 'paypal-submitted',

    ADD_DL: 'start-adding-DL',
    DL_SUBMITTED: 'DL-submitted',

    ADD_ID: 'start-adding-ID',
    ID_SUBMITTED: 'ID-submitted',

    SUBMIT_SCHUFA: 'schufa-submit',
    SCHUFA_SUBMITTED: 'schufa-check-submitted',
  },
  RENTAL: {
    RESERVE: 'reserve',
    RESERVE_DISABLED: 'reserve-disabled',
    FIND_NEAREST: 'find-nearest',
    CANCEL: 'cancel-rental',
    UNLOCK: 'unlock-rental',
    UNLOCK_SUCCESS: 'unlock-rental-success',
    UNLOCK_FAIL: 'unlock-rental-failure',
    SUBMIT_CAR_CHECK: 'submit-car-check',
    SUBMIT_DAMAGE: 'submit-damage',
    OPEN_HANDOVER: 'rental-handover',
    FINISH: 'finish-rental',
    FINISH_BEFORE_DRIVING: 'finish-rental-before-driving',
  },
  DASHBOARD: {
    PROFILE: 'profile-opened',
  },
  BOOKING: {
    RESERVE: 'booking-reserve',
    CONFIRM: 'booking-confirm',
    CONSENT: 'booking-consent',
    SET_FILTER: 'set-filter-time',
  },
  CIRCLES: {
    JOIN: 'circle-join',
  },
};

export const reportGAEvent = (eventName: string) => {
  // @ts-ignore
  window?.dataLayer?.push({
    event: 'gtm.click',
    'gtm.elementId': eventName,
  });
};
