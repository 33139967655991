export type DeeplinkState = {
  deeplinkUrl: string;
};
enum DeeplinkTypes {
  SET_DEEPLINK_URL = 'ui/deeplink/SET_DEEPLINK_URL',
  RESET_STORE = 'RESET_STORE',
}
export default DeeplinkTypes;
export type SetDeeplinkUrl = {
  type: DeeplinkTypes.SET_DEEPLINK_URL;
  payload: {
    deeplinkUrl: string;
  };
};
export type DeeplinkAction = SetDeeplinkUrl;
