import React from 'react';
import Box from '@mui/material/Box';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormikValues } from 'formik';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../utils';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  showPassword: boolean;
  onClose: () => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ResetPasswordView = ({
  localize,
  formik,
  showPassword,
  onClose,
  handleClickShowPassword,
  handleMouseDownPassword,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('reset.password.title')}
        description=""
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body">
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="password"
            className="form-input"
            type={showPassword ? 'text' : 'password'}
            label={localize('reset.password.placeholder')}
            helperText={
              (formik.touched.password && formik.errors.password) || ''
            }
            error={formik.touched.password && Boolean(formik.errors.password)}
            autoComplete="current-password"
            variant="standard"
            {...formik.getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <BrandButton
            id={GA_TITLES.AUTH.RESET_PASSWORD}
            title={localize('reset.password.button.title')}
            formik={formik}
          />
        </Box>
      </div>
    </div>
  );
};

export default ResetPasswordView;
