import React from 'react';
import Divider from '@mui/material/Divider';
import './BrowseDamagesView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import { getBrandImagePath, LocalizePropType } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  damages: Array<any>;
  newDamages: Array<string>;
  enableAddDamage: boolean;
  onAddDamage: () => void;
  onClose: () => void;
};

const BrowseDamagesView = ({
  localize,
  damages,
  newDamages,
  enableAddDamage,
  onAddDamage,
  onClose,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('damages.title')}
        description=""
        showHelp
        onClose={onClose}
      />
      <div className="screen-body">
        {damages.length > 0 &&
          damages.map((damage, index) => (
            <div key={damage.id}>
              <div className="browse-damages-damage-container">
                <img
                  className="browse-damages-damage-image"
                  src={damage.images[0]}
                  alt="damage"
                />
                <div>
                  <div className="browse-damages-damage-title">
                    {`#${damages.length - index}. ${damage.title}`}
                    <span className="browse-damages-damage-new">
                      {newDamages.includes(damage.id)
                        ? ` (${localize('damage.new')})`
                        : ''}
                    </span>
                  </div>
                  <div className="browse-damages-damage-description">
                    {damage.description}
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}
        {damages.length === 0 && (
          <p className="browse-damages-empty-txt">
            {localize('damages.noRecord.text')}
          </p>
        )}
        {enableAddDamage && (
          <div onClick={onAddDamage}>
            <img
              alt="addDamage"
              src={getBrandImagePath('addDamage')}
              style={{
                position: 'absolute',
                right: '16px',
                bottom: '16px',
                width: '54px',
                height: '54px',
                borderRadius: 50,
                boxShadow: '0 0 8px rgb(0 0 0 / 40%)',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BrowseDamagesView;
