import React, { useState } from 'react';
import './VerifyPhoneView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import { isDashboardScreen } from '../../../../utils';
import { TextField } from '@mui/material';
import ListItem from '../../../components/ListItem/ListItem';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  phoneNumber: string;
  onClose: () => void;
  onSubmit: (code: string) => void;
  onResendPressed: () => void;
  onPressSkip: () => void;
  skipCountdown: number;
  resendCountdown: number;
  onPressPhone: () => void;
};

const VerifyPhoneView = ({
  localize,
  phoneNumber,
  onClose,
  onSubmit,
  onResendPressed,
  onPressSkip,
  skipCountdown,
  resendCountdown,
  onPressPhone,
}: Props) => {
  const [code, setCode] = useState('');
  const activateSkip = skipCountdown <= 0;
  const activateResend = resendCountdown <= 0;

  const processInput = (e: any) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    if (num?.length === 4) {
      e.clipboardData = {
        getData: () => num.trim(),
      };
      onPaste(e);
    } else {
      if (num?.length <= 4) {
        setCode(num);
      }
    }
  };

  const onPaste = (e: any) => {
    const num = e?.clipboardData?.getData('text');
    if (/^\d+$/.test(num) && num?.length === 4) {
      setCode(num);
      onSubmit(num);
    }
  };

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('wj.phone.verify.code.title')}
        description={localize('wj.phone.verify.code.desc', phoneNumber)}
        showHelp
        showArrowBack
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'phone.verify.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'phone.verify.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <ListItem
            title={phoneNumber || ''}
            leftIcon="phoneSMS"
            rightIcon="edit"
            rightIconSize={17}
            onClick={onPressPhone}
          />
          <div className="code-input">
            <TextField
              id="otp"
              sx={{ width: 1 }}
              label={localize('wj.phone.code.placeholder')}
              variant="standard"
              size="small"
              type="number"
              value={code}
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                maxLength: 4,
              }}
              name="one-time-code"
              autoComplete="one-time-code"
              onChange={(e) => processInput(e)}
              onPaste={(e) => onPaste(e)}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isDashboardScreen() && (
              <div
                className="verify-email-skip-container"
                onClick={activateSkip ? onPressSkip : () => {}}
              >
                <div
                  className={
                    activateSkip
                      ? 'verify-phone-skip-active'
                      : 'verify-phone-skip'
                  }
                >
                  {activateSkip
                    ? localize('email.verify.skip')
                    : `${localize('email.verify.skip')} (${skipCountdown}s)`}
                </div>
              </div>
            )}
            <div className="verify-phone-btn-container">
              <div
                className={
                  activateResend
                    ? 'verify-phone-btn-txt-active'
                    : 'verify-phone-btn-txt'
                }
                onClick={activateResend ? onResendPressed : () => {}}
              >
                {activateResend
                  ? localize('phone.resend.code')
                  : `${localize('phone.resend.code')} (${resendCountdown}s)`}
              </div>
            </div>
          </div>
        </div>
        {isLocalizeAdded(localize, 'phone.verify.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('phone.verify.faq')}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyPhoneView;
