import { AddressData, ConnectCarData, OutputCarData } from '../@types';

export const titleForDriverCar = (
  car: OutputCarData | ConnectCarData
): string => `${car?.brand || ''} ${car?.model || ''}`;

export const parseAddress = (address: AddressData) => {
  return address
    ? `${address?.streetName || ''} ${address?.streetNumber || ''}`
    : 'Abholzone anzeigen';
};

export const getPriceMarkdown = (key, localize) =>
  !!localize(key) && localize(key) !== key ? localize(key) : null;
