import React, { useMemo, useEffect } from 'react';
import {
  withTextLocalizer,
  distanceToNearestGasStation,
  selectRentalData,
  selectFuelInfo,
  gasStations,
  getFuelInfo,
  locationFromString,
  LocalizePropType,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FuelSettingsView from './FuelSettingsView';
import { navigateTo } from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const FuelSettingsContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentalData = useSelector(selectRentalData) as any;
  const fuelInfo = useSelector(selectFuelInfo) as any;

  const onClose = () => {
    history.goBack();
  };

  const onClickFuelCard = () => {
    navigateTo(history, '/rental/cardVideo');
  };

  useEffect(() => {
    if (getFuelInfo) {
      dispatch(getFuelInfo(rentalData?.id));
    }
  }, []);

  const distanceToClosetGasStation = useMemo(
    () =>
      gasStations.length > 0 && rentalData?.carData?.position
        ? distanceToNearestGasStation(
            locationFromString(rentalData?.carData?.position),
            gasStations
          ).toFixed(2)
        : '',
    [rentalData?.carData?.position]
  );

  return (
    <FuelSettingsView
      localize={localize}
      fuelInfo={fuelInfo}
      distance={distanceToClosetGasStation}
      onClickFuelCard={onClickFuelCard}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(FuelSettingsContainer);
