import React, { useMemo, useEffect, useState, useRef } from 'react';
import './NavigationBar.less';
import BrandIcon from '../BrandIcon/BrandIcon';
import HelpAction from '../HelpAction/HelpAction';
import Stepper from '../Stepper/Stepper';
import { isKYCApp, isMVV, selectUser } from 'getaway-data-layer';
import { useSelector } from 'react-redux';
import {
  getNumberOfChallenges,
  getNumberOfCompletedChallenges,
  isFirstStepsScreen,
} from '../../../utils';
import { navLogo } from '../../../assets/brandImages';

type Props = {
  title: string;
  description: string;
  showHelp?: boolean;
  showArrowBack?: boolean;
  onClose?: () => void;
  containerStyle?: any;
  customActiveStep?: number;
  showLogo?: boolean;
  showBack?: boolean;
  titleStyles?: any;
};

const NavigationBar = ({
  title,
  description = '',
  showHelp,
  showArrowBack,
  onClose,
  containerStyle,
  customActiveStep,
  showLogo,
  showBack,
  titleStyles,
}: Props) => {
  const user = useSelector(selectUser) as any;
  const headerRef = useRef(null);
  // OptimizedKYC for KYC apps takes out address step
  // Deducting one which represents the signup challenge
  const numberOfChallenges = useMemo(() => {
    return isKYCApp() && !isMVV()
      ? getNumberOfChallenges(user) - 2 // deducting signup and address challenges for KYC
      : getNumberOfChallenges(user) - 1; // deducting signup challenge for Rental apps
  }, [user]);

  const activeStep = useMemo(() => {
    return customActiveStep || getNumberOfCompletedChallenges(user) - 1;
  }, [user, customActiveStep]);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    const screenBody =
      document?.querySelector('.screen-body') ||
      document?.querySelector('.screen-body-faq');
    const headerHeight = 60;

    const handleScroll = () => {
      const scrollPosition = screenBody?.scrollTop || 0;
      const totalScrollHeight = screenBody?.scrollHeight || 0;
      const viewableAreaHeight = screenBody?.clientHeight || 0;
      const extraScrollableSpace = totalScrollHeight - viewableAreaHeight;
      if (scrollPosition === 0) {
        setIsHeaderVisible(true);
      } else if (extraScrollableSpace > 2 * headerHeight) {
        if (scrollPosition > headerHeight) {
          setIsHeaderVisible(false);
        } else {
          setIsHeaderVisible(true);
        }
      }
    };

    if (screenBody && title)
      screenBody.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      if (screenBody) screenBody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        key="navigation-bar"
        className="nav-bar-wrapper"
        style={containerStyle}
      >
        <>
          {showBack && onClose && (
            <div className="nav-bar-close" onClick={onClose}>
              <BrandIcon
                name={showArrowBack ? 'arrowLeft' : 'closeIcon'}
                style={{ width: 20, height: 20 }}
              />
            </div>
          )}
          {activeStep !== null && isFirstStepsScreen() && (
            <Stepper
              key="stepper"
              activeStep={activeStep}
              numberOfSteps={numberOfChallenges}
            />
          )}
          {showLogo && !isFirstStepsScreen() && (
            <img src={navLogo} className="nav-logo" />
          )}
          {showHelp && <HelpAction />}
        </>
      </div>
      <div
        ref={headerRef}
        className="nav-bar-header"
        style={{ display: isHeaderVisible ? 'block' : 'none' }}
      >
        {title && (
          <p className="nav-bar-title noselect" style={titleStyles}>
            {title}
          </p>
        )}
        {description && (
          <p className="nav-bar-description noselect">{description}</p>
        )}
      </div>
    </div>
  );
};

NavigationBar.defaultProps = {
  showHelp: false,
  showArrowBack: false,
  onClose: undefined,
  containerStyle: {},
  customActiveStep: null,
  showLogo: true,
  showBack: true,
  titleStyles: {},
};

export default React.memo(NavigationBar);
