import * as yup from 'yup';

export const IDModal = {
  documentType: 'ID_CARD',
};

export const IDSchema = (localize: any): any =>
  yup.object({
    documentType: yup.string().required(localize('validation.required')),
  });
