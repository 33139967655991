import React from 'react';
import {
  withTextLocalizer,
  LocalizePropType,
  DriverCarData,
} from 'getaway-data-layer';
import './SideCarouselItem.less';
import Plate from '../Plate/Plate';
import BrandIcon from '../BrandIcon/BrandIcon';
import LowFuelCallout from '../Carousel/LowFuelCallout/LowFuelCallout';

type Props = {
  localize: LocalizePropType;
  isSelected: boolean;
  car: DriverCarData;
  onClickItem: (car: DriverCarData) => void;
  onClickArrow: () => void;
};

export const getCarouselLightBrandColor = (): string => {
  switch (process.env.REACT_APP_X_BRAND_ID) {
    case '000000000000000000000002':
      return 'rgba(0, 196, 209, 0.6)';
    case '000000000000000000000003':
      return 'rgba(0, 119, 190, 0.13)';
    case '5ef1f2e4c8f48f0f7912d487':
      return 'rgba(250,197,0,0.4)';
    default:
      return 'rgba(0, 119, 190, 0.13)';
  }
};

const CarouselItem = ({
  localize,
  isSelected,
  car,
  car: {
    gear,
    fuelLevel,
    seats,
    plate,
    driverPrice,
    images,
    refuelBonusApplies,
  },
  onClickItem,
  onClickArrow,
}: Props) => {
  const carCarosuelPrice = driverPrice.description.split('+');
  return (
    <div
      className="side-carousel-item-container"
      onClick={() => onClickItem(car)}
      style={
        isSelected
          ? {
              backgroundColor: getCarouselLightBrandColor(),
              borderWidth: 2,
              borderStyle: 'solid',
              paddingRight: 2,
            }
          : {}
      }
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div className="side-carousel-img-wrapper">
            <img
              style={{
                borderRadius: '8px',
                objectFit: 'cover',
              }}
              className="side-carousel-img"
              src={images![0]}
              alt="carImage"
            />
          </div>
          <div className="side-carousel-item-price-wrapper">
            <div>
              <div className="side-carousel-item-price-pre">
                {localize('from')}
              </div>
              <div className="side-carousel-item-price">
                {carCarosuelPrice[0]}
              </div>
              {carCarosuelPrice[1] && (
                <div className="side-carousel-item-price-post">
                  {`+${carCarosuelPrice[1]}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="side-carousel-item-bottom-wrapper">
          <Plate carPlate={plate!} />
          {gear && (
            <div className="side-carousel-item-row">
              <BrandIcon name="gearType" style={{ width: 14, height: 14 }} />
              <div className="side-carousel-item-detail">
                {localize(`car.details.gear.${gear}`)}
              </div>
            </div>
          )}
          {/* @ts-ignore */}
          {fuelLevel !== '' && (
            <div style={{ position: 'relative' }}>
              {refuelBonusApplies && (
                <LowFuelCallout text={localize('map.card.refuel')} />
              )}
              <div className="side-carousel-item-row">
                <BrandIcon
                  name={fuelLevel! < 25 ? 'redCarFuel' : 'carFuel'}
                  style={{ width: 14, height: 14 }}
                />
                <div
                  className="side-carousel-item-detail"
                  style={fuelLevel! < 25 ? { color: 'red' } : {}}
                >
                  {`${fuelLevel}%`}
                </div>
              </div>
            </div>
          )}
          {seats && (
            <div className="side-carousel-item-row">
              <BrandIcon name="carSeat" style={{ width: 14, height: 14 }} />
              <div className="side-carousel-item-detail">{seats}</div>
            </div>
          )}
        </div>
      </div>
      <div
        className="side-carousel-arrow-container"
        onClick={onClickArrow}
        style={
          isSelected ? { visibility: 'visible' } : { visibility: 'hidden' }
        }
      >
        <BrandIcon
          name="arrowRightWhite"
          style={{
            width: 18,
            height: 18,
          }}
        />
      </div>
    </div>
  );
};

export default withTextLocalizer(CarouselItem);
