// @flow
import { useState, useEffect } from 'react';
import {
  differenceInMinutes,
  differenceInSeconds,
  format,
  setMinutes,
  setSeconds,
} from 'date-fns';

export default (timeToExpire: any, onExpire: any) => {
  const [expirationTime, setExpirationTime] = useState('00:00');

  useEffect(() => {
    const updateRentalExpirationTime = (time: any) => {
      const currentTime = new Date();
      let expirationDifferenceInMinutes = differenceInMinutes(
        time,
        currentTime
      );
      expirationDifferenceInMinutes =
        expirationDifferenceInMinutes < 0 ? 0 : expirationDifferenceInMinutes;
      let expirationDifferenceInSeconds = differenceInSeconds(
        time,
        currentTime
      );
      expirationDifferenceInSeconds =
        expirationDifferenceInSeconds < 0 ? 0 : expirationDifferenceInSeconds;
      const expirationDate = setSeconds(
        setMinutes(new Date(), expirationDifferenceInMinutes),
        expirationDifferenceInSeconds % 60
      );
      setExpirationTime(format(expirationDate, 'mm:ss'));
    };

    updateRentalExpirationTime(new Date(timeToExpire));
    const timer = setInterval(() => {
      if (expirationTime === '00:00') {
        onExpire();
        clearInterval(timer);
      }
      updateRentalExpirationTime(new Date(timeToExpire));
    }, 500);
    return () => {
      if (timer) clearInterval(timer);
    };
  });

  return expirationTime;
};
