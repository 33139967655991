import React from 'react';
import { withTextLocalizer } from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import CardVideoView from './CardVideoView';

const CardVideoContainer = () => {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
  };

  return <CardVideoView onClose={onClose} />;
};

export default withTextLocalizer(CardVideoContainer);
