export type DimensionsState = {
  readonly headerHeight: number;
};
enum DimensionsTypes {
  PUBLISH_HEADER_HEIGHT = 'ui/dimensions/PUBLISH_HEADER_HEIGHT',
  RESET_STORE = 'RESET_STORE',
}
export default DimensionsTypes;
export type PublishHeaderHeight = {
  type: DimensionsTypes.PUBLISH_HEADER_HEIGHT;
  payload: {
    headerHeight: number;
  };
};
export type DimensionsAction = PublishHeaderHeight;
