import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setCircleCode, clearCircleCode } from '../redux/store/ui/firstSteps';
import { CircleData, State } from '../@types';
import { switchAccessToCircle } from '../redux/store/carsSearch';
import { setSignUpCircle } from '../redux/store/userData/user';

export type Props = {
  circleCode: string;
  circles: Array<any>;
  isOBI: boolean;
  isExpert: boolean;
  isSPP: boolean;
  isServiceHero: boolean;
  isServiceCircle: boolean;
  setCircleCode: typeof setCircleCode;
  clearCircleCode: typeof clearCircleCode;
  selectedCircle: CircleData;
  switchAccessToCircle: typeof switchAccessToCircle;
  circleId: string;
  setSignUpCircle: typeof setSignUpCircle;
};

const withCircle: any = compose(
  connect(
    ({
      ui: {
        firstSteps: { circleCode },
        map: { selectedCar },
      },
      userData: {
        user: { user },
        rental,
      },
      carsSearch: { circleId },
      appData: { areas },
    }: State) => ({
      circleId,
      circleCode,
      circles: user?.circles,
      selectedCircle: user?.circles?.find((circle) => circle?.id === circleId),
      isServiceHero: user?.serviceHero,
      isServiceCircle: !!user?.circles?.find(
        (circle) => circle.id === circleId && circle.circleType === 'service'
      ),
      isOBI:
        // @ts-ignore
        selectedCar?.fleetName.includes('OBI') ||
        areas?.find((area) =>
          [selectedCar?.areaId, rental?.rentalData?.carData?.areaId].includes(
            area?.id
          )
        )?.fleetStyle === 'obi',
      isExpert:
        // @ts-ignore
        selectedCar?.fleetName.includes('EXPERT') ||
        areas?.find((area) =>
          [selectedCar?.areaId, rental?.rentalData?.carData?.areaId].includes(
            area?.id
          )
        )?.fleetStyle === 'expert',
      isSPP:
        // @ts-ignore
        selectedCar?.fleetName.includes('SPP') ||
        areas?.find((area) =>
          [selectedCar?.areaId, rental?.rentalData?.carData?.areaId].includes(
            area?.id
          )
        )?.fleetStyle === 'spp',
    }),
    {
      setCircleCode,
      clearCircleCode,
      switchAccessToCircle,
      setSignUpCircle,
    }
  )
);
export default withCircle;
