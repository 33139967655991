export const getPhoneCodeByCountry = (countryCode?: string): string => {
  switch (countryCode) {
    case 'DE':
      return '+49';
    case 'NL':
      return '+31';
    case 'BE':
      return '+32';
    case 'FR':
      return '+33';
    case 'LU':
      return '+352';
    case 'CH':
      return '+41';
    case 'AT':
      return '+43';
    case 'ES':
      return '+34';
    case 'CZ':
      return '+420';
    case 'IT':
      return '+39';
    case 'DK':
      return '+45';
    case 'PL':
      return '+48';
    case 'LI':
      return '+423';
    case 'CY':
      return '+357';
    case 'SK':
      return '+421';
    case 'BG':
      return '+359';
    case 'IE':
      return '+353';
    case 'LV':
      return '+371';
    case 'MT':
      return '+356';
    case 'NO':
      return '+47';
    case 'PT':
      return '+351';
    case 'FI':
      return '+358';
    case 'HU':
      return '+36';
    case 'SI':
      return '+386';
    case 'RO':
      return '+40';
    case 'SE':
      return '+46';
    case 'HR':
      return '+385';
    case 'EE':
      return '+372';
    case 'GR':
      return '+30';
    case 'IS':
      return '+354';
    case 'LT':
      return '+370';
    case 'UK':
    case 'GB':
      return '+44';
    default:
      return '';
  }
};
