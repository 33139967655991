import {
  selectAuth,
  selectBookings,
  selectCommon,
  selectRental,
  selectUser,
} from 'getaway-data-layer';
import { useSelector } from 'react-redux';
import {
  getEmailComposerLink,
  getPhoneCallLink,
  openIntercom,
  getIsRentingUser,
  showAlertWithOptions,
} from '../utils';

// Customer support channels are:
// 1- Text support channels: whats app, intercom, email
// 2- Phone support
//
// Renting User = User has on going rental or Has an active booking or Its been 15min since last rental
//
// Business logic:
// If phoneSupportType == always
//   - open dialog with first option phone and second Text always
// If phoneSupportType == duringTrip
//   - If renting user, open dialog with first option phone and second Text
//   - If not renting user, open text support / no dialog
//
// Intercom handling modes:
//   - always: intercom always accessible, user always registered on new sessions
//   - onContactSupport:  intercom always accessible, register user to intercom on new sessions if he contacted support before
//   - onLogin: show/enable intercom when user has logged in only
//   - never: ...

export const useCustomerSupport = (localize: any) => {
  const { firstSteps, brandSettings } = useSelector(selectCommon);
  const user = useSelector(selectUser);
  const basicAuth = useSelector(selectAuth);
  const { rentalData, lastFinishedRentalDate } = useSelector(selectRental);
  const bookings = useSelector(selectBookings);
  const phoneSupportType = brandSettings?.phoneSupportType;
  const isIntercom = brandSettings?.textSupportType === 'intercom';
  const isWhatsaap = brandSettings?.textSupportType === 'whatsapp';
  const isAuthorizedUser = (user && !!basicAuth) as boolean;
  const formattedServiceWhatsapp = brandSettings?.serviceWhatsapp?.includes('+')
    ? brandSettings?.serviceWhatsapp?.replace(/\s/g, '').split('+')[1]
    : brandSettings?.serviceWhatsapp?.replace(/\s/g, '');
  const isRentingUser = getIsRentingUser(
    firstSteps,
    rentalData!,
    bookings,
    lastFinishedRentalDate!
  );

  const textModeAction = () => {
    if (isIntercom) {
      openIntercom(
        localize,
        brandSettings?.intercomHandlingMode,
        isAuthorizedUser,
        brandSettings?.servicePhone
      );
    } else if (isWhatsaap) {
      window.open(`https://wa.me/${formattedServiceWhatsapp}`, '_blank');
    } else {
      // @ts-ignore
      window.location = `mailto:${brandSettings?.serviceEmail}`;
    }
  };

  return {
    hideCS:
      phoneSupportType === 'duringTrip' &&
      !isAuthorizedUser &&
      isIntercom &&
      brandSettings?.intercomHandlingMode === 'onLogin',
    helpAction: (onlyTextMode: boolean = false) => {
      if (
        onlyTextMode ||
        (phoneSupportType === 'duringTrip' && !isRentingUser)
      ) {
        textModeAction();
      } else if (
        phoneSupportType === 'always' ||
        (phoneSupportType === 'duringTrip' && isRentingUser)
      ) {
        showAlertWithOptions(localize('help.dialog.title'), '', [
          {
            title: getPhoneCallLink(
              brandSettings?.servicePhone || localize('customerSupport.number'),
              brandSettings?.servicePhone || localize('customerSupport.call')
            ),
            action: () => {},
          },
          {
            title: isIntercom
              ? localize('user.action.support.text')
              : isWhatsaap
              ? localize('support.whatsapp')
              : getEmailComposerLink(
                  brandSettings?.serviceEmail,
                  brandSettings?.serviceEmail
                ),
            action: isIntercom
              ? () =>
                  openIntercom(
                    localize,
                    brandSettings?.intercomHandlingMode,
                    isAuthorizedUser,
                    brandSettings?.servicePhone
                  )
              : isWhatsaap
              ? () =>
                  window.open(
                    `https://wa.me/${formattedServiceWhatsapp}`,
                    '_blank'
                  )
              : () => {},
          },
          { title: localize('cancel') },
        ]);
      }
    },
  };
};
