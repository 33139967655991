import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// @ts-ignore
import { Carousel } from 'react-responsive-carousel';
import './PhotoAlbum.less';

type Props = {
  images: any;
  showArrows?: boolean;
};

const PhotoAlbum = ({ images, showArrows }: Props) => {
  return (
    <Carousel
      showArrows={showArrows}
      showStatus={false}
      showThumbs={false}
      infiniteLoop={false}
      interval={999999999}
    >
      {images.map((image: any) => (
        <img
          style={{ height: window.innerHeight * 0.28 }}
          className="PhotoAlbum-image"
          key={image}
          src={image}
          alt={image}
        />
      ))}
    </Carousel>
  );
};

PhotoAlbum.defaultProps = {
  showArrows: false,
};

export default PhotoAlbum;
