import url from 'url';
import queryString from 'query-string';
import { FuelStationData, MapCars } from '../../../../@types';

export const selectedTypeFor = (type: any): any => {
  switch (type) {
    case 'availableCar':
      return 'selectedAvailableCar';

    case 'gasStation':
      return 'selectedGasStation';

    case 'unavailableCar':
      return 'selectedUnavailableCar';

    case 'offlineService':
      return 'selectedOfflineService';

    default:
      return type;
  }
};
export const contentIdFromDeeplink = (applink: string): any => {
  const URL = url.parse(applink);

  if (URL && URL.search) {
    const URLParams = queryString.parse(URL.search);

    if (URLParams) {
      if (URL.pathname === '/map' || (URL.href && URL.href.includes('/map?'))) {
        const { section } = URLParams;

        if (section === 'my-cars') {
          return null;
        }

        return URLParams.id;
      }
    }
  }

  return null;
};
export const sectionFromDeeplink = (applink: string) => {
  const URL = url.parse(applink);

  if (URL && URL.search) {
    const URLParams = queryString.parse(URL.search);

    if (URLParams) {
      if (URL.pathname === '/map') {
        const { section } = URLParams;

        if (section === 'my-cars') {
          return null;
        }

        return section;
      }
    }
  }

  return null;
};
export const isFilterDeeplink = (applink: string): boolean => {
  if (applink) {
    const URL = url.parse(applink);
    return URL && URL.pathname === '/map/filter';
  }

  return false;
};
export const deeplinkDataAvailable = (
  applink: string,
  availableCars: MapCars,
  unavailableCars: MapCars,
  gasStations: Array<FuelStationData & { id: string }>
): boolean => {
  const id = contentIdFromDeeplink(applink);

  if (id) {
    const availableCar = availableCars[id];
    const unavailableCar = unavailableCars[id];
    const gasStation = gasStations.find((station) => station.id === id);
    return !!availableCar || !!unavailableCar || !!gasStation;
  }

  return false;
};
export const mapContentFromDeeplink = (
  applink: string,
  availableCars: MapCars,
  unavailableCars: MapCars,
  gasStations: Array<FuelStationData & { id: string }>
): any => {
  const URL = url.parse(applink);

  if (URL && URL.search) {
    const URLParams = queryString.parse(URL.search);

    if (URLParams) {
      if (URL.pathname === '/map' || (URL.href && URL.href.includes('/map?'))) {
        const { id } = URLParams;

        if (id) {
          // @ts-ignore
          const availableCar = availableCars[id];
          // @ts-ignore
          const unavailableCar = unavailableCars[id];
          const gasStation = gasStations.find((station) => station.id === id);

          if (availableCar) {
            return {
              mapType: selectedTypeFor('availableCar'),
              cardType: 'none',
              id,
            };
          }

          if (unavailableCar) {
            return {
              mapType: selectedTypeFor('unavailableCar'),
              cardType: 'none',
              id,
            };
          }

          if (gasStation) {
            return {
              mapType: 'gasStation',
              cardType: 'none',
              id,
            };
          }
        }
      }
    }
  }

  return null;
};
