import React from 'react';
import { useSelector } from 'react-redux';
import { selectCardState, selectMapContent } from 'getaway-data-layer';
import './DraggableCard.less';

type Props = {
  children: React.ReactElement | null;
};

export const getCardHeaderHeight = (cardType: any) => {
  switch (cardType) {
    case 'reservedCar':
      return 120;
    case 'openedCar':
      return 130;
    case 'drivingCar':
    case 'parkingCar':
      return 100;
    // car check card
    default:
      return 140;
  }
};

const DraggableCard = ({ children }: Props) => {
  const mapContent = useSelector(selectMapContent) as any;
  const cardState = useSelector(selectCardState);

  return (
    <div
      style={
        cardState === 'Hidden'
          ? {
              top:
                window.innerHeight - getCardHeaderHeight(mapContent?.cardType),
            }
          : {}
      }
      className="card-root"
    >
      <div>{children}</div>
    </div>
  );
};

export default DraggableCard;
