import { AreaData, StationData } from '../../../../@types';
import TYPES from '../../../../@types/redux/store/PoisTypes';
import { SetPois, Dispatch } from '../../../../@types';

export const setPois = (pois: Array<StationData>): SetPois => ({
  type: TYPES.SET_POIS,
  payload: {
    pois,
  },
});

export const extractPois =
  (areas: Array<AreaData>) => async (dispatch: Dispatch) => {
    const pois = areas
      .filter((area) => area.poi)
      .map((area) => ({
        ...area.poi,
        areaId: area.id,
        availableVehicleCategoriesIds: area?.availableVehicleCategoriesIds,
        id: `${area?.name}-${area?.poi?.location}`,
      }));
    dispatch(setPois(pois));
  };
