import {
  CarStatusData,
  DriverBookingData,
  RentalData,
  UserDataOutput,
} from '../../../@types';
import { injectedStore } from '../../ConfigureStore';
import { setUser } from '../../store/userData/user/actions';
import {
  setDriverCarStatus,
  setRentalData,
} from '../../store/userData/rental/actions';
import { updateBookingRT } from '../../store/userData/bookings/actions';

const getSetter = {
  setUser(user: UserDataOutput) {
    injectedStore.dispatch(setUser(user));
  },

  setRentalData(rentalData: RentalData) {
    injectedStore.dispatch(setRentalData(rentalData));
  },

  updateBooking(booking: DriverBookingData) {
    injectedStore.dispatch(updateBookingRT(booking));
  },

  setDriverCarStatus(driverCarStatus: CarStatusData) {
    injectedStore.dispatch(setDriverCarStatus(driverCarStatus));
  },
};
export default getSetter;
