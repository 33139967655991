import React, { useEffect, useState } from 'react';
import { LocalizePropType, uploadDocument } from 'getaway-data-layer';
import { useFilePicker } from 'use-file-picker';
import { useDispatch } from 'react-redux';
import ListItem from '../ListItem/ListItem';
import * as Sentry from '@sentry/react';

type Props = {
  localize: LocalizePropType;
};

const FileUploadItem = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [openFileSelector, { plainFiles, loading }] = useFilePicker({
    accept: 'image/*',
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  useEffect(() => {
    const handleUpload = async () => {
      if (plainFiles?.[0]) {
        try {
          setUploading(true);
          dispatch(
            uploadDocument(plainFiles?.[0], 'OTHER', () => setUploading(false))
          );
        } catch (error) {
          setUploading(false);
          Sentry.captureException(error);
        }
      }
    };

    handleUpload();
  }, [plainFiles]);

  return (
    <ListItem
      title={localize('settings.uploadDoc')}
      leftItem={
        <img
          style={{
            width: 20,
            height: 20,
            marginRight: 35,
            objectFit: 'contain',
          }}
          src="/images/upload.png"
          alt="user"
        />
      }
      rightIcon="arrowRight"
      rightIconSize={14}
      onClick={() => {
        openFileSelector();
      }}
      isLoading={loading || uploading}
    />
  );
};

FileUploadItem.defaultProps = {};

export default FileUploadItem;
