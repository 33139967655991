import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import { getAreas } from '../redux/store/appData/areas';
export type Props = {
  areas: any;
  getAreas: typeof getAreas;
};
const withAreas: any = compose(
  connect(
    ({ appData: { areas } }: State) => ({
      areas,
    }),
    {
      getAreas,
    }
  )
);
export default withAreas;
