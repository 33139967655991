import React from 'react';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import {
  isLocalizeAdded,
  LocalizePropType,
  UpsellingData,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../../utils';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './UpsellingsView.less';
import TariffItem from '../../../components/TariffItem/TariffItem';

type props = {
  localize: LocalizePropType;
  upsellings: UpsellingData[];
  onClose: () => void;
  onSubmit: () => void;
  upsellingSelected: string;
  setUpsellingSelected: (code: string) => void;
};

const UpsellingsView = ({
  localize,
  upsellings,
  onClose,
  onSubmit,
  upsellingSelected,
  setUpsellingSelected,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('upsellings.title')}
        description={localize('upsellings.header.desc')}
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div
        className={
          isLocalizeAdded(localize, 'upsellings.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'upsellings.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          {upsellings?.length > 0 &&
            upsellings?.map((upselling, index) => (
              <TariffItem
                key={upselling.code}
                firstItem={index === 0}
                lastItem={index === upsellings?.length - 1}
                localize={localize}
                title={localize(`tariff.upselling.${upselling.code}.header`)}
                subTitle={localize(`tariff.upselling.${upselling.code}.title`)}
                price={localize(`tariff.upselling.${upselling.code}.price`)}
                description={
                  upselling?.code === 'free'
                    ? null
                    : isLocalizeAdded(
                        localize,
                        `tariff.upselling.${upselling.code}.desc`
                      )
                    ? localize(`tariff.upselling.${upselling.code}.desc`)
                    : null
                }
                onClick={() => {
                  setUpsellingSelected(upselling.code);
                }}
                selected={upselling?.code === upsellingSelected}
                subTitleClassName={upselling?.code === 'free' ? 'red' : ''}
                showPopularBadge={isLocalizeAdded(
                  localize,
                  `tariff.upselling.${upselling.code?.replace(
                    /\s/g,
                    ''
                  )}.popular`
                )}
                popularText={localize(
                  `tariff.upselling.${upselling.code?.replace(
                    /\s/g,
                    ''
                  )}.popular`
                )}
              />
            ))}
        </div>
        {isLocalizeAdded(localize, 'upsellings.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('upsellings.faq')}
            </Markdown>
          </div>
        )}
        <div style={{ height: 200 }} />
        <BrandButton
          id={GA_TITLES.ON_BOARDING.UPSELLING_SUBMITTED}
          title={localize('upsellings.button.text')}
          onClick={onSubmit}
          enableKeyboardEffect={false}
        />
      </div>
    </div>
  );
};

export default UpsellingsView;
