import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  selectRentalData,
  getRentalData,
  selectCarDamages,
  getDriverCarStatus,
  canFinishRental,
  setCardState,
  selectCardState,
  LocalizePropType,
  unlockCar,
  selectBrandSettings,
  selectNewDamages,
  selectDriverCarStatus,
} from 'getaway-data-layer';
import OpenedCardView from './OpenedCardView';
import { navigateTo, showAlert } from '../../../../utils';

export const RENTAL_UPDATER_DURATION = 300000;

type Props = {
  localize: LocalizePropType;
};

const OpenedCardContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const rentalData = useSelector(selectRentalData) as any;
  const carDamages = useSelector(selectCarDamages) as any;
  const driverCarStatus = useSelector(selectDriverCarStatus) as any;
  const newDamages = useSelector(selectNewDamages);
  const cardState = useSelector(selectCardState);
  const brandSettings = useSelector(selectBrandSettings);

  const onCancelRental = () => {
    showAlert(
      localize('rental.cancel.handover.title'),
      '',
      localize('rental.cancel.handover.ok'),
      () => {
        if (rentalData) {
          dispatch(
            getDriverCarStatus(rentalData?.id, true, () => {
              dispatch(canFinishRental(rentalData?.id));
            })
          );
        }
        navigateTo(history, '/rental/handover');
      },
      localize('cancel'),
      () => {},
      true
    );
  };

  const onCLickKeyItem = () => {
    navigateTo(history, '/rental/keyVideo');
  };

  const onAddDamage = () => {
    navigateTo(history, '/rental/addDamage');
  };

  const toggleCard = () => {
    if (cardState === 'Hidden') {
      dispatch(setCardState('Expanded'));
    } else {
      dispatch(setCardState('Hidden'));
    }
  };

  const onPressUnlockOption = () => {
    dispatch(unlockCar(rentalData?.id, brandSettings?.allroundPhotosMandatory));
  };

  const onPressUnblockOption = () => {
    dispatch(unlockCar(rentalData?.id, false));
  };

  const onPressAddAllAround = () => {
    navigateTo(history, '/rental/allAround');
  };

  useEffect(() => {
    const rentalUpdaterId = setInterval(() => {
      dispatch(getRentalData(rentalData?.id));
    }, RENTAL_UPDATER_DURATION);

    return () => {
      if (rentalUpdaterId) clearInterval(rentalUpdaterId);
    };
  }, []);

  return (
    <OpenedCardView
      localize={localize}
      newDamages={newDamages}
      toggleCard={toggleCard}
      damages={carDamages}
      carPlate={rentalData?.carData?.plate}
      onCancelRental={onCancelRental}
      onCLickKeyItem={onCLickKeyItem}
      onAddDamage={onAddDamage}
      onPressUnlockOption={onPressUnlockOption}
      onPressUnblockOption={onPressUnblockOption}
      immobilizerLocked={driverCarStatus?.immobilizerLocked}
      carCheckImagesSubmitted={rentalData?.startCheckImages?.length >= 4}
      onPressAddAllAround={onPressAddAllAround}
    />
  );
};

export default withTextLocalizer(OpenedCardContainer);
