import { CarsSearchState } from '../../../@types';

const initialState: CarsSearchState = {
  mapCenterLocation: {
    latitude: 52.519783,
    longitude: 13.416321,
  },
  mapZoom: 9,
  radius: 2000,
  from: null,
  backBy: null,
  mileage: null,
  availableCars: {},
  unavailableCars: {},
  searchCars: [],
  circleId: null,
  categories: [],
};

export default initialState;
