import { connect } from 'react-redux';
import { compose } from 'recompose';
import { State } from '../@types';
import { isRentalOngoing, isRentalStarted } from '../helpers/rentalHelpers';
import { setDeviceConnected } from '../redux/store/device';

export type Props = {
  setDeviceConnected: typeof setDeviceConnected;
  deviceConnected: boolean;
  devicePaired: boolean;
  locationPermission: any;
  cameraPermission: any;
  bluetoothPermission: any;
  basicAuth: string;
  bluetoothStatus: any;
  activeAlert: any;
  alertBaseMode: boolean;
  inRental: boolean;
  blockedUser: boolean;
  tripBlocked: boolean;
  xBrandId: string;
  restrictCarActionsToPairedUsers: boolean;
};

const withStatusAlert: any = compose(
  connect(
    ({
      userData: {
        user: { basicAuth, user },
        rental: { rentalData },
      },
      ui: {
        map: { activeAlert, alertBaseMode },
      },
      bluetooth: { status: bluetoothStatus },
      device: {
        status: {
          devicePaired,
          deviceConnected,
          locationPermission,
          cameraPermission,
          bluetoothPermission,
        },
      },
      config: { xBrandId },
      appData: {
        common: { brandSettings },
      },
    }: State) => ({
      rentalData,
      inRental:
        isRentalOngoing(rentalData) &&
        rentalData?.type !== 'plannedServiceTrip' &&
        rentalData?.carData?.vehicleConnection !== 'geoTab' &&
        rentalData?.carMode !== 'offered',
      tripBlocked: isRentalStarted(rentalData)
        ? rentalData?.userBlocked
        : false,
      bluetoothStatus,
      deviceConnected,
      devicePaired,
      cameraPermission,
      locationPermission,
      bluetoothPermission,
      basicAuth,
      activeAlert,
      alertBaseMode,
      xBrandId,
      blockedUser: user?.blocked,
      restrictCarActionsToPairedUsers:
        brandSettings?.restrictCarActionsToPairedUsers,
    }),
    {
      setDeviceConnected,
    }
  )
);
export default withStatusAlert;
