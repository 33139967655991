import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withTextLocalizer,
  dateAndTimeStringFromDate,
  timeStringFromDate,
  locationFromString,
  LocalizePropType,
  selectUserRentals,
  setOpenedModal,
  setSelectedCar,
  parseAddress,
  addressFromCoordinates,
} from 'getaway-data-layer';
import { useHistory, useParams } from 'react-router-dom';
import { isToday } from 'date-fns';
import RentalDetailsView from './RentalDetailsView';
import {
  dateFromObjectId,
  enhanceAddress,
  navigateTo,
} from '../../../../../utils';
import { openRouteDialog } from '../../../../../utils/dialogsHelpers';

type Props = {
  localize: LocalizePropType;
};

const RentalDetailsContainer = ({ localize }: Props) => {
  // @ts-ignore
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const rentals = useSelector(selectUserRentals) as any;
  const selectedRental = rentals?.find((item: any) => item?.tripId === id);
  const car = selectedRental?.carData;
  const [pickupAddress, setPickupAddress] =
    React.useState('Abholzone anzeigen');
  const [returnAddress, setReturnAddress] =
    React.useState('Abholzone anzeigen');
  const from = selectedRental?.from;
  const backBy = selectedRental?.to;
  const fromDate = from ? new Date(from) : null;
  const formattedFrom = fromDate
    ? dateAndTimeStringFromDate(fromDate, localize)
    : '';
  const backByDate = backBy ? new Date(backBy) : null;
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';
  const reservedAt = dateFromObjectId(id);
  const formattedReservedAt = reservedAt
    ? isToday(reservedAt)
      ? timeStringFromDate(reservedAt)
      : dateAndTimeStringFromDate(reservedAt, localize)
    : ' ';
  const title = `${localize('rental.history.header')} ${
    selectedRental?.tripPresentableId
  }`;
  const unlockDate = selectedRental?.initialUnlock
    ? new Date(selectedRental?.initialUnlock)
    : null;
  const formattedUnlock = unlockDate
    ? dateAndTimeStringFromDate(unlockDate, localize)
    : '';
  const lockDate = new Date(selectedRental?.finalLock);
  const formattedLock = lockDate
    ? isToday(lockDate)
      ? timeStringFromDate(lockDate)
      : dateAndTimeStringFromDate(lockDate, localize)
    : localize('now');

  const onClose = () => {
    navigateTo(history, '/rentals');
  };

  const onAddressClicked = (pickup: boolean) => {
    if (pickup) {
      openRouteDialog(
        localize,
        locationFromString(selectedRental?.pickupLocation),
        pickupAddress
      );
    } else if (returnAddress && selectedRental?.returnLocation) {
      openRouteDialog(
        localize,
        locationFromString(selectedRental?.returnLocation),
        returnAddress
      );
    }
  };

  const onPressInvoice = () => {
    window.open(selectedRental?.rentalInvoiceUrl, '_blank');
  };

  const onClickCarProfile = () => {
    dispatch(setSelectedCar(car));
    dispatch(setOpenedModal('CarProfileModal'));
  };

  useEffect(() => {
    if (selectedRental?.startAddress) {
      setPickupAddress(parseAddress(selectedRental?.startAddress));
    } else {
      addressFromCoordinates(
        locationFromString(selectedRental?.pickupLocation),
        (data: any) => {
          setPickupAddress(data);
        },
        process.env.REACT_APP_GOOGLE_MAP_KEY!
      );
    }
    if (selectedRental?.endAddress) {
      setReturnAddress(parseAddress(selectedRental?.endAddress));
    } else {
      addressFromCoordinates(
        locationFromString(selectedRental?.returnLocation),
        (data: any) => {
          setReturnAddress(data);
        },
        process.env.REACT_APP_GOOGLE_MAP_KEY!
      );
    }
  }, []);

  return (
    <RentalDetailsView
      localize={localize}
      onClose={onClose}
      car={car}
      from={formattedFrom}
      until={formattedBackBy}
      title={title}
      finalLock={selectedRental?.finalLock}
      initialUnlock={selectedRental?.initialUnlock}
      distance={selectedRental?.distance}
      tripId={selectedRental?.tripPresentableId}
      formattedReservedAt={formattedReservedAt}
      formattedUnlock={formattedUnlock}
      formattedLock={formattedLock}
      rentalInvoiceUrl={selectedRental?.rentalInvoiceUrl}
      onPressInvoice={onPressInvoice}
      onClickCarProfile={onClickCarProfile}
      insurance={selectedRental?.insuranceData}
      pickUpAddress={enhanceAddress(pickupAddress, localize)}
      returnAddress={enhanceAddress(returnAddress, localize)}
      onPressAddress={onAddressClicked}
    />
  );
};

export default withTextLocalizer(RentalDetailsContainer);
