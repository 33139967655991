import Intl from 'intl';
import 'intl/locale-data/jsonp/de-DE';
import 'intl/locale-data/jsonp/en-DE';

export const formatMoney = (value: number = 0, locale: string = 'de') => {
  if (value < 100) {
    return `${Math.ceil(value)} Ct`;
  }

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
  return currencyFormatter.format(value / 100);
};

export const formatMoneyEuro = (value: number = 0, locale: string = 'de') => {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
  });
  return currencyFormatter.format(value / 100);
};

export const formatMoneyDecimal = (
  value: number = 0,
  locale: string = 'de'
) => {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 1,
  });
  return currencyFormatter.format(value / 100);
};

export const formatMoneyPerKm = (value?: number, locale: string = 'de') =>
  `${formatMoney(value, locale)} / km`;

export const formatMoneyNumber = (value: number) => {
  const numberFormatter = new Intl.NumberFormat([], {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return numberFormatter.format(value / 100);
};

export const formatMoneyPerHour = (
  pricePerHour?: number,
  locale: string = 'de',
  localize?: any
) => `${formatMoney(pricePerHour, locale)}/${localize('hours.short')}`;

export const formatMoneyPerHourNumber = (pricePerHour: number = 0) =>
  `${formatMoneyNumber(pricePerHour)}`;
