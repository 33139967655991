import TYPES from '../../../../@types/redux/store/DamagesTypes';
import { Dispatch, ClearNewDamages } from '../../../../@types';

export const setNewDamage = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.SET_NEW_DAMAGE,
    payload: {
      id,
    },
  });
};
export const clearNewDamages = (): ClearNewDamages => ({
  type: TYPES.CLEAR_NEW_DAMAGES,
});
