import {
  getAPIBaseURL,
  getBasicAuth,
  getAPIVersion,
  getDeviceUUID,
  getSetter,
  getXBrandID,
} from './clientHelpers';
import { isWeb } from '../../helpers/platformHelpers';

type Headers = Record<string, string>;
export class EventsClient {
  eventSource: any;

  async initialize() {
    const basicAuth = getBasicAuth();
    const apiBaseURL = getAPIBaseURL();
    const apiVersion = getAPIVersion();
    const deviceUUID = getDeviceUUID();
    const xBrandID = getXBrandID();
    const headers: Headers = {
      Accept: 'text/event-stream',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };

    if (basicAuth) {
      headers.Authorization = basicAuth;
    }

    if (apiVersion) {
      headers['X-API-Version'] = apiVersion;
    }

    if (deviceUUID) {
      headers['X-Device-UUID'] = deviceUUID;
    }

    if (xBrandID) {
      headers['X-Brand-ID'] = xBrandID;
    }

    if (!isWeb()) {
      const { default: RNEventSource } = await import(
        'react-native-event-source'
      );
      this.eventSource = new RNEventSource(`${apiBaseURL}events`, {
        headers,
      });
    } else {
      const { EventSourcePolyfill } = await import('event-source-polyfill');
      this.eventSource = new EventSourcePolyfill(`${apiBaseURL}/events`, {
        headers,
      });
    }
  }

  start() {
    this.eventSource.addEventListener('user', ({ data }: any) => {
      getSetter.setUser(JSON.parse(data));
    });
    this.eventSource.addEventListener('rental', ({ data }: any) => {
      getSetter.setRentalData(JSON.parse(data));
    });
    this.eventSource.addEventListener('driverCarStatus', ({ data }: any) => {
      getSetter.setDriverCarStatus(JSON.parse(data));
    });
    this.eventSource.addEventListener('booking', ({ data }: any) => {
      getSetter.updateBooking(JSON.parse(data));
    });
  }

  stop() {
    if (this.eventSource) {
      if (!isWeb()) {
        this.eventSource?.removeAllListeners();
      }

      this.eventSource?.close();
    }
  }
}
