import { PricingTableData } from '../../Types.api';
export type PricingState = any;
enum PricingTypes {
  SET_CAR_PRICE_DETAILS = 'carsSearch/SET_CAR_PRICE_DETAILS',
  CLEAR_CAR_PRICE_DETAILS = 'carsSearch/CLEAR_CAR_PRICE_DETAILS',
  RESET_STORE = 'RESET_STORE',
}
export default PricingTypes;
export type SetCarPriceDetails = {
  type: PricingTypes.SET_CAR_PRICE_DETAILS;
  payload: {
    priceDetails: PricingTableData;
    carId: string;
  };
};
export type ClearCarPriceDetails = {
  type: PricingTypes.CLEAR_CAR_PRICE_DETAILS;
};
export type PricingAction = SetCarPriceDetails | ClearCarPriceDetails;
