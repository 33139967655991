import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isBefore } from 'date-fns';
import {
  withTextLocalizer,
  LocalizePropType,
  selectBookings,
  msToHMS,
} from 'getaway-data-layer';
import { useHistory, useParams } from 'react-router-dom';
import PaymentLinkView from './PaymentLinkView';
import { RENTAL_UI_UPDATER_DURATION } from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const PaymentLinkContainer = ({ localize }: Props) => {
  const { id } = useParams() as any;
  const history = useHistory();
  const bookings = useSelector(selectBookings) as any;
  const selectedBooking = bookings?.find((item: any) => item.id === id);
  const [paymentExpiryCountdown, setPaymentExpiryCountdown] = useState(
    null
  ) as any;
  const title = selectedBooking?.paymentLinkExpiresAt
    ? isBefore(new Date(), new Date(selectedBooking?.paymentLinkExpiresAt))
      ? `${localize('booking.header.in')} ${paymentExpiryCountdown}`
      : localize('booking.label.expired')
    : null;

  const onClose = () => {
    history.goBack();
  };

  useEffect(() => {
    setPaymentExpiryCountdown(
      isBefore(
        new Date(),
        // @ts-ignore
        new Date(selectedBooking?.paymentLinkExpiresAt)
      )
        ? // @ts-ignore
          msToHMS(
            Math.abs(
              // @ts-ignore
              new Date(selectedBooking?.paymentLinkExpiresAt) - new Date()
            ),
            localize
          )
        : null
    );
    const expiryCountdownUpdaterId = setInterval(() => {
      setPaymentExpiryCountdown(
        isBefore(
          new Date(),
          // @ts-ignore
          new Date(selectedBooking?.paymentLinkExpiresAt)
        )
          ? // @ts-ignore
            msToHMS(
              Math.abs(
                // @ts-ignore
                new Date(selectedBooking?.paymentLinkExpiresAt) - new Date()
              ),
              localize
            )
          : null
      );
    }, RENTAL_UI_UPDATER_DURATION);

    return () => {
      if (expiryCountdownUpdaterId) {
        clearInterval(expiryCountdownUpdaterId);
      }
    };
  }, []);

  return (
    <PaymentLinkView
      onClose={onClose}
      title={title}
      paymentLinkUrl={selectedBooking?.paymentLinkUrl}
    />
  );
};

export default withTextLocalizer(PaymentLinkContainer);
