import { setDialog } from '../redux/store/appData/common/actions';
import { DialogOption } from './showAlert';
import { injectedStore } from '../redux/ConfigureStore';

export const showWebAlert = (
  title: string,
  message: string,
  yesActionText: any = null,
  yesAction: () => void = () => {},
  noActionText: any = null,
  noAction: () => void = () => {},
  isDestructive: boolean = false
) => {
  const dialog = {
    title,
    message,
    yesActionText,
    yesAction,
    noActionText,
    noAction,
    isDestructive,
  };
  injectedStore.dispatch(setDialog(dialog));
};

export const showWebAlertWithOptions = (
  title: string,
  message: string,
  options: DialogOption[] = [],
  isDestructive: boolean = false
) => {
  const dialog = {
    title,
    message,
    options,
    isDestructive,
  };
  injectedStore.dispatch(setDialog(dialog));
};
