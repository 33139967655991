import React from 'react';
import './GasStationView.less';
import { getGasStationLogo } from '../../../utils';
import BrandIcon from '../BrandIcon/BrandIcon';
import { LocalizePropType, isMSM } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  brand: string;
  status: string | null;
  onClickNavigation: () => void;
  onClickPhone: () => void;
};

const GasStationView = ({
  localize,
  brand,
  status,
  onClickNavigation,
  onClickPhone,
}: Props) => {
  return (
    <div className="gas-station-container">
      <img
        src={`/images/${getGasStationLogo(brand)}.png`}
        className="gas-station-brand-logo"
        alt="brand-logo"
      />
      <div className="gas-station-brand">
        {localize(`fuelstation.${brand?.toLowerCase()}.name`)}
      </div>
      <div className="gas-station-status-container">
        <div className="gas-station-status-prefix">
          {localize('fuelstation.status.prefix')}
        </div>
        {status && <div className="gas-station-status">{`· ${status}`}</div>}
      </div>
      {!isMSM() && (
        <a
          className="gas-station-help-text"
          href={localize('fuelstation.help.url')}
          target="_blank"
          rel="noreferrer"
        >
          {localize('fuelstation.help.text')}
        </a>
      )}
      <div className="gas-station-button-container">
        <div
          className="gas-station-navigate-button"
          onClick={onClickNavigation}
        >
          <BrandIcon
            name="navigate"
            style={{
              width: 20,
              height: 20,
            }}
          />
          <div className="gas-station-navigate-text">
            {localize('navigate')}
          </div>
        </div>
        <div className="gas-station-call-button" onClick={onClickPhone}>
          <BrandIcon
            name="call"
            style={{
              width: 20,
              height: 20,
            }}
          />
          <div className="gas-station-call-text">{localize('call')}</div>
        </div>
      </div>
    </div>
  );
};

export default GasStationView;
