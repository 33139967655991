import React from 'react';
import { useDispatch } from 'react-redux';
import {
  joinCircle,
  LocalizePropType,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import AddCircleView from './AddCircleView';
import { navigateTo } from '../../../../utils';
import {
  AddCircleModal,
  AddCircleSchema,
} from '../../../../validation/addCircle.schema';

interface Values {
  circleCode: string;
}

type Props = {
  localize: LocalizePropType;
};

const AddCircleContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = ({ circleCode }: Values) => {
    dispatch(joinCircle(circleCode, () => navigateTo(history, '/settings')));
  };

  const addCircleFormik = useFormik({
    initialValues: AddCircleModal,
    validationSchema: AddCircleSchema(localize),
    onSubmit,
  });

  const onClose = () => {
    navigateTo(history, '/settings');
  };

  return (
    <AddCircleView
      localize={localize}
      formik={addCircleFormik}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(AddCircleContainer);
