import React from 'react';
import './Plate.less';

type Props = {
  carPlate: string;
  titleWrapperStyles?: any;
  titleStyles?: any;
  containerStyles?: any;
};

const Plate = ({
  carPlate,
  containerStyles,
  titleWrapperStyles,
  titleStyles,
}: Props) => {
  return (
    <div className="plate-container" style={containerStyles}>
      <div className="plate-label" />
      <div className="plate-titleWrapper" style={titleWrapperStyles}>
        <div className="plate-title" style={titleStyles}>
          {carPlate}
        </div>
      </div>
    </div>
  );
};

Plate.defaultProps = {
  containerStyles: {},
  titleWrapperStyles: {},
  titleStyles: {},
};

export default Plate;
