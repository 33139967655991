export default [
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-04T13:54:34.086Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '12.2534533 51.3465114',
              '12.2621222 51.3423028',
              '12.2831936 51.3404262',
              '12.2830649 51.3449566',
              '12.2537108 51.3474496',
              '12.2534533 51.3465114',
            ],
          },
        },
      ],
    },
    id: '5d1e051abc0e410c3edf80e5',
    lastUpdatedAt: '2019-10-15T11:53:23.815Z',
    version: 27,
    kmlFilename: 'Leipzig_07_191015.kml',
    lastUpdatedBy: 'Hironori Kawaida',
    name: 'testArea',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T19:01:54.701Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0867146 53.51266',
              '10.0871572 53.5127302',
              '10.0695782 53.5238037',
              '10.0690954 53.5235389',
              '10.0867146 53.51266',
            ],
          },
        },
      ],
    },
    id: '5d3f42a2bc0e413286f6e64f',
    lastUpdatedAt: '2019-07-29T19:03:49.822Z',
    version: 7,
    kmlFilename: 'planned_Hamburg_01_190620.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:07:46.56Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.0695604 52.4009362',
              '13.0687263 52.4009149',
              '13.0676641 52.3998496',
              '13.0680208 52.3995829',
              '13.069775 52.399825',
              '13.0695604 52.4009362',
            ],
          },
        },
      ],
    },
    id: '5d3f6e32bc0e415b9c1ea771',
    lastUpdatedAt: '2019-07-29T22:08:05.835Z',
    version: 6,
    kmlFilename: 'planned_Berlin_22_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Berlin_22_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:08:42.329Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3994579 52.5046445',
              '13.3993828 52.4985641',
              '13.4143388 52.4988123',
              '13.4146285 52.4999553',
              '13.4152615 52.5010721',
              '13.412472 52.5030314',
              '13.4047258 52.5052127',
              '13.3994579 52.5046445',
            ],
          },
        },
      ],
    },
    id: '5d3f6e6abc0e415b9c1ea776',
    lastUpdatedAt: '2019-07-29T22:08:54.144Z',
    version: 6,
    kmlFilename: 'planned_Berlin_23_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Berlin_23_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:10:43.814Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5691031 52.0394945',
              '8.5721072 52.0408507',
              '8.5784023 52.0444967',
              '8.5770853 52.0451532',
              '8.5711201 52.0433716',
              '8.5669144 52.0415502',
              '8.5691031 52.0394945',
            ],
          },
        },
      ],
    },
    id: '5d3f6ee3bc0e415b9c1ea780',
    lastUpdatedAt: '2019-08-21T06:23:33.859Z',
    version: 15,
    kmlFilename: 'planned_Bielefeld_01_190618.kml.xml',
    lastUpdatedBy: 'Hironori Kawaida',
    name: 'planned_Bielefeld_01_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:11:36.266Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5783025 52.0386416',
              '8.5742255 52.0369389',
              '8.5735737 52.0363433',
              '8.5724231 52.0348665',
              '8.5725947 52.0345761',
              '8.5770579 52.0365561',
              '8.577766 52.0371369',
              '8.5784741 52.0379685',
              '8.5786672 52.0386284',
              '8.5783025 52.0386416',
            ],
          },
        },
      ],
    },
    id: '5d3f6f18bc0e415b9c1ea785',
    lastUpdatedAt: '2019-07-29T22:11:45.23Z',
    version: 6,
    kmlFilename: 'planned_Bielefeld_02_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bielefeld_02_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:12:17.305Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.5572059 51.9986364',
              '8.557344 51.9985968',
              '8.5575304 51.9990113',
              '8.557599 51.9993816',
              '8.5576914 52.0005086',
              '8.5579771 52.0012554',
              '8.5586383 52.0025835',
              '8.5587188 52.0032134',
              '8.5587986 52.0037269',
              '8.5590554 52.0042379',
              '8.5588757 52.004285',
              '8.5585806 52.0036146',
              '8.5585967 52.0032514',
              '8.558409 52.0022804',
              '8.5576204 52.0007976',
              '8.5574434 51.9997837',
              '8.5573844 51.9990109',
              '8.5572059 51.9986364',
            ],
          },
        },
      ],
    },
    id: '5d3f6f41bc0e415b9c1ea78a',
    lastUpdatedAt: '2019-07-29T22:12:31.848Z',
    version: 6,
    kmlFilename: 'planned_Bielefeld_03_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bielefeld_03_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-29T22:13:30.188Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.4946076 51.9799112',
              '8.4932772 51.9804201',
              '8.4926657 51.9803044',
              '8.4895436 51.9790522',
              '8.4925262 51.9784739',
              '8.4926925 51.9784574',
              '8.494511 51.9792273',
              '8.4946076 51.9799112',
            ],
          },
        },
      ],
    },
    id: '5d3f6f8abc0e415b9c1ea78f',
    lastUpdatedAt: '2019-07-29T22:13:39.192Z',
    version: 6,
    kmlFilename: 'planned_Bielefeld_04_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bielefeld_04_190618',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    createdAt: '2019-07-30T13:38:11.92Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0257883 53.5512159',
              '10.0280414 53.5480542',
              '10.0314854 53.5488319',
              '10.0289319 53.5519871',
              '10.0257883 53.5512159',
            ],
          },
        },
      ],
    },
    id: '5d404843bc0e41581a347a34',
    lastUpdatedAt: '2019-07-30T13:38:35.038Z',
    version: 6,
    kmlFilename: 'test_Hamburg_55-190730.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Test_Hamburg_55',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    description: 'map description test',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '8.7259503 50.1190296',
              '8.7073679 50.1120261',
              '8.7087519 50.1100239',
              '8.7274201 50.1171515',
              '8.7259503 50.1190296',
            ],
          },
        },
      ],
    },
    id: '5d405d57bc0e417dcf8afb0b',
    lastUpdatedAt: '2019-07-30T15:08:17.761Z',
    createdAt: '2019-07-30T15:08:07.492Z',
    version: 7,
    kmlFilename: 'test_Frankfurt_55_190730.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Test_Frankfurt_55',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000006',
    description: 'Factory Area 24/7',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4138011 52.5494872',
              '13.3634184 52.5446331',
              '13.3627318 52.5270391',
              '13.4119128 52.5307986',
              '13.4138011 52.5494872',
            ],
          },
        },
      ],
    },
    id: '5d4aa08ab73ec61899176a50',
    lastUpdatedAt: '2019-08-07T09:58:14.776Z',
    createdAt: '2019-08-07T09:57:30.115Z',
    version: 10,
    kmlFilename: 'Factory area.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Factory Area',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
];
