import React from 'react';
import { Result } from 'antd';
import './MobileSwitchView.less';
import { QR } from '../../../assets/brandImages';
import { LocalizePropType } from 'getaway-data-layer';
import NavigationBar from '../../components/NavigationBar/NavigationBar';

type Props = {
  localize: LocalizePropType;
  onClose: () => void;
};

const MobileSwitchView = ({ localize, onClose }: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('desktop.switch.mobile.title')}
        description={localize('desktop.switch.mobile.desc')}
        showHelp
        onClose={onClose}
      />
      <div className="screen-body">
        <Result
          icon={() => null}
          extra={<img className="mobile-QR" src={QR} alt="QR" />}
        />
      </div>
    </div>
  );
};

export default MobileSwitchView;
