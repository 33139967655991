import { CONSTRAINTS } from './constraints';

const validate = require('validate.js');

export const validatePhoneNumber = (phoneNumber: string) => {
  const result = validate({ phoneNumber }, CONSTRAINTS.PHONE_NUMBER(), {
    fullMessages: false,
  });
  if (result && result.phoneNumber && result.phoneNumber.length) {
    return result.phoneNumber[0];
  }
  return '';
};
