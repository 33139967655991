import React, { useEffect } from 'react';
import {
  selectUser,
  withTextLocalizer,
  getTransactions,
  getMoreTransactions,
  selectUserTransactions,
  LocalizePropType,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BalanceView from './BalanceView';
import { navigateTo } from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const BalanceContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const transactions = useSelector(selectUserTransactions);

  const onClose = () => {
    navigateTo(history, '/map');
  };

  const onLoadMoreTransactions = () => {
    dispatch(getMoreTransactions());
  };

  useEffect(() => {
    dispatch(getTransactions());
  }, []);

  return (
    <BalanceView
      localize={localize}
      balance={user?.balance}
      transactions={transactions?.transactions}
      transactionsCount={transactions?.totalCount}
      onLoadMoreTransactions={onLoadMoreTransactions}
      onClose={onClose}
    />
  );
};

export default withTextLocalizer(BalanceContainer);
