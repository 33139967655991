import { getBrandName } from 'getaway-data-layer/src/helpers/brandHelpers';
import { LocalizePropType } from 'getaway-data-layer';

export const titleForDriverCar = (car: any): string =>
  `${car?.brand || ''} ${car?.model || ''}`;

export const getCarPricingUrl = (url: string, xBrandId: any): string => {
  if (url === 'pricing.CharterlinePricing.url') {
    if (getBrandName(xBrandId) === 'ubq') {
      return 'https://ubq.app/feetable-deDe-pdf';
    }
    return 'https://robbe.app/feetable-deDe-pdf';
  }
  return url;
};

export const enhanceAddress = (
  address: string,
  localize: LocalizePropType
): string =>
  address && address !== 'undefined'
    ? address
    : localize('booking.conformation.no.address');
