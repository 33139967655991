import TYPES from '../../../../@types/redux/store/CommonTypes';
import {
  SetAppRated,
  SetBrandSettings,
  SetFirstSteps,
  SetSupportContacted,
  SetOpenedModal,
  ClearOpenedModal,
  SetDialog,
  ClearDialog,
  SetIsIntercomUserRegistered,
} from '../../../../@types';
import { BrandData } from '../../../../@types';

export const setOpenedModal = (modalName: string): SetOpenedModal => ({
  type: TYPES.SET_OPENED_MODAL,
  payload: {
    modalName,
  },
});

export const clearOpenedModal = (): ClearOpenedModal => ({
  type: TYPES.CLEAR_OPENED_MODAL,
});

export const setDialog = (dialog: any): SetDialog => ({
  type: TYPES.SET_DIALOG,
  payload: {
    dialog,
  },
});

export const clearDialog = (): ClearDialog => ({
  type: TYPES.CLEAR_DIALOG,
});

export const setAppRated = (appRated: boolean): SetAppRated => ({
  type: TYPES.SET_APP_RATED,
  payload: {
    appRated,
  },
});

export const setFirstSteps = (firstSteps: boolean): SetFirstSteps => ({
  type: TYPES.SET_FIRST_STEPS,
  payload: {
    firstSteps,
  },
});

export const setSupportContacted = (
  supportContacted: boolean
): SetSupportContacted => ({
  type: TYPES.SET_SUPPORT_CONTACTED,
  payload: {
    supportContacted,
  },
});

export const setIsIntercomUserRegistered = (
  isIntercomUserRegistered: boolean
): SetIsIntercomUserRegistered => ({
  type: TYPES.SET_IS_INTERCOM_USER_REGISTERED,
  payload: {
    isIntercomUserRegistered,
  },
});

export const setBrandSettings = (
  brandSettings?: BrandData
): SetBrandSettings => ({
  type: TYPES.SET_BRAND_SETTINGS,
  payload: {
    brandSettings,
  },
});
