import React from 'react';
import {
  withTextLocalizer,
  selectBookings,
  LocalizePropType,
  selectPreSignupBooking,
} from 'getaway-data-layer';
import './HelpAction.less';
import { useSelector } from 'react-redux';
import BrandIcon from '../BrandIcon/BrandIcon';
import { mapHelp } from '../../../assets/brandImages';
import { helpIconStyles } from '../../pages/Map/map.helpers';
import { useCustomerSupport } from '../../../hooks/useCustomerSupport';

type Props = {
  localize: LocalizePropType;
  name: string;
  style?: any;
};

const HelpAction = ({ localize, name, style }: Props) => {
  const { hideCS, helpAction } = useCustomerSupport(localize);
  const bookings = useSelector(selectBookings);
  const preSignupBooking = useSelector(selectPreSignupBooking);
  const activeBookingsList =
    bookings
      ?.filter((booking: any) =>
        ['confirmed', 'offered', 'waiting'].includes(booking.status)
      ) // @ts-ignore
      ?.sort((a, b) => new Date(a?.from) - new Date(b?.from)) || null;

  return !hideCS ? (
    <a className="help-icon" onClick={() => helpAction()}>
      {name === 'mapHelp' ? (
        <img
          alt="mapHelp"
          src={mapHelp}
          // @ts-ignore
          style={{
            ...helpIconStyles,
            top:
              activeBookingsList?.length || preSignupBooking ? '45px' : '20px',
          }}
        />
      ) : (
        <BrandIcon name={name || 'helpIcon'} style={style} />
      )}
    </a>
  ) : null;
};

HelpAction.defaultProps = {
  style: { width: 24, height: 24 },
};

export default withTextLocalizer(HelpAction);
