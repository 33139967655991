import React from 'react';
import './BookingView.less';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import PointItem from '../../components/PointItem/PointItem';
import ListItem from '../../components/ListItem/ListItem';
import ExpandableInsuranceItem from '../../components/ExpandableInsuranceItem/ExpandableInsuranceItem';
import { InsuranceData, LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES, isMobileBrowser } from '../../../utils';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
  carPlate: string;
  address: string;
  insurancesData: Array<InsuranceData>;
  selectedInsurance: string;
  onInsuranceSelected: (id: string) => void;
  from: string;
  until: string;
  onPressReserveNow: () => void;
  bookingId: string;
  formattedRequestedAt: string;
  noLaterThanDate: string;
  title: string;
  onPressCancelBooking: any;
  isButtonVisible: boolean;
  onAddressClicked: () => void;
  circleName: string;
  paymentLinkUrl: string;
  paymentLinkStatus: string;
  paymentExpiryCountdown: string;
  onPressPayNow: () => void;
  extras: any;
  orderedOptions: any;
  onPressExtraOption: (code: string) => void;
};

const BookingView = ({
  localize,
  onClose,
  carPlate,
  address,
  insurancesData,
  selectedInsurance,
  onInsuranceSelected,
  from,
  until,
  onPressReserveNow,
  bookingId,
  formattedRequestedAt,
  noLaterThanDate,
  title,
  onPressCancelBooking,
  isButtonVisible,
  onAddressClicked,
  circleName,
  paymentLinkUrl,
  paymentLinkStatus,
  paymentExpiryCountdown,
  onPressPayNow,
  extras,
  orderedOptions,
  onPressExtraOption,
}: props) => {
  const payInDialog =
    paymentLinkUrl &&
    (!['confirmed', 'settled'].includes(paymentLinkStatus) ? (
      <div className="booking-warning">
        <div className="booking-warning-title">
          {`${localize('booking.warning.payin.title')} ${
            paymentExpiryCountdown ? `(noch ${paymentExpiryCountdown})` : ''
          }`}
        </div>
        <div className="booking-warning-msg">
          {localize('booking.warning.payin.text')}
        </div>
        <div className="booking-warning-container">
          <button className="booking-warning-btn" onClick={onPressPayNow}>
            {localize('booking.warning.payin.button')}
          </button>
          <div className="booking-warning-value">
            {localize('booking.warning.payin.value')}
          </div>
        </div>
      </div>
    ) : (
      <div className="booking-warning">
        <div className="booking-warning-title">
          {`${localize('booking.warning.payin.title.payed')}`}
        </div>
        <div className="booking-warning-msg">
          {localize('booking.warning.payin.text.payed')}
        </div>
      </div>
    ));
  return (
    <div className="screen-container">
      <NavigationBar
        title={title}
        description={localize('booking.details.header.descr')}
        onClose={onClose}
      />
      <div className="screen-body booking-confirmation-content">
        <PointItem title={localize('station.booking.group')} />
        <div className="booking-confirm-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="booking-confirmation-dashed"
          />
          {payInDialog}
          {circleName && (
            <ListItem
              title={localize('booking.consent.circle')}
              greyTextColor
              value={
                circleName.length > 17
                  ? `${circleName.slice(0, 17)}...`
                  : circleName
              }
              showSeparator
              onClick={() => {}}
            />
          )}
          {!!noLaterThanDate && (
            <>
              <ListItem
                title={localize('station.booking.later.than')}
                value={noLaterThanDate}
                showSeparator
                onClick={() => {}}
              />
            </>
          )}
          <ExpandableInsuranceItem
            localize={localize}
            insurancesData={insurancesData}
            onInsuranceSelected={onInsuranceSelected}
            selectedInsurance={selectedInsurance}
          />
          {extras?.map((extra: { code: string }) => (
            <ListItem
              key={extra.code}
              title={localize(`rental.extra.${extra.code}.title`)}
              greyTextColor
              onClick={() => onPressExtraOption(extra.code)}
              checkboxChecked={orderedOptions.includes(extra.code)}
              checkboxItem
            />
          ))}
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator
            onClick={onAddressClicked}
          />
          <div className="booking-confirmation-insurance-desc">
            {localize('bookings.where.desc')}
          </div>
        </div>
        <PointItem
          title={localize('bookings.headline.reservation')}
          value={formattedRequestedAt}
        />
        <div className="reserved-card-section">
          <div className="reserved-card-section-line" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('booking.confirm.profile')}
            carPlate={carPlate}
            showSeparator={false}
            onClick={() => {}}
          />
          <div className="reserved-card-separator" />
          {!!from && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.from')}
                value={from}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!until && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.to')}
                value={until}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!bookingId && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('station.booking.code')}
                code={bookingId}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
        </div>
        <div className="booking-cancel" onClick={onPressCancelBooking}>
          {localize('bookings.profile.cancel.text')}
        </div>
        <div className="booking-confirmation-vertical-space" />
        {isButtonVisible && (
          <BrandButton
            id={GA_TITLES.BOOKING.RESERVE}
            title={localize(
              isMobileBrowser()
                ? 'booking.driver.offer.reserve'
                : 'booking.desktop.start'
            )}
            onClick={onPressReserveNow}
          />
        )}
      </div>
    </div>
  );
};

export default BookingView;
