type Event = {
  event: string;
  properties?: {
    result?: string;
  };
};

export const AnalyticsManager = {
  analyticsCenter: null as any,

  event({ event, properties }: any) {
    if (this.analyticsCenter) {
      if (typeof properties === 'string') {
        this.analyticsCenter.event({
          event: `${event} ${properties}`,
        });
      } else {
        this.analyticsCenter.event({
          event,
          properties,
        });
      }
    }
  },
};
