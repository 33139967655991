import { AddressData } from '../@types';
import { LocalizePropType } from '../enhancers';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const formattedAddress = (
  address: AddressData,
  localize: LocalizePropType
) => {
  const country = address.country ? localize(address.country) : localize('DE');
  return `${capitalizeFirstLetter(address.streetName)} ${
    address.streetNumber
  },\n${address.postalCode} ${capitalizeFirstLetter(address.city)}, ${country}`;
};

export const streetNameNumFromAddress = (address: AddressData) => {
  return address?.streetName
    ? `${capitalizeFirstLetter(address?.streetName)} ${
        address?.streetNumber || ''
      }`
    : 'Address';
};
