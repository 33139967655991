import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withTextLocalizer,
  dateAndTimeStringFromDate,
  locationFromString,
  LocalizePropType,
  selectUser,
  selectBookings,
  confirmBooking,
  selectCircleId,
  msToHMS,
  getBrandName,
  switchAccessToCircle,
  cancelBooking,
  setNotification,
  sendVerificationCode,
  isPaymentVerified,
  submitIdentityShopper,
  submitChallenge,
  setRecoverPaymentAuth,
  selectDevicePaired,
  selectFeatures,
  selectPricing,
  getCarPriceDetails,
  parseAddress,
} from 'getaway-data-layer';
import { useHistory, useParams } from 'react-router-dom';
import BookingConsentView from './BookingConsentView';
import { navigateTo, showAlert, showAlertWithOptions } from '../../../../utils';
import { openRouteDialog } from '../../../../utils/dialogsHelpers';
import config from '../../../../env.config';
import { useCustomerSupport } from '../../../../hooks/useCustomerSupport';
import { handleRentalViolations } from 'getaway-data-layer/src/helpers/rentalsViolationsHelpers';

type Props = {
  localize: LocalizePropType;
};

const BookingConsentContainer = ({ localize }: Props) => {
  const { id } = useParams() as any;
  const history = useHistory();
  const dispatch = useDispatch();
  const bookings = useSelector(selectBookings) as any;
  const circleId = useSelector(selectCircleId) as any;
  const user = useSelector(selectUser);
  const devicePaired = useSelector(selectDevicePaired);
  const features = useSelector(selectFeatures);
  const pricing = useSelector(selectPricing) as any;
  const { helpAction } = useCustomerSupport(localize);
  const selectedBooking = bookings?.find((item: any) => item.id === id);
  const selectedCar = selectedBooking?.accepted.car;
  const filteredCircles =
    selectedBooking?.bookingType === 'service'
      ? user?.circles?.filter((circle) => circle?.circleType === 'service')
      : user?.circles;
  let selectedCircle = null as any;
  if (user && user?.circles) {
    selectedCircle = user.circles.find((circle: any) => circle.id === circleId);
  }

  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [sortedInsurances, setSortedInsurances] = useState([]) as any[];
  const address = parseAddress(selectedCar?.releaseAddress);
  const [isDriverInjurySelected, setIsDriverInjurySelected] = useState(false);

  const fromDate = new Date(selectedBooking?.from);
  const formattedFrom = fromDate
    ? dateAndTimeStringFromDate(fromDate, localize)
    : '';
  const backByDate = new Date(selectedBooking?.to);
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';

  const onClose = () => {
    const timeUntilCancellation = msToHMS(
      // @ts-ignore
      Math.abs(new Date(selectedBooking?.from) - new Date()),
      localize
    );

    showAlert(
      `${localize('booking.header.in')} ${timeUntilCancellation}`,
      localize('booking.consent.later.dialog.message'),
      localize('booking.consent.later.dialog.yes'),
      () => {
        navigateTo(history, '/map');
      },
      localize('cancel'),
      () => {},
      false
    );
  };

  const onCancel = () => {
    showAlert(
      localize('booking.consent.cancel.dialog.title'),
      localize('booking.consent.cancel.dialog.message'),
      localize('booking.consent.cancel.dialog.yes'),
      () => {
        dispatch(
          cancelBooking(id, () => {
            history.goBack();
          })
        );
      },
      localize('cancel'),
      () => {},
      true
    );
  };

  const setInitialInsurance = () => {
    if (selectedCar) {
      const { defaultInsuranceData, insurancesData } = selectedCar;
      const sortedInsurancesArray = [...insurancesData];
      sortedInsurancesArray.sort(
        (a: any, b: any) => b.deductibleAmount - a.deductibleAmount
      );
      const initialSelectedInsurance =
        defaultInsuranceData?.id &&
        sortedInsurancesArray
          .map((insurance: any) => insurance.id)
          .includes(defaultInsuranceData.id)
          ? defaultInsuranceData.id
          : sortedInsurancesArray && sortedInsurancesArray.length > 0
          ? sortedInsurancesArray[0].id
          : ' ';
      setSortedInsurances(sortedInsurancesArray);
      setSelectedInsurance(initialSelectedInsurance);
    }
  };

  const onInsuranceSelected = (insurance: string) => {
    setSelectedInsurance(insurance);
  };

  const onPressConfirmBooking = () => {
    const notificationCallback = () => {
      navigateTo(history, '/rentals');
    };
    const successCallback = () => {
      navigateTo(history, '/map');
    };
    const orderedOptions = isDriverInjurySelected ? 'F3023' : [];
    if (selectedCar?.id) {
      dispatch(
        confirmBooking(
          localize,
          id,
          selectedCircle?.id!,
          selectedInsurance!,
          orderedOptions,
          new Date(selectedBooking?.from),
          null,
          notificationCallback,
          successCallback,
          (violations) =>
            handleRentalViolations(
              violations,
              user,
              localize,
              setNotification,
              devicePaired,
              null,
              features,
              sendVerificationCode,
              helpAction,
              isPaymentVerified,
              submitIdentityShopper,
              submitChallenge,
              setRecoverPaymentAuth,
              dispatch,
              history
            )
        )
      );
    }
  };

  const onAddressClicked = () => {
    openRouteDialog(
      localize,
      locationFromString(selectedCar?.position),
      address
    );
  };

  const onClickCircles = () => {
    const options = filteredCircles?.map((circle: any) => ({
      title:
        circle.name ||
        localize(`brand.${getBrandName(config.brand.xBrandId!)}.name`),
      action: () => dispatch(switchAccessToCircle(circle.id)),
    }));
    showAlertWithOptions('', '', options);
  };

  const onPressExtraOption = () => {
    setIsDriverInjurySelected(!isDriverInjurySelected);
  };

  useEffect(() => {
    setInitialInsurance();
    dispatch(
      getCarPriceDetails(selectedCar?.driverPrice?.pricingId, selectedCar?.id)
    );
  }, []);

  return (
    <BookingConsentView
      localize={localize}
      onClose={onClose}
      car={selectedCar}
      circles={filteredCircles}
      selectedCircle={selectedCircle}
      carPlate={selectedCar?.plate}
      address={address}
      insurancesData={sortedInsurances}
      selectedInsurance={selectedInsurance}
      onInsuranceSelected={onInsuranceSelected}
      from={formattedFrom}
      until={formattedBackBy}
      onSubmit={onPressConfirmBooking}
      onAddressClicked={onAddressClicked}
      onClickCircles={onClickCircles}
      onCancel={onCancel}
      extras={pricing[selectedCar?.id!!]?.feeItems?.filter(
        (item: { type: string }) => item?.type === 'extra'
      )}
      isDriverInjurySelected={isDriverInjurySelected}
      onPressExtraOption={onPressExtraOption}
    />
  );
};

export default withTextLocalizer(BookingConsentContainer);
