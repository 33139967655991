import { $PropertyType } from 'utility-types';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import { State, ConfigState } from '../@types';

export type Props = {
  googleApiAccessToken: $PropertyType<ConfigState, 'googleApiAccessToken'>;
  apiBaseURL: $PropertyType<ConfigState, 'apiBaseURL'>;
  apiVersion: $PropertyType<ConfigState, 'apiVersion'>;
  bundleId: $PropertyType<ConfigState, 'bundleId'>;
  appleAppId: $PropertyType<ConfigState, 'appleAppId'>;
  xBrandId: $PropertyType<ConfigState, 'xBrandId'>;
};

const withConfig: any = compose(
  connect(
    ({
      config: {
        googleApiAccessToken,
        apiBaseURL,
        apiVersion,
        bundleId,
        appleAppId,
        xBrandId,
      },
    }: State) => ({
      googleApiAccessToken,
      apiBaseURL,
      apiVersion,
      bundleId,
      appleAppId,
      xBrandId,
    })
  ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ dispatch, ...props }: any) => ({ ...props }))
);
export default withConfig;
