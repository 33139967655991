import React from 'react';
import './SlideItem.less';

type Props = {
  title: string;
  description: string;
  imageSrc: any;
};

const SlideItem = ({ title, description, imageSrc }: Props) => {
  return (
    <div style={{ height: window.innerHeight }} className="slide-item-wrapper">
      <img className="slide-item-img" src={imageSrc} alt={imageSrc} />
      <div className="slide-item-title"> {title} </div>
      <div className="slide-item-description"> {description} </div>
    </div>
  );
};

export default SlideItem;
