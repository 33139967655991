import {
  APIResponse,
  UserDataOutput,
  SignUpInputData,
  UpdateUserInputData,
} from '../../@types';

export const authClient = {
  login: async (): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/user',
    });
    return {
      notModified,
      data,
      error,
    };
  },
  signUp: async (
    signUpDataInput: SignUpInputData,
    utmSource?: string,
    utmMedium?: string
  ): Promise<APIResponse<UserDataOutput>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    let queryParams = '';
    if (utmSource) queryParams += `utm_source=${encodeURIComponent(utmSource)}`;
    if (utmMedium) {
      queryParams += `${queryParams ? '&' : ''}utm_medium=${encodeURIComponent(
        utmMedium
      )}`;
    }

    const url = `/user${queryParams ? `?${queryParams}` : ''}`;

    const { notModified, data, error } = await makeAPICall({
      method: 'POST',
      url,
      data: signUpDataInput,
    });
    return {
      notModified,
      data,
      error,
    };
  },
  forgetPassword: async (email: string): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'POST',
      url: '/forgot',
      data: {
        email,
      },
    });
    return {
      error,
    };
  },
  resetPassword: async (
    email: string,
    password: string,
    token: string
  ): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'PUT',
      url: '/forgot',
      data: {
        email,
        password,
        token,
      },
    });
    return {
      error,
    };
  },
  checkReferral: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'POST',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      error,
    };
  },
  checkCircle: async (
    updateUserInputData: UpdateUserInputData
  ): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'POST',
      url: '/user',
      data: updateUserInputData,
    });
    return {
      error,
    };
  },
};
