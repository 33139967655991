type PlatformType = 'Android' | 'iOS' | 'Web' | 'Unknown' | undefined;
let platform: PlatformType;

const loadPlatform = () => {
  if (!platform || platform === 'Unknown') {
    platform = getPlatform();
  }
  return platform;
};
export const isIOS = () => {
  return loadPlatform() === 'iOS';
};

export const isWeb = () => {
  return loadPlatform() === 'Web';
};

export const isAndroid = () => {
  return loadPlatform() === 'Android';
};

const getPlatform = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    // We are in a browser environment, so it's the web
    return 'Web';
  } else if (
    typeof navigator !== 'undefined' &&
    navigator.product === 'ReactNative'
  ) {
    // @ts-ignore
    return global.platform;
  }
  return 'Unknown';
};

export default getPlatform;

export const isWebView = (user) => {
  // @ts-ignore
  var userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  const isEmbeddedBrowser =
    userAgent.indexOf('FBAN') > -1 ||
    userAgent.indexOf('FBAV') > -1 ||
    userAgent.indexOf('Instagram') > -1 ||
    userAgent.indexOf('WebView') > -1 ||
    userAgent.indexOf('wv') > -1 ||
    userAgent.indexOf('AndroidWebView') > -1 ||
    (userAgent.indexOf('AppleWebKit') > -1 &&
      userAgent.indexOf('Mobile/') > -1 &&
      userAgent.indexOf('Safari') === -1) ||
    userAgent.indexOf('FB_IAB') > -1;

  return isEmbeddedBrowser && user.email.includes('getaway.pro');
};

export const isSafariBrowser = () => {
  // @ts-ignore
  var userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  return (
    userAgent.includes('AppleWebKit') &&
    userAgent.includes('Mobile/') &&
    userAgent.includes('Safari')
  );
};

export const isIOSBrowser = () => {
  // @ts-ignore
  var userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  // @ts-ignore
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};
