export const isSafari = ['iPhone', 'iPad', 'iPod']?.includes(
  // @ts-ignore
  window?.navigator?.userAgentData?.platform || window?.navigator?.platform
);

const forceDownload = (blob: any, filename: string) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadResource = (url: string, filename: string) => {
  // @ts-ignore
  const name = filename || url.split('\\').pop().split('/').pop();
  fetch(url, {
    headers: new Headers({
      Origin: document.location.origin,
      'Cache-Control': 'no-cache',
    }),
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      let blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, name!);
    })
    .catch((e) => console.error(e));
};
