import { connect } from 'react-redux';
import { compose, withHandlers, mapProps } from 'recompose';
import withTextLocalizer from './withTextLocalizer';
import {
  formatMoney,
  formatMoneyDecimal,
  formatMoneyEuro,
  formatMoneyNumber,
  formatMoneyPerHour,
  formatMoneyPerKm,
} from '../helpers/formatMoney';
import {
  dateAndTimeStringFromDate,
  dateStringFromDate,
  formatMonthFromDate,
  formatMonthYearFromDate,
  msToHMS,
  msToHour,
  timeStringFromDate,
} from '../helpers/dateFormatters';
import { formatKgToTone, formatMmToCm } from '../helpers/unitFormatter';
import { formatDistance } from '../helpers/numbersFormatters';
import { Languages, Texts, State } from '../@types';
import {
  getBrandGroup,
  getBrandLanguage,
  isBrand,
} from '../helpers/brandHelpers';
import { openingHoursString } from '../helpers/gasStationHelpers';

export type Props = {
  formatMoneyEuro: (value?: number) => string;
  formatMoneyNumber: (value?: number) => string;
  formatMoney: (value?: number) => string;
  formatMoneyDecimal: (value?: number) => string;
  formatMoneyPerKm: (value?: number) => string;
  formatMoneyPerHour: (value?: number) => string;
  formatDistance: (value?: number) => string;
  formatMonthFromDate: (date: Date, short?: boolean) => string;
  formatMonthYearFromDate: (date: Date, short?: boolean) => string;
  formatHourMinuteFromDate: (date: Date) => string;
  timeStringFromDate: (date: Date) => string;
  dateAndTimeStringFromDate: (date: Date) => string;
  dateStringFromDate: (date: Date) => string;
  openingHoursString: any;
  formatMmToCm: any;
  formatKgToTone: (value?: number) => string;
  msToHMS: (ms: any) => string;
  msToHour: (ms: number) => string;
};

const withFormatters: any = compose(
  withTextLocalizer,
  connect(
    ({
      appData: { texts },
      device: { language: deviceLanguage },
      config: { xBrandId },
    }: State) => {
      const group = getBrandGroup(xBrandId);
      const language = isBrand(xBrandId)
        ? getBrandLanguage(xBrandId)
        : deviceLanguage;
      return {
        locale:
          texts[group as keyof Texts] &&
          texts[group as keyof Texts][language as keyof Languages] &&
          texts[group as keyof Texts][language as keyof Languages]?.textkey,
      };
    }
  ),
  withHandlers({
    formatMoneyEuro:
      ({ locale }: any) =>
      (value?: number) =>
        formatMoneyEuro(value, locale),
    formatMoneyNumber:
      ({ locale }: any) =>
      (value?: any) =>
        formatMoneyNumber(value),
    formatMoney:
      ({ locale }: any) =>
      (value?: number) =>
        formatMoney(value, locale),
    formatMoneyDecimal:
      ({ locale }: any) =>
      (value?: number) =>
        formatMoneyDecimal(value, locale),
    formatMoneyPerKm:
      ({ locale }: any) =>
      (value?: number) =>
        formatMoneyPerKm(value, locale),
    formatMoneyPerHour:
      ({ locale, localize }: any) =>
      (value?: number) =>
        formatMoneyPerHour(value, locale, localize),
    formatDistance: () => (value?: number) => formatDistance(value),
    formatMonthFromDate:
      ({ locale }: any) =>
      (date: Date, short?: boolean) =>
        formatMonthFromDate(date, short, locale),
    formatMonthYearFromDate:
      ({ locale }: any) =>
      (date: Date, short?: boolean) =>
        formatMonthYearFromDate(date, short, locale),
    timeStringFromDate: () => (date: Date) => timeStringFromDate(date),
    dateStringFromDate:
      ({ localize, locale }: any) =>
      (date: Date) =>
        dateStringFromDate(date, localize, locale),
    dateAndTimeStringFromDate:
      ({ localize, locale }: any) =>
      (date: Date) =>
        dateAndTimeStringFromDate(date, localize, locale),
    openingHoursString:
      ({ localize }: any) =>
      (
        weekDayFrom: string,
        weekDayTo: string,
        saturdayFrom: string,
        saturdayTo: string,
        sundayFrom: string,
        sundayTo: string,
        short?: boolean,
        date?: Date
      ) =>
        openingHoursString(
          localize,
          weekDayFrom,
          weekDayTo,
          saturdayFrom,
          saturdayTo,
          sundayFrom,
          sundayTo,
          short,
          date
        ),
    formatMmToCm: () => (value?: number) => formatMmToCm(value),
    formatKgToTone: () => (value?: number) => formatKgToTone(value),
    msToHMS:
      ({ localize }: any) =>
      (ms: number, showSeconds: boolean = false) =>
        msToHMS(ms, localize, showSeconds),
    msToHour:
      ({ localize }: any) =>
      (ms: number) =>
        msToHour(ms, localize),
  } as any),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mapProps(({ locale, dispatch, ...props }: any) => ({ ...props }))
);
export default withFormatters;
