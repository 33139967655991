import TYPES from '../../../../@types/redux/store/DeeplinkTypes';
import { Dispatch } from '../../../../@types';

export const setDeeplinkUrl = (deeplinkUrl: string) => (dispatch: Dispatch) => {
  if (deeplinkUrl && deeplinkUrl.replace('/', '') === 'map') return;
  dispatch({
    type: TYPES.SET_DEEPLINK_URL,
    payload: {
      deeplinkUrl,
    },
  });
};
