import getHomeWalkthrogh1 from './GET/homeWalkthrogh1.png';
import getHomeWalkthrogh2 from './GET/homeWalkthrogh2.png';
import getDashboardImage from './GET/dashboardImage.png';
import getLogo from './GET/logo.png';
import getDimensionsCarousel from './GET/dimensionsCarousel.png';
import getCarSize from './GET/carSize.png';
import getShieldActive from './GET/shieldActive.png';
import getMapProfile from './GET/mapProfile.png';
import getMapHelp from './GET/mapHelp.png';
import getMapNavigate from './GET/mapNavigate.png';
import getAvailableMapMarker from './GET/availableCarMarker.png';
import getQR from './GET/QR.png';
import getNavLogo from './GET/navLogo.png';

import robHomeWalkthrogh1 from './ROB/homeWalkthrogh1.png';
import robHomeWalkthrogh2 from './ROB/homeWalkthrogh2.png';
import robDashboardImage from './ROB/dashboardImage.png';
import robLogo from './ROB/logo.png';
import robDimensionsCarousel from './ROB/dimensionsCarousel.png';
import robCarSize from './ROB/carSize.png';
import robShieldActive from './ROB/shieldActive.png';
import robMapProfile from './ROB/mapProfile.png';
import robMapHelp from './ROB/mapHelp.png';
import robMapNavigate from './ROB/mapNavigate.png';
import robAvailableMapMarker from './ROB/availableCarMarker.png';
import robQR from './ROB/QR.png';
import robNavLogo from './ROB/navLogo.png';

import ubqHomeWalkthrogh1 from './UBQ/homeWalkthrogh1.webp';
import ubqHomeWalkthrogh2 from './UBQ/homeWalkthrogh2.webp';
import ubqDashboardImage from './UBQ/dashboardImage.png';
import ubqLogo from './UBQ/logo.png';
import ubqDimensionsCarousel from './UBQ/dimensionsCarousel.png';
import ubqCarSize from './UBQ/carSize.png';
import ubqShieldActive from './UBQ/shieldActive.png';
import ubqMapProfile from './UBQ/mapProfile.png';
import ubqMapHelp from './UBQ/mapHelp.png';
import ubqMapNavigate from './UBQ/mapNavigate.png';
import ubqAvailableMapMarker from './UBQ/availableCarMarker.png';
import ubqQR from './UBQ/QR.png';
import ubqNavLogo from './UBQ/navLogo.webp';

import msmHomeWalkthrogh1 from './MSM/homeWalkthrogh1.png';
import msmHomeWalkthrogh2 from './MSM/homeWalkthrogh2.png';
import msmDashboardImage from './MSM/dashboardImage.png';
import msmLogo from './MSM/logo.png';
import msmDimensionsCarousel from './MSM/dimensionsCarousel.png';
import msmCarSize from './MSM/carSize.png';
import msmShieldActive from './MSM/shieldActive.png';
import msmMapProfile from './MSM/mapProfile.png';
import msmMapHelp from './MSM/mapHelp.png';
import msmMapNavigate from './MSM/mapNavigate.png';
import msmAvailableMapMarker from './MSM/availableCarMarker.png';
import msmQR from './MSM/QR.png';
import msmNavLogo from './MSM/navLogo.png';

import mbaHomeWalkthrogh1 from './MBA/homeWalkthrogh1.png';
import mbaHomeWalkthrogh2 from './MBA/homeWalkthrogh2.png';
import mbaDashboardImage from './MBA/dashboardImage.png';
import mbaLogo from './MBA/logo.png';
import mbaDimensionsCarousel from './MBA/dimensionsCarousel.png';
import mbaCarSize from './MBA/carSize.png';
import mbaShieldActive from './MBA/shieldActive.png';
import mbaMapProfile from './MBA/mapProfile.png';
import mbaMapHelp from './MBA/mapHelp.png';
import mbaMapNavigate from './MBA/mapNavigate.png';
import mbaAvailableMapMarker from './MBA/availableCarMarker.png';
import mbaQR from './MBA/QR.png';
import mbaNavLogo from './MBA/navLogo.png';

import bndHomeWalkthrogh1 from './BND/homeWalkthrogh1.png';
import bndHomeWalkthrogh2 from './BND/homeWalkthrogh2.png';
import bndDashboardImage from './BND/dashboardImage.png';
import bndLogo from './BND/logo.png';
import bndDimensionsCarousel from './BND/dimensionsCarousel.png';
import bndCarSize from './BND/carSize.png';
import bndShieldActive from './BND/shieldActive.png';
import bndMapProfile from './BND/mapProfile.png';
import bndMapHelp from './BND/mapHelp.png';
import bndMapNavigate from './BND/mapNavigate.png';
import bndAvailableMapMarker from './BND/availableCarMarker.png';
import bndQR from './BND/QR.png';
import bndNavLogo from './BND/navLogo.webp';

import e2eHomeWalkthrogh1 from './E2E/homeWalkthrogh1.png';
import e2eHomeWalkthrogh2 from './E2E/homeWalkthrogh2.png';
import e2eDashboardImage from './E2E/dashboardImage.png';
import e2eLogo from './E2E/logo.png';
import e2eDimensionsCarousel from './E2E/dimensionsCarousel.png';
import e2eCarSize from './E2E/carSize.png';
import e2eShieldActive from './E2E/shieldActive.png';
import e2eMapProfile from './E2E/mapProfile.png';
import e2eMapHelp from './E2E/mapHelp.png';
import e2eMapNavigate from './E2E/mapNavigate.png';
import e2eAvailableMapMarker from './E2E/availableCarMarker.png';
import e2eQR from './E2E/QR.png';
import e2eNavLogo from './E2E/navLogo.webp';

import tlaHomeWalkthrogh1 from './TLA/homeWalkthrogh1.png';
import tlaHomeWalkthrogh2 from './TLA/homeWalkthrogh2.png';
import tlaDashboardImage from './TLA/dashboardImage.png';
import tlaLogo from './TLA/logo.png';
import tlaDimensionsCarousel from './TLA/dimensionsCarousel.png';
import tlaCarSize from './TLA/carSize.png';
import tlaShieldActive from './TLA/shieldActive.png';
import tlaMapProfile from './TLA/mapProfile.png';
import tlaMapHelp from './TLA/mapHelp.png';
import tlaMapNavigate from './TLA/mapNavigate.png';
import tlaAvailableMapMarker from './TLA/availableCarMarker.png';
import tlaQR from './TLA/QR.png';
import tlaNavLogo from './TLA/navLogo.png';

import ctfHomeWalkthrogh1 from './CTF/homeWalkthrogh1.png';
import ctfHomeWalkthrogh2 from './CTF/homeWalkthrogh2.png';
import ctfDashboardImage from './CTF/dashboardImage.png';
import ctfLogo from './CTF/logo.png';
import ctfDimensionsCarousel from './CTF/dimensionsCarousel.png';
import ctfCarSize from './CTF/carSize.png';
import ctfShieldActive from './CTF/shieldActive.png';
import ctfMapProfile from './CTF/mapProfile.png';
import ctfMapHelp from './CTF/mapHelp.png';
import ctfMapNavigate from './CTF/mapNavigate.png';
import ctfAvailableMapMarker from './CTF/availableCarMarker.png';
import ctfQR from './CTF/QR.png';
import ctfNavLogo from './CTF/navLogo.png';

import mvvHomeWalkthrogh1 from './MVV/homeWalkthrogh1.png';
import mvvHomeWalkthrogh2 from './MVV/homeWalkthrogh2.png';
import mvvDashboardImage from './MVV/dashboardImage.png';
import mvvLogo from './MVV/logo.svg';
import mvvDimensionsCarousel from './MVV/dimensionsCarousel.png';
import mvvCarSize from './MVV/carSize.png';
import mvvShieldActive from './MVV/shieldActive.png';
import mvvMapProfile from './MVV/mapProfile.png';
import mvvMapHelp from './MVV/mapHelp.png';
import mvvMapNavigate from './MVV/mapNavigate.png';
import mvvAvailableMapMarker from './MVV/availableCarMarker.png';
import mvvQR from './MVV/QR.png';
import mvvNavLogo from './MVV/navLogo.png';

import frmHomeWalkthrogh1 from './FRM/homeWalkthrogh1.png';
import frmHomeWalkthrogh2 from './FRM/homeWalkthrogh2.png';
import frmDashboardImage from './FRM/dashboardImage.png';
import frmLogo from './FRM/logo.svg';
import frmDimensionsCarousel from './FRM/dimensionsCarousel.png';
import frmCarSize from './FRM/carSize.png';
import frmShieldActive from './FRM/shieldActive.png';
import frmMapProfile from './FRM/mapProfile.png';
import frmMapHelp from './FRM/mapHelp.png';
import frmMapNavigate from './FRM/mapNavigate.png';
import frmAvailableMapMarker from './FRM/availableCarMarker.png';
import frmQR from './FRM/QR.png';
import frmNavLogo from './FRM/navLogo.png';

import spsHomeWalkthrogh1 from './SPS/homeWalkthrogh1.png';
import spsHomeWalkthrogh2 from './SPS/homeWalkthrogh2.png';
import spsDashboardImage from './SPS/dashboardImage.png';
import spsLogo from './SPS/logo.png';
import spsDimensionsCarousel from './SPS/dimensionsCarousel.png';
import spsCarSize from './SPS/carSize.png';
import spsShieldActive from './SPS/shieldActive.png';
import spsMapProfile from './SPS/mapProfile.png';
import spsMapHelp from './SPS/mapHelp.png';
import spsMapNavigate from './SPS/mapNavigate.png';
import spsAvailableMapMarker from './SPS/availableCarMarker.png';
import spsQR from './SPS/QR.png';
import spsNavLogo from './SPS/navLogo.png';

import grfHomeWalkthrogh1 from './GRF/homeWalkthrogh1.png';
import grfHomeWalkthrogh2 from './GRF/homeWalkthrogh2.png';
import grfDashboardImage from './GRF/dashboardImage.png';
import grfLogo from './GRF/logo.png';
import grfDimensionsCarousel from './GRF/dimensionsCarousel.png';
import grfCarSize from './GRF/carSize.png';
import grfShieldActive from './GRF/shieldActive.png';
import grfMapProfile from './GRF/mapProfile.png';
import grfMapHelp from './GRF/mapHelp.png';
import grfMapNavigate from './GRF/mapNavigate.png';
import grfAvailableMapMarker from './GRF/availableCarMarker.png';
import grfQR from './GRF/QR.png';
import grfNavLogo from './GRF/navLogo.png';

const images = {
  GET: {
    homeWalkthrogh1: getHomeWalkthrogh1,
    homeWalkthrogh2: getHomeWalkthrogh2,
    dashboardImage: getDashboardImage,
    logo: getLogo,
    dimensionsCarousel: getDimensionsCarousel,
    carSize: getCarSize,
    shieldActive: getShieldActive,
    mapProfile: getMapProfile,
    mapHelp: getMapHelp,
    mapNavigate: getMapNavigate,
    availableMapMarker: getAvailableMapMarker,
    QR: getQR,
    navLogo: getNavLogo,
  },

  ROB: {
    homeWalkthrogh1: robHomeWalkthrogh1,
    homeWalkthrogh2: robHomeWalkthrogh2,
    dashboardImage: robDashboardImage,
    logo: robLogo,
    dimensionsCarousel: robDimensionsCarousel,
    carSize: robCarSize,
    shieldActive: robShieldActive,
    mapProfile: robMapProfile,
    mapHelp: robMapHelp,
    mapNavigate: robMapNavigate,
    availableMapMarker: robAvailableMapMarker,
    QR: robQR,
    navLogo: robNavLogo,
  },

  UBQ: {
    homeWalkthrogh1: ubqHomeWalkthrogh1,
    homeWalkthrogh2: ubqHomeWalkthrogh2,
    dashboardImage: ubqDashboardImage,
    logo: ubqLogo,
    dimensionsCarousel: ubqDimensionsCarousel,
    carSize: ubqCarSize,
    shieldActive: ubqShieldActive,
    mapProfile: ubqMapProfile,
    mapHelp: ubqMapHelp,
    mapNavigate: ubqMapNavigate,
    availableMapMarker: ubqAvailableMapMarker,
    QR: ubqQR,
    navLogo: ubqNavLogo,
  },

  MSM: {
    homeWalkthrogh1: msmHomeWalkthrogh1,
    homeWalkthrogh2: msmHomeWalkthrogh2,
    dashboardImage: msmDashboardImage,
    logo: msmLogo,
    dimensionsCarousel: msmDimensionsCarousel,
    carSize: msmCarSize,
    shieldActive: msmShieldActive,
    mapProfile: msmMapProfile,
    mapHelp: msmMapHelp,
    mapNavigate: msmMapNavigate,
    availableMapMarker: msmAvailableMapMarker,
    QR: msmQR,
    navLogo: msmNavLogo,
  },

  MBA: {
    homeWalkthrogh1: mbaHomeWalkthrogh1,
    homeWalkthrogh2: mbaHomeWalkthrogh2,
    dashboardImage: mbaDashboardImage,
    logo: mbaLogo,
    dimensionsCarousel: mbaDimensionsCarousel,
    carSize: mbaCarSize,
    shieldActive: mbaShieldActive,
    mapProfile: mbaMapProfile,
    mapHelp: mbaMapHelp,
    mapNavigate: mbaMapNavigate,
    availableMapMarker: mbaAvailableMapMarker,
    QR: mbaQR,
    navLogo: mbaNavLogo,
  },

  BND: {
    homeWalkthrogh1: bndHomeWalkthrogh1,
    homeWalkthrogh2: bndHomeWalkthrogh2,
    dashboardImage: bndDashboardImage,
    logo: bndLogo,
    dimensionsCarousel: bndDimensionsCarousel,
    carSize: bndCarSize,
    shieldActive: bndShieldActive,
    mapProfile: bndMapProfile,
    mapHelp: bndMapHelp,
    mapNavigate: bndMapNavigate,
    availableMapMarker: bndAvailableMapMarker,
    QR: bndQR,
    navLogo: bndNavLogo,
  },

  E2E: {
    homeWalkthrogh1: e2eHomeWalkthrogh1,
    homeWalkthrogh2: e2eHomeWalkthrogh2,
    dashboardImage: e2eDashboardImage,
    logo: e2eLogo,
    dimensionsCarousel: e2eDimensionsCarousel,
    carSize: e2eCarSize,
    shieldActive: e2eShieldActive,
    mapProfile: e2eMapProfile,
    mapHelp: e2eMapHelp,
    mapNavigate: e2eMapNavigate,
    availableMapMarker: e2eAvailableMapMarker,
    QR: e2eQR,
    navLogo: e2eNavLogo,
  },

  FRM: {
    homeWalkthrogh1: frmHomeWalkthrogh1,
    homeWalkthrogh2: frmHomeWalkthrogh2,
    dashboardImage: frmDashboardImage,
    logo: frmLogo,
    dimensionsCarousel: frmDimensionsCarousel,
    carSize: frmCarSize,
    shieldActive: frmShieldActive,
    mapProfile: frmMapProfile,
    mapHelp: frmMapHelp,
    mapNavigate: frmMapNavigate,
    availableMapMarker: frmAvailableMapMarker,
    QR: frmQR,
    navLogo: frmNavLogo,
  },

  MVV: {
    homeWalkthrogh1: mvvHomeWalkthrogh1,
    homeWalkthrogh2: mvvHomeWalkthrogh2,
    dashboardImage: mvvDashboardImage,
    logo: mvvLogo,
    dimensionsCarousel: mvvDimensionsCarousel,
    carSize: mvvCarSize,
    shieldActive: mvvShieldActive,
    mapProfile: mvvMapProfile,
    mapHelp: mvvMapHelp,
    mapNavigate: mvvMapNavigate,
    availableMapMarker: mvvAvailableMapMarker,
    QR: mvvQR,
    navLogo: mvvNavLogo,
  },

  TLA: {
    homeWalkthrogh1: tlaHomeWalkthrogh1,
    homeWalkthrogh2: tlaHomeWalkthrogh2,
    dashboardImage: tlaDashboardImage,
    logo: tlaLogo,
    dimensionsCarousel: tlaDimensionsCarousel,
    carSize: tlaCarSize,
    shieldActive: tlaShieldActive,
    mapProfile: tlaMapProfile,
    mapHelp: tlaMapHelp,
    mapNavigate: tlaMapNavigate,
    availableMapMarker: tlaAvailableMapMarker,
    QR: tlaQR,
    navLogo: tlaNavLogo,
  },

  CTF: {
    homeWalkthrogh1: ctfHomeWalkthrogh1,
    homeWalkthrogh2: ctfHomeWalkthrogh2,
    dashboardImage: ctfDashboardImage,
    logo: ctfLogo,
    dimensionsCarousel: ctfDimensionsCarousel,
    carSize: ctfCarSize,
    shieldActive: ctfShieldActive,
    mapProfile: ctfMapProfile,
    mapHelp: ctfMapHelp,
    mapNavigate: ctfMapNavigate,
    availableMapMarker: ctfAvailableMapMarker,
    QR: ctfQR,
    navLogo: ctfNavLogo,
  },

  SPS: {
    homeWalkthrogh1: spsHomeWalkthrogh1,
    homeWalkthrogh2: spsHomeWalkthrogh2,
    dashboardImage: spsDashboardImage,
    logo: spsLogo,
    dimensionsCarousel: spsDimensionsCarousel,
    carSize: spsCarSize,
    shieldActive: spsShieldActive,
    mapProfile: spsMapProfile,
    mapHelp: spsMapHelp,
    mapNavigate: spsMapNavigate,
    availableMapMarker: spsAvailableMapMarker,
    QR: spsQR,
    navLogo: spsNavLogo,
  },

  GRF: {
    homeWalkthrogh1: grfHomeWalkthrogh1,
    homeWalkthrogh2: grfHomeWalkthrogh2,
    dashboardImage: grfDashboardImage,
    logo: grfLogo,
    dimensionsCarousel: grfDimensionsCarousel,
    carSize: grfCarSize,
    shieldActive: grfShieldActive,
    mapProfile: grfMapProfile,
    mapHelp: grfMapHelp,
    mapNavigate: grfMapNavigate,
    availableMapMarker: grfAvailableMapMarker,
    QR: grfQR,
    navLogo: grfNavLogo,
  },
};

const {
  homeWalkthrogh1,
  homeWalkthrogh2,
  dashboardImage,
  logo,
  dimensionsCarousel,
  carSize,
  shieldActive,
  mapProfile,
  mapHelp,
  mapNavigate,
  availableMapMarker,
  QR,
  navLogo,
} =
  // @ts-ignore
  images[process.env.REACT_APP_THEME];

export {
  homeWalkthrogh1,
  homeWalkthrogh2,
  dashboardImage,
  logo,
  dimensionsCarousel,
  carSize,
  shieldActive,
  mapProfile,
  mapHelp,
  mapNavigate,
  availableMapMarker,
  QR,
  navLogo,
};
