import * as React from 'react';
import './Message.less';
import BrandIcon from '../BrandIcon/BrandIcon';

type MessageProps = {
  message: string;
  type: string;
  onDismissNotification: () => void;
  isKeyboardOpened: boolean;
};

const Message = ({
  message,
  type = 'INFO',
  isKeyboardOpened,
  onDismissNotification = () => {},
}: MessageProps) => {
  const onPressDismiss = () => {
    onDismissNotification();
  };

  return (
    <div
      className={
        type === 'ERROR'
          ? 'notification-container-error'
          : 'notification-container-ok'
      }
      style={isKeyboardOpened ? { bottom: 'unset', top: 'calc(15%)' } : {}}
    >
      {type === 'ERROR' ? (
        <img
          alt="notOkIcon"
          src="/images/notOkIcon.png"
          className="notOkIcon"
        />
      ) : (
        <BrandIcon name="okIcon" style={{ width: '30px', height: '30px' }} />
      )}
      <div
        className={
          type === 'ERROR' ? 'notification-msg-error' : 'notification-msg-ok'
        }
      >
        {message}
      </div>
      <img
        alt="closeIconBlack"
        src="/images/closeIconBlack.png"
        className="closeIconBlack"
        onClick={onPressDismiss}
      />
    </div>
  );
};

export default Message;
