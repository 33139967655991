import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/PricingTypes';
import { PricingAction, PricingState } from '../../../@types';

export default (
  state: PricingState = initialState,
  action: PricingAction
): PricingState => {
  switch (action.type) {
    case TYPES.SET_CAR_PRICE_DETAILS: {
      return { ...state, [action.payload.carId]: action.payload.priceDetails };
    }

    case TYPES.CLEAR_CAR_PRICE_DETAILS: {
      return {
        initialState,
      };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions
      return state;
    }
  }
};
