import React from 'react';
import './AddSepaView.less';
import { FormikValues } from 'formik';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import {
  isLocalizeAdded,
  LocalizePropType,
  UserDataOutput,
} from 'getaway-data-layer';
import { GA_TITLES, isDashboardScreen } from '../../../../../utils';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  formik: FormikValues;
  user: UserDataOutput;
  onClose: () => void;
  expanded: boolean;
  onIbanChanged: (value: string) => void;
  onExpandPressed: (value: boolean) => void;
  supportedPayments: any;
};

const AddSepaView = ({
  localize,
  formik,
  user,
  onClose,
  expanded,
  supportedPayments,
  onIbanChanged,
  onExpandPressed,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('firstSteps.payment.sepa.title')}
        description={localize('firstSteps.payment.sepa.instruction')}
        showHelp
        showArrowBack={
          isDashboardScreen() ||
          (supportedPayments?.length === 1 && user?.schufaCheckRequired)
        }
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'sepa.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'sepa.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            <TextField
              required
              fullWidth
              id="name"
              className="form-input"
              label={localize('firstSteps.payment.sepa.name.placeholder')}
              helperText={(formik.touched.name && formik.errors.name) || ''}
              error={formik.touched.name && Boolean(formik.errors.name)}
              variant="standard"
              autoComplete="name"
              autoFocus
              type="text"
              disabled={user?.firstName && user?.lastName}
              {...formik.getFieldProps('name')}
            />
            <TextField
              required
              fullWidth
              id="iban"
              className="form-input"
              label={localize('firstSteps.payment.sepa.iban.placeholder')}
              helperText={(formik.touched.iban && formik.errors.iban) || ''}
              error={formik.touched.iban && Boolean(formik.errors.iban)}
              variant="standard"
              autoComplete="iban"
              type="text"
              {...formik.getFieldProps('iban')}
              onChange={onIbanChanged}
            />
            <div className="add-sepa-text">
              {`${localize('firstSteps.payment.sepa.description.text').slice(
                0,
                expanded
                  ? localize('firstSteps.payment.sepa.description.text').length
                  : 120
              )}${expanded ? '' : '...'}`}
            </div>
            {!expanded ? (
              <div
                className="add-sepa-expand-text"
                onClick={() => onExpandPressed(true)}
              >
                {localize('firstSteps.payment.sepa.url.text')}
              </div>
            ) : (
              <div
                className="add-sepa-expand-text"
                onClick={() => onExpandPressed(false)}
              >
                {localize('less')}
              </div>
            )}
            <div>
              <img
                className="add-sepa-logo"
                src="/images/trustLogos.png"
                alt="add-sepa-logo"
              />
            </div>
            <BrandButton
              id={GA_TITLES.ON_BOARDING.ADD_SEPA}
              title={localize('firstSteps.payment.sepa.submit.text')}
              formik={formik}
            />
          </Box>
        </div>
        {isLocalizeAdded(localize, 'sepa.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('sepa.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default AddSepaView;
