import React, { useEffect, useState } from 'react';
import {
  withTextLocalizer,
  getUser,
  LocalizePropType,
  isServicesTabEnabled,
  isKYCApp,
  selectUser,
  getTransactionPaymentLink,
  getTransactions,
  selectUserTransactions,
} from 'getaway-data-layer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProfileView from './ProfileView';
import {
  GA_TITLES,
  isIncompleteProfile,
  isMobileBrowser,
  navigateTo,
  reportGAEvent,
} from '../../../../utils';
import { PROFILE_TABS } from '../../../../enums/Profile';

type Props = {
  localize: LocalizePropType;
};

const ProfileContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const transactions = useSelector(selectUserTransactions);
  const openTransactions =
    transactions?.transactions?.filter(
      (transaction) => transaction.status === 'open'
    ) || [];

  const inCompleteProfile = isIncompleteProfile(user);
  const onboardingOnlyProduct = isKYCApp();
  const readOnlyMode = !inCompleteProfile && onboardingOnlyProduct;
  const isServicesLink = history?.location?.pathname?.includes('/services');
  const [activeTab, setActiveTab] = useState(
    readOnlyMode || isServicesLink
      ? PROFILE_TABS.SERVICES
      : PROFILE_TABS.ACCOUNT
  );

  const onClose = () => {
    navigateTo(history, isMobileBrowser() ? '/map' : '/');
  };

  const onClickOpenInvoice = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const onClickPayOpenInvoice = (transactionId: number) => {
    dispatch(
      getTransactionPaymentLink(transactionId, (payByLinkData) => {
        if (payByLinkData?.url) {
          window.open(payByLinkData?.url, '_blank');
        }
      })
    );
  };

  useEffect(() => {
    dispatch(getUser());
    dispatch(getTransactions());
    reportGAEvent(GA_TITLES.DASHBOARD.PROFILE);
  }, []);

  return (
    <ProfileView
      localize={localize}
      user={user}
      openTransactions={openTransactions}
      onClose={onClose}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      enableServicesTab={isServicesTabEnabled()}
      onClickOpenInvoice={onClickOpenInvoice}
      onClickPayOpenInvoice={onClickPayOpenInvoice}
    />
  );
};

export default withTextLocalizer(ProfileContainer);
