import TYPES from '../../../../@types/redux/store/DimensionsTypes';
import { PublishHeaderHeight } from '../../../../@types';

export const publishHeaderHeight = (
  headerHeight: number
): PublishHeaderHeight => ({
  type: TYPES.PUBLISH_HEADER_HEIGHT,
  payload: {
    // In android we get a lot of values after coma...
    headerHeight: Number(headerHeight.toFixed()),
  },
});
