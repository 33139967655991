import {
  format,
  isYesterday,
  isToday,
  isTomorrow,
  isThisYear,
  setMinutes,
  setHours,
  setSeconds,
} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
export type TimeDetails = {
  days: number;
  hours: number;
  minutes: number;
};

const datefnsLocale = (locale: string = 'de') => {
  if (locale.startsWith('de')) return deLocale;
  return enLocale;
};

export const formattedDurationFromMinutes = (minutes: number): string => {
  const helperDate = setSeconds(
    setMinutes(setHours(new Date(), 0), minutes),
    0
  );
  return format(helperDate, minutes > 60 ? 'HH:mm' : 'm');
};
export const timeStringFromDate = (date: Date) => `${format(date, 'HH:mm')}`;
export const dayString = (day: number, localize: any): string =>
  `${day} ${localize(day === 1 ? 'day' : 'days')}`;
export const shortHoursString = (hours: number, localize: any): string =>
  `${hours} ${localize('hours.short')}`;
export const dateAndTimeStringFromDate = (
  date: Date,
  localize: any,
  locale: string = 'de'
) => {
  const formattedTime = format(date, 'HH:mm');

  if (isToday(date)) {
    return `${localize('today')}, ${formattedTime}`;
  }

  if (isTomorrow(date)) {
    return `${localize('tomorrow')}, ${formattedTime}`;
  }

  if (isYesterday(date)) {
    return `${localize('yesterday')}, ${formattedTime}`;
  }

  if (isThisYear(date)) {
    if (locale.startsWith('de')) {
      return `${format(date, 'dd MMM', {
        locale: datefnsLocale(locale),
      })}, ${timeStringFromDate(date)}`;
    }

    return `${format(date, 'dd MMM', {
      locale: datefnsLocale(locale),
    })}, ${timeStringFromDate(date)}`;
  }

  if (locale.startsWith('de')) {
    return `${format(date, 'dd MMM yyyy', {
      locale: datefnsLocale(locale),
    })}, ${timeStringFromDate(date)}`;
  }

  return `${format(date, 'dd MMM yyyy', {
    locale: datefnsLocale(locale),
  })}, ${timeStringFromDate(date)}`;
};
export const dateStringFromDate = (
  date: Date,
  localize: any,
  locale: string = 'de'
) => {
  if (isThisYear(date)) {
    if (locale.startsWith('de')) {
      return `${format(date, 'dd MMM', {
        locale: datefnsLocale(locale),
      })}`;
    }

    return `${format(date, 'dd MMM', {
      locale: datefnsLocale(locale),
    })}`;
  }

  if (locale.startsWith('de')) {
    return `${format(date, 'dd MMM yyyy', {
      locale: datefnsLocale(locale),
    })}`;
  }

  return `${format(date, 'dd MMM yyyy', {
    locale: datefnsLocale(locale),
  })}`;
};
export const formatMonthFromDate = (
  date: Date,
  short: boolean = false,
  locale: string = 'de'
): string => {
  if (short) {
    return format(date, 'MMM', {
      locale: datefnsLocale(locale),
    });
  }

  return format(date, 'MMMM', {
    locale: datefnsLocale(locale),
  });
};
export const formatMonthYearFromDate = (
  date: Date,
  short: boolean = false,
  locale: string = 'de'
): string => {
  if (short) {
    return format(date, 'MMM yyyy', {
      locale: datefnsLocale(locale),
    });
  }

  return format(date, 'MMMM yyyy', {
    locale: datefnsLocale(locale),
  });
};
export const msToMHSObject = (ms: number): TimeDetails => {
  let seconds = ms / 1000;
  const days = parseInt(String(seconds / (24 * 3600)), 10);
  seconds %= 24 * 3600;
  const hours = parseInt(String(seconds / 3600), 10);
  seconds %= 3600;
  const minutes = parseInt(String(seconds / 60), 10);
  return {
    days,
    hours,
    minutes,
  };
};
export const msToHMS = (
  ms: number,
  localize: any,
  showSeconds: boolean = false
) => {
  let seconds = ms / 1000;
  const days = parseInt(String(seconds / (24 * 3600)), 10);
  seconds %= 24 * 3600;
  const hours = parseInt(String(seconds / 3600), 10);
  seconds %= 3600;
  const minutes = parseInt(String(seconds / 60), 10);

  if (days === 0 && hours === 0) {
    return `${minutes !== 0 ? minutes : 1} ${localize('minute.short')} ${
      showSeconds
        ? `${((ms % 60000) / 1000).toFixed(0)} ${localize('seconds.short')}`
        : ''
    }`;
  }

  if (days === 0 && hours !== 0) {
    return `${hours} ${localize('hours.short')} ${minutes} ${localize(
      'minute.short'
    )}`;
  }

  if (days !== 0 && hours === 0) {
    return `${days} ${
      days > 1 ? localize('days.short') : localize('day.short')
    } ${minutes} ${localize('minute.short')}`;
  }

  return `${days} ${
    days > 1 ? localize('days.short') : localize('day.short')
  } ${hours} ${localize('hours.short')}`;
};
export const msToHour = (ms: number, localize: any) => {
  let seconds = ms / 1000;
  const days = parseInt(String(seconds / (24 * 3600)), 10);
  seconds %= 24 * 3600;
  const hours = parseInt(String(seconds / 3600), 10);

  if (days === 0 && hours === 0) {
    return `1 ${localize('hours.short')}`;
  }

  if (days === 0 && hours !== 0) {
    return `${hours} ${localize('hours.short')}`;
  }

  if (days !== 0 && hours === 0) {
    return `${days} ${
      days > 1 ? localize('days.short') : localize('day.short')
    }`;
  }

  return `${days} ${
    days > 1 ? localize('days.short') : localize('day.short')
  } ${hours} ${localize('hours.short')}`;
};
