import distance from '@turf/distance';
import centroid from '@turf/centroid';
import inside from 'point-in-polygon';
import { locationFromString } from './locationFromString';
import { AreaData, FuelStationData, Location } from '../@types';
import { polygon } from '@turf/helpers';

export const nearestGasStation = (
  currentLocation: any,
  gasStations: Array<FuelStationData>
): any => {
  const distances = gasStations
    .map((gasStation) => locationFromString(gasStation.location))
    .map((target) =>
      distance(
        [
          currentLocation?.latitude || currentLocation?.lat,
          currentLocation?.longitude || currentLocation.lng,
        ],
        [target.latitude, target.longitude]
      )
    );
  return gasStations[distances.indexOf(Math.min(...distances))];
};

export const isInsidePolygon = (
  target: Location,
  targetPolygon: Array<Location>
): boolean => {
  if (target && targetPolygon) {
    const targetCoordinate = [target.latitude, target.longitude];
    const polygonCoordinates = targetPolygon.map((location) => [
      location.latitude,
      location.longitude,
    ]);
    return inside(targetCoordinate, polygonCoordinates);
  }

  return true;
};

export const getAreaCoordinates = (area: AreaData): any => {
  if (area?.geoJSONData?.features) {
    return area?.geoJSONData.features[0]?.geometry?.coordinates?.map(
      (coordinate: any) =>
        locationFromString(coordinate.split(' ').reverse().join(','))
    );
  }
};

export const checkInCheckOutAreaCenter = (area: AreaData): any => {
  const coordinates = getAreaCoordinates(area);

  if (coordinates.length > 3) {
    const center = centroid(
      polygon([
        coordinates.map((coordinate: { latitude: any; longitude: any }) => [
          coordinate.latitude,
          coordinate.longitude,
        ]),
      ])
    );
    return {
      latitude: Number(center.geometry.coordinates[0]),
      longitude: Number(center.geometry.coordinates[1]),
    };
  }

  return {
    latitude: Number(coordinates[0].latitude),
    longitude: Number(coordinates[0].longitude),
  };
};
