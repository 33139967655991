import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import { Dispatch } from '../../../../@types';
import { CLIENT_TYPE } from '../../../../@types';
import { setNotification } from '../../ui/notifications';
import { APP } from '../../../../enums';
import { rentalClient } from '../../../clients/rentalClient';
import TYPES, {
  SetServiceTypes,
  ClearServiceTypes,
} from '../../../../@types/redux/store/ServiceTypeTypes';

export const setServiceTypes = (serviceTypes: string[]): SetServiceTypes => ({
  type: TYPES.SET_SERVICE_TYPES,
  payload: {
    serviceTypes,
  },
});
export const clearServiceTypes = (): ClearServiceTypes => ({
  type: TYPES.CLEAR_SERVICE_TYPES,
});
export const getServiceTypes =
  (rentalId: string, successCallbackFunction?: (services?: string[]) => any) =>
  async (dispatch: Dispatch) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.RENTAL_CLIENT.GET_SERVICE_TYPES));
    // $FlowFixMe
    const { data, notModified, error } = await rentalClient.getServiceTypes(
      rentalId
    );

    if (error) {
      dispatch(
        setNetworkError(CLIENT_TYPE.RENTAL_CLIENT.GET_SERVICE_TYPES, error)
      );
      dispatch(
        setNotification({
          message: 'backend.error',
          type: APP.NOTIFICATION_TYPE.ERROR,
        })
      );
    } else if (!!data?.serviceTypes && !notModified) {
      dispatch(setServiceTypes(data.serviceTypes));

      if (typeof successCallbackFunction === 'function') {
        successCallbackFunction(data.serviceTypes);
      }

      dispatch(setNetworkSuccess(CLIENT_TYPE.RENTAL_CLIENT.GET_SERVICE_TYPES));
    }
  };
