import React, { ReactNode, MouseEvent } from 'react';
import './LabeledListItem.less';

type PropsType = {
  label?: ReactNode;
  value: ReactNode;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onClick?: (e: MouseEvent) => void;
  locationType?: boolean;
  style?: any;
};

const LabeledListItem: React.FC<PropsType> = ({
  label,
  value,
  onMouseEnter,
  onMouseLeave,
  onClick,
  style = {},
}) => {
  return (
    <>
      <div
        className="list-item-wrapper"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        style={style}
      >
        {label && <p className="label">{label}</p>}
        <div className="value-wrapper">{value || './.'}</div>
      </div>
    </>
  );
};

export default LabeledListItem;
