import React from 'react';
import { FormikValues } from 'formik';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { LocalizePropType } from 'getaway-data-layer';
import './DynamicFormView.less';

type props = {
  localize: LocalizePropType;
  iframeUrl: string;
  formId: string;
  page: number;
  onClose: () => void;
};

const DynamicFormView = ({
  localize,
  iframeUrl,
  formId,
  page,
  onClose,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={formId ? localize(`form.${formId}.${page}.title`) : '..'}
        description={formId ? localize(`form.${formId}.${page}.desc`) : '..'}
        showHelp
        onClose={onClose}
      />
      <div className="screen-body">
        <iframe
          data-tally-src={iframeUrl}
          loading="lazy"
          width="100%"
          height="100%"
          frameBorder="0"
          // @ts-ignore
          marginHeight="0"
          // @ts-ignore
          marginWidth="0"
          title="POC Form"
        ></iframe>
      </div>
    </div>
  );
};

export default DynamicFormView;
