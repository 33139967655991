import { CarEquipmentData } from '../../Types.api';

export type CarEquipmentsState = CarEquipmentData[] | undefined;

enum CarEquipmentsTypes {
  SET_CAR_EQUIPMENTS = 'appData/carEquipments/SET_CAR_EQUIPMENTS',
}

export default CarEquipmentsTypes;

export type SetCarEquipments = {
  type: CarEquipmentsTypes.SET_CAR_EQUIPMENTS;
  payload: {
    carEquipments: CarEquipmentData[];
  };
};

export type CarEquipmentsAction = SetCarEquipments;
