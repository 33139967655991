export const getGasStationLogo = (brand: string) => {
  switch (brand) {
    case 'ARAL':
      return 'aralLogo';
    case 'TOTAL':
      return 'totalLogo';
    case 'Eni':
      return 'eniLogo';
    case 'Avanti':
      return 'avantiLogo';
    case 'Westfalen':
      return 'westfalenLogo';
    case 'OMV':
      return 'omvLogo';
    case 'BP':
      return 'bpLogo';
    case 'OM':
      return 'omvLogo';
    case 'AP':
      return 'apLogo';
    default:
      return 'aralLogo';
  }
};
