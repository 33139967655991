import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  getFleetInsurances,
  bookCar,
  cancelBooking,
  confirmBooking,
  setPreSignupBooking,
} from '../redux/store/userData/bookings/actions';
import {
  CLIENT_TYPE,
  OutputCarData,
  PreSignupBooking,
  State,
  UserDataOutput,
} from '../@types';

export type Props = {
  from: string;
  backBy: string;
  bookingCarInProgress: boolean;
  bookCar: typeof bookCar;
  getFleetInsurances: typeof getFleetInsurances;
  selectedCar: OutputCarData;
  bookings: any;
  cancelBooking: typeof cancelBooking;
  confirmBooking: typeof confirmBooking;
  user: UserDataOutput;
  preSignupBooking: PreSignupBooking;
  isLoadingEstimation: boolean;
  setPreSignupBooking: typeof setPreSignupBooking;
};
const withBookingConfirmation: any = compose(
  connect(
    ({
      carsSearch: { backBy, from },
      networkStatus: { activities },
      ui: {
        map: { selectedCar },
      },
      userData: {
        user: { user },
        bookings: { bookings, preSignupBooking },
      },
    }: State) => ({
      from,
      backBy,
      selectedCar,
      bookings,
      preSignupBooking,
      user,
      bookingCarInProgress: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.BOOK_CAR].includes(activityType)
      ),
      isLoadingEstimation: activities.some((activityType: string) =>
        [CLIENT_TYPE.BOOKING_CLIENT.ESTIMATE_COST].includes(activityType)
      ),
    }),
    {
      bookCar,
      getFleetInsurances,
      cancelBooking,
      confirmBooking,
      setPreSignupBooking,
    }
  )
);
export default withBookingConfirmation;
