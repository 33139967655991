import { getBrandName, isBND, isBrand, isTLA } from 'getaway-data-layer';
import { openRouteInGoogleMaps } from './mapUtils';
import { showAlertWithOptions } from './showAlert';
import config from '../env.config';
import { GA_TITLES, reportGAEvent } from './analytics.helpers';

export const showAboutDialog = (
  xBrandId: string,
  localize: any,
  title?: string
) => {
  const options = [
    {
      title: localize('dashboard.dialog.terms'),
      action: () => window.open(localize('app.terms.url'), '_blank'),
    },
    {
      title: localize('dashboard.dialog.privacy'),
      action: () => window.open(localize('app.privacy.url'), '_blank'),
    },
    {
      title: localize('dashboard.dialog.cookies'),
      action: () => window.open(localize('app.cookies.url'), '_blank'),
    },
  ];
  if (isBrand(xBrandId)) {
    options.push({
      title: localize('dashboard.dialog'),
      action: () =>
        window.open(
          localize(`dashboard.dialog.${getBrandName(xBrandId)}.url`),
          '_blank'
        ),
    });
  }
  options.push({
    title: localize('dashboard.dialog.getaway'),
    action: () =>
      window.open(localize('dashboard.dialog.getaway.url'), '_blank'),
  });

  showAlertWithOptions(title || localize('dashboard.about'), '', options);
};

export const showSignupTermsDialog = (localize: any) => {
  const options = [
    {
      title: localize('signup.terms.url.text'),
      action: () => window.open(localize('app.terms.url'), '_blank'),
    },
    {
      title: localize('signup.name.policy.text'),
      action: () => window.open(localize('app.privacy.url'), '_blank'),
    },
  ];

  showAlertWithOptions(localize('dashboard.about'), '', options);
};

export const showSignupTypeDialog = (localize: any) => {
  const options = [
    ...(isTLA()
      ? [
          {
            title: localize('signup.type.dialog.tla'),
            action: () => {
              reportGAEvent(GA_TITLES.AUTH.SIGN_UP_VIA_TLA_CLICKED);
              window.open(localize('signup.type.dialog.tla.link'));
            },
          },
        ]
      : []),
    ...(isBND()
      ? [
          {
            title: localize('signup.type.dialog.bnd'),
            action: () => {
              reportGAEvent(GA_TITLES.AUTH.SIGN_UP_VIA_BND_CLICKED);
              window.open(localize('signup.type.dialog.bnd.link'));
            },
          },
        ]
      : []),
    ...(isBND()
      ? [
          {
            title: localize('signup.type.dialog.cf'),
            action: () => {
              reportGAEvent(GA_TITLES.AUTH.SIGN_UP_VIA_CF_CLICKED);
              window.open(localize('signup.type.dialog.cf.link'));
            },
          },
        ]
      : []),
  ];

  showAlertWithOptions(localize('signup.type.dialog.title'), '', options);
};

export const openRouteDialog = (
  localize: any,
  location: any,
  address: string
) => {
  const options = [
    {
      title: localize('route.dialog.googlemaps'),
      action: () => {
        openRouteInGoogleMaps(location);
      },
    },
    {
      title: localize('route.dialog.copy'),
      action: () => {
        navigator.clipboard.writeText(address);
      },
    },
  ];
  showAlertWithOptions(address, '', options);
};
