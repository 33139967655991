import React from 'react';
import './TransactionItem.less';
import Divider from '@mui/material/Divider';

type Props = {
  leftValue: string;
  title: string;
  subTitle: string;
  value: string;
  isPositiveValue: boolean;
  showSeparator?: boolean;
};

const TransactionItem = ({
  leftValue,
  title,
  subTitle,
  value,
  isPositiveValue,
  showSeparator,
}: Props) => {
  return (
    <>
      <div className="transaction-item-container">
        <div className="transaction-item-left-value">{leftValue}</div>
        <div className="transaction-item-title-container">
          <div className="transaction-item-title">{title}</div>
          <div className="transaction-item-sub-title">{subTitle}</div>
        </div>
        <div
          className="transaction-item-value"
          style={isPositiveValue ? { color: '#0ab735' } : {}}
        >
          {value}
        </div>
      </div>
      {showSeparator && <Divider />}
    </>
  );
};

TransactionItem.defaultProps = {
  showSeparator: true,
};

export default TransactionItem;
