import React, { useMemo } from 'react';
import base64url from '../../../../../utils/base64/base64url';
import ChallengeView from './ChallengeView';

type Props = {
  challengeShopperData: any;
};

const ChallengeContainer = ({ challengeShopperData }: Props) => {
  const { threeDSServerTransID, acsTransID, messageVersion, acsURL } =
    challengeShopperData;

  const encodedcReq = useMemo(() => {
    const cReqData = {
      threeDSServerTransID,
      acsTransID,
      messageVersion,
      challengeWindowSize: '05',
      messageType: 'CReq',
    };
    const stringifiedDataObject = JSON.stringify(cReqData);
    return base64url.encode(stringifiedDataObject);
  }, [threeDSServerTransID, acsTransID, messageVersion]);

  return (
    <>
      <ChallengeView acsURL={acsURL} encodedcReq={encodedcReq} />
    </>
  );
};

export default ChallengeContainer;
