import React from 'react';
import './BookingHistoryItem.less';
import { Divider, ListItemButton } from '@mui/material';
import BrandIcon from '../BrandIcon/BrandIcon';

type Props = {
  leftValue: string;
  title: string;
  value: string;
  descriptionComponent: React.ReactElement;
  showSeparator?: boolean;
  onClick: () => void;
};

const BookingHistoryItem = ({
  leftValue,
  title,
  value,
  descriptionComponent,
  showSeparator,
  onClick,
}: Props) => {
  return (
    <>
      <ListItemButton className="booking-item-container" onClick={onClick}>
        <div className="booking-item-left-value">{leftValue}</div>
        <div className="booking-item-title-container">
          <div className="booking-item-title">{title}</div>
          {descriptionComponent}
        </div>
        <div className="booking-item-value">{value}</div>
        <BrandIcon
          name="arrowRight"
          style={{
            width: 14,
            height: 14,
            marginLeft: '10px',
          }}
        />
      </ListItemButton>
      {showSeparator && <Divider />}
    </>
  );
};

BookingHistoryItem.defaultProps = {
  showSeparator: true,
};

export default BookingHistoryItem;
