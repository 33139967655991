import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Drawer from '@mui/material/Drawer';
import {
  selectAvailableCars,
  selectSelectedCar,
  setSelectedCar,
  setMapContent,
  selectMapContent,
  setOpenedModal,
  DriverCarData,
  selectUser,
  locationFromString,
} from 'getaway-data-layer';
import SideCarouselItem from './SideCarouselItem';
import { useHistory } from 'react-router-dom';
import { sortCarsRelativeToPosition } from '../../../utils';

const SideCarousel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedCar) as any;
  const user = useSelector(selectUser);
  const searchCars = Object.values(useSelector(selectAvailableCars));
  const mapContent = useSelector(selectMapContent) as any;

  const [carouselCars, setCarouselCars] = useState([]);

  const onClickArrow = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  const onClickItem = (car: DriverCarData) => {
    dispatch(setSelectedCar(car));
    dispatch(
      // @ts-ignore
      setMapContent('availableCar', mapContent.cardType, car.id)
    );

    const params = new URLSearchParams(location.search);
    // @ts-ignore
    params.set('id', car?.id);
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    setCarouselCars([
      // @ts-ignore
      selectedCar,
      // @ts-ignore
      ...sortCarsRelativeToPosition(
        searchCars.filter(
          (item: any) =>
            selectedCar?.areaId === item.areaId &&
            item?.id !== selectedCar?.id &&
            item?.vehicleConnection !== 'geoTab'
        ),
        locationFromString(selectedCar?.position!)
      ),
    ]);
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!carouselCars?.find((car) => car?.id === selectedCar?.id)) {
      setCarouselCars([
        // @ts-ignore
        selectedCar,
        // @ts-ignore
        ...sortCarsRelativeToPosition(
          searchCars.filter(
            (item: any) =>
              selectedCar?.areaId === item.areaId &&
              item?.id !== selectedCar?.id &&
              item?.vehicleConnection !== 'geoTab'
          ),
          locationFromString(selectedCar?.position!)
        ),
      ]);
    }
  }, [selectedCar]);

  return carouselCars.length > 0 ? (
    <Drawer
      hideBackdrop
      variant="persistent"
      anchor="left"
      open={!!selectedCar}
      onClose={() => {}}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(255,255,255, 0.9)',
          height: user ? '100%' : 'calc(100% - 150px)',
          top: user ? '50%' : '51%',
          transform: 'translateY(-50%)',
          zIndex: 10,
        },
      }}
    >
      <div
        className="screen-container"
        style={{
          width: '400px',
          height: '100%',
          position: 'unset',
          backgroundColor: user ? 'transparent' : 'white',
          justifyContent: 'end',
        }}
      >
        <div
          className="screen-body"
          style={{
            padding: 16,
            width: 'unset',
            backgroundColor: user ? 'transparent' : 'white',
            paddingBottom: user ? 90 : 0,
            flex: 'unset',
          }}
        >
          {carouselCars.map((car: DriverCarData) => (
            <SideCarouselItem
              key={car.id}
              car={car}
              isSelected={car.id === selectedCar.id}
              onClickItem={onClickItem}
              onClickArrow={onClickArrow}
            />
          ))}
        </div>
      </div>
    </Drawer>
  ) : null;
};

export default SideCarousel;
