import React from 'react';
import './Card.less';

type Props = {
  hideShadow?: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  children: React.ReactNode;
  containerStyle?: any;
};

const Card = ({
  containerStyle,
  hideShadow,
  firstItem,
  lastItem,
  children,
}: Props) => {
  const className = [
    'card-container',
    firstItem ? 'card-first-item' : '',
    lastItem ? 'card-last-item' : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div
      className={className}
      style={hideShadow ? { boxShadow: 'none' } : containerStyle}
    >
      {children}
    </div>
  );
};

Card.defaultProps = {
  hideShadow: false,
  firstItem: false,
  lastItem: false,
  containerStyle: {},
};

export default Card;
