import Geocode from 'react-geocode';
import { isWeb } from './platformHelpers';

export const formatCoordinates = (latlng) => {
  let lat, lng;

  if (typeof latlng === 'string') {
    [lat, lng] = latlng.split(',');
  } else if (latlng?.latitude && latlng?.longitude) {
    lat = latlng.latitude;
    lng = latlng.longitude;
  } else if (latlng?.lat && latlng?.lng) {
    lat = latlng.lat;
    lng = latlng.lng;
  } else {
    return null;
  }

  const formattedLat = lat.toString().slice(0, 8);
  const formattedLng = lng.toString().slice(0, 8);

  return `${formattedLat},${formattedLng}`;
};

const getAddressStreetName = (address: Array<any>) => {
  const streetName = address.find((item) => item?.types?.includes('route'));
  return streetName?.short_name || streetName?.long_name || undefined;
};

const getAddressStreetNumber = (address: Array<any>) => {
  const streetNumber = address.find((item) =>
    item?.types?.includes('street_number')
  );
  return streetNumber?.short_name || streetNumber?.long_name || undefined;
};

export const addressFromCoordinates = async (
  coordinates: any,
  callback: (address: string) => void,
  googleApiAccessToken: string
) => {
  if (isWeb()) {
    if (coordinates?.latitude && coordinates?.longitude) {
      Geocode.setApiKey(googleApiAccessToken);
      Geocode.setLanguage('de');
      Geocode.fromLatLng(coordinates.latitude, coordinates.longitude).then(
        (response) => {
          if (response?.results?.length > 0) {
            const addressResults = response?.results?.map(
              (result: any) => result.address_components
            );
            const addressComponents = addressResults?.find((addressItem: any) =>
              addressItem.find((item: any) => item?.types.includes('route'))
            );
            const streetName = getAddressStreetName(addressComponents);
            const streetNumber = getAddressStreetNumber(addressComponents);
            callback(
              streetName
                ? `${streetName || ''} ${streetNumber || ''}`
                : 'Abholzone anzeigen'
            );
          } else {
            callback('Abholzone anzeigen');
          }
        }
      );
    }
  } else {
    // @ts-ignore
    const { default: Geocoder } = await import('react-native-geocoder');

    if (coordinates?.latitude && coordinates?.longitude) {
      Geocoder.fallbackToGoogle(googleApiAccessToken);
      Geocoder.geocodePosition({
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      }).then((res: any) => {
        if (res.length > 0 && res[0].streetName) {
          if (res[0].streetNumber) {
            callback(`${res[0].streetName} ${res[0].streetNumber}`);
          } else {
            callback(res[0].streetName);
          }
        } else {
          callback('undefined');
        }
      });
    }
  }
};
