import { textClient } from '../../../clients/textClient';
import {
  setNetworkActivity,
  setNetworkSuccess,
  setNetworkError,
} from '../../networkStatus';
import { CLIENT_TYPE } from '../../../../@types';
import TYPES from '../../../../@types/redux/store/TextsTypes';
import {
  SetTexts,
  Dispatch,
  GetState,
  TextGroup,
  Language,
} from '../../../../@types';
import {
  getBrandLanguage,
  isBrand,
  getBrandGroup,
} from '../../../../helpers/brandHelpers';

export const setTexts = (
  group: TextGroup,
  language: Language,
  texts: any
): SetTexts => ({
  type: TYPES.SET_TEXTS,
  payload: {
    group,
    language,
    texts,
  },
});
export const getTexts =
  (callback?: () => void) => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setNetworkActivity(CLIENT_TYPE.TEXT_CLIENT.GET));
    const { language: deviceLanguage } = getState().device;
    const { xBrandId } = getState().config;

    const textGroup = getBrandGroup(xBrandId);
    const language = isBrand(xBrandId)
      ? getBrandLanguage(xBrandId)
      : deviceLanguage;
    const { data, error } = await textClient.get(textGroup, language);

    if (error) {
      dispatch(setNetworkError(CLIENT_TYPE.TEXT_CLIENT.GET, error));
    } else {
      if (data?.texts) {
        dispatch(setTexts(textGroup, language, data.texts));
      }
      dispatch(setNetworkSuccess(CLIENT_TYPE.TEXT_CLIENT.GET));
    }

    if (typeof callback === 'function') {
      callback();
    }
  };
