import { State } from '../@types';
import { PaymentRequestData, RentalHistoryData, Transactions } from '../@types';

export const selectAuth = (store: State) => store.userData.user.basicAuth;

export const selectUser = (store: State) => store.userData.user.user;

export const selectUserTransactions = (store: State): Transactions =>
  store.userData.user?.transactions;

export const selectUserRentals = (
  store: State
): Array<RentalHistoryData> | undefined =>
  store.userData.user?.rentalHistory?.rentals;

export const selectActivePaymentData = (
  store: State
): PaymentRequestData | null | undefined =>
  store.userData.user.activePaymentData;

export const selectPaymentPublicKey = (
  store: State
): string | null | undefined => store.userData.user.paymentPublicKey;

export const selectJumioRedirectData = (store: State): any =>
  store.userData.user.jumioRedirectData;

export const selectTariffs = (store: State): any => store.userData.user.tariffs;
