import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  finishRental,
  selectRentalData,
  selectDriverCarStatus,
  clearCameraImages,
  getRentalData,
  getDriverCarStatus,
  selectFinishRentalViolations,
  canFinishRental,
  selectCarCheckImages,
  generateEndCheckImageUrl,
  uploadEndCheckImageS3,
  uploadEndCheckImage,
  selectCanLock,
  removeCarCheckCameraImage,
  setCarCheckCameraImage,
  LocalizePropType,
  gasStations,
  SUPPORTED_END_RENTAL_VIOLATIONS,
  getEndRentalViolationTitle,
  selectAreas,
  distanceBetweenLocationsInKm,
  locationFromString,
  checkInCheckOutAreaCenter,
  setMapContent,
  selectMapContent,
  nearestGasStation,
  dateAndTimeStringFromDate,
} from 'getaway-data-layer';
import {
  CAR_STATUS_UPDATER_DURATION_SHORT,
  navigateTo,
  RENTAL_UPDATER_DURATION_SHORT,
  showAlert,
} from '../../../../../utils';
import LockView from './LockView';

type Props = {
  localize: LocalizePropType;
};

const LockContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const finishRentalViolations = useSelector(
    selectFinishRentalViolations
  ) as any;
  const areas = useSelector(selectAreas);
  const rentalData = useSelector(selectRentalData) as any;
  const driverCarStatus = useSelector(selectDriverCarStatus) as any;
  const carCheckImages = useSelector(selectCarCheckImages) as any;
  const canLock = useSelector(selectCanLock) as any;
  const mapContent = useSelector(selectMapContent) as any;
  const isOriginallyBooking = !!rentalData?.bookingId;
  const backByDate = isOriginallyBooking
    ? new Date(rentalData?.bookingTo)
    : null;
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';

  const [initialLoad, setInitialLoad] = useState(true);
  const [rentalId, setRentalId] = useState(rentalData?.id);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [handoverChecklist, setHandoverChecklist] = useState([
    {
      name: 'key',
      title: 'key',
      isSelected: driverCarStatus ? driverCarStatus?.keyFobIn : false,
    },
    {
      name: 'card',
      title: 'card',
      isSelected: driverCarStatus ? driverCarStatus?.fuelCardIn : false,
    },
    { name: 'doorsWidows', title: 'doorsWidows', isSelected: false },
  ]);

  const isServiceTrip = rentalData?.type === 'serviceTrip';
  const returnArea = areas.find(
    (areaToFind: { id: any }) => areaToFind?.id === rentalData?.carData?.areaId
  );
  const distanceToReturnLocation = returnArea
    ? distanceBetweenLocationsInKm(
        locationFromString(rentalData?.carData?.position),
        checkInCheckOutAreaCenter(returnArea)
      ).toFixed(2)
    : 0;

  const onPressRemoveImage = (index: number) => {
    dispatch(removeCarCheckCameraImage(index));
  };

  const onLock = () => {
    const forceViolations = [] as Array<string>;
    SUPPORTED_END_RENTAL_VIOLATIONS.forEach((supportedViolation: string) => {
      if (
        handoverChecklist.find(
          (item: { name: string; isSelected: boolean }) =>
            supportedViolation.includes(item.name) && item.isSelected
        )
      ) {
        forceViolations.push(supportedViolation);
      }
    });
    // if (canLock) {
    //   BluetoothManager.lock();
    // }
    setIsSubmitted(true);
    const id = rentalData?.id || rentalId;
    dispatch(
      finishRental(
        localize,
        id,
        () => {
          if (Object.keys(carCheckImages).length === 4) {
            const { imageOne, imageTwo, imageThree, imageFour } =
              carCheckImages;
            const upload = (
              image: any,
              resolvePromise: () => void = () => {}
            ) => {
              dispatch(
                generateEndCheckImageUrl(
                  id,
                  (url: any) => {
                    dispatch(
                      uploadEndCheckImageS3(
                        url,
                        image.file,
                        resolvePromise,
                        () => {
                          // Fallback to api upload id s3 upload fails
                          dispatch(
                            uploadEndCheckImage(id, image.file, resolvePromise)
                          );
                        }
                      )
                    );
                  },
                  () => {
                    // Fallback to api upload id s3 url generations fails
                    dispatch(
                      uploadEndCheckImage(id, image.file, resolvePromise)
                    );
                  }
                )
              );
            };
            setTimeout(() => {
              upload(imageOne, () => {
                dispatch(removeCarCheckCameraImage(1));
              });
              upload(imageTwo, () => {
                dispatch(removeCarCheckCameraImage(2));
              });
              upload(imageThree, () => {
                dispatch(removeCarCheckCameraImage(3));
              });
              upload(imageFour, () => {
                dispatch(removeCarCheckCameraImage(4));
              });
            }, 300);
          }
        },
        () => {
          if (canLock) {
            navigateTo(history, '/rental/unlockCar');
          }
        },
        forceViolations
      )
    );
  };

  const onSlideLock = () => {
    if (
      Object.keys(carCheckImages).length === 4 ||
      rentalData?.type === 'serviceTrip'
    ) {
      onLock();
    } else {
      showAlert(
        '',
        localize('handover.all.around.warning'),
        localize('handover.all.around.confirm'),
        () => {
          onLock();
        },
        localize('handover.all.around.no'),
        () => {},
        true
      );
    }
  };

  const onPressOption = (name: any) => {
    if (name === 'insideArea') {
      dispatch(
        setMapContent(
          'rentalReturnLocation',
          mapContent.cardType,
          rentalData.carData.id
        )
      );
      history.goBack();
    } else {
      const station = nearestGasStation(
        locationFromString(rentalData.carData.position),
        gasStations
      );
      dispatch(
        setMapContent(
          'rentalNearestGasStation',
          mapContent.cardType,
          station.id
        )
      );
      history.goBack();
    }
  };

  const onSelectItem = (name: any) => {
    if (isSubmitted) return;

    const violations = Object.keys(finishRentalViolations);

    if (
      name === 'key' &&
      handoverChecklist.find((item) => !item.isSelected && item.name === 'key')
    ) {
      getDriverCarStatus(rentalData.id);
      navigateTo(history, '/rental/keyVideo');
    } else if (
      name === 'card' &&
      handoverChecklist.find((item) => !item.isSelected && item.name === 'card')
    ) {
      getDriverCarStatus(rentalData.id);
      navigateTo(history, '/rental/cardVideo');
    } else if (
      [
        'payAsReserved',
        'doorsWidows',
        'fuelLevel',
        'extremelyLowFuelLevel',
        'bitLessFuelOnReturn',
      ].includes(name)
    ) {
      setHandoverChecklist((prevState) =>
        prevState.map((item) => {
          if (item.name === name) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          }
          return item;
        })
      );
      dispatch(canFinishRental(rentalData.id));
    } else if (isServiceTrip && name === 'insideArea') {
      setHandoverChecklist((prevState) =>
        prevState.map((item) => {
          if (item.name === name) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          }
          return item;
        })
      );
    } else if (!isServiceTrip && name === 'insideArea') {
      if (!violations.includes('finishLocationAllowed')) {
        setHandoverChecklist((prevState) =>
          prevState.map((item) => {
            if (item.name === name) {
              return {
                ...item,
                isSelected: !item.isSelected,
              };
            }
            return item;
          })
        );
      }
      dispatch(canFinishRental(rentalData.id));
    }
  };

  const onChangeFirstImage = async () => {
    // @ts-ignore
    const file = document.getElementById('car-check-image-1').files;
    const imageFile = file[file.length - 1];
    dispatch(
      setCarCheckCameraImage(1, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeSecondImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-2').files[0];
    dispatch(
      setCarCheckCameraImage(2, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeThirdImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-3').files[0];
    dispatch(
      setCarCheckCameraImage(3, URL.createObjectURL(imageFile), imageFile)
    );
  };

  const onChangeForthImage = () => {
    // @ts-ignore
    const imageFile = document.getElementById('car-check-image-4').files[0];
    dispatch(
      setCarCheckCameraImage(4, URL.createObjectURL(imageFile), imageFile)
    );
  };

  useEffect(() => {
    dispatch(clearCameraImages());

    const carStatusUpdaterId = setInterval(() => {
      dispatch(getDriverCarStatus(rentalData?.id));
    }, CAR_STATUS_UPDATER_DURATION_SHORT);

    const rentalUpdaterId = setInterval(() => {
      dispatch(getRentalData(rentalData?.id));
    }, RENTAL_UPDATER_DURATION_SHORT);

    // @ts-ignore
    document
      .getElementById('car-check-image-1')
      ?.addEventListener('change', onChangeFirstImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-2')
      ?.addEventListener('change', onChangeSecondImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-3')
      ?.addEventListener('change', onChangeThirdImage, true);

    // @ts-ignore
    document
      .getElementById('car-check-image-4')
      ?.addEventListener('change', onChangeForthImage, true);

    dispatch(removeCarCheckCameraImage(1));
    dispatch(removeCarCheckCameraImage(2));
    dispatch(removeCarCheckCameraImage(3));
    dispatch(removeCarCheckCameraImage(4));

    return () => {
      if (rentalUpdaterId) {
        clearInterval(rentalUpdaterId);
      }
      if (carStatusUpdaterId) {
        clearInterval(carStatusUpdaterId);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !rentalData ||
      ['finished', 'waitingForCheckin'].includes(rentalData.carMode)
    ) {
      history.goBack();
    }
  }, [rentalData]);

  useEffect(() => {
    setHandoverChecklist((prevState) => {
      if (
        // @ts-ignore
        prevState?.find((item) => item.name === 'key') &&
        // @ts-ignore
        prevState?.find((item) => item.name === 'key').isSelected !==
          driverCarStatus?.keyFobIn
      ) {
        setInitialLoad(false);
      }
      return prevState.map((item) => {
        if (item.name === 'key') {
          return {
            ...item,
            isSelected: driverCarStatus?.keyFobIn,
          };
        }
        return item;
      });
    });
  }, [driverCarStatus?.keyFobIn]);

  useEffect(() => {
    setHandoverChecklist((prevState) => {
      if (
        // @ts-ignore
        prevState?.find((item) => item.name === 'card') &&
        // @ts-ignore
        prevState?.find((item) => item.name === 'card').isSelected !==
          driverCarStatus?.fuelCardIn
      ) {
        setInitialLoad(false);
      }
      return prevState.map((item) => {
        if (item.name === 'card') {
          return {
            ...item,
            isSelected: driverCarStatus?.fuelCardIn,
          };
        }
        return item;
      });
    });
  }, [driverCarStatus?.fuelCardIn]);

  useEffect(() => {
    if (
      finishRentalViolations ||
      handoverChecklist.find((item) =>
        SUPPORTED_END_RENTAL_VIOLATIONS.includes(item.name)
      )
    ) {
      const violations = Object.keys(finishRentalViolations);
      const handoverListUpdates = [...handoverChecklist];
      let updated = false;

      SUPPORTED_END_RENTAL_VIOLATIONS.forEach((supportedViolation: string) => {
        if (
          violations.includes(supportedViolation) &&
          !handoverChecklist.find(
            (item: { name: string }) => item.name === supportedViolation
          )
        ) {
          handoverListUpdates.push({
            name: supportedViolation,
            title: getEndRentalViolationTitle(
              supportedViolation,
              finishRentalViolations,
              rentalData?.pricingData,
              localize
            ),
            isSelected: false,
          });
          updated = true;
        } else if (
          !violations.includes(supportedViolation) &&
          handoverChecklist.find(
            (item: { name: string }) => item.name === supportedViolation
          )
        ) {
          handoverListUpdates.splice(
            handoverListUpdates.findIndex(
              (item) => item.name === supportedViolation
            ),
            1
          );
          updated = true;
        }
      });
      if (updated) {
        setHandoverChecklist([...handoverListUpdates]);
      }
    }
  }, [finishRentalViolations]);

  return (
    rentalData && (
      <LockView
        localize={localize}
        initialLoad={initialLoad}
        fuelLevelStart={rentalData?.fuelLevelStart}
        fuelTankVolume={rentalData?.carData?.fuelTankVolume || 0}
        backBy={formattedBackBy}
        fuelLevel={driverCarStatus?.fuelLevel}
        distanceToReturnLocation={distanceToReturnLocation}
        handoverChecklist={handoverChecklist}
        onSelectItem={onSelectItem}
        finishRentalViolations={finishRentalViolations}
        carCheckImages={carCheckImages}
        isServiceTrip={isServiceTrip}
        onPressRemoveImage={onPressRemoveImage}
        onLock={onSlideLock}
        onPressOption={onPressOption}
      />
    )
  );
};

export default withTextLocalizer(LockContainer);
