export default [
  {
    fleetId: '000000000000000000000007',
    description: 'map description test',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3446839 52.5383399',
              '13.344635 52.5382119',
              '13.3467687 52.5379623',
              '13.3470919 52.5379421',
              '13.3475311 52.5379295',
              '13.3479542 52.5379366',
              '13.3485262 52.5379625',
              '13.3490821 52.538021',
              '13.3499967 52.5381731',
              '13.3533817 52.5388995',
              '13.3535718 52.5389162',
              '13.3537646 52.5389134',
              '13.3540965 52.5388938',
              '13.3547383 52.5388098',
              '13.35503 52.5387543',
              '13.3556113 52.5386083',
              '13.3567188 52.5383033',
              '13.3571665 52.5381727',
              '13.3574615 52.5380292',
              '13.3600041 52.5373358',
              '13.3602126 52.5374374',
              '13.3586439 52.5378515',
              '13.3577962 52.538062',
              '13.3570919 52.5383255',
              '13.3553197 52.5387882',
              '13.3546685 52.5389149',
              '13.3542561 52.5389779',
              '13.3534702 52.5390129',
              '13.3530891 52.5389628',
              '13.352574 52.5388622',
              '13.3507541 52.5384551',
              '13.3495016 52.5382039',
              '13.348426 52.5380766',
              '13.3476535 52.5380587',
              '13.3467438 52.5380773',
              '13.3457112 52.5382021',
              '13.3446839 52.5383399',
            ],
          },
        },
      ],
    },
    id: '5d1de929bc0e41544c8cb0a7',
    createdAt: '2019-07-04T11:55:21.758Z',
    version: 6,
    kmlFilename: 'planned_Berlin_01_190613.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'FaridBerlinArea01190613',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-09T15:49:52.323Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.0695604 52.4009362',
              '13.0687263 52.4009149',
              '13.0676641 52.3998496',
              '13.0680208 52.3995829',
              '13.069775 52.399825',
              '13.0695604 52.4009362',
            ],
          },
        },
      ],
    },
    id: '5d24b7a0bc0e416e9195207b',
    lastUpdatedAt: '2019-07-09T15:50:02.643Z',
    version: 8,
    kmlFilename: 'planned_Berlin_22_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'FaridBerlin22',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5d2b3f8abc0e415990546a50',
    version: 4,
    createdAt: '2019-07-14T14:43:22.832Z',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Area12',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-25T12:14:05.219Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3641945 52.5625341',
              '13.3637895 52.5640292',
              '13.3606861 52.5702098',
              '13.3659594 52.5723682',
              '13.3657448 52.5725303',
              '13.360611 52.5704282',
              '13.351513 52.5677416',
              '13.3383326 52.5663558',
              '13.3356397 52.5655732',
              '13.3307366 52.5628014',
              '13.3310156 52.5626873',
              '13.3355968 52.5648428',
              '13.3391587 52.5507798',
              '13.3394431 52.5502595',
              '13.3641945 52.5625341',
            ],
          },
        },
      ],
    },
    id: '5d399d0dbc0e416211237603',
    lastUpdatedAt: '2019-07-25T12:19:29.092Z',
    version: 8,
    kmlFilename: 'planned_Berlin_24_190618.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'HusamOnboardingArea',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T20:07:18.514Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4129025 52.4819536',
              '13.4064961 52.4832775',
              '13.4018612 52.4841417',
              '13.4001285 52.483944',
              '13.3946086 52.4843458',
              '13.3945334 52.4835831',
              '13.4174462 52.4798269',
              '13.4209786 52.4797859',
              '13.4206977 52.4805618',
              '13.4129025 52.4819536',
            ],
          },
        },
      ],
    },
    id: '5d3f51f6bc0e414632834fbc',
    lastUpdatedAt: '2019-08-20T17:26:08.674Z',
    version: 9,
    kmlFilename: 'planned_Berlin_03_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T20:07:53.697Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4201431 52.4802613',
              '13.4192312 52.4801494',
              '13.4181274 52.480209',
              '13.4125096 52.4814709',
              '13.4051335 52.4830031',
              '13.4032775 52.4833903',
              '13.4023146 52.4835479',
              '13.4012175 52.4836859',
              '13.3991455 52.4839056',
              '13.394571 52.4840714',
              '13.394587 52.4838575',
              '13.3990851 52.4837382',
              '13.400496 52.4836043',
              '13.4027638 52.4832797',
              '13.4047446 52.4828668',
              '13.4083468 52.4820999',
              '13.4104094 52.4816531',
              '13.4124318 52.4812129',
              '13.4180685 52.4800425',
              '13.4186029 52.4799926',
              '13.41914 52.4799804',
              '13.4206353 52.4800865',
              '13.4206333 52.4802286',
              '13.4201431 52.4802613',
            ],
          },
        },
      ],
    },
    id: '5d3f5219bc0e414632834fc7',
    lastUpdatedAt: '2019-07-29T20:08:54.321Z',
    version: 9,
    kmlFilename: 'planned_Berlin_03_190613.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    description: 'map description test',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.1663439 52.5519777',
              '13.164874 52.5498378',
              '13.19844 52.5427451',
              '13.2015565 52.5409959',
              '13.2019588 52.5411526',
              '13.1950304 52.5461718',
              '13.1663439 52.5519777',
            ],
          },
        },
      ],
    },
    id: '5d3f52a5bc0e414632834fd6',
    lastUpdatedAt: '2019-08-20T17:26:47.618Z',
    createdAt: '2019-07-29T20:10:13.898Z',
    version: 9,
    kmlFilename: 'planned_Berlin_04_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T20:18:40.393Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2751553 52.4605064',
              '13.2751232 52.4603086',
              '13.2760351 52.4553614',
              '13.2796615 52.450523',
              '13.2800048 52.450026',
              '13.2828587 52.4511507',
              '13.2848328 52.4521184',
              '13.2860773 52.4529161',
              '13.2960535 52.4564631',
              '13.3003289 52.4579406',
              '13.3038802 52.4585943',
              '13.3041591 52.4591958',
              '13.3033437 52.4593331',
              '13.3050925 52.4655496',
              '13.3055753 52.4659091',
              '13.2992346 52.4653601',
              '13.298387 52.4652228',
              '13.296767 52.4646868',
              '13.2963056 52.4644842',
              '13.29637 52.4642555',
              '13.2880981 52.4584048',
              '13.2751553 52.4605064',
            ],
          },
        },
      ],
    },
    id: '5d3f54a0bc0e414632834ff1',
    lastUpdatedAt: '2019-07-29T20:19:35.791Z',
    version: 10,
    kmlFilename: 'planned_Berlin_05_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T20:57:32.944Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f5dbcbc0e414bdd17da67',
    lastUpdatedAt: '2019-07-29T20:59:57.12Z',
    version: 6,
    kmlFilename: 'planned_Berlin_01_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:03:58.117Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3277869 52.5242966',
              '13.3288365 52.5272741',
              '13.3286864 52.5332461',
              '13.3280728 52.5336862',
              '13.3185798 52.5325739',
              '13.3131349 52.531838',
              '13.3060821 52.5318748',
              '13.3060432 52.5319587',
              '13.2961673 52.5307008',
              '13.296296 52.5301525',
              '13.3055013 52.5314709',
              '13.3061343 52.5309814',
              '13.3062121 52.5305906',
              '13.3061666 52.5302129',
              '13.3063596 52.5287884',
              '13.3080897 52.5289956',
              '13.3119386 52.5295651',
              '13.3133334 52.5300024',
              '13.3167471 52.5303774',
              '13.3230194 52.5317358',
              '13.3274096 52.5327371',
              '13.3285733 52.5273178',
              '13.3272049 52.5244843',
              '13.3277869 52.5242966',
            ],
          },
        },
      ],
    },
    id: '5d3f5f3ebc0e414bdd17da72',
    lastUpdatedAt: '2019-07-29T21:04:39.317Z',
    version: 7,
    kmlFilename: 'planned_Berlin_02_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:08:34.788Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4201431 52.4802613',
              '13.4192312 52.4801494',
              '13.4181274 52.480209',
              '13.4125096 52.4814709',
              '13.4051335 52.4830031',
              '13.4032775 52.4833903',
              '13.4023146 52.4835479',
              '13.4012175 52.4836859',
              '13.3991455 52.4839056',
              '13.394571 52.4840714',
              '13.394587 52.4838575',
              '13.3990851 52.4837382',
              '13.400496 52.4836043',
              '13.4027638 52.4832797',
              '13.4047446 52.4828668',
              '13.4083468 52.4820999',
              '13.4104094 52.4816531',
              '13.4124318 52.4812129',
              '13.4180685 52.4800425',
              '13.4186029 52.4799926',
              '13.41914 52.4799804',
              '13.4206353 52.4800865',
              '13.4206333 52.4802286',
              '13.4201431 52.4802613',
            ],
          },
        },
      ],
    },
    id: '5d3f6052bc0e414bdd17db6f',
    lastUpdatedAt: '2019-07-29T21:09:09.948Z',
    version: 61,
    kmlFilename: 'planned_Berlin_03_190613.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:28:22.504Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.1238576 50.7444922',
              '7.1239541 50.7438778',
              '7.1253382 50.743944',
              '7.1261831 50.7435243',
              '7.1260302 50.7443089',
              '7.1253789 50.7443038',
              '7.1253285 50.7444209',
              '7.1252034 50.7445515',
              '7.1238576 50.7444922',
            ],
          },
        },
      ],
    },
    id: '5d3f64f6bc0e414bdd17dc3b',
    lastUpdatedAt: '2019-07-29T21:28:42.872Z',
    version: 6,
    kmlFilename: 'planned_Bonn_01_190619.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bonn_01_190619',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:29:05.966Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f6521bc0e414bdd17dc40',
    lastUpdatedAt: '2019-07-29T21:30:59.21Z',
    version: 41,
    kmlFilename: 'planned_Berlin_01_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:31:37.41Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0637259 50.7527811',
              '7.0620388 50.7536712',
              '7.0605099 50.7544466',
              '7.0603315 50.7543584',
              '7.0635771 50.7526573',
              '7.0637259 50.7527811',
            ],
          },
        },
      ],
    },
    id: '5d3f65b9bc0e414bdd17dcb2',
    lastUpdatedAt: '2019-07-29T21:32:02.041Z',
    version: 6,
    kmlFilename: 'planned_Bonn_03_190619.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bonn_02_190619',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:33:15.242Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '7.0420037 50.7468763',
              '7.0418428 50.7465504',
              '7.0504473 50.7449279',
              '7.0505439 50.7452809',
              '7.0476686 50.7456849',
              '7.0420037 50.7468763',
            ],
          },
        },
      ],
    },
    id: '5d3f661bbc0e414bdd17dcc1',
    lastUpdatedAt: '2019-07-29T21:33:41.268Z',
    version: 6,
    kmlFilename: 'planned_Bonn_04_190619.kml.xml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'planned_Bonn_04_190619',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:34:31.255Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f6667bc0e414bdd17dcc6',
    lastUpdatedAt: '2019-07-29T21:34:52.694Z',
    version: 11,
    kmlFilename: 'planned_Berlin_01_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:35:51.71Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f66b7bc0e414bdd17dce1',
    lastUpdatedAt: '2019-07-29T21:36:09.383Z',
    version: 8,
    kmlFilename: 'planned_Berlin_01_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5d3f672ebc0e414bdd17dcff',
    version: 3,
    createdAt: '2019-07-29T21:37:50.488Z',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:38:54.281Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3277869 52.5242966',
              '13.3288365 52.5272741',
              '13.3286864 52.5332461',
              '13.3280728 52.5336862',
              '13.3185798 52.5325739',
              '13.3131349 52.531838',
              '13.3060821 52.5318748',
              '13.3060432 52.5319587',
              '13.2961673 52.5307008',
              '13.296296 52.5301525',
              '13.3055013 52.5314709',
              '13.3061343 52.5309814',
              '13.3062121 52.5305906',
              '13.3061666 52.5302129',
              '13.3063596 52.5287884',
              '13.3080897 52.5289956',
              '13.3119386 52.5295651',
              '13.3133334 52.5300024',
              '13.3167471 52.5303774',
              '13.3230194 52.5317358',
              '13.3274096 52.5327371',
              '13.3285733 52.5273178',
              '13.3272049 52.5244843',
              '13.3277869 52.5242966',
            ],
          },
        },
      ],
    },
    id: '5d3f676ebc0e414bdd17dd08',
    lastUpdatedAt: '2019-07-29T21:39:04.057Z',
    version: 6,
    kmlFilename: 'planned_Berlin_02_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:41:34.844Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4129025 52.4819536',
              '13.4064961 52.4832775',
              '13.4018612 52.4841417',
              '13.4001285 52.483944',
              '13.3946086 52.4843458',
              '13.3945334 52.4835831',
              '13.4174462 52.4798269',
              '13.4209786 52.4797859',
              '13.4206977 52.4805618',
              '13.4129025 52.4819536',
            ],
          },
        },
      ],
    },
    id: '5d3f680ebc0e414bdd17dd14',
    lastUpdatedAt: '2019-07-29T21:42:26.798Z',
    version: 8,
    kmlFilename: 'planned_Berlin_03_190617.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:44:04.405Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f68a4bc0e414bdd17dd29',
    lastUpdatedAt: '2019-07-29T21:44:19.651Z',
    version: 6,
    kmlFilename: 'planned_Berlin_01_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:45:15.07Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3277869 52.5242966',
              '13.3288365 52.5272741',
              '13.3286864 52.5332461',
              '13.3280728 52.5336862',
              '13.3185798 52.5325739',
              '13.3131349 52.531838',
              '13.3060821 52.5318748',
              '13.3060432 52.5319587',
              '13.2961673 52.5307008',
              '13.296296 52.5301525',
              '13.3055013 52.5314709',
              '13.3061343 52.5309814',
              '13.3062121 52.5305906',
              '13.3061666 52.5302129',
              '13.3063596 52.5287884',
              '13.3080897 52.5289956',
              '13.3119386 52.5295651',
              '13.3133334 52.5300024',
              '13.3167471 52.5303774',
              '13.3230194 52.5317358',
              '13.3274096 52.5327371',
              '13.3285733 52.5273178',
              '13.3272049 52.5244843',
              '13.3277869 52.5242966',
            ],
          },
        },
      ],
    },
    id: '5d3f68ebbc0e414bdd17dd33',
    lastUpdatedAt: '2019-07-29T21:45:34.842Z',
    version: 7,
    kmlFilename: 'planned_Berlin_02_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:46:19.704Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4129025 52.4819536',
              '13.4064961 52.4832775',
              '13.4018612 52.4841417',
              '13.4001285 52.483944',
              '13.3946086 52.4843458',
              '13.3945334 52.4835831',
              '13.4174462 52.4798269',
              '13.4209786 52.4797859',
              '13.4206977 52.4805618',
              '13.4129025 52.4819536',
            ],
          },
        },
      ],
    },
    id: '5d3f692bbc0e414bdd17dd3e',
    lastUpdatedAt: '2019-07-29T21:46:44.945Z',
    version: 6,
    kmlFilename: 'planned_Berlin_03_190617.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:49:02.31Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.1663439 52.5519777',
              '13.164874 52.5498378',
              '13.19844 52.5427451',
              '13.2015565 52.5409959',
              '13.2019588 52.5411526',
              '13.1950304 52.5461718',
              '13.1663439 52.5519777',
            ],
          },
        },
      ],
    },
    id: '5d3f69cebc0e414bdd17dd50',
    lastUpdatedAt: '2019-07-29T21:49:26.056Z',
    version: 6,
    kmlFilename: 'planned_Berlin_04_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:50:27.359Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2751553 52.4605064',
              '13.2751232 52.4603086',
              '13.2760351 52.4553614',
              '13.2796615 52.450523',
              '13.2800048 52.450026',
              '13.2828587 52.4511507',
              '13.2848328 52.4521184',
              '13.2860773 52.4529161',
              '13.2960535 52.4564631',
              '13.3003289 52.4579406',
              '13.3038802 52.4585943',
              '13.3041591 52.4591958',
              '13.3033437 52.4593331',
              '13.3050925 52.4655496',
              '13.3055753 52.4659091',
              '13.2992346 52.4653601',
              '13.298387 52.4652228',
              '13.296767 52.4646868',
              '13.2963056 52.4644842',
              '13.29637 52.4642555',
              '13.2880981 52.4584048',
              '13.2751553 52.4605064',
            ],
          },
        },
      ],
    },
    id: '5d3f6a23bc0e414bdd17dd55',
    lastUpdatedAt: '2019-07-29T21:51:36.458Z',
    version: 7,
    kmlFilename: 'planned_Berlin_05_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5d761ab0b73ec647d4271f7f',
    version: 2,
    createdAt: '2019-09-09T09:26:08.632Z',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_test',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    id: '5dde469091c079030d31c771',
    version: 1,
    createdAt: '2019-11-27T09:49:04.57Z',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Blah',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
];
