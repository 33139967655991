import React, { useMemo } from 'react';
import IdentifyShopperView from './IdentifyShopperView';
import config from '../../../../../env.config';
import base64url from '../../../../../utils/base64/base64url';

type Props = {
  identifyShopperData: any;
};

const IdentifyShopperContainer = ({ identifyShopperData }: Props) => {
  const { threeDSServerTransID, threeDSMethodURL } = identifyShopperData;

  const threeDSData = useMemo(() => {
    const dataObj = {
      threeDSServerTransID,
      threeDSMethodNotificationURL: `${config.api.baseURL}/payment/adyen/3ds/web/identifyShopper/callback`,
    };
    const stringifiedDataObject = JSON.stringify(dataObj);
    return base64url.encode(stringifiedDataObject);
  }, [threeDSServerTransID]);

  return (
    <>
      <IdentifyShopperView
        threeDSData={threeDSData}
        threeDSMethodURL={threeDSMethodURL}
      />
    </>
  );
};

export default IdentifyShopperContainer;
