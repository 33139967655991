import { State } from '../@types';

export const selectMapContent = (store: State) => store.ui.map.content;

export const selectMapBoundaries = (store: State) => store.ui.map.mapBoundaries;

export const selectSelectedCar = (store: State) => store.ui.map.selectedCar;

export const selectIsCarOpened = (store: State) => store.ui.map.opened;

export const selectPhoneNumber = (store: State) =>
  store.ui.firstSteps.phone.phoneNumber;

export const selectPhoneCodes = (store: State) =>
  store.ui.firstSteps.phoneCodes;

export const selectIsVerifyingGDPREmail = (store: State) =>
  store.ui.firstSteps.isVerifyingGDPREmail;

export const selectIsEditingPhone = (store: State) =>
  store.ui.firstSteps.isEditingPhone;

export const selectDateOfBirth = (store: State) =>
  store.ui.firstSteps.dateOfBirth;

export const selectIban = (store: State) => store.ui.firstSteps.iban;

export const selectBIC = (store: State) => store.ui.firstSteps.bic;

export const selectCardState = (store: State) => store.ui.map.cardState;

export const selectDefaultTab = (store: State) => store.ui.map.defaultTab;

export const selectShowHomeWT = (store: State) => store.ui.map.showHomeWT;

export const selectShowUserLocation = (store: State) =>
  store.ui.map.showUserLocation;

export const selectUserAddress = (store: State) => store.ui.map.userAddress;

export const selectDeeplink = (store: State) => store.ui.deeplink.deeplinkUrl;

export const selectIsFirstRental = (store: State) => store.ui.map.isFirstRental;

export const selectVisibleRegion = (store: State) => store.ui.map.visibleRegion;

export const selectIsEmbedded = (store: State) => store.ui.map.isEmbedded;

export const selectNewDamages = (store: State) => store.ui.damages.newDamages;

export const selectShowExternalBrowserScreen = (store: State) =>
  store.ui.firstSteps.showExternalBrowserScreen;
