import { StationData } from '../../Types';
export type PoisState = Array<StationData>;

enum PoisTypes {
  SET_POIS = 'appData/poIs/SET_POIS',
}

export default PoisTypes;

export type SetPois = {
  type: PoisTypes.SET_POIS;
  payload: {
    pois: Array<StationData>;
  };
};

export type PoisAction = SetPois;
