import React from 'react';
import TextField from '@mui/material/TextField';
import './AddDamageView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
  images: any;
  freeText: any;
  error: boolean;
  cameraEnabled: boolean;
  checkCameraPermission: () => void;
  handleChangeFreeText: any;
  onClose: () => void;
  onPressRemoveImage: (index: number) => void;
  onSubmit: () => void;
};

const AddDamageView = ({
  localize,
  images,
  freeText,
  cameraEnabled,
  checkCameraPermission,
  error,
  handleChangeFreeText,
  onPressRemoveImage,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('car.damage.create.dialog.title')}
        description=""
        showHelp
        onClose={onClose}
      />
      <div className="screen-body" style={{ padding: 0 }}>
        <div className="add-damage-container">
          <div className="add-damage-horizontal">
            <div
              className="add-damage-image-wrapper"
              onClick={checkCameraPermission}
            >
              <input
                id="add-damage-image"
                type="file"
                accept="image/*"
                capture="environment"
                className="add-damage-image-input invisible"
                disabled={!cameraEnabled}
              />
              <img
                alt="add"
                src="/images/addImage.png"
                className="add-damage-image"
              />
            </div>
            {images?.length > 0 &&
              images.map((image: any) => (
                <div style={{ position: 'relative' }} key={image.id}>
                  <img
                    alt="damage"
                    src={image.uri}
                    className="damage-image"
                    style={{ objectFit: 'cover' }}
                  />
                  <div
                    onClick={() => {
                      onPressRemoveImage(image.id);
                    }}
                    className="add-damage-trashTouchable"
                  >
                    <img
                      alt="trash"
                      src="/images/trash.png"
                      className="add-damage-trash"
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="add-damage-note">
            {localize('damages.add.image.note')}
          </div>
        </div>
        <div style={{ padding: '0 16px' }}>
          <TextField
            error={error}
            label={localize('description')}
            multiline
            maxRows={4}
            value={freeText}
            onChange={handleChangeFreeText}
            style={{ width: '100%' }}
            variant="standard"
            helperText={error ? localize('validation.required') : ''}
          />
        </div>
      </div>
      <BrandButton
        id={GA_TITLES.RENTAL.SUBMIT_DAMAGE}
        title={localize('add')}
        onClick={onSubmit}
      />
    </div>
  );
};

export default AddDamageView;
