import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setNotification,
  dismissNotification,
  clearNotifications,
} from '../redux/store/ui/notifications';
import { State, NotificationsState } from '../@types';
export type Props = {
  notifications: NotificationsState;
  setNotification: typeof setNotification;
  dismissNotification: typeof dismissNotification;
  clearNotifications: typeof clearNotifications;
};
const withNotifications: any = compose(
  connect(
    ({ ui: { notifications }, config: { xBrandId } }: State) => ({
      notifications,
      xBrandId,
    }),
    {
      setNotification,
      dismissNotification,
      clearNotifications,
    }
  )
);
export default withNotifications;
