import React from 'react';
import {
  formatMoneyEuro,
  LocalizePropType,
  isMBA,
  isMSM,
} from 'getaway-data-layer';
import packageInfo from '../../../../package.json';
import './SideMenuView.less';
import { dashboardImage, logo } from '../../../assets/brandImages';
import BrandIcon from '../BrandIcon/BrandIcon';

type Props = {
  localize: LocalizePropType;
  userName: string;
  balance: number;
  inCompleteProfile: boolean;
  bookingCountdown: string;
  isStarted: boolean;
  isWaitingForCheckin: boolean;
  onClickProfile: () => void;
  onClickBalance: () => void;
  onClickUserRentals: () => void;
  onPressAbout: () => void;
  onPressHelpCenter: () => void;
};

const SideMenuView = ({
  localize,
  userName,
  balance,
  inCompleteProfile,
  bookingCountdown,
  isStarted,
  isWaitingForCheckin,
  onClickProfile,
  onClickBalance,
  onClickUserRentals,
  onPressAbout,
  onPressHelpCenter,
}: Props) => {
  return (
    <>
      <div id="sidebar" className="side-menu">
        <div>
          <img className="sidebar-logo" src={logo} alt="sidebar-logo" />
          <img
            className="dashboard-image"
            src={dashboardImage}
            alt="dashboardImage"
          />
          <div className="sideMenu-hello">Hallo 👋</div>
          <div className="sideMenu-name">{userName}</div>
        </div>
        <div className="menu">
          <div className="menu-item" onClick={onClickUserRentals}>
            {localize('dashboard.myRentals')}
            {bookingCountdown !== '' && (
              <div
                style={{
                  color: isStarted
                    ? isWaitingForCheckin
                      ? '#F2994A'
                      : '#02ac00'
                    : 'black',
                }}
              >
                {bookingCountdown}
              </div>
            )}
          </div>
          <div className="menu-item" onClick={onClickBalance}>
            {localize('dashboard.balance')}
            <div
              style={{ backgroundColor: balance < 0 ? 'red' : 'black' }}
              className="menu-item-balance"
            >
              {formatMoneyEuro(balance)}
            </div>
          </div>
          <div className="menu-item" onClick={onClickProfile}>
            {localize('dashboard.profile')}
            {inCompleteProfile && (
              <BrandIcon
                name="iconIncomplete"
                style={{
                  width: '12px',
                  height: '12px',
                }}
              />
            )}
          </div>
          <div className="side-menu-footer-container">
            <div className="side-menu-footer-item" onClick={onPressAbout}>
              {localize('dashboard.about')}
            </div>
            {!isMSM() && !isMBA() && (
              <div
                className="side-menu-footer-item"
                onClick={onPressHelpCenter}
              >
                {localize('dashboard.help')}
              </div>
            )}
            <div className="side-menu-footer-version">
              {`© GETAWAY・v${packageInfo.version} - ${process?.env?.REACT_APP_COMMIT_HASH}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenuView;
