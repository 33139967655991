import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFRM,
  isTLA,
  loadUserTariffs,
  LocalizePropType,
  selectTariffs,
  selectUser,
  updateUser,
  withTextLocalizer,
  uploadDocument,
  isMVV,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import TariffView from './TariffView';
import {
  getDLVerificationStatus,
  getIDVerificationStatus,
  isDashboardScreen,
  isDLVerificationRequired,
  isIDVerificationRequired,
  navigateTo,
  showAlert,
  showAlertWithOptions,
  TALLY_LINKS,
} from '../../../../utils';
import { TARIFF_CONSENT_STATUS } from '../../../../enums/Tariff';
import { useFilePicker } from 'use-file-picker';

interface Values {}

type Props = {
  localize: LocalizePropType;
};

const TariffContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tariffs = useSelector(selectTariffs);
  const user = useSelector(selectUser);
  const [tariffConsentStatus, setTariffConsentStatus] = useState(
    isMVV() ? TARIFF_CONSENT_STATUS.APPROVED : TARIFF_CONSENT_STATUS.OPEN
  );
  const [tariffSelected, setTariffSelected] = useState(null);
  const [upsellingSelected, setUpsellingSelected] = useState(null);
  const [tlaExtraTariffs, setTlaExtraTariffs] = useState([]);
  const [selectedTlaExtraOption, setSelectedTlaExtraOption] = useState(null);
  const displayTariffs = isTLA()
    ? selectedTlaExtraOption ||
      tariffs?.filter(
        (tariff) =>
          !(
            tariff?.name.startsWith('Rahmentarif') &&
            tariff?.id !== '6661aeeb02a17c06e80801c7'
          )
      )
    : tariffs;
  const [subscriptionNum, setSubscriptionNum] = useState('');
  const [subscriptionError, setSubscriptionError] = useState('');

  const [openFileSelector, { plainFiles, loading, clear: clearFile }] =
    useFilePicker({
      accept: 'image/*',
      multiple: false,
      limitFilesConfig: { max: 1 },
    });
  const [fileError, setFileError] = useState('');

  const onTariffSelected = (tariff) => {
    setTariffSelected(tariff);
    setUpsellingSelected(null);
  };

  const onSubmit = () => {
    // Mandatory subscriptionNum if Marego-Abo or MOBIcar tariffs selected
    if (selectedTlaExtraOption) {
      if (
        !subscriptionNum &&
        selectedTlaExtraOption?.[0]?.id != '6661b99d02a17c06e80801dc'
      ) {
        setSubscriptionError(localize('validation.required'));
      }
      if (!plainFiles?.[0]) {
        setFileError(localize('validation.required'));
      }
      if (
        (!subscriptionNum &&
          selectedTlaExtraOption?.[0]?.id != '6661b99d02a17c06e80801dc') ||
        !plainFiles?.[0]
      ) {
        return;
      }
    }
    const extraData = user?.extraData ? JSON.parse(user?.extraData) : {};
    if (selectedTlaExtraOption && !plainFiles?.[0]) return;
    if (tariffSelected) {
      dispatch(
        // @ts-ignore
        updateUser(
          {
            ...(subscriptionNum && {
              extraData: JSON.stringify({ subscriptionNum: subscriptionNum }),
            }),
            tariff: {
              id: tariffSelected.id,
              upsellings:
                upsellingSelected || selectedTlaExtraOption
                  ? [
                      {
                        ...(upsellingSelected && { code: upsellingSelected }),
                      },
                    ]
                  : [],
            },
          },
          (user) => {
            if (plainFiles?.[0]) {
              dispatch(uploadDocument(plainFiles?.[0], 'OTHER', () => {}));
            }

            if (tariffSelected?.upsellings?.length > 0) {
              navigateTo(
                history,
                isDashboardScreen()
                  ? '/dashboard/upsellings'
                  : '/onboarding/upsellings'
              );
            } else if (
              !isFRM() &&
              getIDVerificationStatus(user) === 'completed' &&
              getDLVerificationStatus(user) === 'completed'
            ) {
              navigateTo(
                history,
                isDashboardScreen() ? '/settings' : '/onboarding/optimizedSepa'
              );
            } else if (isFRM()) {
              if (extraData?.referral) {
                navigateTo(
                  history,
                  isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
                );
              } else {
                navigateTo(
                  history,
                  isDashboardScreen()
                    ? `/dashboard/dynamicForm?url=${
                        extraData?.partner
                          ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                          : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
                      }`
                    : `/onboarding/dynamicForm?url=${
                        extraData?.partner
                          ? TALLY_LINKS.FRM_PARTNER_REFERRAL
                          : TALLY_LINKS.FRM_NON_PARTNER_REFERRAL
                      }`
                );
              }
            } else if (isMVV()) {
              navigateTo(
                history,
                isDashboardScreen()
                  ? `/dashboard/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
                  : `/onboarding/dynamicForm?url=${TALLY_LINKS.MVV_EXTRA_FIELDS}`
              );
            } else if (
              !isDLVerificationRequired(user) &&
              !isIDVerificationRequired(user)
            ) {
              navigateTo(
                history,
                isDashboardScreen() ? '/settings' : '/onboarding/optimizedSepa'
              );
            } else {
              navigateTo(
                history,
                isDashboardScreen() ? '/settings' : '/onboarding/IDOnboarding'
              );
            }
          }
        )
      );
    }
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, '/settings'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onClickExtraOptions = () => {
    const options = tlaExtraTariffs.map((tariff) => ({
      title: tariff.name,
      action: () => {
        setTariffSelected(null);
        setUpsellingSelected(null);
        setSelectedTlaExtraOption([tariff]);
      },
    }));
    showAlertWithOptions(localize('tariff.tla.extra'), '', options);
  };

  const onResetExtraOptions = () => {
    setTariffSelected(null);
    setUpsellingSelected(null);
    setSelectedTlaExtraOption(null);
    setSubscriptionError('');
    setFileError('');
  };

  const onChangeSubscriptionNum = (value) => {
    setSubscriptionNum(value);
    setSubscriptionError('');
  };

  useEffect(() => {
    setFileError('');
  }, [plainFiles?.[0]]);

  useEffect(() => {
    dispatch(loadUserTariffs());
  }, []);

  useEffect(() => {
    if (!user?.tariff?.id) {
      if (tariffs?.length >= 1) {
        setTariffSelected(tariffs[0]);
      }
    } else if (tariffs && user?.tariff?.id) {
      const isTlaExtraTariff =
        user?.tariff.name.startsWith('Rahmentarif') &&
        user?.tariff.id !== '6661aeeb02a17c06e80801c7';
      if (isTlaExtraTariff) {
        setSelectedTlaExtraOption(
          tariffs?.filter((tariff) => tariff?.id === user?.tariff?.id)
        );
        setTariffSelected(
          tariffs?.find((tariff) => tariff?.id === user?.tariff?.id)
        );
        const subscription = user.extraData
          ? JSON.parse(user.extraData)?.subscriptionNum
          : null;
        if (subscription) {
          setSubscriptionNum(subscription);
        }
      } else {
        setTariffSelected(
          tariffs?.find((tariff) => tariff?.id === user?.tariff?.id)
        );
      }
      setTariffConsentStatus(TARIFF_CONSENT_STATUS.APPROVED);
      if (user?.tariff?.upsellings) {
        setUpsellingSelected(user?.tariff?.upsellings?.[0]?.code);
      }
    }

    if (isTLA() && tariffs) {
      const filteredTariffs = tariffs.filter(
        (tariff) =>
          tariff.name.startsWith('Rahmentarif') &&
          tariff.id !== '6661aeeb02a17c06e80801c7'
      );
      setTlaExtraTariffs(filteredTariffs);
    }
  }, [tariffs, user]);

  return (
    <TariffView
      tariffs={displayTariffs}
      tlaExtraTariffs={tlaExtraTariffs}
      isOrg={user?.userType === 'org'}
      localize={localize}
      onClose={onClose}
      tariffSelected={tariffSelected}
      setTariffSelected={onTariffSelected}
      tariffConsentStatus={tariffConsentStatus}
      setTariffConsentStatus={setTariffConsentStatus}
      onSubmit={onSubmit}
      onClickExtraOptions={onClickExtraOptions}
      selectedTlaExtraOption={selectedTlaExtraOption}
      onResetExtraOptions={onResetExtraOptions}
      openFileSelector={openFileSelector}
      fileLoading={loading}
      filesContent={plainFiles?.[0]}
      clearFile={clearFile}
      subscriptionNum={subscriptionNum}
      setSubscriptionNum={onChangeSubscriptionNum}
      subscriptionError={subscriptionError}
      fileError={fileError}
    />
  );
};

export default withTextLocalizer(TariffContainer);
