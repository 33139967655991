import React from 'react';
import { TextField, Box } from '@mui/material';
import { FormikValues } from 'formik';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import BrandButton from '../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../utils';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  onClose: () => void;
};

const ForgotPasswordView = ({ localize, formik, onClose }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('login.forgot.title')}
        description=""
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body">
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="email"
            className="form-input"
            label={localize('login.email.placeholder')}
            helperText={(formik.touched.email && formik.errors.email) || ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
            variant="standard"
            type="email"
            autoComplete="email"
            {...formik.getFieldProps('email')}
          />
          <BrandButton
            id={GA_TITLES.AUTH.FORGOT}
            title={localize('login.forgot.text')}
            formik={formik}
          />
        </Box>
      </div>
    </div>
  );
};

export default ForgotPasswordView;
