import React from 'react';
import './RentlalHistoryItem.less';
import { Divider, ListItemButton } from '@mui/material';
import Plate from '../Plate/Plate';

type Props = {
  leftValue: string;
  title: string;
  description: string;
  carPlate: string;
  showSeparator?: boolean;
  onClick: () => void;
};

const RentalHistoryItem = ({
  leftValue,
  title,
  description,
  carPlate,
  showSeparator,
  onClick,
}: Props) => {
  return (
    <>
      <ListItemButton className="rental-item-container" onClick={onClick}>
        <div className="rental-item-left-value">{leftValue}</div>
        <div className="rental-item-title-container">
          <div className="rental-item-title">{title}</div>
          <div className="rental-item-description-container">
            <Plate carPlate={carPlate} />
            <div className="rental-item-description">{description}</div>
          </div>
        </div>
        {/* <div className="rental-item-value">
          <BrandIcon
            name="arrowRight"
            style={{
              width: 14,
              height: 14,
              marginLeft: '10px',
            }}
          />
        </div> */}
      </ListItemButton>
      {showSeparator && <Divider />}
    </>
  );
};

RentalHistoryItem.defaultProps = {
  showSeparator: true,
};

export default RentalHistoryItem;
