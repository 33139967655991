import React from 'react';
import MobileSwitchView from './MobileSwitchView';
import { useDispatch } from 'react-redux';
import {
  LocalizePropType,
  setSelectedCar,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { navigateTo } from '../../../utils';

type Props = {
  localize: LocalizePropType;
};

const MobileSwitchContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClose = () => {
    dispatch(setSelectedCar(null));
    navigateTo(history, '/map');
  };
  return <MobileSwitchView localize={localize} onClose={onClose} />;
};

export default withTextLocalizer(MobileSwitchContainer);
