import Intl from 'intl';
import 'intl/locale-data/jsonp/de-DE';
import 'intl/locale-data/jsonp/en-DE';

export const formatDistance = (distance: number = 0) => {
  const distanceFormatter = new Intl.NumberFormat([], {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  let formattedDistance = '';
  formattedDistance = `${distanceFormatter.format(distance)} km`;

  if (formattedDistance.includes('.')) {
    formattedDistance = formattedDistance.replace('.', ',');
  } else if (formattedDistance.includes(',')) {
    formattedDistance = formattedDistance.replace(',', '.');
  }

  return formattedDistance;
};
