import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CarsCarousel.less';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// @ts-ignore
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';
import {
  selectAvailableCars,
  selectSelectedCar,
  setSelectedCar,
  setMapContent,
  selectMapContent,
  setOpenedModal,
  locationFromString,
  selectFleetStyle,
  selectIsOBI,
  selectIsExpert,
} from 'getaway-data-layer';
import CarouselItem from './CarouselItem';
import { sortCarsRelativeToPosition } from '../../../utils';

const CarsCarousel = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCar = useSelector(selectSelectedCar) as any;
  const searchCars = Object.values(useSelector(selectAvailableCars));
  const mapContent = useSelector(selectMapContent) as any;
  const fleetStyle = useSelector(selectFleetStyle);
  const isOBI = useSelector(selectIsOBI);
  const isExpert = useSelector(selectIsExpert);

  const [carouselCars, setCarouselCars] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChangeItem = (index: number) => {
    setSelectedIndex(index);
    dispatch(setSelectedCar(carouselCars[index]));
    dispatch(
      // @ts-ignore
      setMapContent('availableCar', mapContent.cardType, carouselCars[index].id)
    );
    const params = new URLSearchParams(location.search);
    // @ts-ignore
    params.set('id', carouselCars[index]?.id);
    history.push({ search: params.toString() });
  };

  const onClickItem = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  useEffect(() => {
    setCarouselCars([
      // @ts-ignore
      selectedCar,
      // @ts-ignore
      ...sortCarsRelativeToPosition(
        searchCars.filter(
          (item: any) =>
            selectedCar?.areaId === item.areaId &&
            item?.id !== selectedCar?.id &&
            item?.vehicleConnection !== 'geoTab'
        ),
        locationFromString(selectedCar?.position!)
      ),
    ]);
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!carouselCars?.find((car) => car?.id === selectedCar?.id)) {
      setSelectedIndex(0);
      setCarouselCars([
        // @ts-ignore
        selectedCar,
        // @ts-ignore
        ...sortCarsRelativeToPosition(
          searchCars.filter(
            (item: any) =>
              selectedCar?.areaId === item.areaId &&
              item?.id !== selectedCar?.id &&
              item?.vehicleConnection !== 'geoTab'
          ),
          locationFromString(selectedCar?.position!)
        ),
      ]);
    } else {
      setSelectedIndex(
        carouselCars.findIndex((car: any) => car.id === selectedCar.id)
      );
    }
  }, [selectedCar]);

  return carouselCars.length > 0 ? (
    <div className="carousel-map">
      <Carousel
        selectedItem={selectedIndex}
        // centerMode
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        infiniteLoop={false}
        interval={999999999}
        // centerSlidePercentage={100}
        onChange={onChangeItem}
        // className="carousel"
      >
        {carouselCars.map((car: any) => (
          <CarouselItem
            key={car.id}
            car={car}
            onClickItem={onClickItem}
            fleetStyle={fleetStyle}
            isExpert={isExpert}
            isOBI={isOBI}
          />
        ))}
      </Carousel>
    </div>
  ) : null;
};

export default CarsCarousel;
