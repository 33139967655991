import distance from '@turf/distance';
import { pointFromLocation } from './pointFromLocation';
import { Location } from '../@types';
import { locationFromString } from './locationFromString';

export const distanceBetweenLocationsInKm = (
  location1: Location,
  location2: Location
): number => {
  if (location1 && location2) {
    const point1 = pointFromLocation(location1);
    const point2 = pointFromLocation(location2);
    const units = 'kilometers';
    // @ts-ignore
    return distance(point1, point2, units);
  }

  return 0;
};

export const estimateTravelTimeInMinutes = (
  location1: string,
  location2: string,
  mode: 'walking' | 'driving'
): number => {
  const distance = distanceBetweenLocationsInKm(
    locationFromString(location1),
    locationFromString(location2)
  );
  const walkingDetourIndex = 1.1; // Assuming 10% longer paths for walking
  const drivingDetourIndex = 2; // Assuming 50% longer paths for driving
  let speed;
  let detourIndex;

  if (mode === 'walking') {
    speed = 5; // Average walking speed in km/h
    detourIndex = walkingDetourIndex;
  } else if (mode === 'driving') {
    speed = 15; // Normal car speed in km/h as per your specification
    detourIndex = drivingDetourIndex;
  } else {
    throw new Error('Invalid mode of transportation');
  }

  // Adjust distance by the detour index
  const adjustedDistance = distance * detourIndex;
  const timeInHours = adjustedDistance / speed;
  const timeInMinutes = timeInHours * 60;

  return Math.round(timeInMinutes); // Rounds to the nearest minute
};
