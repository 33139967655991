import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Card from '../Card/Card';
import './TariffItem.less';
import { getLightBrandColor, LocalizePropType } from 'getaway-data-layer';

type Props = {
  title: string;
  subTitle?: string;
  price: string;
  description: string;
  deductionTitle?: string;
  deductionPrice?: string;
  selected: boolean;
  onClick: () => void;
  localize: LocalizePropType;
  showExtraOptionsLink?: boolean;
  onClickExtraOptions?: () => void;
  firstItem?: boolean;
  lastItem?: boolean;
  subTitleClassName?: string;
  showPopularBadge?: boolean;
  popularText?: string;
};

const TariffItem = ({
  localize,
  title,
  subTitle,
  price,
  description,
  selected,
  onClick,
  showExtraOptionsLink,
  onClickExtraOptions,
  firstItem,
  lastItem,
  subTitleClassName,
  showPopularBadge,
  popularText,
}: Props) => {
  return (
    <Card hideShadow={true} firstItem={firstItem} lastItem={lastItem}>
      <div
        className="tariff-container"
        onClick={onClick}
        style={selected ? { backgroundColor: getLightBrandColor() } : {}}
      >
        <div className="tariff-title-container">
          <strong className="tariff-title">{title}</strong>
          {showPopularBadge && (
            <div className={`tariff-badge-popular`}>{popularText}</div>
          )}
        </div>

        {subTitle && (
          <Markdown
            rehypePlugins={[rehypeRaw]}
            className={`tariff-subtitle ${subTitleClassName}`}
          >
            {subTitle}
          </Markdown>
        )}

        {description && (
          <Markdown rehypePlugins={[rehypeRaw]} className="tariff-desc">
            {description}
          </Markdown>
        )}
        {showExtraOptionsLink && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onClickExtraOptions();
            }}
            className="tariff-link"
          >
            {localize('tariff.extra.options.link')}
          </div>
        )}
        <div className="tariff-radio-wrapper">
          <div className="tariff-price">
            <Markdown rehypePlugins={[rehypeRaw]}>{price}</Markdown>
          </div>
          <input
            type="radio"
            id="radioButton"
            name="radioGroup"
            className="tariff-radio"
            checked={selected}
          />
        </div>
      </div>
    </Card>
  );
};

TariffItem.defaultProps = {
  subTitleClassName: '',
  showPopularBadge: false,
};

export default TariffItem;
