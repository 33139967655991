import TYPES from '../../../@types/redux/store/BluetoothTypes';
import {
  BluetoothStatus,
  SetBluetoothStatus,
  SetDeviceInfo,
  SetAccessToken,
  Dispatch,
  GetState,
} from '../../../@types';
import { CarAccessTokenData, CarStatusData } from '../../../@types';
import { isRentalOngoing } from '../../../helpers/rentalHelpers';

export const setBluetoothStatus = (
  status: BluetoothStatus | string
): SetBluetoothStatus => ({
  type: TYPES.SET_BLUETOOTH_STATUS,
  payload: {
    status,
  },
});

export const setDeviceInfo = (
  carId: string,
  deviceQnr: string
): SetDeviceInfo => ({
  type: TYPES.SET_DEVICE_INFO,
  payload: {
    carId,
    deviceQnr,
  },
});

export const setAccessToken = (
  accessToken: CarAccessTokenData
): SetAccessToken => ({
  type: TYPES.SET_ACCESS_TOKEN,
  payload: {
    accessToken,
  },
});

export const setCarStatus =
  (carStatus: CarStatusData) => (dispatch: Dispatch) => {
    dispatch({
      type: TYPES.SET_CAR_STATUS,
      payload: {
        carStatus,
      },
    });
  };

const clearConnectedDevice = (dispatch: Dispatch) => {
  dispatch({
    type: TYPES.CLEAR_CONNECTED_DEVICE,
  });
};

export const reloadDeviceInfo =
  () => (dispatch: Dispatch, getState: GetState) => {
    const { rentalData } = getState().userData.rental;
    let expectedCarId: string | undefined = '';
    let expectedQnR: string | undefined = '';
    let expectsACar = false;
    const { carId, deviceQnr } = getState().bluetooth.connectedDevice;
    const { status } = getState().bluetooth;

    if (rentalData && isRentalOngoing(rentalData)) {
      expectedCarId = rentalData?.carData?.id;
      expectedQnR = rentalData?.access?.deviceQnr;
      expectsACar = true;
    }

    // everything is as expected
    if (carId === expectedCarId && deviceQnr === expectedQnR) return;

    if (
      expectedCarId &&
      expectedQnR &&
      expectsACar &&
      carId !== expectedCarId &&
      deviceQnr !== expectedQnR
    ) {
      dispatch(setDeviceInfo(expectedCarId, expectedQnR));

      if (status !== 'disabled') {
        dispatch(setBluetoothStatus('disconnected'));
      }
    } else {
      clearConnectedDevice(dispatch);

      if (status !== 'disabled') {
        dispatch(setBluetoothStatus('disconnected'));
      }
    }
  };
