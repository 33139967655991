import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withTextLocalizer,
  dateAndTimeStringFromDate,
  selectBookings,
  timeStringFromDate,
  updateBooking,
  msToHMS,
  cancelBooking,
  selectUser,
  selectAvailableCars,
  locationFromString,
  LocalizePropType,
  setNotification,
  sendVerificationCode,
  isPaymentVerified,
  submitIdentityShopper,
  submitChallenge,
  setRecoverPaymentAuth,
  selectDevicePaired,
  selectFeatures,
  isPayinPayment,
  getBooking,
  parseAddress,
  startBooking,
} from 'getaway-data-layer';
import { useHistory, useParams } from 'react-router-dom';
import { isToday, addMinutes, isBefore } from 'date-fns';
import BookingView from './BookingView';
import {
  dateFromObjectId,
  isBookingAddressAvailable,
  isBookingExpired,
  isBookingStarted,
  navigateTo,
  RENTAL_UI_UPDATER_DURATION,
  showAlert,
} from '../../../utils';
import { openRouteDialog } from '../../../utils/dialogsHelpers';
import { useCustomerSupport } from '../../../hooks/useCustomerSupport';
import { handleRentalViolations } from 'getaway-data-layer/src/helpers/rentalsViolationsHelpers';

type Props = {
  localize: LocalizePropType;
};

const BookingContainer = ({ localize }: Props) => {
  // @ts-ignore
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const bookings = useSelector(selectBookings) as any;
  const user = useSelector(selectUser) as any;
  const devicePaired = useSelector(selectDevicePaired);
  const features = useSelector(selectFeatures);
  const { helpAction } = useCustomerSupport(localize);
  const availableCars = useSelector(selectAvailableCars) as any;
  const selectedBooking = bookings?.find((item: any) => item.id === id);
  const car = selectedBooking?.accepted.car;

  const [selectedInsurance, setSelectedInsurance] = useState('') as any;
  const [sortedInsurances, setSortedInsurances] = useState([]) as any;
  const [bookingCountdown, setBookingCountdown] = useState(null) as any;
  const [paymentExpiryCountdown, setPaymentExpiryCountdown] = useState(
    null
  ) as any;
  const address = parseAddress(car?.releaseAddress);
  const from = selectedBooking?.from;
  const backBy = selectedBooking?.to;
  const fromDate = from ? new Date(from) : null;
  const formattedFrom = fromDate
    ? dateAndTimeStringFromDate(fromDate, localize)
    : '';
  const backByDate = backBy ? new Date(backBy) : null;
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';
  const requestedAt = dateFromObjectId(id);
  const formattedRequestedAt = requestedAt
    ? isToday(requestedAt)
      ? timeStringFromDate(requestedAt)
      : dateAndTimeStringFromDate(requestedAt, localize)
    : ' ';
  const noLaterThanDate = fromDate ? addMinutes(fromDate, 30) : null;
  const formattedNoLaterThanDate = noLaterThanDate
    ? dateAndTimeStringFromDate(noLaterThanDate, localize)
    : ' ';
  const isStarted = isBookingStarted(new Date(from));
  const isExpired = isBookingExpired(new Date(from));
  const title = isExpired
    ? localize('booking.headline.expired')
    : isStarted
    ? localize('booking.headline.started')
    : `${localize('booking.header.in')} ${bookingCountdown}`;
  const isButtonVisible =
    availableCars[car?.id] &&
    car?.status === 'RELEASED' &&
    isBookingAddressAvailable(new Date(from));

  const onClose = () => {
    navigateTo(history, '/map');
  };

  const setInitialInsurance = () => {
    if (car) {
      const { insurancesData } = car;
      const sortedInsurancesArray = [...insurancesData];
      sortedInsurancesArray.sort(
        (a: any, b: any) => b.deductibleAmount - a.deductibleAmount
      );
      const initialSelectedInsurance =
        selectedBooking?.insuranceId &&
        sortedInsurancesArray
          .map((insurance: any) => insurance.id)
          .includes(selectedBooking?.insuranceId)
          ? selectedBooking?.insuranceId
          : sortedInsurancesArray && sortedInsurancesArray.length > 0
          ? sortedInsurancesArray[0].id
          : ' ';
      setSortedInsurances(sortedInsurancesArray);
      setSelectedInsurance(initialSelectedInsurance);
    }
  };

  const onInsuranceSelected = (insuranceId: string) => {
    dispatch(
      updateBooking(selectedBooking?.id, { insuranceId }, () => {
        setSelectedInsurance(insuranceId);
      })
    );
  };

  const onPressReserveNow = () => {
    if (!selectedBooking) return;
    const { accepted } = selectedBooking;

    dispatch(
      startBooking(
        selectedBooking?.id,
        localize,
        null,
        (violations) =>
          handleRentalViolations(
            violations,
            user,
            localize,
            setNotification,
            devicePaired,
            null,
            features,
            sendVerificationCode,
            helpAction,
            isPaymentVerified,
            submitIdentityShopper,
            submitChallenge,
            setRecoverPaymentAuth,
            dispatch,
            history
          ),
        () => navigateTo(history, '/map')
      )
    );
  };

  const onPressCancelBooking = () => {
    showAlert(
      localize('booking.driver.cancel.dialog.title'),
      localize('booking.driver.cancel.dialog.message'),
      localize('booking.driver.cancel.dialog.yes'),
      () => {
        dispatch(
          cancelBooking(selectedBooking?.id, () => {
            history.goBack();
          })
        );
      },
      localize('cancel'),
      () => {},
      true
    );
  };

  const onPressPayNow = () => {
    if (selectedBooking?.paymentLinkUrl) {
      window.open(selectedBooking?.paymentLinkUrl);
    }
  };

  const onAddressClicked = () => {
    openRouteDialog(localize, locationFromString(car?.position), address);
  };

  const onPressExtraOption = (code: any) => {
    dispatch(
      updateBooking(
        selectedBooking.id!,
        {
          orderedOptions: selectedBooking.orderedOptions!.includes(code)
            ? []
            : [code],
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    let countdownUpdaterId: any;
    dispatch(getBooking(id!, () => history.goBack()));
    setInitialInsurance();
    if (selectedBooking && !bookingCountdown) {
      setBookingCountdown(
        msToHMS(
          // @ts-ignore
          Math.abs(new Date(selectedBooking?.from) - new Date()),
          localize
        )
      );
      countdownUpdaterId = setInterval(() => {
        setBookingCountdown(
          msToHMS(
            // @ts-ignore
            Math.abs(new Date(selectedBooking?.from) - new Date()),
            localize
          )
        );
      }, RENTAL_UI_UPDATER_DURATION);
    }

    if (
      selectedBooking?.paymentLinkExpiresAt &&
      isBefore(new Date(), new Date(selectedBooking?.paymentLinkExpiresAt))
    ) {
      setPaymentExpiryCountdown(
        // @ts-ignore
        msToHMS(
          Math.abs(
            // @ts-ignore
            new Date(selectedBooking?.paymentLinkExpiresAt) - new Date()
          ),
          localize
        )
      );
    }

    const expiryCountdownUpdaterId = setInterval(() => {
      setPaymentExpiryCountdown(
        isBefore(
          new Date(),
          // @ts-ignore
          new Date(selectedBooking?.paymentLinkExpiresAt)
        )
          ? // @ts-ignore
            msToHMS(
              Math.abs(
                // @ts-ignore
                new Date(selectedBooking?.paymentLinkExpiresAt) - new Date()
              ),
              localize
            )
          : null
      );
    }, RENTAL_UI_UPDATER_DURATION);

    return () => {
      if (countdownUpdaterId) {
        clearInterval(countdownUpdaterId);
      }
      if (expiryCountdownUpdaterId) {
        clearInterval(expiryCountdownUpdaterId);
      }
    };
  }, []);

  return (
    <BookingView
      localize={localize}
      onClose={onClose}
      carPlate={car?.plate}
      address={address}
      circleName={
        user?.circles?.find(
          (circle: any) => circle?.id === selectedBooking?.circleId
        )?.name
      }
      bookingId={selectedBooking?.presentableId}
      insurancesData={sortedInsurances}
      selectedInsurance={selectedInsurance}
      onInsuranceSelected={onInsuranceSelected}
      from={formattedFrom}
      until={formattedBackBy}
      onPressReserveNow={onPressReserveNow}
      formattedRequestedAt={formattedRequestedAt}
      noLaterThanDate={formattedNoLaterThanDate}
      title={title}
      onPressCancelBooking={onPressCancelBooking}
      isButtonVisible={isButtonVisible}
      onAddressClicked={onAddressClicked}
      paymentLinkUrl={selectedBooking?.paymentLinkUrl}
      paymentLinkStatus={selectedBooking?.paymentLinkStatus}
      paymentExpiryCountdown={paymentExpiryCountdown}
      onPressPayNow={onPressPayNow}
      extras={selectedBooking?.pricingData?.items?.filter(
        (item) => item?.type === 'extra'
      )}
      orderedOptions={selectedBooking?.orderedOptions}
      onPressExtraOption={onPressExtraOption}
    />
  );
};

export default withTextLocalizer(BookingContainer);
