import React from 'react';
import Modal from '@mui/material/Modal';
import './MapFilterView.less';
import {
  dateAndTimeStringFromDate,
  LocalizePropType,
  withTextLocalizer,
} from 'getaway-data-layer';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import { format } from 'date-fns';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { GA_TITLES, isMobileBrowser } from '../../../../utils';
import BrandButton from '../../../components/BrandButton/BrandButton';

type Props = {
  localize: LocalizePropType;
  backByOnly: boolean;
  isOpened: boolean;
  handleClose: () => void;
  isStartTimeUpdated: boolean;
  bookingRange: string;
  selectedStartDate: Date;
  onChangeStartDate: () => void;
  selectedEndDate: Date;
  onChangeEndDate: () => void;
  minStart: string;
  minEnd: string;
  maxEnd: string;
  onReset: () => void;
  onPressFilter: () => void;
  customError: string;
  isSafari: boolean;
  nextBookingStart: Date | null;
  onPressVehicleTable: () => void;
};

const MapFilterView = ({
  localize,
  backByOnly,
  isOpened,
  handleClose,
  isStartTimeUpdated,
  bookingRange,
  selectedStartDate,
  onChangeStartDate,
  selectedEndDate,
  onChangeEndDate,
  minStart,
  minEnd,
  maxEnd,
  onReset,
  onPressFilter,
  customError,
  isSafari,
  nextBookingStart,
  onPressVehicleTable,
}: Props) => {
  return (
    <Modal open={isOpened} onClose={handleClose}>
      <div className="map-filter-content">
        <NavigationBar
          title={
            isStartTimeUpdated ? bookingRange : localize('map.filter.header')
          }
          titleStyles={{ fontSize: 22 }}
          description={localize(
            backByOnly ? 'rental.backby.update.desc' : 'map.filter.description'
          )}
          onClose={handleClose}
          containerStyle={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            width: '100%',
          }}
        />
        {(isSafari || !isMobileBrowser()) && (
          <div className="map-filter-inputs">
            <div style={{ marginBottom: 8 }}>
              <TextField
                id="datetime-local"
                label={localize('map.filter.pickup')}
                type="datetime-local"
                defaultValue={`${format(
                  selectedStartDate,
                  'yyyy-MM-dd'
                )}T${format(selectedStartDate, 'HH:mm')}`}
                value={`${format(selectedStartDate, 'yyyy-MM-dd')}T${format(
                  selectedStartDate,
                  'HH:mm'
                )}`}
                onChange={onChangeStartDate}
                sx={{ width: '250px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: `${format(new Date(minStart), 'yyyy-MM-dd')}T${format(
                    new Date(minStart),
                    'HH:mm'
                  )}`,
                  step: 15,
                }}
                disabled={backByOnly}
              />
            </div>
            <TextField
              id="datetime-local"
              label={localize('map.filter.return')}
              type="datetime-local"
              defaultValue={`${format(selectedEndDate, 'yyyy-MM-dd')}T${format(
                selectedEndDate,
                'HH:mm'
              )}`}
              value={`${format(selectedEndDate, 'yyyy-MM-dd')}T${format(
                selectedEndDate,
                'HH:mm'
              )}`}
              onChange={onChangeEndDate}
              sx={{ width: '250px' }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: `${format(new Date(minEnd), 'yyyy-MM-dd')}T${format(
                  new Date(minEnd),
                  'HH:mm'
                )}`,
                max:
                  !backByOnly && maxEnd
                    ? `${format(new Date(maxEnd), 'yyyy-MM-dd')}T${format(
                        new Date(maxEnd),
                        'HH:mm'
                      )}`
                    : undefined,
                step: 15,
              }}
            />
            {customError && (
              <div className="map-filter-error">{customError}</div>
            )}
          </div>
        )}
        {!isSafari && isMobileBrowser() && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="map-filter-inputs">
              <div style={{ marginRight: 10 }}>
                <MobileDateTimePicker
                  inputFormat="dd-MM-yyyy HH:mm"
                  minDateTime={minStart}
                  minutesStep={15}
                  label={localize('map.filter.pickup')}
                  value={selectedStartDate}
                  onChange={onChangeStartDate}
                  renderInput={(params) => (
                    <TextField className="map-filter-input" {...params} />
                  )}
                  disabled={backByOnly}
                />
              </div>
              <MobileDateTimePicker
                inputFormat="dd-MM-yyy HH:mm"
                minDateTime={minEnd}
                maxDateTime={!backByOnly ? maxEnd : undefined}
                minutesStep={15}
                label={localize('map.filter.return')}
                value={selectedEndDate}
                onChange={onChangeEndDate}
                renderInput={(params) => (
                  <TextField className="map-filter-input" {...params} />
                )}
              />
            </div>
          </LocalizationProvider>
        )}
        <div className="map-filter-bottom">
          {!backByOnly && (
            <div className="map-filter-reset" onClick={onReset}>
              {localize('map.filter.reset')}
            </div>
          )}

          {backByOnly && (
            <div
              className="booking-availability-container"
              onClick={onPressVehicleTable}
            >
              {nextBookingStart && (
                <div>
                  {localize(
                    'rental.vehicle.booking.prelink',
                    dateAndTimeStringFromDate(nextBookingStart, localize)
                  )}
                </div>
              )}
              <div className="booking-availability-link">
                {localize('rental.vehicle.booking.link')}
              </div>
            </div>
          )}

          <BrandButton
            id={GA_TITLES.BOOKING.SET_FILTER}
            containerStyles={
              !backByOnly
                ? {
                    width: 'auto',
                    flex: 1,
                    position: 'unset',
                    left: 'unset',
                    bottom: 'unset',
                    fontSize: '15px',
                    marginLeft: '30px',
                  }
                : {}
            }
            title={localize(
              backByOnly ? 'rental.backby.button' : 'map.filter.check'
            )}
            disabled={!!customError && isSafari}
            onClick={onPressFilter}
          />
        </div>
      </div>
    </Modal>
  );
};

export default withTextLocalizer(MapFilterView);
