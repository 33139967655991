import * as yup from 'yup';
import { postalCodePattern } from 'getaway-data-layer';

export const ADDRESS_STREET_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(.*?)\s+([\d]+\s*[a-zA-Z]?([\/\-]?\s*[\d]+[a-zA-Z]?)?)\s*$/;

export const ADDRESS_STREET_NUMBER_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(.*?)\s+([\d]+\s*[a-zA-Z]?([/\-]?\s*[\d]+[a-zA-Z]?)?)\s*$/;

export const AddressModal = (user) => ({
  streetName: user?.address?.streetName || '',
  streetNumber: user?.address?.streetNumber || '',
  postalCode: user?.address?.postalCode || '',
  city: user?.address?.city || '',
  country: user?.address?.country || 'DE',
});

export const AddressSchema = (localize: any, country: string): any =>
  yup.object({
    streetName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^([A-ZÄÖÜ|a-zäöüß .\-0-9]{4,}|[A-z]+[1-9][0-9]?[A-z]*)$/,
        localize('input.error.invalid')
      ),
    streetNumber: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^(([a-zA-Z]\s?)?[1-9][0-9]{0,2}(\s?[a-zA-Z])?(([/,-]([a-zA-Z]\s?)?[1-9,a-z][0-9]{0,2}(\s?[a-zA-Z])?){0,3}))$/,
        localize('input.error.invalid')
      ),
    postalCode: yup
      .string()
      .required(localize('validation.required'))
      .test('isValidPostalCode', localize('input.error.invalid'), (value) =>
        postalCodePattern(country, value)
      ),
    city: yup.string().required(localize('validation.required')),
    country: yup.string().required(localize('validation.required')),
  });
