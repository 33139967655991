import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withTextLocalizer,
  cancelRental,
  selectRentalData,
  getRentalData,
  updateRental,
  selectBrandSettings,
  timeStringFromDate,
  getDriverCarStatus,
  dateAndTimeStringFromDate,
  unlockCar,
  setOpened,
  setOpenedModal,
  locationFromString,
  selectLastKnownLocation,
  selectCardState,
  setCardState,
  LocalizePropType,
  selectIsUnlockingCar,
  parseAddress,
  blockPayment,
} from 'getaway-data-layer';
import { addMinutes, isToday } from 'date-fns';
import ReservedCardView from './ReservedCardView';
import {
  dateFromObjectId,
  distanceBetweenLocationsInKm,
  GA_TITLES,
  reportGAEvent,
  getCurrentPosition,
} from '../../../../utils';
import { showAlert } from '../../../../utils/showAlert';

import { openRouteDialog } from '../../../../utils/dialogsHelpers';
export const RENTAL_UPDATER_DURATION = 300000;

type Props = {
  localize: LocalizePropType;
};

const ReservedCardContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const rentalData = useSelector(selectRentalData) as any;
  const brandSettings = useSelector(selectBrandSettings) as any;
  const lastKnownLocation = useSelector(selectLastKnownLocation);
  const cardState = useSelector(selectCardState);
  const isUnlocking = useSelector(selectIsUnlockingCar);

  const nextToCar = useMemo(() => {
    return (
      rentalData?.carData?.position &&
      lastKnownLocation &&
      distanceBetweenLocationsInKm(
        locationFromString(rentalData?.carData?.position),
        lastKnownLocation
      ) *
        1000 <
        50
    );
  }, [lastKnownLocation]);

  const isOriginallyBooking = !!rentalData.bookingId;
  const reservedAt = dateFromObjectId(rentalData.id);
  const formattedReservedAt = reservedAt
    ? isToday(reservedAt)
      ? timeStringFromDate(reservedAt)
      : dateAndTimeStringFromDate(reservedAt, localize)
    : ' ';
  const requestedAt = isOriginallyBooking
    ? dateFromObjectId(rentalData.bookingId)
    : null;
  const formattedRequestedAt = requestedAt
    ? isToday(requestedAt)
      ? timeStringFromDate(requestedAt)
      : dateAndTimeStringFromDate(requestedAt, localize)
    : ' ';
  const startDate = isOriginallyBooking
    ? new Date(rentalData.bookingFrom)
    : null;
  const backByDate = isOriginallyBooking
    ? new Date(rentalData.bookingTo)
    : null;
  const formattedFrom = startDate
    ? dateAndTimeStringFromDate(startDate, localize)
    : '';
  const formattedBackBy = backByDate
    ? dateAndTimeStringFromDate(backByDate, localize)
    : '';

  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [sortedInsurances, setSortedInsurances] = useState([]) as any[];
  const address = parseAddress(rentalData?.startAddress);
  const onInsuranceSelected = (insuranceId: string) => {
    dispatch(
      updateRental(rentalData.id, { insuranceId }, () => {
        setSelectedInsurance(insuranceId);
      })
    );
  };

  const toggleCard = () => {
    if (cardState === 'Hidden') {
      dispatch(setCardState('Expanded'));
    } else {
      dispatch(setCardState('Hidden'));
    }
  };

  const onCancelRental = () => {
    dispatch(cancelRental());
    dispatch(getRentalData());
  };

  const setInitialInsurance = () => {
    if (rentalData?.carData) {
      const { insurancesData } = rentalData.carData;
      const { insuranceData } = rentalData;
      const initialSortedInsurances = insurancesData ? [...insurancesData] : [];
      initialSortedInsurances.sort(
        (a: any, b: any) => b.deductibleAmount - a.deductibleAmount
      );
      const initialSelectedInsurance = insuranceData?.id
        ? insuranceData.id
        : brandSettings?.defaultInsurance?.id &&
          sortedInsurances
            .map((insurance: any) => insurance.id)
            .includes(brandSettings?.defaultInsurance?.id)
        ? brandSettings?.defaultInsurance.id
        : sortedInsurances && sortedInsurances.length > 0
        ? sortedInsurances[0].id
        : ' ';
      updateRental(rentalData.id, { insuranceId: selectedInsurance });
      setSortedInsurances(initialSortedInsurances);
      setSelectedInsurance(initialSelectedInsurance);
    }
  };

  const onRentalCancel = () => {
    showAlert(
      localize('trip.cancel.dialog.title'),
      '',
      localize('ok'),
      () => dispatch(cancelRental()),
      localize('cancel')
    );
  };

  const onClickUnlock = (updatedRental = null) => {
    const rental = updatedRental || rentalData;
    const isServiceTrip = rental?.type === 'serviceTrip';
    if (rental?.id && !isUnlocking) {
      dispatch(
        getDriverCarStatus(rental?.id, false, () =>
          dispatch(getRentalData(rental?.id))
        )
      );
      if (rental?.pendingBlockPayment) {
        showAlert(
          localize('car.reserve.blockPayment.title'),
          localize('car.reserve.blockPayment.description'),
          localize('car.reserve.blockPayment.yes.text'),
          () =>
            dispatch(
              blockPayment(rental?.id, (data) => {
                onClickUnlock(data);
              })
            ),
          localize('cancel'),
          () => {}
        );
      } else if (nextToCar) {
        dispatch(
          unlockCar(
            rental?.id,
            !isServiceTrip && brandSettings?.allroundPhotosMandatory,
            () => {
              dispatch(setOpened(true));
              reportGAEvent(GA_TITLES.RENTAL.UNLOCK_SUCCESS);
            }
          )
        );
      } else {
        if (lastKnownLocation) {
          showAlert(
            localize('car.unlock.tooFar.title'),
            localize('car.unlock.tooFar.message'),
            localize('ok'),
            () => {},
            localize('cancel')
          );
        } else {
          if (brandSettings?.webUnlockFromAnywhere) {
            dispatch(
              unlockCar(
                rental?.id,
                !isServiceTrip && brandSettings?.allroundPhotosMandatory,
                () => {
                  dispatch(setOpened(true));
                  reportGAEvent(GA_TITLES.RENTAL.UNLOCK_SUCCESS);
                }
              )
            );
          } else {
            getCurrentPosition({
              onSuccess: () => {
                onClickUnlock();
              },
              onError: () => {
                reportGAEvent(GA_TITLES.RENTAL.UNLOCK_FAIL);
                showAlert(
                  localize('car.unlock.locationpermission.dialog.title'),
                  localize('car.unlock.locationpermission.dialog.message'),
                  localize('ok'),
                  () => {},
                  localize('cancel')
                );
              },
            });
          }
        }
      }
    }
  };

  const onClickCarProfile = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  const onAddressClicked = () => {
    openRouteDialog(
      localize,
      locationFromString(rentalData?.carData?.position),
      address
    );
  };

  const onPressExtraOption = (code: any) => {
    dispatch(
      updateRental(
        rentalData?.id!,
        {
          orderedOptions: rentalData?.orderedOptions!.includes(code)
            ? []
            : [code],
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    const rentalUpdaterId = setInterval(() => {
      dispatch(getRentalData(rentalData?.id));
    }, RENTAL_UPDATER_DURATION);
    setInitialInsurance();
    return () => {
      if (rentalUpdaterId) clearInterval(rentalUpdaterId);
    };
  }, []);

  return (
    <ReservedCardView
      localize={localize}
      // @ts-ignore
      time={addMinutes(reservedAt, 30)}
      nextToCar={nextToCar}
      isUnlocking={isUnlocking}
      toggleCard={toggleCard}
      onExpire={onCancelRental}
      carPlate={rentalData?.carData?.plate}
      address={address}
      insurancesData={sortedInsurances}
      selectedInsurance={selectedInsurance}
      onInsuranceSelected={onInsuranceSelected}
      isOriginallyBooking={isOriginallyBooking}
      formattedReservedAt={formattedReservedAt}
      formattedRequestedAt={formattedRequestedAt}
      from={formattedFrom}
      until={formattedBackBy}
      tripId={rentalData?.presentableId}
      onRentalCancel={onRentalCancel}
      onClickUnlock={onClickUnlock}
      onClickCarProfile={onClickCarProfile}
      onAddressClicked={onAddressClicked}
      extras={rentalData?.pricingData?.items?.filter(
        (item) => item?.type === 'extra'
      )}
      orderedOptions={rentalData?.orderedOptions}
      onPressExtraOption={onPressExtraOption}
    />
  );
};

export default withTextLocalizer(ReservedCardContainer);
