import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/BluetoothTypes';
import { BluetoothAction, BluetoothState } from '../../../@types';

export default (
  state: BluetoothState = initialState,
  action: BluetoothAction
): BluetoothState => {
  switch (action.type) {
    case TYPES.SET_BLUETOOTH_STATUS: {
      return { ...state, status: action.payload.status };
    }

    case TYPES.SET_DEVICE_INFO: {
      return {
        ...state,
        connectedDevice: {
          ...state.connectedDevice,
          carId: action.payload.carId,
          deviceQnr: action.payload.deviceQnr,
        },
      };
    }

    case TYPES.SET_ACCESS_TOKEN: {
      return {
        ...state,
        connectedDevice: {
          ...state.connectedDevice,
          accessToken: action.payload.accessToken,
        },
      };
    }

    case TYPES.SET_CAR_STATUS: {
      return {
        ...state,
        connectedDevice: {
          ...state.connectedDevice,
          carStatus: action.payload.carStatus,
        },
      };
    }

    case TYPES.CLEAR_CONNECTED_DEVICE: {
      return {
        ...state,
        connectedDevice: initialState.connectedDevice,
      };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
