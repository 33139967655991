import React from 'react';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import { TextField, Box } from '@mui/material';
import { FormikValues } from 'formik';
import './AddCCPaymentView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import IdentifyShopperContainer from '../IdentifyShopper/IdentifyShopperContainer';
import RedirectContainer from '../RedirectCCPayment/RedirectContainer';
import ChallengeContainer from '../Challenge/ChallengeContainer';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../../../utils';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  formik: FormikValues;
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
  redirectData: any;
  identifyShopperData: any;
  challengeShopperData: any;
  isAddingPayment: boolean;
  onChangeNumber: (e: any) => void;
  onChangeExpiry: (e: any) => void;
  onChangeCVC: (e: any) => void;
  onNumberError: (e: any) => void;
  onExpiryError: (e: any) => void;
  onCVCError: (e: any) => void;
  onClose: () => void;
};

const AddCCPaymentView = ({
  localize,
  formik,
  cardNumber,
  cardExpiry,
  cardCVC,
  redirectData,
  identifyShopperData,
  challengeShopperData,
  isAddingPayment,
  onChangeNumber,
  onChangeExpiry,
  onChangeCVC,
  onNumberError,
  onExpiryError,
  onCVCError,
  onClose,
}: Props) => {
  const validating =
    !!redirectData || !!identifyShopperData || !!challengeShopperData;
  const hideAddCCButton = !!challengeShopperData || !!redirectData;

  return (
    <div className="screen-container cc-screen">
      <NavigationBar
        title={localize('payment.card.add.title')}
        description={localize('payment.card.add.description')}
        showHelp
        showArrowBack
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'cc.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'cc.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          {!validating && (
            <CreditCardInput
              cardNumberInputProps={{
                value: cardNumber,
                onChange: onChangeNumber,
                onError: onNumberError,
                autoComplete: 'cc-number',
              }}
              cardExpiryInputProps={{
                value: cardExpiry,
                onChange: onChangeExpiry,
                onError: onExpiryError,
                autoComplete: 'cc-exp',
              }}
              cardCVCInputProps={{
                value: cardCVC,
                onChange: onChangeCVC,
                onError: onCVCError,
                autoComplete: 'cc-csc',
              }}
              fieldClassName="add-cc-field"
              dangerTextClassName="add-cc-danger-text"
              customTextLabels={{
                invalidCardNumber: localize('input.error.invalid'),
                expiryError: {
                  invalidExpiryDate: localize('input.error.invalid'),
                },
                invalidCvc: localize('input.error.invalid'),
                cardNumberPlaceholder: localize('card.number'),
                expiryPlaceholder: 'MM/JJ',
                cvcPlaceholder: 'CVC',
              }}
            />
          )}
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            {!validating && (
              <TextField
                required
                fullWidth
                id="name"
                sx={{ mt: 2 }}
                label={localize('firstSteps.payment.sepa.name.placeholder')}
                helperText={(formik.touched.name && formik.errors.name) || ''}
                error={formik.touched.name && Boolean(formik.errors.name)}
                variant="outlined"
                autoComplete="cc-name"
                size="medium"
                type="text"
                {...formik.getFieldProps('name')}
              />
            )}
            {!hideAddCCButton && (
              <BrandButton
                id={
                  validating || isAddingPayment
                    ? GA_TITLES.ON_BOARDING.VALIDATE_CC
                    : GA_TITLES.ON_BOARDING.ADD_CC
                }
                title={localize(
                  validating || isAddingPayment
                    ? 'payment.card.add.verifying'
                    : 'firstSteps.payment.sepa.submit.text'
                )}
                disabled={validating || isAddingPayment}
                isLoading={validating || isAddingPayment}
                formik={formik}
              />
            )}
          </Box>
          {!!redirectData && (
            <>
              <RedirectContainer redirectData={redirectData} />
              <iframe
                className="add-cc-redirect-iframe"
                id="redirect-iframe"
                name="redirect-iframe"
              />
            </>
          )}
          {!!identifyShopperData && (
            <>
              <IdentifyShopperContainer
                identifyShopperData={identifyShopperData}
              />
              <iframe
                className="add-cc-identify-iframe"
                id="identify-iframe"
                name="identify-iframe"
              />
            </>
          )}
          {!!challengeShopperData && (
            <>
              <ChallengeContainer challengeShopperData={challengeShopperData} />
              <iframe
                className="add-cc-challenge-iframe"
                id="challenge-iframe"
                name="challenge-iframe"
              />
            </>
          )}
        </div>
        {isLocalizeAdded(localize, 'cc.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('cc.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default AddCCPaymentView;
