import { addMinutes, subMinutes, isAfter, subHours } from 'date-fns';

export const RENTAL_UPDATER_DURATION_SHORT = 10000;
export const CAR_STATUS_UPDATER_DURATION_SHORT = 60000;
export const RENTAL_UI_UPDATER_DURATION = 15000;

export const INITIAL_RETURN_TIME = 180;
export const MIN_RETURN_TIME = 60;
export const MIN_BOOKING_START = 0;
export const RESERVATION_DURATION = 30;
export const MIN_BOOKING_ADDRESS_SHOW = 2;

export const minimumStartTime = (): Date => {
  const currentMinutes = new Date().getMinutes();
  return addMinutes(
    new Date(),
    currentMinutes < 15
      ? 15 - currentMinutes
      : currentMinutes < 30
      ? 30 - currentMinutes
      : currentMinutes < 45
      ? 45 - currentMinutes
      : 60 - currentMinutes
  );
};

export const minimumReturnTime = (date: Date = new Date()): Date =>
  addMinutes(date, MIN_RETURN_TIME);

export const initialReturnTime = (date: Date = new Date()): Date =>
  addMinutes(date, INITIAL_RETURN_TIME);

export const isBookingStarted = (fromDate: Date): boolean =>
  isAfter(new Date(), subMinutes(new Date(fromDate), 0));

export const isBookingExpired = (fromDate: Date): boolean =>
  isAfter(
    new Date(),
    addMinutes(new Date(fromDate), RESERVATION_DURATION - MIN_BOOKING_START)
  );

export const isBookingAddressAvailable = (fromDate: Date): boolean =>
  isAfter(new Date(), subHours(new Date(fromDate), MIN_BOOKING_ADDRESS_SHOW));
