import { APIResponse, S3TemporaryAccessData } from '../../@types';

export type RentalImagePath =
  | 'serviceImage'
  | 'startCheckImage'
  | 'endCheckImage';

export const awsS3Client = {
  getRentalCredential: async (
    rentalId: string
  ): Promise<APIResponse<S3TemporaryAccessData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: `/aws/s3/temporaryCredentials/trip/${rentalId}`,
    });
    return {
      data,
      error,
    };
  },
};
