import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setActiveAlert } from '../redux/store/ui/map';

export type Props = {
  setActiveAlert: typeof setActiveAlert;
};
const withAddDamages: any = compose(
  connect(null, {
    setActiveAlert,
  })
);
export default withAddDamages;
