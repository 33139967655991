import { Image } from './CameraTypes';

export type ConnectCameraState = {
  readonly vehicleRegistration: Image | null;
  readonly fuelCardFront: Image | null;
  readonly fuelCardBack: Image | null;
};
enum ConnectCameraTypes {
  SET_VEHICLE_REGISTRATION = 'connect/camera/SET_VEHICLE_REGISTRATION',
  SET_FUEL_CARD_FRONT = 'connect/camera/SET_FUEL_CARD_FRONT',
  SET_FUEL_CARD_BACK = 'connect/camera/SET_FUEL_CARD_BACK',
}
export default ConnectCameraTypes;
export type SetVehicleRegistration = {
  type: ConnectCameraTypes.SET_VEHICLE_REGISTRATION;
  payload: {
    vehicleRegistration: Image | null;
  };
};
export type SetFuelCardFront = {
  type: ConnectCameraTypes.SET_FUEL_CARD_FRONT;
  payload: {
    fuelCardFront: Image | null;
  };
};
export type SetFuelCardBack = {
  type: ConnectCameraTypes.SET_FUEL_CARD_BACK;
  payload: {
    fuelCardBack: Image | null;
  };
};

export type ConnectCameraAction =
  | SetVehicleRegistration
  | SetFuelCardFront
  | SetFuelCardBack;
