export type DamagesState = {
  readonly newDamages: Array<string>;
};

enum DamagesTypes {
  SET_NEW_DAMAGE = 'ui/damages/SET_NEW_DAMAGE',
  CLEAR_NEW_DAMAGES = 'ui/damages/CLEAR_NEW_DAMAGES',
}

export default DamagesTypes;

export type SetNewDamage = {
  type: DamagesTypes.SET_NEW_DAMAGE;
  payload: {
    id: string;
  };
};

export type ClearNewDamages = {
  type: DamagesTypes.CLEAR_NEW_DAMAGES;
};

export type DamagesAction = SetNewDamage | ClearNewDamages;
