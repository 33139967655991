import React from 'react';
import './ToggleTab.less';
import { LocalizePropType } from 'getaway-data-layer';

interface ToggleTabProps {
  localize: LocalizePropType;
  onToggleValueChange: () => void;
  isOptionOneSelected: boolean; // Renamed for clarity
  optionOne: string;
  optionTwo: string;
  hideOR?: boolean;
}

const ToggleTab: React.FC<ToggleTabProps> = ({
  localize,
  isOptionOneSelected,
  optionOne,
  optionTwo,
  onToggleValueChange,
  hideOR = false,
}) => {
  const leftOptionClass = `toggle-tab-option-left${
    isOptionOneSelected ? '-active' : ''
  } noselect`;
  const rightOptionClass = `toggle-tab-option-right${
    !isOptionOneSelected ? '-active' : ''
  } noselect`;

  return (
    <div className="toggle-tab-container" onClick={onToggleValueChange}>
      <div className={leftOptionClass}>{optionOne}</div>
      {!hideOR && (
        <div className="toggle-tab-or noselect">{localize('toggle.or')}</div>
      )}
      <div className={rightOptionClass}>{optionTwo}</div>
    </div>
  );
};

export default ToggleTab;
