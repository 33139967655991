import * as yup from 'yup';

export const ResetPasswordModal = {
  password: '',
};

export const ResetPasswordSchema = (localize: any, regex?: string): any => {
  const regexObj = regex
    ? new RegExp(regex)
    : /^(?=(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[#?!@$%^&*-]))(?=.*?[a-z]).{8,}$/;
  return yup.object({
    password: yup
      .string()
      .required(localize('validation.required'))
      .matches(regexObj, localize('input.password.error.invalid')),
  });
};
