import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deletePayment,
  selectRentalData,
  selectUser,
  updatePayment,
  withTextLocalizer,
} from 'getaway-data-layer';
import { WithTextLocalizer } from 'getaway-data-layer';
import PaymentProfileView from './PaymentProfileView';
import { showAlert } from '../../../../../utils';
import { navigateTo } from '../../../../../utils';
import { useHistory, useParams } from 'react-router-dom';
import BillingAddressContainer from '../BillingAddress/BillingAddressContainer';

type Props = {
  route: any;
  navigation: any;
} & WithTextLocalizer;

const PaymentProfileContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore
  const { id } = useParams();
  const rentalData = useSelector(selectRentalData) as any;

  const user = useSelector(selectUser);
  const selectedPayment = user?.paymentMethods?.find(
    (payment) => payment.id === id
  );

  const [isPrivate, setIsPrivate] = useState(
    selectedPayment?.billingInfo?.billingProfile
      ? selectedPayment?.billingInfo?.billingProfile === 'personal'
      : true
  );
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [uiBillingInfo, setUiBillingInfo] = useState(
    selectedPayment?.billingInfo
  );

  const [isDefault, setIsDefault] = useState(
    selectedPayment?.defaultOne !== undefined
      ? selectedPayment?.defaultOne
      : selectedPayment
      ? user?.paymentMethods?.length === 1
      : true
  );

  const onPressBack = () => {
    if (selectedPayment?.id) {
      dispatch(
        updatePayment(selectedPayment.id, {
          defaultOne: isDefault,
          billingInfo: {
            billingProfile: isPrivate ? 'personal' : 'business',
            country: uiBillingInfo?.country,
            city: uiBillingInfo?.city,
            streetName: uiBillingInfo?.streetName,
            streetNumber: uiBillingInfo?.streetNumber,
            postalCode: uiBillingInfo?.postalCode,
            companyName: uiBillingInfo?.companyName,
            companyContact: uiBillingInfo?.companyContact,
          },
        })
      );
    }
    history.goBack();
  };

  const onPressAddPayment = () => {
    navigateTo(history, '/dashboard/selectPayment');
  };

  const onDeletePayment = (id: string) => {
    if (rentalData?.carMode) {
      showAlert(
        '',
        localize('user.logout.cancelReservation.message'),
        null,
        null,
        localize('user.logout.cancelReservation.ok'),
        () => {}
      );
    } else {
      showAlert(
        localize('user.deletePayment.dialog.title'),
        localize('user.deletePayment.dialog.message'),
        localize('user.deletePayment.dialog.yes'),
        () => {
          dispatch(deletePayment(id));
          history.goBack();
        },
        localize('user.deletePayment.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onPressAddBillingAddress = () => {
    setIsAddressModalOpen(true);
  };

  const onPressReset = () => {
    setIsPrivate(
      selectedPayment?.billingInfo?.billingProfile
        ? selectedPayment?.billingInfo?.billingProfile === 'personal'
        : true
    );
    setIsDefault(
      selectedPayment?.defaultOne !== undefined
        ? selectedPayment?.defaultOne
        : selectedPayment
        ? user?.paymentMethods?.length === 1
        : true
    );
  };

  const onChangeDefault = () => {
    setIsDefault(!isDefault);
  };

  const onChangeIsPrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const onCloseAddressModal = () => {
    setIsAddressModalOpen(false);
  };

  const onUpdateBillingInfo = (billingInfo) => {
    setUiBillingInfo(billingInfo);
  };

  return (
    <>
      <BillingAddressContainer
        billingInfo={{
          isPrivate,
          billingProfile: isPrivate ? 'personal' : 'business',
          ...uiBillingInfo,
        }}
        isAddressModalOpen={isAddressModalOpen}
        onCloseAddressModal={onCloseAddressModal}
        onUpdateBillingInfo={onUpdateBillingInfo}
      />
      <PaymentProfileView
        localize={localize}
        onPressBack={onPressBack}
        onPressDeletePayment={onDeletePayment}
        onPressAddPayment={onPressAddPayment}
        onPressAddBillingAddress={onPressAddBillingAddress}
        onPressReset={onPressReset}
        onChangeDefault={onChangeDefault}
        isDefault={isDefault}
        isPrivate={isPrivate}
        paymentMethods={user?.paymentMethods}
        onChangeIsPrivate={onChangeIsPrivate}
        selectedPayment={selectedPayment}
        billingInfo={uiBillingInfo}
        // billingInfo={route?.params?.billingInfo || selectedPayment?.billingInfo}
      />
    </>
  );
};

export default withTextLocalizer(PaymentProfileContainer);
