import { isAndroid } from '../helpers/platformHelpers';
import { injectedAlert } from '../redux/ConfigureGlobals';

export const UIManager = {
  nativeManager: null as any,

  showAlert(
    title: string | null | undefined,
    message: string | null | undefined,
    yesText: string,
    noText: string,
    yesAction: () => void,
    noAction: () => void = () => {}
  ) {
    if (isAndroid()) {
      injectedAlert?.alert(
        title || '',
        message || '',
        [
          {
            text: noText || 'Cancel',
            onPress: noAction,
            style: 'cancel',
          },
          {
            text: yesText || 'OK',
            onPress: yesAction,
          },
        ],
        { cancelable: true }
      );
    }
  },

  showListAlert(
    title: string | null | undefined,
    items: Array<string>,
    onItemClicked: (arg0: Record<string, any>) => void
  ) {
    if (isAndroid()) {
      this.nativeManager?.showListAlert(title, items, onItemClicked);
    }
  },
};
