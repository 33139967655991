import { RentalData } from '../../Types.api';

export type ServiceState = Array<RentalData>;

enum ServiceTypes {
  SET_SERVICES = '/service/SET_SERVICES',
  RESET_STORE = 'RESET_STORE',
}

export default ServiceTypes;

export type SetServices = {
  type: ServiceTypes.SET_SERVICES;
  payload: {
    services: Array<RentalData>;
  };
};

export type ServiceAction = SetServices;
