import React from 'react';
import {
  callPhoneNumber,
  locationFromString,
  statusForGasStation,
  withTextLocalizer,
  LocalizePropType,
} from 'getaway-data-layer';
import GasStationView from './GasStationView';
import { openRouteDialog } from '../../../utils/dialogsHelpers';

type Props = {
  localize: LocalizePropType;
  gasStation: any;
};

const GasStationContainer = ({ localize, gasStation }: Props) => {
  const onClickNavigation = () => {
    openRouteDialog(
      localize,
      locationFromString(gasStation?.location),
      gasStation?.['Address'] || ''
    );
  };

  const onClickPhone = async () => {
    if (gasStation?.Telephone) {
      callPhoneNumber(gasStation?.Telephone);
    }
  };

  return (
    <GasStationView
      localize={localize}
      brand={gasStation?.Brand}
      status={statusForGasStation(localize, gasStation)}
      onClickNavigation={onClickNavigation}
      onClickPhone={onClickPhone}
    />
  );
};

export default withTextLocalizer(GasStationContainer);
