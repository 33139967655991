import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateUser,
  selectPhoneNumber,
  withTextLocalizer,
  LocalizePropType,
  isFRM,
  selectUser,
  isKYCApp,
} from 'getaway-data-layer';
import {
  attachInputListeners,
  GA_TITLES,
  isDashboardScreen,
  isTariffOpen,
  navigateTo,
  removeInputListeners,
  reportGAEvent,
  TALLY_LINKS,
} from '../../../../utils';
import VerifyPhoneView from './VerifyPhoneView';

type Props = {
  localize: LocalizePropType;
};

const SKIP_AFTER_DURATION = 15;
const RESEND_COUNTDOWN = 15;

const VerifyPhoneContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storedPhoneNumber: any = useSelector(selectPhoneNumber);
  const user = useSelector(selectUser);
  const [skipCountdown, setSkipCountdown] = useState(
    SKIP_AFTER_DURATION
  ) as any;
  const [resendCountdown, setResendCountdown] = useState(
    RESEND_COUNTDOWN
  ) as any;

  const onSuccess = () => {
    if (isTariffOpen(user)) {
      if (isFRM()) {
        navigateTo(
          history,
          isDashboardScreen()
            ? '/settings'
            : `/onboarding/dynamicForm?url=${TALLY_LINKS.FRM_PARTNER_AREA}`
        );
      } else {
        navigateTo(
          history,
          isDashboardScreen() ? '/settings' : `/onboarding/tariff`
        );
      }
    } else {
      navigateTo(
        history,
        isDashboardScreen()
          ? '/settings'
          : isKYCApp()
          ? '/onboarding/IDOnboarding'
          : '/onboarding/addAddress'
      );
    }
    reportGAEvent(GA_TITLES.ON_BOARDING.PHONE_CODE_SUBMITTED);
  };

  const onSubmit = (phoneCode: string) => {
    dispatch(
      updateUser({ phoneNumberVerificationCode: phoneCode.trim() }, onSuccess)
    );
  };

  const onResendPressed = () => {
    dispatch(
      updateUser({ phoneNumber: storedPhoneNumber }, () => {
        setResendCountdown(RESEND_COUNTDOWN);
      })
    );
  };

  const onClose = () => {
    history.goBack();
  };

  const onPressSkip = () => {
    onSuccess();
  };

  const onPressPhone = () => {
    history.goBack();
  };

  useEffect(() => {
    attachInputListeners();
    const countdownUpdaterId = setInterval(() => {
      setSkipCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;

        if (newCountdown <= 0) {
          clearInterval(countdownUpdaterId);
          return 0;
        }

        return newCountdown;
      });
    }, 1000);

    const resendCountdownUpdaterId = setInterval(() => {
      setResendCountdown((prevCountdown) => {
        return prevCountdown - 1;
      });
    }, 1000);

    setTimeout(() => {
      const inputElement = document?.querySelector(
        'input[name="one-time-code"]'
      );
      // @ts-ignore
      inputElement && inputElement?.click();
    }, 300);

    return () => {
      if (countdownUpdaterId) {
        clearInterval(countdownUpdaterId);
      }
      if (countdownUpdaterId) {
        clearInterval(resendCountdownUpdaterId);
      }
      removeInputListeners();
    };
  }, []);

  return (
    <VerifyPhoneView
      localize={localize}
      phoneNumber={storedPhoneNumber}
      skipCountdown={skipCountdown}
      resendCountdown={resendCountdown}
      onClose={onClose}
      onSubmit={onSubmit}
      onResendPressed={onResendPressed}
      onPressSkip={onPressSkip}
      onPressPhone={onPressPhone}
    />
  );
};

export default withTextLocalizer(VerifyPhoneContainer);
