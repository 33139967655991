import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  msToHour,
  msToHMS,
  formatDistance,
  datesAreOnSameDay,
  timeStringFromDate,
  formatMonthFromDate,
  dateAndTimeStringFromDate,
  LocalizePropType,
  RentalHistoryData,
  BookingData,
  DriverBookingData,
} from 'getaway-data-layer';
import './UserRentalsView.less';
import { format, isToday } from 'date-fns';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import RentalHistoryItem from '../../../../components/RentalHistoryItem/RentalHistoryItem';
import ActiveBookingItem from '../../../../components/ActiveBookingItem/ActiveBookingItem';
import { titleForDriverCar } from '../../../../../utils';
import BookingHistoryItem from '../../../../components/BookingHistoryItem/BookingHistoryItem';
import Plate from '../../../../components/Plate/Plate';

type Props = {
  localize: LocalizePropType;
  rentals: Array<RentalHistoryData>;
  bookings: Array<DriverBookingData>;
  onLoadMoreRentals: () => void;
  onClickRentalItem: (tripId: string) => void;
  onClickConfirmedBooking: (bookingId: string) => void;
  onClickOfferedBooking: (booking: BookingData) => void;
  onClickStartedBooking: () => void;
  onClickCancelledBooking: (booking: BookingData) => void;
  onClose: () => void;
};

const UserRentalsView = ({
  localize,
  rentals,
  bookings,
  onLoadMoreRentals,
  onClickRentalItem,
  onClickStartedBooking,
  onClickOfferedBooking,
  onClickConfirmedBooking,
  onClickCancelledBooking,
  onClose,
}: Props) => {
  const formattedFrom = (date: any) =>
    date
      ? isToday(date)
        ? timeStringFromDate(date)
        : dateAndTimeStringFromDate(date, localize)
      : localize('now');
  const formattedBackBy = (backDate: any, startDate: any) =>
    backDate
      ? isToday(backDate)
        ? timeStringFromDate(backDate)
        : datesAreOnSameDay(backDate, startDate)
        ? timeStringFromDate(backDate)
        : dateAndTimeStringFromDate(backDate, localize)
      : '';
  const activeBookingsList =
    bookings?.filter((booking) =>
      ['confirmed', 'started', 'offered', 'waiting'].includes(booking.status!)
    ) || [];
  const bookingsList =
    bookings?.filter((booking) => ['cancelled'].includes(booking.status!)) ||
    [];
  const rentalsList = [...rentals].filter((item) =>
    ['finished'].includes(item.status)
  );
  const history: any = [...bookingsList, ...rentalsList];
  history.sort(
    (
      a: { from: any; initialUnlock: any; created: any },
      b: { from: any; initialUnlock: any; created: any }
    ) =>
      (new Date(b.from || b.initialUnlock || b.created) as any) -
      (new Date(a.from || a.initialUnlock || a.created) as any)
  );
  const dataSource = [...activeBookingsList, ...history];
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('dashboard.myRentals')}
        description=""
        showHelp
        onClose={onClose}
      />
      <div
        id="rentals-history"
        className="screen-body"
        style={{ paddingTop: 0 }}
      >
        {dataSource.length === 0 && (
          <div className="user-rentals-empty">
            <img
              src="/images/emptyRecord.png"
              className="user-rentals-empty-records"
              alt="empty-records"
            />
            <div className="user-rentals-empty-text">
              {localize('noRentals')}
            </div>
          </div>
        )}
        <InfiniteScroll
          dataLength={dataSource.length}
          next={onLoadMoreRentals}
          hasMore
          scrollableTarget="rentals-history"
          loader={<h4> </h4>}
        >
          {dataSource.map((item, index) => {
            const prevItem =
              index > activeBookingsList.length
                ? history[index - activeBookingsList.length - 1]
                : null;
            const showMonthLabel =
              index === activeBookingsList.length ||
              (index > activeBookingsList.length &&
                new Date(
                  prevItem?.from || prevItem?.initialUnlock || prevItem?.created
                ).getUTCMonth() !==
                  new Date(
                    item?.from || item?.initialUnlock || item?.created
                  ).getUTCMonth());

            return item?.status === 'confirmed' ||
              item?.status === 'started' ||
              item?.status === 'offered' ||
              item?.status === 'waiting' ? (
              <ActiveBookingItem
                title={
                  item?.accepted?.car
                    ? titleForDriverCar(item.accepted.car)
                    : item?.status === 'offered' || item?.status === 'waiting'
                    ? item?.presentableId
                    : localize('booking.car.unavailable')
                }
                description={`${formattedFrom(
                  new Date(item.from)
                )} - ${formattedBackBy(
                  new Date(item.to),
                  new Date(item.from)
                )}`}
                value={
                  item?.status === 'started'
                    ? item?.rental?.carMode !== 'waitingForCheckin'
                      ? localize('booking.label.started')
                      : localize('booking.label.waiting.checkin')
                    : msToHMS(
                        (new Date(item.from) as any) - (new Date() as any),
                        localize
                      )
                }
                status={item?.status}
                carMode={item?.rental?.carMode}
                onClick={
                  item?.status === 'started'
                    ? item?.rental?.carMode !== 'waitingForCheckin'
                      ? () => onClickStartedBooking()
                      : () => onClickRentalItem(item?.rental?.id)
                    : item?.status === 'offered' || item?.status === 'waiting'
                    ? () => onClickOfferedBooking(item)
                    : () => onClickConfirmedBooking(item.id)
                }
              />
            ) : (
              <div>
                {showMonthLabel && (
                  <div className="user-rentals-month-header">
                    {formatMonthFromDate(
                      new Date(
                        item?.from || item?.initialUnlock || item?.created
                      ),
                      false
                    )}
                  </div>
                )}
                {item?.tripId ? (
                  <RentalHistoryItem
                    title={item.tripPresentableId}
                    carPlate={item.carPlate}
                    description={`${msToHour(
                      (item.finalLock
                        ? (new Date(item.finalLock) as any)
                        : (new Date() as any)) -
                        (new Date(item.initialUnlock) as any),
                      localize
                    )} | ${formatDistance(item.distance || 1)}`}
                    leftValue={format(
                      new Date(
                        item?.from || item?.initialUnlock || item?.created
                      ),
                      'dd'
                    )}
                    onClick={() => onClickRentalItem(item?.tripId)}
                  />
                ) : (
                  <BookingHistoryItem
                    title={item?.presentableId}
                    value={localize('booking.label.expired')}
                    descriptionComponent={
                      item?.accepted?.car?.plate ? (
                        <Plate carPlate={item?.accepted?.car?.plate} />
                      ) : (
                        <div>{`${item?.category?.name} | ${item?.category?.acrissCode}`}</div>
                      )
                    }
                    leftValue={format(
                      new Date(
                        item?.from || item?.initialUnlock || item?.created
                      ),
                      'dd'
                    )}
                    onClick={() => onClickCancelledBooking(item)}
                  />
                )}
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default UserRentalsView;
