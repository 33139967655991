import React, { useState, useEffect } from 'react';
import {
  selectOpenedModal,
  clearOpenedModal,
  withTextLocalizer,
  selectFrom,
  selectBackby,
  msToHMS,
  setFrom,
  setBackBy,
  clearFilter,
  selectBrandSettings,
  selectRentalData,
  getCarBookings,
  getClosestFromDate,
  setOpenedModal,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import {
  differenceInMinutes,
  differenceInHours,
  addMilliseconds,
  isBefore,
  addDays,
} from 'date-fns';
import MapFilterView from './MapFilterView';
import {
  initialReturnTime,
  minimumReturnTime,
  minimumStartTime,
  MIN_BOOKING_START,
  isMobileBrowser,
} from '../../../../utils';
import moment from 'moment';
import { updateReturnTime } from 'getaway-data-layer/src/redux/store/userData/rental/actions';

type Props = { localize: any };

export const isSafari = ['iPhone', 'iPad', 'iPod']?.includes(
  // @ts-ignore
  window?.navigator?.userAgentData?.platform || window?.navigator?.platform
);

const MapFilterContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const [carBookings, setCarBookings] = useState();
  const rentalData = useSelector(selectRentalData);
  const openedModal = useSelector(selectOpenedModal);
  const isOpened = ['MapFilterModal', 'BackByUpdateModal'].includes(
    openedModal
  );
  const backByOnly = openedModal === 'BackByUpdateModal';
  const bookingDuration = useSelector(selectBrandSettings)?.bookingDuration;
  const from = useSelector(selectFrom) as any;
  const backBy = useSelector(selectBackby) as any;

  const unlockTime = rentalData?.initialUnlock
    ? new Date(rentalData.initialUnlock)
    : null;
  const returnTime = rentalData?.backBy
    ? new Date(rentalData?.backBy)
    : new Date();

  const startDate = backByOnly
    ? unlockTime
    : from
    ? new Date(from)
    : minimumStartTime();
  const backByDate = backByOnly
    ? returnTime
    : backBy
    ? new Date(backBy)
    : initialReturnTime(startDate);

  const [selectedStartDate, setSelectedStartDate] = useState(startDate) as any;
  const [selectedEndDate, setSelectedEndDate] = useState(backByDate) as any;
  const [customError, setCustomError] = useState('') as any;
  const bookingDurationAsMilliseconds = moment
    .duration(bookingDuration)
    .asMilliseconds();

  const maximumDate = bookingDuration
    ? addMilliseconds(
        new Date(selectedStartDate),
        bookingDurationAsMilliseconds
      )
    : undefined;

  const handleClose = () => {
    dispatch(clearOpenedModal());
  };

  const isStartTimeUpdated = () => {
    return (
      backByOnly ||
      (from &&
        differenceInMinutes(selectedStartDate, new Date()) > MIN_BOOKING_START)
    );
  };

  const onChangeStartDate = (e: any) => {
    if (e?.target?.value) {
      setSelectedStartDate(new Date(e.target.value));
      if (isBefore(selectedEndDate, new Date(e.target.value))) {
        setSelectedEndDate(initialReturnTime(new Date(e.target.value)));
        dispatch(setBackBy(initialReturnTime(new Date(e.target.value))));
      }
    } else {
      if (isMobileBrowser()) {
        setSelectedStartDate(e);
        if (isBefore(selectedEndDate, e)) {
          setSelectedEndDate(initialReturnTime(e));
          dispatch(setBackBy(initialReturnTime(e)));
        }
      }
    }
  };

  const onChangeEndDate = (e: any) => {
    if (e?.target?.value) {
      setSelectedEndDate(new Date(e.target.value));
    } else {
      if (isMobileBrowser()) {
        setSelectedEndDate(e);
      }
    }
  };

  const onReset = () => {
    // setSelectedStartDate(minimumStartTime());
    // setSelectedEndDate(initialReturnTime(minimumStartTime()));
    dispatch(clearFilter());
    handleClose();
  };

  const isValidDates = () => {
    return bookingDuration
      ? Math.abs(selectedEndDate - selectedStartDate) <
          bookingDurationAsMilliseconds
      : true;
  };

  const onPressFilter = () => {
    if (backByOnly) {
      dispatch(
        updateReturnTime(
          rentalData.id,
          {
            backBy: selectedEndDate.toISOString(),
          },
          () => {
            handleClose();
          }
        )
      );
    } else {
      if (isValidDates()) {
        dispatch(setFrom(selectedStartDate.toUTCString()));
        dispatch(setBackBy(selectedEndDate.toUTCString()));
        handleClose();
      } else {
        setCustomError(localize('wj.map.filter.violation.period'));
      }
    }
  };

  const onPressVehicleTable = () => {
    dispatch(setOpenedModal('CarProfileModal'));
  };

  useEffect(() => {
    if (isOpened) {
      // min for selectedStartDate
      if (
        (isSafari || !isMobileBrowser()) &&
        isBefore(selectedStartDate, new Date())
      ) {
        setCustomError(localize('wj.map.filter.violation.min'));
      } else if (
        (isSafari || !isMobileBrowser()) &&
        isBefore(selectedEndDate, selectedStartDate)
      ) {
        setCustomError(localize('wj.map.filter.violation.order'));
      } else if (
        (isSafari || !isMobileBrowser()) &&
        differenceInHours(selectedEndDate, selectedStartDate) < 1
      ) {
        setCustomError(localize('wj.map.filter.violation.duration'));
      } else {
        setCustomError('');
        dispatch(setFrom(selectedStartDate.toUTCString()));
        dispatch(setBackBy(selectedEndDate.toUTCString()));
      }
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    if (isOpened && backByOnly) {
      const from = new Date();
      const to = new Date(addDays(new Date(), 16));
      const carId = rentalData.carData?.id;
      if (carId) {
        dispatch(
          getCarBookings(carId, from, to, (bookings) => {
            setCarBookings(bookings);
          })
        );
      }
    }
  }, [isOpened]);

  return (
    <MapFilterView
      localize={localize}
      backByOnly={backByOnly}
      selectedStartDate={selectedStartDate}
      onChangeStartDate={onChangeStartDate}
      selectedEndDate={selectedEndDate}
      onChangeEndDate={onChangeEndDate}
      minStart={new Date()}
      minEnd={minimumReturnTime(selectedStartDate)}
      maxEnd={maximumDate}
      isStartTimeUpdated={isStartTimeUpdated()}
      bookingRange={msToHMS(
        Math.abs(selectedStartDate - selectedEndDate),
        localize
      )}
      onReset={onReset}
      isOpened={isOpened}
      handleClose={handleClose}
      onPressFilter={onPressFilter}
      customError={customError}
      isSafari={isSafari}
      nextBookingStart={getClosestFromDate(carBookings)}
      onPressVehicleTable={onPressVehicleTable}
    />
  );
};

export default withTextLocalizer(MapFilterContainer);
