// @flow
import * as React from 'react';

type Props = {
  opacity?: number;
  color?: string;
  width?: number;
  height?: number;
  top?: number;
};

const SwipeIndicator = ({ opacity, color, width, height, top }: Props) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2,
    }}
  >
    <div
      style={{
        top,
        width,
        height,
        opacity,
        backgroundColor: color,
        borderRadius: 4,
      }}
    />
  </div>
);

SwipeIndicator.defaultProps = {
  opacity: 0.4,
  color: 'rgba(255, 255, 255, 0.5)',
  width: 42,
  height: 6,
  top: 7,
};

export default SwipeIndicator;
