import React from 'react';
import { isRentalOngoing } from 'getaway-data-layer';
import { differenceInMinutes } from 'date-fns';
import { showAlert } from './showAlert';

export const getPhoneCallLink = (phoneNumber: string, title: string) => {
  return <a href={`tel:${phoneNumber}`}>{title}</a>;
};

export const getEmailComposerLink = (email: any, title: string) => {
  return <a href={`mailto:${email}`}>{title}</a>;
};

export const displayEmailComposer = (localize: any, serviceEmail: string) => {
  if (serviceEmail) {
    showAlert(
      localize('help.dialog.title'),
      '',
      getEmailComposerLink(serviceEmail, localize('support.email')),
      () => {},
      localize('cancel')
    );
  }
};

const displayMessenger = () => {
  // @ts-ignore
  window?.Intercom('show');
};

export const getIsRentingUser = (
  isVerifiedUser: boolean,
  rentalData: any,
  bookings: any,
  lastFinishedRentalDate: any
): boolean =>
  isVerifiedUser &&
  (isRentalOngoing(rentalData) ||
    bookings?.filter((booking: any) =>
      ['confirmed', 'offered'].includes(booking.status)
    ).length > 0 ||
    (lastFinishedRentalDate &&
      differenceInMinutes(new Date(), new Date(lastFinishedRentalDate)) < 15));

export const openIntercom = (
  localize: any,
  intercomHandlingMode: any,
  isAuthorizedUser: boolean,
  servicePhone: any
) => {
  if (intercomHandlingMode === 'never') {
    showAlert(
      localize('help.dialog.title'),
      '',
      getPhoneCallLink(
        servicePhone || localize('customerSupport.number'),
        localize('support.call')
      ),
      () => {},
      localize('cancel')
    );
  } else if (
    ['always', 'onContactSupport'].includes(intercomHandlingMode) ||
    (intercomHandlingMode === 'onLogin' && isAuthorizedUser)
  ) {
    displayMessenger();
  }
};
