import React from 'react';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import { LocalizePropType } from 'getaway-data-layer';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';
import ListItem from '../../../components/ListItem/ListItem';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
  consentDate: string;
  onUpdateSchufa: () => void;
  onUpdateNewsLetter: () => void;
  marketingConsentGiven: boolean;
  schufaAgreed: boolean;
  schufaAgreedAt: string;
  newsLetterConsentAt: string;
  readOnlyMode: boolean;
};

const ConsentsView = ({
  localize,
  onClose,
  consentDate,
  onUpdateSchufa,
  onUpdateNewsLetter,
  marketingConsentGiven,
  schufaAgreed,
  schufaAgreedAt,
  newsLetterConsentAt,
  readOnlyMode,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('consents.title')}
        description=""
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body">
        {/* SCHUFA CONSENT */}
        <ExpandableItem
          containerStyles={{ padding: '10px 0' }}
          title={localize('consents.schufa.title')}
          value={localize('consents.details')}
          initiallyOpened={false}
          showSeparator
        >
          <Markdown rehypePlugins={[rehypeRaw]}>
            {localize('consents.schufa.desc')}
          </Markdown>
        </ExpandableItem>
        <ListItem
          containerStyles={{ padding: '10px 0', margin: 0, marginBottom: 20 }}
          title={localize('consents.consent')}
          showSeparator={false}
          switchItem
          switchValue={schufaAgreed}
          onSwitchValueChange={onUpdateSchufa}
          value={schufaAgreedAt}
          valueStyles={{ marginRight: 6 }}
          disableSwitch={readOnlyMode || schufaAgreed}
        />

        {/* NEWSLETTER CONSENT */}
        <ExpandableItem
          containerStyles={{ padding: '10px 0' }}
          title={localize('consents.newsletter.title')}
          value={localize('consents.details')}
          initiallyOpened={false}
          showSeparator
        >
          <Markdown rehypePlugins={[rehypeRaw]}>
            {localize('consents.newsletter.desc')}
          </Markdown>
        </ExpandableItem>
        <ListItem
          containerStyles={{ padding: '10px 0', margin: 0, marginBottom: 20 }}
          title={localize('consents.consent')}
          showSeparator={false}
          switchItem
          switchValue={Boolean(marketingConsentGiven)}
          onSwitchValueChange={onUpdateNewsLetter}
          value={newsLetterConsentAt}
          valueStyles={{ marginRight: 6 }}
          disableSwitch={readOnlyMode}
        />

        {/* DATA PROTECTION CONSENT */}
        <ExpandableItem
          containerStyles={{ padding: '10px 0' }}
          title={localize('consents.privacy.policy.title')}
          value={localize('consents.details')}
          initiallyOpened={false}
          showSeparator
        >
          <Markdown rehypePlugins={[rehypeRaw]}>
            {localize('consents.privacy.policy.desc')}
          </Markdown>
        </ExpandableItem>
        <ListItem
          containerStyles={{ padding: '10px 0', margin: 0, marginBottom: 20 }}
          title={localize('consents.consent')}
          showSeparator={false}
          switchItem
          disableSwitch
          value={consentDate}
          valueStyles={{ marginRight: 6 }}
        />
      </div>
    </div>
  );
};

export default ConsentsView;
