import React from 'react';
import './CardVideoView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';

type props = {
  onClose: () => void;
};

const CardVideoView = ({ onClose }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title=" "
        description=""
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body" style={{ padding: 0 }}>
        <video
          width="100%"
          height="100%"
          autoPlay
          loop
          src="/videos/card.mp4"
        />
      </div>
    </div>
  );
};

export default CardVideoView;
