import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/CarsSearchTypes';
import { CarsSearchAction, CarsSearchState } from '../../../@types';

export default (
  state: CarsSearchState = initialState,
  action: CarsSearchAction
): CarsSearchState => {
  switch (action.type) {
    case TYPES.SET_SEARCH_CARS: {
      return { ...state, searchCars: action.payload.searchCars };
    }

    case TYPES.SET_AVAILABLE_CARS: {
      return { ...state, availableCars: action.payload.availableCars };
    }

    case TYPES.CLEAR_AVAILABLE_CARS: {
      return { ...state, availableCars: initialState.availableCars };
    }

    case TYPES.SET_MAP_ZOOM: {
      return { ...state, mapZoom: action.payload.zoom };
    }

    case TYPES.SET_CATEGORIES: {
      return { ...state, categories: action.payload.categories };
    }

    case TYPES.SET_UNAVAILABLE_CARS: {
      return { ...state, unavailableCars: action.payload.unavailableCars };
    }

    case TYPES.CLEAR_UNAVAILABLE_CARS: {
      return { ...state, unavailableCars: initialState.unavailableCars };
    }

    case TYPES.SET_MAP_CENTER_LOCATION: {
      return { ...state, mapCenterLocation: action.payload.mapCenterLocation };
    }

    case TYPES.SET_RADIUS: {
      return { ...state, radius: action.payload.radius };
    }

    case TYPES.SET_FROM: {
      return { ...state, from: action.payload.from };
    }

    case TYPES.CLEAR_FROM: {
      return { ...state, from: initialState.from };
    }

    case TYPES.SET_BACK_BY: {
      return { ...state, backBy: action.payload.backBy };
    }

    case TYPES.CLEAR_BACK_BY: {
      return { ...state, backBy: initialState.backBy };
    }

    case TYPES.SET_MAP_CIRCLE_ID_SEARCH: {
      return { ...state, circleId: action.payload.circleId };
    }

    case TYPES.CLEAR_MAP_CIRCLE_ID_SEARCH: {
      return { ...state, circleId: initialState.circleId };
    }
    // @ts-ignore
    case PURGE:
      return {
        ...initialState,
        availableCars: state.availableCars,
        unavailableCars: state.unavailableCars,
        searchCars: state.searchCars,
      };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
