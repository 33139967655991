import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  isKYCApp,
  selectAuth,
  selectDeeplink,
  selectUser,
} from 'getaway-data-layer';

type Props = {
  component: React.ElementType;
  [x: string]: any;
};

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const user = useSelector(selectUser);
  const basicAuth = useSelector(selectAuth);
  const deeplink = useSelector(selectDeeplink);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && basicAuth) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname:
                deeplink && deeplink !== '/'
                  ? '/login'
                  : isKYCApp()
                  ? '/'
                  : '/map',
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
