import { PURGE } from 'redux-persist';
import initialState from './InitialState';
import TYPES from '../../../../@types/redux/store/ConnectCameraTypes';
import { ConnectCameraAction, ConnectCameraState } from '../../../../@types';
export default (
  state: ConnectCameraState = initialState,
  action: ConnectCameraAction
): ConnectCameraState => {
  switch (action.type) {
    case TYPES.SET_VEHICLE_REGISTRATION: {
      return {
        ...state,
        vehicleRegistration: action.payload.vehicleRegistration!,
      };
    }
    case TYPES.SET_FUEL_CARD_FRONT: {
      return {
        ...state,
        fuelCardFront: action.payload.fuelCardFront!,
      };
    }
    case TYPES.SET_FUEL_CARD_BACK: {
      return {
        ...state,
        fuelCardBack: action.payload.fuelCardBack!,
      };
    }

    // @ts-ignore
    case PURGE:
      return { ...initialState };

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
