import React from 'react';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { FormikValues } from 'formik';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import { LocalizePropType } from 'getaway-data-layer';
import { GA_TITLES } from '../../../../utils';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  onClose: () => void;
};

const AddCircle = ({ localize, formik, onClose }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('joinCircle.title')}
        description=""
        showHelp
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body">
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="circleCode"
            className="form-input"
            label={localize('circles.input.placeholder')}
            helperText={
              (formik.touched.circleCode && formik.errors.circleCode) || ''
            }
            error={
              formik.touched.circleCode && Boolean(formik.errors.circleCode)
            }
            variant="standard"
            type="string"
            {...formik.getFieldProps('circleCode')}
          />
          <BrandButton
            id={GA_TITLES.CIRCLES.JOIN}
            title={localize('circles.button.text')}
            formik={formik}
          />
        </Box>
      </div>
    </div>
  );
};

export default AddCircle;
