import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  withTextLocalizer,
  selectJumioRedirectData,
  verifyJumioSuccess,
  getUser,
  LocalizePropType,
  isKYCApp,
} from 'getaway-data-layer';
import DLScanningView from './DLScanningView';
import {
  GA_TITLES,
  isDashboardScreen,
  isJSON,
  navigateTo,
  reportGAEvent,
  showAlert,
} from '../../../../../utils';

type Props = {
  localize: LocalizePropType;
};

const DLScanningContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jumioRedirectData: any = useSelector(selectJumioRedirectData);

  const [scanResult, setScanResult] = useState('');

  const receiveMessage = (event: any) => {
    if (event?.data && isJSON(event.data)) {
      const data = window.JSON.parse(event.data);
      if (data?.payload?.value) {
        setScanResult(data.payload.value);
      }
    }
  };

  const onClose = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      showAlert(
        '',
        localize('cancel.FirstSteps.dialog.message'),
        localize('cancel.FirstSteps.dialog.yes'),
        () => navigateTo(history, '/settings'),
        localize('cancel.FirstSteps.dialog.no'),
        () => {},
        true
      );
    }
  };

  const onSuccess = () => {
    if (isDashboardScreen()) {
      navigateTo(history, '/settings');
    } else {
      if (isKYCApp()) {
        dispatch(
          getUser(() => {
            navigateTo(history, '/onboarding/optimizedSepa');
          })
        );
      } else {
        navigateTo(history, isKYCApp() ? '/settings' : '/map');
      }
    }
    reportGAEvent(GA_TITLES.ON_BOARDING.DL_SUBMITTED);
  };

  const onError = () => {
    if (isKYCApp()) {
      dispatch(getUser(() => navigateTo(history, '/onboarding/optimizedSepa')));
    } else {
      navigateTo(history, '/settings');
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
  }, []);

  useEffect(() => {
    if (scanResult) {
      if (['success'].includes(scanResult)) {
        dispatch(
          verifyJumioSuccess(jumioRedirectData?.transactionReference, onSuccess)
        );
        setTimeout(() => {
          dispatch(getUser());
        }, 1000);
      } else if (['error'].includes(scanResult)) {
        onError();
      }
    }
  }, [scanResult]);

  return (
    <>
      <DLScanningView
        jumioRedirectUrl={jumioRedirectData?.redirectUrl}
        onClose={onClose}
      />
    </>
  );
};

export default withTextLocalizer(DLScanningContainer);
