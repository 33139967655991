import React from 'react';
import Hyperlink from 'react-native-hyperlink';
import {
  getBrandColor,
  getBrandName,
  isBND,
  isTLA,
  LocalizePropType,
  isKYCApp,
  selectIsKeyboardOpened,
  isFRM,
  isGRF,
  isLocalizeAdded,
} from 'getaway-data-layer';
import './SignupView.less';
import { FormikValues } from 'formik';
import Box from '@mui/material/Box';
import {
  IconButton,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BrandButton from '../../components/BrandButton/BrandButton';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import config from '../../../env.config';
import { GA_TITLES, isMobileBrowser } from '../../../utils';
import { LEGAL_TYPES, SIGNUP_TYPES } from '../../../validation';
import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { PRIVACY_POLICY_STATUS } from '../../../enums/PrivacyPolicy';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type props = {
  localize: LocalizePropType;
  formik: FormikValues;
  signupType: string;
  showPassword: boolean;
  onClose: () => void;
  showAboutDialog: () => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeSignupType: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeLegalType: (event: React.MouseEvent<HTMLButtonElement>) => void;
  containerStyles: any;
  showBack: boolean;
  onPressLogin: () => void;
  showLoginLink: boolean;
  privacyPolicyStatus: string;
  privacyPolicyConsentRequired: boolean;
  onPressPrivacyPolicyConsent: () => void;
  handleCompanyNameChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const SignupView = ({
  localize,
  formik,
  signupType,
  showPassword,
  onClose,
  showAboutDialog,
  handleClickShowPassword,
  handleMouseDownPassword,
  onChangeSignupType,
  onChangeLegalType,
  containerStyles,
  showBack,
  onPressLogin,
  showLoginLink,
  privacyPolicyStatus,
  privacyPolicyConsentRequired,
  onPressPrivacyPolicyConsent,
  handleCompanyNameChange,
}: props) => {
  const isKeyboardOpened = useSelector(selectIsKeyboardOpened) as any;

  const orgForm = (
    <>
      <TextField
        required
        fullWidth
        id="companyName"
        sx={{ pr: 1 }}
        label={localize('signup.company.name.placeholder')}
        helperText={
          (formik.touched.companyName && formik.errors.companyName) || ''
        }
        error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        variant="standard"
        size="small"
        type="text"
        {...formik.getFieldProps('companyName')}
        onChange={handleCompanyNameChange}
      />
      <FormControl fullWidth variant="standard">
        <InputLabel id="legalType-label">
          {localize('signup.legal.type.placeholder')}
        </InputLabel>
        <Select
          fullWidth
          required
          id="legalType"
          variant="standard"
          {...formik.getFieldProps('legalType')}
          onChange={(event: any) => {
            onChangeLegalType(event);
          }}
          style={{ margin: '10px 0' }}
          inputProps={{
            label: localize('signup.legal.type.placeholder'),
          }}
          label={localize('signup.legal.type.placeholder')}
        >
          {Object.values(LEGAL_TYPES)?.map((typ) => (
            <MenuItem key={typ} value={typ}>
              {typ}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {formik.values.legalType === LEGAL_TYPES.Sonstige && (
        <>
          <TextField
            required
            fullWidth
            id="otherLegalType"
            sx={{ pr: 1 }}
            label={localize('signup.legal.other.placeholder')}
            helperText={
              (formik.touched.otherLegalType && formik.errors.otherLegalType) ||
              ''
            }
            error={
              formik.touched.otherLegalType &&
              Boolean(formik.errors.otherLegalType)
            }
            variant="standard"
            size="small"
            type="text"
            {...formik.getFieldProps('otherLegalType')}
          />
          {isLocalizeAdded(localize, 'signup.org.other.footnote') && (
            <div className="other-footnote">
              <Markdown rehypePlugins={[rehypeRaw]}>
                {localize('signup.org.other.footnote')}
              </Markdown>
            </div>
          )}
        </>
      )}
      <TextField
        required
        id="registerNr"
        sx={{ width: 0.5, pr: 1 }}
        label={localize('signup.register.nr.placeholder')}
        helperText={
          (formik.touched.registerNr && formik.errors.registerNr) || ''
        }
        error={formik.touched.registerNr && Boolean(formik.errors.registerNr)}
        variant="standard"
        size="small"
        type="text"
        {...formik.getFieldProps('registerNr')}
      />
      <TextField
        required
        id="registerLocation"
        sx={{ width: 0.5 }}
        label={localize('signup.register.location.placeholder')}
        helperText={
          (formik.touched.registerLocation && formik.errors.registerLocation) ||
          ''
        }
        error={
          formik.touched.registerLocation &&
          Boolean(formik.errors.registerLocation)
        }
        variant="standard"
        size="small"
        type="text"
        {...formik.getFieldProps('registerLocation')}
      />

      <div className="signup-section">
        {localize('signup.personal.section')}
      </div>
    </>
  );

  return (
    <div className="screen-container" style={containerStyles}>
      <NavigationBar
        title={localize(
          signupType === SIGNUP_TYPES.ORG
            ? 'signup.org.header'
            : 'signup.header'
        )}
        description={localize(
          signupType === SIGNUP_TYPES.ORG ? 'signup.org.desc' : 'signup.desc'
        )}
        showHelp
        onClose={onClose}
        showLogo
        showBack={showBack}
      />
      <div className="screen-body">
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <>
            {signupType !== SIGNUP_TYPES.ORG &&
              isKYCApp() &&
              (isBND() || isTLA() || isFRM() || isGRF()) && (
                <Select
                  fullWidth
                  required
                  labelId="select-label"
                  id="signupType"
                  variant="standard"
                  {...formik.getFieldProps('signupType')}
                  onChange={(event: any) => {
                    onChangeSignupType(event);
                  }}
                  style={{ marginBottom: 16 }}
                >
                  <MenuItem value={SIGNUP_TYPES.PRIVATE}>
                    {localize('signup.type.private')}
                  </MenuItem>
                  {(isBND() || isGRF()) && (
                    <MenuItem value={SIGNUP_TYPES.COMPANY}>
                      {localize('signup.type.company')}
                    </MenuItem>
                  )}
                  {isFRM() && (
                    <MenuItem value={SIGNUP_TYPES.CO_USER}>
                      {localize('signup.type.co_user')}
                    </MenuItem>
                  )}
                  {isFRM() && (
                    <MenuItem value={SIGNUP_TYPES.BIKE}>
                      {localize('signup.type.bike')}
                    </MenuItem>
                  )}
                  {isTLA() && (
                    <MenuItem value={SIGNUP_TYPES.ORG}>
                      {localize('signup.type.org')}
                    </MenuItem>
                  )}
                  {isTLA() && (
                    <MenuItem value={SIGNUP_TYPES.PARTNER}>
                      {localize('signup.type.partner')}
                    </MenuItem>
                  )}
                </Select>
              )}
            {signupType === SIGNUP_TYPES.ORG && orgForm}
            <TextField
              required
              id="firstName"
              sx={{ width: 0.5, pr: 1 }}
              label={localize('signup.name.placeholder')}
              helperText={
                (formik.touched.firstName && formik.errors.firstName) || ''
              }
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              variant="standard"
              size="small"
              type="text"
              autoComplete="given-name"
              {...formik.getFieldProps('firstName')}
            />
            <TextField
              required
              id="lastName"
              sx={{ width: 0.5 }}
              label={localize('signup.facebook.lastname.placeholder')}
              helperText={
                (formik.touched.lastName && formik.errors.lastName) || ''
              }
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              variant="standard"
              size="small"
              type="text"
              autoComplete="family-name"
              {...formik.getFieldProps('lastName')}
            />
            <TextField
              required
              fullWidth
              id="email"
              className="form-input"
              label={localize(
                signupType === SIGNUP_TYPES.ORG
                  ? 'signup.email.org.placeholder'
                  : 'signup.email.placeholder'
              )}
              helperText={(formik.touched.email && formik.errors.email) || ''}
              error={formik.touched.email && Boolean(formik.errors.email)}
              variant="standard"
              size="small"
              type="email"
              autoComplete="email"
              {...formik.getFieldProps('email')}
            />
            <TextField
              required
              fullWidth
              id="password"
              className="form-input"
              type={showPassword ? 'text' : 'password'}
              label={localize('signup.password.placeholder')}
              helperText={
                (formik.touched.password && formik.errors.password) || ''
              }
              error={formik.touched.password && Boolean(formik.errors.password)}
              variant="standard"
              size="small"
              autoComplete="new-password"
              {...formik.getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{ display: 'flex', marginTop: 24 }}
              onClick={
                privacyPolicyConsentRequired
                  ? onPressPrivacyPolicyConsent
                  : null
              }
            >
              {privacyPolicyConsentRequired && (
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={
                    privacyPolicyStatus === PRIVACY_POLICY_STATUS.APPROVED
                  }
                />
              )}
              <div className="signup-privacy-policy-container">
                <Hyperlink
                  linkStyle={{ color: getBrandColor() }}
                  linkText={(url) => {
                    if (url === localize('signup.terms.url')) {
                      return localize('signup.terms.url.text');
                    }
                    if (url === localize('privacy.url')) {
                      return localize('signup.name.policy.text');
                    }
                    return url;
                  }}
                  onPress={showAboutDialog}
                >
                  <div
                    style={
                      privacyPolicyStatus === PRIVACY_POLICY_STATUS.ERROR
                        ? { color: 'red' }
                        : {}
                    }
                  >
                    {localize(
                      'signup.terms.text',
                      localize('signup.terms.url'),
                      localize('privacy.url'),
                      localize(
                        `brand.${getBrandName(config.brand.xBrandId!)}.name`
                      )
                    )}
                  </div>
                </Hyperlink>
              </div>
            </div>
            <div
              className="signup-footnote-container"
              style={
                isKeyboardOpened && isMobileBrowser()
                  ? {
                      position: 'unset',
                      width: '100%',
                      marginTop: 30,
                      padding: 0,
                    }
                  : {}
              }
            >
              <BrandButton
                id={GA_TITLES.AUTH.SIGN_UP}
                title={localize('signup.button.title')}
                containerStyles={{ position: 'unset', width: '100%' }}
                formik={formik}
              />
              <div onClick={onPressLogin} className="signup-footnote">
                <div className="signup-footnote-text">
                  {localize('signup.footnote.login.text')}
                </div>
                <div className="signup-footnote-action">
                  {localize('signup.footnote.login.action')}
                </div>
              </div>
            </div>
          </>
        </Box>
      </div>
    </div>
  );
};

export default SignupView;
