import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizePropType,
  resetPassword,
  selectBrandSettings,
  withTextLocalizer,
} from 'getaway-data-layer';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import ResetPasswordView from './ResetPasswordView';
import { GA_TITLES, navigateTo, reportGAEvent } from '../../../utils';
import { ResetPasswordModal, ResetPasswordSchema } from '../../../validation';
import { useQuery } from '../../../hooks/useQuery';

interface Values {
  password: string;
}

type Props = {
  localize: LocalizePropType;
};

const ResetPasswordContainer = ({ localize }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const query = useQuery();
  const brandSettings = useSelector(selectBrandSettings);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = ({ password }: Values) => {
    dispatch(
      resetPassword(
        query.get('email')!,
        password.trim(),
        query.get('token')!,
        localize,
        () => {
          navigateTo(history, '/login');
          reportGAEvent(GA_TITLES.AUTH.RESET_PASSWORD_SUBMITTED);
        }
      )
    );
  };

  const resetPasswordFormik = useFormik({
    initialValues: ResetPasswordModal,
    validationSchema: ResetPasswordSchema(
      localize,
      brandSettings?.passwordRegex
    ),
    onSubmit,
  });

  const onClose = () => {
    navigateTo(history, '/map');
  };

  useEffect(() => {
    if (!(query.get('email') && query.get('token')))
      navigateTo(history, '/map');
  }, []);

  return (
    <ResetPasswordView
      localize={localize}
      formik={resetPasswordFormik}
      showPassword={showPassword}
      onClose={onClose}
      handleClickShowPassword={handleClickShowPassword}
      handleMouseDownPassword={handleMouseDownPassword}
    />
  );
};

export default withTextLocalizer(ResetPasswordContainer);
