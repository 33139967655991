/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import {
  addDays,
  format,
  isSameDay,
  isAfter,
  addHours,
  isSameHour,
  isBefore,
  subMinutes,
} from 'date-fns';
import deLocale from 'date-fns/locale/de';
import './BookingTable.less';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';
import {
  BookingData,
  LocalizePropType,
  DriverCarData,
  withTextLocalizer,
} from 'getaway-data-layer';
import { isMobileBrowser } from '../../../utils';

type Props = {
  localize: LocalizePropType;
  carBookings: Array<BookingData>;
  car: DriverCarData;
  fleetColor: string;
};

export const DAY_PER_PAGE = 3;
const NUMBER_OF_CALENDER_DAYS = 89;
const DAY_HRS = [
  '00:00',
  // '00:30',
  '01:00',
  // '01:30',
  '02:00',
  // '02:30',
  '03:00',
  // '03:30',
  '04:00',
  // '04:30',
  '05:00',
  // '05:30',
  '06:00',
  // '06:30',
  '07:00',
  // '07:30',
  '08:00',
  // '08:30',
  '09:00',
  // '09:30',
  '10:00',
  // '10:30',
  '11:00',
  // '11:30',
  '12:00',
  // '12:30',
  '13:00',
  // '13:30',
  '14:00',
  // '14:30',
  '15:00',
  // '15:30',
  '16:00',
  // '16:30',
  '17:00',
  // '17:30',
  '18:00',
  // '18:30',
  '19:00',
  // '19:30',
  '20:00',
  // '20:30',
  '21:00',
  // '21:30',
  '22:00',
  // '22:30',
  '23:00',
  // '23:30',
];

const BookingTable = ({ localize, fleetColor, carBookings, car }: Props) => {
  const sliderRef = useRef();

  const daysList = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < NUMBER_OF_CALENDER_DAYS; index++) {
    daysList.push(index);
  }

  const pagesDays = daysList.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / DAY_PER_PAGE);
    if (!resultArray[chunkIndex]) {
      // @ts-ignore
      resultArray[chunkIndex] = []; // start a new chunk
    }
    // @ts-ignore
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
  };

  const onPressToday = () => {
    if (sliderRef) {
      // @ts-ignore
      sliderRef.current.slickGoTo(0);
    }
  };

  return (
    <div className="booking-table-container">
      <div className="booking-table-colsWrapper">
        <div className="booking-table-timeColWrapper">
          <div className="booking-table-timeBoxHeader">
            <div
              className="booking-table-timeTextHeader"
              style={{ color: fleetColor }}
              onClick={onPressToday}
            >
              Heute
            </div>
          </div>
          {DAY_HRS.map((time, timeIndex) => (
            <div key={time} className="booking-table-timeBox">
              {timeIndex !== 0 && (
                <div className="booking-table-timeText">
                  <pre className="booking-table-timeText-pre">{`${time}   `}</pre>
                  <div className="booking-table-timeTextDashWrapper">
                    <div className="booking-table-timeTextDash">{`  |`}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <Slider
          // @ts-ignore
          ref={sliderRef}
          {...settings}
          // @ts-ignore
          style={{
            display: 'flex',
            width: isMobileBrowser()
              ? window.innerWidth - 70
              : `calc(100% - 70px)`,
          }}
        >
          {pagesDays.map((days: any, daysIndex) => (
            <div
              key={addDays(new Date(), daysIndex).toString()}
              className="booking-table-swiperPage"
              style={{ width: window.innerWidth - 70 }}
            >
              {days.map((day: any, index: any) => (
                <div key={day} className="booking-table-dayColWrapper">
                  <div className="booking-table-dayBoxHeader">
                    <div className="booking-table-dayTextHeaderMonth">
                      {format(
                        new Date(
                          addDays(new Date(), daysIndex * DAY_PER_PAGE + index)
                        ),
                        'EE',
                        {
                          locale: deLocale,
                        }
                      )}
                    </div>
                    <div
                      className={
                        day === 0
                          ? 'booking-table-dayTextHeaderHighlighted'
                          : 'booking-table-dayTextHeader'
                      }
                    >
                      {format(
                        new Date(
                          addDays(new Date(), daysIndex * DAY_PER_PAGE + index)
                        ),
                        'dd.LL'
                      )}
                    </div>
                  </div>
                  {DAY_HRS.map((time, i) => {
                    const now = new Date();
                    const currentDay = new Date(
                      addDays(new Date(), daysIndex * DAY_PER_PAGE + index)
                    );
                    const currentHr = new Date(
                      addHours(currentDay.setHours(0), i)
                    );
                    const bookings = carBookings.filter(
                      (item: any) => item.status !== 'started'
                    );
                    if (car.status === 'RENTED') {
                      const currentRental: any = {
                        from: new Date(),
                        to: car.backBy,
                      };
                      bookings.push(currentRental);
                    }
                    return (
                      <div key={time} className="booking-table-slotBox">
                        {bookings.map(
                          (booking: any) =>
                            (isSameHour(currentHr, new Date(booking.from)) ||
                              isAfter(currentHr, new Date(booking.from))) &&
                            isBefore(currentHr, new Date(booking.to)) && (
                              <div
                                key={booking.id}
                                className={
                                  isSameHour(currentHr, new Date(booking.from))
                                    ? 'booking-table-fromActiveSlot'
                                    : isSameHour(
                                        currentHr,
                                        new Date(booking.to)
                                      ) ||
                                      isSameHour(
                                        currentHr,
                                        subMinutes(new Date(booking.to), 1)
                                      )
                                    ? 'booking-table-toActiveSlot'
                                    : 'booking-table-betweenActiveSlot'
                                }
                              />
                            )
                        )}
                        {(isSameHour(currentHr, now) ||
                          isBefore(currentHr, now)) && (
                          <div className="booking-table-greySlot" />
                        )}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default withTextLocalizer(BookingTable);
