import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectMapContent,
  selectRentalData,
  selectRental,
} from 'getaway-data-layer';
import ReservedCardContainer from '../Rental/ReservedCard/ReservedCardContainer';
import DraggableCard from '../../components/DraggableCard/DraggableCard';
import OpenedCardContainer from '../Rental/OpenedCard/OpenedCardContainer';
import CarCheckCardContainer from '../Rental/CarCheckCard/CarCheckCardContainer';
import DrivingParkingCardContainer from '../Rental/DrivingParkingCard/DrivingParkingCardContainer';

const MapCardContainer = () => {
  const mapContent = useSelector(selectMapContent) as any;
  const rental = useSelector(selectRental) as any;
  const rentalData = useSelector(selectRentalData) as any;

  const isServiceTrip = rentalData?.type === 'serviceTrip';
  const carCheckImagesSubmitted = rentalData?.startCheckImages?.length >= 4;

  const renderCardContent = () => {
    switch (mapContent.cardType) {
      case 'reservedCar': {
        return <ReservedCardContainer />;
      }
      case 'openedCar': {
        return rental?.isCarChecked ||
          isServiceTrip ||
          carCheckImagesSubmitted ? (
          <OpenedCardContainer />
        ) : (
          <CarCheckCardContainer />
        );
      }
      case 'drivingCar':
      case 'parkingCar': {
        return rental?.isCarChecked ||
          isServiceTrip ||
          carCheckImagesSubmitted ? (
          <DrivingParkingCardContainer />
        ) : (
          <CarCheckCardContainer />
        );
      }
      case 'offlineService': {
        return null;
      }
      case 'reservedOfflineService': {
        return null;
      }
      case 'startedOfflineService': {
        return null;
      }
      default:
        return null;
    }
  };

  return !['none', 'signUp'].includes(mapContent.cardType) ? (
    <DraggableCard>{renderCardContent()}</DraggableCard>
  ) : null;
};

export default MapCardContainer;
