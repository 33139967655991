import React from 'react';
import Hyperlink from 'react-native-hyperlink';
// @ts-ignore
import { Linking } from 'react-native-web';
import {
  getInsuranceDescription,
  isFreeInsurance,
  LocalizePropType,
  getBrandColor,
  getSecondaryBrandColor,
} from 'getaway-data-layer';
import Plate from '../../../components/Plate/Plate';
import SwipeIndicator from '../../../components/SwitchIndicator/SwipeIndicator';
import './ReservedCardView.less';
import useReservationExpiration from './useReservationExpiration';
import { GA_TITLES } from '../../../../utils';
import InsuranceItem from '../../../components/InsuranceItem/insuranceItem';
import ListItem from '../../../components/ListItem/ListItem';
import PointItem from '../../../components/PointItem/PointItem';
import BrandButton from '../../../components/BrandButton/BrandButton';

type Props = {
  localize: LocalizePropType;
  time: any;
  nextToCar: boolean;
  isUnlocking: boolean;
  onExpire: () => void;
  carPlate: string;
  address: string;
  insurancesData: any;
  selectedInsurance: any;
  onInsuranceSelected: (id: string) => void;
  isOriginallyBooking: boolean;
  formattedRequestedAt: string;
  formattedReservedAt: string;
  from: string;
  until: string;
  tripId: string;
  onClickUnlock: () => void;
  onRentalCancel: () => void;
  onClickCarProfile: () => void;
  onAddressClicked: () => void;
  toggleCard: () => void;
  extras: any;
  orderedOptions: any;
  onPressExtraOption: (code: string) => void;
};

const ReservedCardView = ({
  localize,
  time,
  nextToCar,
  isUnlocking,
  onExpire,
  carPlate,
  address,
  insurancesData,
  selectedInsurance,
  onInsuranceSelected,
  isOriginallyBooking,
  formattedRequestedAt,
  formattedReservedAt,
  from,
  until,
  tripId,
  onClickUnlock,
  onRentalCancel,
  onClickCarProfile,
  onAddressClicked,
  toggleCard,
  extras,
  orderedOptions,
  onPressExtraOption,
}: Props) => {
  const rentalExpirationTime = useReservationExpiration(time, onExpire);
  const headerTitle = nextToCar
    ? localize('rental.reserved.header.connected')
    : localize('rental.reserved.header.searching');
  const headerDescription = nextToCar
    ? localize('rental.reserved.desc.connected')
    : localize('rental.reserved.desc.searching');

  return (
    <>
      <div onClick={toggleCard}>
        <strong className="cursor">
          <div className="reserved-card-header-wrapper">
            <div className="reserved-card-header-title">{`${headerTitle} ${rentalExpirationTime}`}</div>
            <div className="reserved-card-header-description-wrapper">
              <Plate
                carPlate={carPlate}
                titleWrapperStyles={{ backgroundColor: 'white' }}
              />
              <div className="reserved-card-header-description">
                {`${headerDescription}`}
              </div>
            </div>
            <SwipeIndicator
              opacity={1}
              height={3}
              width={40}
              color={getSecondaryBrandColor()}
            />
          </div>
        </strong>
      </div>
      <div
        className="screen-body reserved-card-content"
        style={{ maxHeight: window?.innerHeight - 100 }}
      >
        <div className="reserved-card-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="reserved-card-dashed"
          />
          <div className="reserved-card-insuranceTitle">
            {localize('rental.settings.insurance.title')}
          </div>
          <div className="reserved-insuranceScroll">
            {insurancesData.map((insurance: any) => {
              const checked = insurance.id === selectedInsurance;
              return insurance.deductibleAmount ? (
                <InsuranceItem
                  key={insurance.id}
                  title={localize(
                    isFreeInsurance(insurance?.dailyRate)
                      ? 'insurance.description.free'
                      : 'insurance.description'
                  )}
                  description={getInsuranceDescription(
                    insurance.deductibleAmount
                  )}
                  dailyRate={insurance?.dailyRate}
                  value={
                    isFreeInsurance(insurance?.dailyRate)
                      ? localize('insurance.value.free')
                      : `+${insurance?.dailyRate / 100} ${localize(
                          'insurance.value'
                        )}`
                  }
                  onPress={() => {
                    onInsuranceSelected(insurance.id);
                  }}
                  checked={checked}
                />
              ) : null;
            })}
          </div>
          <div className="reserved-card-linkContainer">
            <Hyperlink
              linkStyle={{ color: getBrandColor() }}
              linkText={(url: string) =>
                url === localize('rental.insurance.settings.more.url')
                  ? localize('rental.insurance.settings.more.url.text')
                  : url
              }
              onPress={(url: string) => {
                Linking.canOpenURL(url).then((supported: any) => {
                  if (supported) {
                    Linking.openURL(url);
                  }
                });
              }}
            >
              <div>
                {localize(
                  'rental.insurance.settings.footnote',
                  localize('rental.insurance.settings.more.url')
                )}
              </div>
            </Hyperlink>
          </div>
          {extras?.map((extra: { code: string }) => (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                key={extra.code}
                title={localize(`rental.extra.${extra.code}.title`)}
                greyTextColor
                onClick={() => onPressExtraOption(extra.code)}
                checkboxChecked={orderedOptions.includes(extra.code)}
                checkboxItem
                showSeparator={false}
              />
              <div className="reserved-card-separator" />
            </>
          ))}
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator={false}
            onClick={onAddressClicked}
          />
          <div className="reserved-card-separator" />
          <div className="reserved-card-verticalSpace" />
        </div>
        <PointItem
          title={localize('rental.section.reserved')}
          value={
            isOriginallyBooking ? formattedRequestedAt : formattedReservedAt
          }
        />
        <div className="reserved-card-section">
          <div className="reserved-card-section-line" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('booking.confirm.profile')}
            greyTextColor
            carPlate={carPlate}
            rightIcon="arrowRightBrand"
            rightIconSize={12}
            showSeparator={false}
            onClick={onClickCarProfile}
          />
          <div className="reserved-card-separator" />
          {!!from && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.from')}
                greyTextColor
                value={from}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!until && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.to')}
                greyTextColor
                value={until}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('station.booking.code')}
            greyTextColor
            code={tripId}
            showSeparator={false}
            onClick={() => {}}
          />
          <div className="reserved-card-separator" />
        </div>
      </div>
      <div className="reserved-card-bottom">
        <img
          className="reserved-card-bottom-gradiant"
          src="/images/gradientBackground50.png"
          alt="gradiant"
        />
        <BrandButton
          id={GA_TITLES.RENTAL.UNLOCK}
          containerStyles={{
            position: 'unset',
            width: '100%',
            marginBottom: '20px',
          }}
          title={localize(
            isUnlocking
              ? 'car.details.unlocking.text'
              : 'car.details.unlock.text.brand'
          )}
          onClick={() => onClickUnlock()}
          onClickDisabled={() => onClickUnlock()}
          disabled={isUnlocking}
        />
        <div className="reserved-card-cancelContainer" onClick={onRentalCancel}>
          <img
            alt="redClose"
            src="/images/redClose.png"
            className="reserved-card-closeIcon"
          />
          <div id={GA_TITLES.RENTAL.CANCEL} className="reserved-card-close">
            {localize('rental.cancel')}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservedCardView;
