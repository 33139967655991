import React from 'react';
import './BrandIcon.less';

type Props = {
  id?: string;
  name: string;
  style?: any;
};

const BrandIcon = ({ id, name, style }: Props) => {
  return (
    <svg id={id} className="brand-icon" style={style}>
      <use id={id} xlinkHref={`/svg/${name}.svg#icon`} />
    </svg>
  );
};

BrandIcon.defaultProps = {
  id: '',
  style: {},
};

export default React.memo(BrandIcon);
