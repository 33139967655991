import React from 'react';
import Hyperlink from 'react-native-hyperlink';
// @ts-ignore
import { Linking } from 'react-native-web';
import './PricingTable.less';
import {
  withTextLocalizer,
  selectPricing,
  LocalizePropType,
  DriverCarData,
  getBrandColor,
  selectCircleId,
  selectUser,
  getPriceMarkdown,
  selectBrandSettings,
} from 'getaway-data-layer';
import { useSelector } from 'react-redux';
import ListItem from '../ListItem/ListItem';
import { getCarPricingUrl } from '../../../utils';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

type Props = { localize: LocalizePropType; selectedCar: DriverCarData };

const PricingTable = ({ localize, selectedCar }: Props) => {
  const user = useSelector(selectUser);
  const pricing = useSelector(selectPricing) as any;
  const brandSettings = useSelector(selectBrandSettings) as any;
  const priceItems =
    selectedCar?.id! in pricing ? pricing[selectedCar?.id!].items : null;
  const pricingName =
    selectedCar?.id! in pricing ? pricing[selectedCar?.id!]?.name : null;
  const activeCircleId = useSelector(selectCircleId);
  const activeCircleName =
    user?.circles?.find((circle) => circle?.id === activeCircleId)?.name ||
    brandSettings?.defaultCircle?.name;
  const pricingMarkdown = getPriceMarkdown(
    // @ts-ignore
    `${pricingName?.replace(/\s/g, '')}.${activeCircleName?.replace(
      /\s/g,
      ''
    )}.pricing`,
    localize
  );
  console.log(
    // @ts-ignore
    `${pricingName?.replace(/\s/g, '')}.${activeCircleName?.replace(
      /\s/g,
      ''
    )}.pricing`
  );
  return (
    <>
      {pricingMarkdown && (
        <div className="pricing-table-container">
          <Markdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
            {pricingMarkdown}
          </Markdown>
          <div className="screen-vertical-space" />
        </div>
      )}
      {!pricingMarkdown && (
        <>
          {priceItems && (
            <div className="pricing-table-container">
              {priceItems.map((item: any) => (
                <>
                  <ListItem title={item.title} showSeparator={false} />
                  {item.items?.map((sectionItem: string) => (
                    <>
                      <ListItem
                        key={sectionItem}
                        title=""
                        value={sectionItem}
                        showSeparator={false}
                      />
                      <div className="shortSeparator" />
                    </>
                  ))}
                </>
              ))}

              {/* footnote */}
              <Hyperlink
                linkStyle={{ color: getBrandColor() }}
                linkText={(url) =>
                  url ===
                  getCarPricingUrl(
                    selectedCar?.driverPrice?.url,
                    process.env.REACT_APP_X_BRAND_ID
                  )
                    ? localize('price.details.footnote.url.text')
                    : url
                }
                onPress={(url) => {
                  Linking.openURL(url);
                }}
              >
                <div className="descriptionText">
                  {localize(
                    'price.details.footnote',
                    getCarPricingUrl(
                      selectedCar?.driverPrice?.url,
                      process.env.REACT_APP_X_BRAND_ID
                    )
                  )}
                </div>
              </Hyperlink>
              <div className="screen-vertical-space" />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTextLocalizer(PricingTable);
