export const CLIENT_TYPE = {
  API_CLIENT: {
    GET_STATUS: 'apiClient/GET_STATUS',
  },
  AUTH_CLIENT: {
    LOGIN: 'authClient/LOGIN',
    SIGNUP: 'authClient/SIGNUP',
    FORGET_PASSWORD: 'authClient/FORGET_PASSWORD',
    RESET_PASSWORD: 'authClient/RESET_PASSWORD',
    CHECK_REFERRAL: 'authClient/CHECK_REFERRAL',
    CHECK_CIRCLE: 'authClient/CHECK_CIRCLE',
  },
  DATA_CLIENT: {
    GET_GAS_STATIONS: 'dataClient/GET_GAS_STATIONS',
    GET_AREAS: 'dataClient/GET_AREAS',
    GET_FEATURES: 'dataClient/GET_FEATURES',
  },
  CARS_CLIENT: {
    GET: 'carsClient/GET',
    GET_COMPACT: 'carsClient/GET_COMPACT',
    GET_WITH_LOADER: 'carsClient/GET_WITH_LOADER',
    GET_BY_ID: 'carsClient/GET_BY_ID',
    GET_CAR_EQUIPMENTS_DATA: 'carsClient/GET_CAR_EQUIPMENTS_DATA',
    GET_AVERAGE_PRICE: 'carsClient/GET_AVERAGE_PRICE',
    GET_PRICE_DETAILS: 'carsClient/GET_PRICE_DETAILS',
    GET_CATEGORIES: 'carsClient/GET_CATEGORIES',
  },
  TEXT_CLIENT: {
    GET: 'textClient/GET',
  },
  BRAND_CLIENT: {
    GET_SETTINGS: 'brandClient/GET_SETTINGS',
  },
  USER_CLIENT: {
    GET_USER: 'userClient/GET_USER',
    UPDATE_USER: 'userClient/UPDATE_USER',
    VERIFY_EMAIL: 'userClient/VERIFY_EMAIL',
    TERMINATE_USER: 'userClient/TERMINATE_USER',
    GET_PAYMENT_DATA: 'userClient/GET_PAYMENT_DATA',
    ADD_PAYMENT: 'userClient/ADD_PAYMENT',
    GET_PAYMENT_PAYMENTS: 'userClient/GET_PAYMENT_PAYMENTS',
    ADD_PAYMENT_DETAILS: 'userClient/ADD_PAYMENT_DETAILS',
    UPDATE_PAYMENT: 'userClient/UPDATE_PAYMENT',
    JUMIO_DOCUMENT_UPLOADED: 'userClient/JUMIO_DOCUMENT_UPLOADED',
    DELETE_PAYMENT: 'userClient/DELETE_PAYMENT',
    GET_TRANSACTION_PAYMENT_LINK: 'userClient/GET_TRANSACTION_PAYMENT_LINK',
    GET_TRANSACTIONS: 'userClient/GET_TRANSACTIONS',
    GET_MORE_TRANSACTIONS: 'userClient/GET_MORE_TRANSACTIONS',
    GET_JUMIO_CALLBACK_URL: 'userClient/GET_JUMIO_CALLBACK_URL',
    SCHUFA_CHECK: 'userClient/SCHUFA_CHECK',
    ADDRESS_CHECK: 'userClient/ADDRESS_CHECK',
    SEND_VERIFICATION_CODE: 'userClient/SEND_VERIFICATION_CODE',
    JOIN_CIRCLE: 'userClient/JOIN_CIRCLE',
    REMOVE_CIRCLE: 'userClient/REMOVE_CIRCLE',
    GET_RENTAL_HISTORY: 'userClient/GET_RENTAL_HISTORY',
    GET_MORE_RENTAL_HISTORY: 'userClient/GET_MORE_RENTAL_HISTORY',
    UPLOAD_DOCUMENT: 'rentalClient/UPLOAD_DOCUMENT',
    GET_PAYMENT_PUBLIC_KEY: 'userClient/GET_PAYMENT_PUBLIC_KEY',
    INIT_JUMIO_FOR_WEB: 'userClient/INIT_JUMIO_FOR_WEB',
    INIT_JUMIO_FOR_MOBILE: 'userClient/INIT_JUMIO_FOR_MOBILE',
    VERIFY_JUMIO_SUCCESS: 'userClient/VERIFY_JUMIO_SUCCESS',
    SEND_VERIFICATION_EMAIL: 'userClient/SEND_VERIFICATION_EMAIL',
    GENERATE_DEPOSIT_PAYMENT_LINK: 'userClient/GENERATE_DEPOSIT_PAYMENT_LINK',
    DOWNLOAD_GDPR: 'userClient/DOWNLOAD_GDPR',
    REFUND_DEPOSIT: 'userClient/REFUND_DEPOSIT',
    DELETE_PHONE: 'userClient/DELETE_PHONE',
    LOAD_TARIFFS: 'userClient/LOAD_TARIFFS',
    GENERATE_MAGIC_LINK: 'userClient/GENERATE_MAGIC_LINK',
  },
  CONNECT_CLIENT: {
    GET_ADMIN: 'connectClient/GET_ADMIN',
    LOGIN: 'connectClient/LOGIN',
    FORGET_PASSWORD: 'connectClient/FORGET_PASSWORD',
    FIND_CARS: 'connectClient/FIND_CARS',
    FIND_DEVICES: 'connectClient/FIND_DEVICES',
    FIND_FUEL_CARDS: 'connectClient/FIND_FUEL_CARDS',
    START_INSTALLATION: 'connectClient/START_INSTALLATION',
    CANCEL_INSTALLATION: 'connectClient/CANCEL_INSTALLATION',
    UPDATE_INSTALLATION: 'connectClient/UPDATE_INSTALLATION',
    FINISH_INSTALLATION: 'connectClient/FINISH_INSTALLATION',
    GET_DEVICE_STATUS: 'connectClient/GET_CAR_STATUS',
    LOCK_DEVICE: 'connectClient/LOCK_CAR',
    UNLOCK_DEVICE: 'connectClient/UNLOCK_CAR',
    UPLOAD_DOCUMENT: 'connectClient/UPLOAD_DOCUMENT',
    UNINSTALL_CAR: 'connectClient/UNINSTALL_CAR',
  },
  PAYMENT_CLIENT: {
    SUBMIT_IDENTITY_SHOPPER: 'paymentClient/SUBMIT_IDENTITY_SHOPPER',
    SUBMIT_CHALLENGE: 'paymentClient/SUBMIT_CHALLENGE',
    IS_PAYMENT_VERIFIED: 'paymentClient/IS_PAYMENT_VERIFIED',
  },
  RENTAL_CLIENT: {
    GET_SERVICE_TYPES: 'rentalClient/GET_SERVICE_TYPES',
    GET_RENTAL_DATA: 'rentalClient/GET_RENTAL_DATA',
    GET_DRIVER_CAR_STATUS: 'rentalClient/GET_DRIVER_CAR_STATUS',
    GET_DRIVER_CAR_STATUS_LOADER: 'rentalClient/GET_DRIVER_CAR_STATUS_LOADER',
    RESERVE_CAR: 'rentalClient/RESERVE_CAR',
    CANCEL_RESERVATION: 'rentalClient/CANCEL_RESERVATION',
    FINISH_SERVICE_TYPE: 'rentalClient/FINISH_SERVICE_TYPE',
    UPLOAD_SERVICE_IMAGE_S3: 'rentalClient/UPLOAD_SERVICE_IMAGE_S3',
    UPLOAD_SERVICE_IMAGE: 'rentalClient/UPLOAD_SERVICE_IMAGE',
    FINISH_RENTAL: 'rentalClient/FINISH_RENTAL',
    UPDATE_RENTAL: 'rentalClient/UPDATE_RENTAL',
    GET_FUEL_INFO: 'rentalClient/GET_FUEL_INFO',
    RATE_CAR: 'rentalClient/RATE_CAR',
    LOCK_CAR: 'rentalClient/LOCK_CAR',
    CAN_FINISH: 'rentalClient/CAN_FINISH',
    ADD_DAMAGE: 'rentalClient/ADD_DAMAGE',
    DELETE_DAMAGE: 'rentalClient/DELETE_DAMAGE',
    ADD_DAMAGE_IMAGE: 'rentalClient/ADD_DAMAGE_IMAGE',
    DELETE_DAMAGE_IMAGE: 'rentalClient/DELETE_DAMAGE_IMAGE',
    UNLOCK_CAR: 'rentalClient/UNLOCK_CAR',
    TURN_IGNITION_ON: 'rentalClient/TURN_IGNITION_ON',
    TURN_IGNITION_OFF: 'rentalClient/TURN_IGNITION_OFF',
    UPLOAD_START_CHECK_IMAGE_S3: 'rentalClient/UPLOAD_START_CHECK_IMAGE_S3',
    UPLOAD_START_CHECK_IMAGE: 'rentalClient/UPLOAD_START_CHECK_IMAGE',
    UPLOAD_END_CHECK_IMAGE_S3: 'rentalClient/UPLOAD_END_CHECK_IMAGE_S3',
    UPLOAD_END_CHECK_IMAGE: 'rentalClient/UPLOAD_END_CHECK_IMAGE',
    TAKE_SERVICE: 'rentalClient/TAKE_SERVICE',
    START_SERVICE: 'rentalClient/START_SERVICE',
    GENERATE_SERVICE_IMAGE_URL: 'rentalClient/GENERATE_SERVICE_IMAGE_URL',
    GENERATE_START_CHECK_IMAGE_URL:
      'rentalClient/GENERATE_START_CHECK_IMAGE_URL',
    GENERATE_END_CHECK_IMAGE_URL: 'rentalClient/GENERATE_END_CHECK_IMAGE_URL',
    GET_CAR_DAMAGES: 'rentalClient/GET_CAR_DAMAGES',
    GET_S3_CREDENTIALS: 'rentalClient/GET_RENTAL_S3_CREDENTIALS',
    UPLOAD_S3_RESOURCE: 'rentalClient/UPLOAD_S3_RESOURCE',
    GET_MYM_TRIP_OFFER: 'rentalClient/GET_MYM_TRIP_OFFER',
    CONFIRM_MYM_TRIP: 'rentalClient/GET_MYM_TRIP',
    UPDATE_RETURN_TIME: 'rentalClient/UPDATE_RETURN_TIME',
  },
  SERVICE_CLIENT: {
    GET_SERVICES: 'serviceClient/GET_SERVICES',
  },
  BOOKING_CLIENT: {
    GET_DRIVER_BOOKING: 'bookingClient/GET_DRIVER_BOOKING',
    GET_DRIVER_BOOKINGS: 'bookingClient/GET_DRIVER_BOOKINGS',
    GET_MORE_DRIVER_BOOKINGS: 'bookingClient/GET_MORE_DRIVER_BOOKINGS',
    BOOK_CAR: 'bookingClient/BOOK_CAR',
    BOOK_POI: 'bookingClient/BOOK_POI',
    CANCEL_BOOKING_REQUEST: 'bookingClient/CANCEL_BOOKING_REQUEST',
    ACCEPT_BOOKING_OFFER: 'bookingClient/ACCEPT_BOOKING_OFFER',
    REMOVE_BOOKING_OFFER: 'bookingClient/REMOVE_BOOKING_OFFER',
    GET_OWNER_BOOKINGS: 'bookingClient/GET_OWNER_BOOKINGS',
    ACCEPT_BOOKING_REQUEST: 'bookingClient/ACCEPT_BOOKING',
    CANCEL_BOOKING_OFFER: 'bookingClient/CANCEL_BOOKING_OFFER',
    REMOVE_BOOKING_REQUEST: 'bookingClient/REMOVE_BOOKING_REQUEST',
    GET_CAR_BOOKINGS: 'bookingClient/GET_CAR_BOOKINGS',
    UPDATE_BOOKING: 'bookingClient/UPDATE_BOOKING',
    CONFIRM_BOOKING: 'bookingClient/CONFIRM_BOOKING',
    GET_BOOKING_HANDOVER: 'bookingClient/GET_BOOKING_HANDOVER',
    GET_FLEET_INSURANCES: 'bookingClient/GET_FLEET_INSURANCES',
    ESTIMATE_COST: 'bookingClient/ESTIMATE_COST',
    START_BOOKING: 'bookingClient/START_BOOKING',
  },
};
