import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setSupportContacted } from '../redux/store/appData/common';
import { State } from '../@types';
import { setIsIntercomUserRegistered } from '../redux/store/appData/common/actions';

export type Props = {
  setSupportContacted: typeof setSupportContacted;
  intercomHandlingMode: string;
  isIntercomUserRegistered: boolean;
  setIsIntercomUserRegistered: typeof setIsIntercomUserRegistered;
};

const withSubscriber: any = compose(
  connect(
    ({
      appData: {
        common: { brandSettings, isIntercomUserRegistered },
      },
    }: State) => ({
      isIntercomUserRegistered,
      intercomHandlingMode: brandSettings?.intercomHandlingMode,
    }),
    {
      setSupportContacted,
      setIsIntercomUserRegistered,
    }
  )
);
export default withSubscriber;
