import TYPES from '../../../@types/redux/store/DeviceTypes';
import {
  Language,
  PlatformType as PLATFORM,
  SetDeviceUUID,
  SetLanguage,
  SetPlatform,
  SetModel,
  SetLastKnownLocation,
  SetUserAgent,
  SetLocationPermission,
  SetPushToken,
  SetPushDeviceID,
  SetDevicePaired,
  SetCameraPermission,
  SetDeviceConnected,
  SetBluetoothPermission,
  SetOrientation,
  SetTotalMemory,
  SetUsedMemory,
  SetIsKeyboardOpened,
} from '../../../@types/redux/store/DeviceTypes';
import { Location } from '../../../@types';

export const setIsKeyboardOpened = (
  isKeyboardOpened: boolean
): SetIsKeyboardOpened => ({
  type: TYPES.SET_IS_KEYBOARD_OPENED,
  payload: {
    isKeyboardOpened,
  },
});

export const setTotalMemory = (
  totalMemory: string | number
): SetTotalMemory => ({
  type: TYPES.SET_TOTAL_MEMORY,
  payload: {
    totalMemory,
  },
});

export const setUsedMemory = (usedMemory: string | number): SetUsedMemory => ({
  type: TYPES.SET_USED_MEMORY,
  payload: {
    usedMemory,
  },
});

export const setOrientation = (orientation: string): SetOrientation => ({
  type: TYPES.SET_DEVICE_ORIENTATION,
  payload: {
    orientation,
  },
});

export const setDeviceUUID = (deviceUUID: string): SetDeviceUUID => ({
  type: TYPES.SET_DEVICE_UUID,
  payload: {
    deviceUUID,
  },
});

export const setLanguage = (language: Language): SetLanguage => ({
  type: TYPES.SET_LANGUAGE,
  payload: {
    language,
  },
});

export const setPlatform = (platform: string): SetPlatform => ({
  type: TYPES.SET_PLATFORM,
  payload: {
    platform,
  },
});

export const setModel = (model: string): SetModel => ({
  type: TYPES.SET_MODEL,
  payload: {
    model,
  },
});

export const setPushToken = (pushToken: string): SetPushToken => ({
  type: TYPES.SET_PUSH_TOKEN,
  payload: {
    pushToken,
  },
});

export const setPushDeviceID = (pushDeviceID: string): SetPushDeviceID => ({
  type: TYPES.SET_PUSH_DEVICE_ID,
  payload: {
    pushDeviceID,
  },
});

export const setLastKnownLocation = (
  lastKnownLocation: Location
): SetLastKnownLocation => ({
  type: TYPES.SET_LAST_KNOWN_LOCATION,
  payload: {
    lastKnownLocation,
  },
});

export const setUserAgent = (userAgent: string): SetUserAgent => ({
  type: TYPES.SET_USER_AGENT,
  payload: {
    userAgent,
  },
});
export const setCameraPermission = (
  cameraPermission: any
): SetCameraPermission => ({
  type: TYPES.SET_CAMERA_PERMISSION,
  payload: {
    cameraPermission,
  },
});

export const setLocationPermission = (
  locationPermission: any
): SetLocationPermission => ({
  type: TYPES.SET_LOCATION_PERMISSION,
  payload: {
    locationPermission,
  },
});

export const setBluetoothPermission = (
  bluetoothPermission: any
): SetBluetoothPermission => ({
  type: TYPES.SET_BLUETOOTH_PERMISSION,
  payload: {
    bluetoothPermission,
  },
});

export const setDevicePaired = (devicePaired: boolean): SetDevicePaired => ({
  type: TYPES.SET_DEVICE_PAIRED,
  payload: {
    devicePaired,
  },
});

export const setDeviceConnected = (
  deviceConnected: boolean
): SetDeviceConnected => ({
  type: TYPES.SET_DEVICE_CONNECTED,
  payload: {
    deviceConnected,
  },
});
