import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizePropType,
  withTextLocalizer,
  selectIsFirstRental,
  isRentalOngoing,
  selectRentalData,
  setIsFirstRental,
} from 'getaway-data-layer';
import RentalWalkThroughView from './RentalWalkThroughView';
import { isMobileBrowser } from '../../../../utils';

type Props = {
  localize: LocalizePropType;
};
const RentalWalkThroughContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const isFirstRental = useSelector(selectIsFirstRental) as boolean;
  const rentalData = useSelector(selectRentalData) as any;
  const [sliderIndex, setSliderIndex] = useState(0);

  const onClickNext = () => {
    if (sliderRef) {
      // @ts-ignore
      sliderRef.current.slickNext();
    }
    if (sliderIndex === 1) {
      dispatch(setIsFirstRental(false));
    }
  };

  const afterSlide = (index: number) => {
    setSliderIndex(index);
  };

  return (
    <RentalWalkThroughView
      localize={localize}
      open={
        (isMobileBrowser() &&
          isFirstRental &&
          sliderIndex !== 2 &&
          rentalData &&
          isRentalOngoing(rentalData)) ||
        false
      }
      sliderIndex={sliderIndex}
      sliderRef={sliderRef}
      onClickNext={onClickNext}
      afterSlide={afterSlide}
    />
  );
};

export default withTextLocalizer(RentalWalkThroughContainer);
