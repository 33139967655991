import { RentalState } from '../../../../@types';

const initialState: RentalState = {
  rentalData: null,
  driverCarStatus: null,
  rentalViolations: null,
  finishRentalViolations: [],
  fuelInfo: null,
  canLock: false,
  isServiceReportSubmitted: false,
  isBookingStartedEventSent: false,
  isCarChecked: false,
  lastFinishedRentalDate: null,
  carDamages: null,
  forceRentalDriveMode: false,
  mymUiTrip: {
    startLocation: null,
    destinationLocation: null,
    adultCount: 1,
    childrenCount: 0,
    wheelChair: false,
  },
};

export default initialState;
