import { State } from '../@types';

export const selectRental = (store: State) => store.userData.rental;

export const selectRentalData = (store: State) =>
  store.userData.rental.rentalData;

export const selectDriverCarStatus = (store: State) =>
  store.userData.rental.driverCarStatus;

export const selectFuelInfo = (store: State) => store.userData.rental.fuelInfo;

export const selectCarDamages = (store: State) =>
  store.userData.rental.carDamages;

export const selectFinishRentalViolations = (store: State) =>
  store.userData.rental.finishRentalViolations;

export const selectCanLock = (store: State) => store.userData.rental.canLock;

export const selectMymUiTrip = (store: State) =>
  store.userData.rental.mymUiTrip;

export const selectLastFinishedRentalDate = (store: State) =>
  store.userData.rental.lastFinishedRentalDate;
