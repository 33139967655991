import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  initJumioForMobile,
  jumioDocumentUploaded,
  verifyJumioSuccess,
} from '../redux/store/userData/user';
import { State } from '../@types';
import { setAlertBaseMode } from '../redux/store/ui/map';

export type Props = {
  jumioCallbackURL: string;
  initJumioForMobile: typeof initJumioForMobile;
  jumioDocumentUploaded: typeof jumioDocumentUploaded;
  setAlertBaseMode: typeof setAlertBaseMode;
  xBrandId: string;
};
const withJumio: any = compose(
  connect(
    ({
      userData: {
        user: { user, basicAuth },
      },
      config: { apiBaseURL, xBrandId },
    }: State) => ({
      user,
      basicAuth,
      apiBaseURL,
      xBrandId,
    }),
    {
      initJumioForMobile,
      jumioDocumentUploaded,
      setAlertBaseMode,
      verifyJumioSuccess,
    }
  )
);
export default withJumio;
