import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTextLocalizer, LocalizePropType } from 'getaway-data-layer';
import { useHistory, useParams } from 'react-router-dom';
import DepositPaymentView from './DepositPaymentView';
import { useQuery } from '../../../../../hooks/useQuery';

type Props = {
  localize: LocalizePropType;
};

const DepositPaymentContainer = ({ localize }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const onClose = () => {
    history.goBack();
  };

  useEffect(() => {}, []);

  return (
    <DepositPaymentView
      localize={localize}
      onClose={onClose}
      paymentLinkUrl={query.get('paymentLink')}
    />
  );
};

export default withTextLocalizer(DepositPaymentContainer);
