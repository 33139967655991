import React from 'react';
import './PaymentLinkView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';

type props = {
  onClose: () => void;
  title: string;
  paymentLinkUrl: string;
};

const PaymentLinkView = ({ onClose, title, paymentLinkUrl }: props) => {
  return (
    <div className="screen-container">
      <NavigationBar title={title} description="" onClose={onClose} />
      <div className="screen-body payment-link-content">
        <iframe
          src={paymentLinkUrl}
          width="100%"
          height="100%"
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default PaymentLinkView;
