import * as yup from 'yup';

export enum SIGNUP_TYPES {
  PRIVATE = 'private',
  COMPANY = 'company',
  CO_USER = 'CO_USER',
  BIKE = 'BIKE',
  ORG = 'ORG',
  PARTNER = 'PARTNER',
}

export enum LEGAL_TYPES {
  AG = 'AG',
  EV = 'e.V.',
  GmbH = 'GmbH',
  CoKG = 'GmbH & Co KG',
  KG = 'KG',
  OHG = 'OHG',
  Sonstige = 'Sonstige',
}

export const SignupModal = (signupType) => ({
  ...(signupType === SIGNUP_TYPES.ORG && {
    companyName: '',
    legalType: '',
    otherLegalType: '',
    registerNr: '',
    registerLocation: '',
  }),
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  signupType: SIGNUP_TYPES.PRIVATE,
});

export const SignupSchema = (
  signupType: string,
  localize: any,
  passwordRegex: string
): any =>
  yup.object({
    ...(signupType === SIGNUP_TYPES.ORG && {
      companyName: yup.string().required(localize('validation.required')),
      legalType: yup.string().required(localize('validation.required')),
      otherLegalType: yup.string(),
      registerNr: yup.string().required(localize('validation.required')),
      registerLocation: yup.string().required(localize('validation.required')),
    }),
    firstName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z-.]+/,
        localize('input.error.invalid')
      ),
    lastName: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /[ÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐðşa-zA-Z-.]+/,
        localize('input.error.invalid')
      ),
    email: yup
      .string()
      .required(localize('validation.required'))
      .matches(
        /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
        localize('input.error.invalid')
      ),
    password: yup
      .string()
      .required(localize('validation.required'))
      // @ts-ignore
      .matches(passwordRegex, localize('input.password.error.invalid')),
  });
