import { APIResponse } from '../../@types';

export const apiClient = {
  getStatus: async (): Promise<APIResponse<null>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');

    const { error } = await makeAPICall({
      method: 'GET',
      url: '/status',
      checkModified: false,
    });
    return {
      error,
    };
  },
};
