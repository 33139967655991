import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizePropType,
  selectUser,
  setShowHomeWT,
  withTextLocalizer,
} from 'getaway-data-layer';
import HomeView from './DesktopWTView';
import { navigateTo } from '../../../utils';
import { showAboutDialog } from '../../../utils/dialogsHelpers';
import { useHistory } from 'react-router-dom';
import config from '../../../env.config';
import packageInfo from '../../../../package.json';

type Props = {
  localize: LocalizePropType;
};
const DesktopWTContainer = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const history = useHistory();
  const user = useSelector(selectUser);
  const [sliderIndex, setSliderIndex] = useState(0);

  const afterSlide = (index: number) => {
    setSliderIndex(index);
  };

  const onClickLogin = () => {
    dispatch(setShowHomeWT(false));
    navigateTo(history, '/login');
  };

  const onClickSignup = () => {
    dispatch(setShowHomeWT(false));
    navigateTo(history, '/signup');
  };

  const onClickVersionHeader = () => {
    showAboutDialog(
      config.brand.xBrandId!!,
      localize,
      `© GETAWAY・v${packageInfo.version}`
    );
  };

  return (
    <HomeView
      localize={localize}
      user={user!}
      sliderIndex={sliderIndex}
      sliderRef={sliderRef}
      afterSlide={afterSlide}
      onClickVersionHeader={onClickVersionHeader}
      onClickLogin={onClickLogin}
      onClickSignup={onClickSignup}
    />
  );
};

export default withTextLocalizer(DesktopWTContainer);
