import { Buffer } from 'buffer';
import padString from './padString';

type BufferEncoding =
  | 'ascii'
  | 'utf8'
  | 'utf-8'
  | 'utf16le'
  | 'ucs2'
  | 'ucs-2'
  | 'base64'
  | 'base64url'
  | 'latin1'
  | 'binary'
  | 'hex';

function encode(
  input: string | Buffer,
  encoding: BufferEncoding = 'utf8'
): string {
  if (Buffer.isBuffer(input)) {
    return fromBase64(input.toString('base64'));
  }
  return fromBase64(Buffer.from(input as string, encoding).toString('base64'));
}

function decode(base64url: string, encoding: BufferEncoding = 'utf8'): string {
  return Buffer.from(toBase64(base64url), 'base64').toString(encoding);
}

function toBase64(base64url: string | Buffer): string {
  base64url = base64url.toString();
  return padString(base64url).replace(/-/g, '+').replace(/_/g, '/');
}

function fromBase64(base64: string): string {
  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

function toBuffer(base64url: string): Buffer {
  return Buffer.from(toBase64(base64url), 'base64');
}

export interface Base64Url {
  (input: string | Buffer, encoding?: BufferEncoding): string;
  encode(input: string | Buffer, encoding?: BufferEncoding): string;
  decode(base64url: string, encoding?: BufferEncoding): string;
  toBase64(base64url: string | Buffer): string;
  fromBase64(base64: string): string;
  toBuffer(base64url: string): Buffer;
}

const base64url: Base64Url = encode as Base64Url;

base64url.encode = encode;
base64url.decode = decode;
base64url.toBase64 = toBase64;
base64url.fromBase64 = fromBase64;
base64url.toBuffer = toBuffer;

export default base64url;
