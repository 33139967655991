import { PURGE } from 'redux-persist';
import clone from 'lodash.clone';
import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/CameraTypes';
import { CameraAction, CameraState } from '../../../@types';
import { revokeObjectURL } from './revokeObjectURL';

const imageKeys = {
  1: 'imageOne',
  2: 'imageTwo',
  3: 'imageThree',
  4: 'imageFour',
};

export default (
  state: CameraState = initialState,
  action: CameraAction
): CameraState => {
  switch (action.type) {
    case TYPES.SET_CAMERA_IMAGES: {
      return { ...state, images: [...state.images, action.payload] };
    }

    case TYPES.REMOVE_CAMERA_IMAGE: {
      return {
        ...state,
        images: state.images.filter(
          // $FlowFixMe
          (n) => n.id !== action.payload.id
        ),
      };
    }

    case TYPES.CLEAR_CAMERA_IMAGES: {
      revokeObjectURL(state.images);
      Object.values(imageKeys).forEach((key) => {
        if (state.carCheckImages[key]) {
          revokeObjectURL(state.carCheckImages[key]);
        }
      });
      return { ...state, images: [], carCheckImages: {} };
    }

    case TYPES.SET_CAR_CHECK_CAMERA_IMAGE: {
      const updated = clone(state.carCheckImages);
      const key = imageKeys[action.payload.index];
      if (key) {
        updated[key] = action.payload;
      }
      return { ...state, carCheckImages: updated };
    }

    case TYPES.REMOVE_CAR_CHECK_CAMERA_IMAGE: {
      const updated = clone(state.carCheckImages);
      const key = imageKeys[action.payload.index];
      if (key && updated[key]) {
        revokeObjectURL(updated[key]);
        delete updated[key];
      }
      return { ...state, carCheckImages: updated };
    }
    // @ts-ignore
    case PURGE:
      return initialState;

    default: {
      action as never; // eslint-disable-line no-unused-expressions

      return state;
    }
  }
};
