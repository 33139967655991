import { isIOS } from '../helpers/isIOS';
import { isAndroid } from '../helpers/platformHelpers';
import { injectedPermissionsManager } from '../redux/ConfigureGlobals';

export const BluetoothManager = {
  nativeManager: null as any,

  lockAndImmobilize() {
    if (isIOS()) {
      this.nativeManager?.lockAndImmobilize();
    } else {
      injectedPermissionsManager.requestBluetoothPermission(() => {
        this.nativeManager?.lockAndImmobilize();
      });
    }
  },

  unlockAndMobilize() {
    if (isIOS()) {
      this.nativeManager?.unlockAndMobilize();
    } else {
      injectedPermissionsManager.requestBluetoothPermission(() => {
        this.nativeManager?.unlockAndMobilize();
      });
    }
  },

  unlock() {
    if (isIOS()) {
      this.nativeManager?.unlock();
    } else {
      injectedPermissionsManager.requestBluetoothPermission(() => {
        this.nativeManager?.unlock();
      });
    }
  },

  enableBluetooth() {
    if (isAndroid()) {
      injectedPermissionsManager.requestBluetoothPermission(() => {
        this.nativeManager?.enableBluetooth();
      });
    }
  },

  activateBluetooth() {
    if (isIOS()) {
      this.nativeManager?.activate();
    }
  },

  getStatus(onReadSuccess: any) {
    if (isIOS()) {
      this.nativeManager?.getStatus(onReadSuccess);
    }
  },
};
