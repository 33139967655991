import { APIResponse, TextsData, Language, TextGroup } from '../../@types';

export const textClient = {
  get: async (
    group: TextGroup,
    language: Language
  ): Promise<APIResponse<TextsData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: `/texts/${group}`,
      headers: {
        'Accept-Language': language,
      },
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
