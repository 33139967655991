import React from 'react';
import Divider from '@mui/material/Divider';
import './DLOnboardingView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import { GA_TITLES, isDashboardScreen } from '../../../../../utils';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import BrandIcon from '../../../../components/BrandIcon/BrandIcon';
import { isLocalizeAdded, LocalizePropType } from 'getaway-data-layer';
import ToggleTab from '../../../../components/ToggleTab/ToggleTab';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

type Props = {
  localize: LocalizePropType;
  onClose: () => void;
  onPressStart: () => void;
  scanEnabled: boolean;
  isPlasticCard: boolean;
  setIsPlasticCard: (arg: boolean) => void;
  onClickDisabled: () => void;
};

const DLOnboardingView = ({
  localize,
  onClose,
  onPressStart,
  scanEnabled,
  isPlasticCard,
  setIsPlasticCard,
  onClickDisabled,
}: Props) => {
  const steps = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'stepFive'];

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('DL.title')}
        description={localize('DL.desr')}
        onClose={onClose}
        showHelp
        showArrowBack={isDashboardScreen()}
      />
      <div
        className={
          isLocalizeAdded(localize, 'DL.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'DL.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          <ToggleTab
            localize={localize}
            optionOne={localize(`dl.tab.plastic`)}
            optionTwo={localize(`dl.tab.paper`)}
            isOptionOneSelected={isPlasticCard}
            onToggleValueChange={() => {
              setIsPlasticCard(!isPlasticCard);
            }}
          />
          <div className="add-DL-img-container">
            <img
              alt="DL"
              src={
                isPlasticCard ? '/images/DL-de.png' : '/images/DL-paper-de.png'
              }
              className="add-DL-img"
            />
          </div>
          {steps.map((step, index) => {
            const localizedText = localize(
              isPlasticCard ? `DL.${step}` : `DL.paper.${step}`
            );
            if (
              localizedText.includes('DL.step') ||
              localizedText.includes('DL.paper') ||
              localizedText.trim().length === 0
            ) {
              return null;
            }

            return (
              <>
                <div key={index} className="add-DL-item-container">
                  <BrandIcon
                    name="selected"
                    style={{ width: 24, height: 24, marginRight: 18 }}
                  />
                  <div className="add-DL-item-text">{localizedText}</div>
                  {index < steps.length - 1 && <Divider />}
                </div>
                <Divider />
              </>
            );
          })}

          <BrandButton
            id={GA_TITLES.ON_BOARDING.ADD_DL}
            title={localize('DL.button.text')}
            onClick={onPressStart}
            disabled={!scanEnabled}
            onClickDisabled={onClickDisabled}
          />
        </div>
        {isLocalizeAdded(localize, 'DL.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize('DL.faq')}
            </Markdown>
          </div>
        )}
        <div className="screen-vertical-space" />
      </div>
    </div>
  );
};

export default DLOnboardingView;
