import React, { useState, useEffect } from 'react';
import {
  withTextLocalizer,
  selectFrom,
  selectBackby,
  setFrom,
  setBackBy,
  clearFilter,
  selectMapCenterLocation,
  selectMapZoom,
} from 'getaway-data-layer';
import { useSelector, useDispatch } from 'react-redux';
import { differenceInHours, isBefore } from 'date-fns';
import MapFilterView from './EmbeddedCardView';
import {
  initialReturnTime,
  minimumReturnTime,
  minimumStartTime,
  isMobileBrowser,
} from '../../../../utils';

type Props = { localize: any };

export const isSafari =
  ['iPhone', 'iPad', 'iPod']?.includes(
    // @ts-ignore
    window?.navigator?.userAgentData?.platform || window?.navigator?.platform
  ) || /^((?!chrome|android).)*safari/i.test(window?.navigator?.userAgent);

const EmbeddedCard = ({ localize }: Props) => {
  const dispatch = useDispatch();
  const from = useSelector(selectFrom) as any;
  const backBy = useSelector(selectBackby) as any;
  const mapCenterLocation = useSelector(selectMapCenterLocation);
  const zoom = useSelector(selectMapZoom);

  const startDate = from ? new Date(from) : minimumStartTime();
  const backByDate = backBy ? new Date(backBy) : initialReturnTime(startDate);

  const [selectedStartDate, setSelectedStartDate] = useState(startDate) as any;
  const [selectedEndDate, setSelectedEndDate] = useState(backByDate) as any;
  const [customError, setCustomError] = useState('') as any;

  const onChangeStartDate = (e: any) => {
    setSelectedStartDate(e);
    if (isBefore(selectedEndDate, e)) {
      setSelectedEndDate(initialReturnTime(e));
      // dispatch(setBackBy(initialReturnTime(e)));
    }
  };

  const onChangeEndDate = (e: any) => {
    setSelectedEndDate(e);
  };

  const onReset = () => {
    dispatch(clearFilter());
    setSelectedStartDate(minimumStartTime());
    setSelectedEndDate(initialReturnTime(minimumStartTime()));
  };

  const onPressFilter = () => {
    const currentUrl = new URL(window?.location?.href);
    const url = new URL(`${window?.location?.origin}/map`);

    url.searchParams.append('from', selectedStartDate.toISOString());
    url.searchParams.append('backBy', selectedEndDate.toISOString());

    if (mapCenterLocation?.latitude && mapCenterLocation?.longitude) {
      url.searchParams.append('lat', mapCenterLocation.latitude.toString());
      url.searchParams.append('lng', mapCenterLocation.longitude.toString());
    }

    if (zoom) {
      url.searchParams.append('zoom', zoom.toString());
    }

    // Preserve existing UTM parameters if they exist
    const utmSource = currentUrl?.searchParams?.get('utm_source');
    const utmMedium = currentUrl?.searchParams?.get('utm_medium');

    if (utmSource) {
      url.searchParams.append('utm_source', utmSource);
    }

    if (utmMedium) {
      url.searchParams.append('utm_medium', utmMedium);
    }

    window.open(url.toString(), '_blank');
  };

  useEffect(() => {
    // min for selectedStartDate
    if (
      (isSafari || !isMobileBrowser()) &&
      isBefore(selectedStartDate, new Date())
    ) {
      setCustomError(localize('wj.map.filter.violation.min'));
    } else if (
      (isSafari || !isMobileBrowser()) &&
      isBefore(selectedEndDate, selectedStartDate)
    ) {
      setCustomError(localize('wj.map.filter.violation.order'));
    } else if (
      (isSafari || !isMobileBrowser()) &&
      differenceInHours(selectedEndDate, selectedStartDate) < 1
    ) {
      setCustomError(localize('wj.map.filter.violation.duration'));
    } else {
      setCustomError('');
      dispatch(setFrom(selectedStartDate.toUTCString()));
      dispatch(setBackBy(selectedEndDate.toUTCString()));
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <MapFilterView
      localize={localize}
      selectedStartDate={selectedStartDate}
      onChangeStartDate={onChangeStartDate}
      selectedEndDate={selectedEndDate}
      onChangeEndDate={onChangeEndDate}
      minStart={new Date()}
      minEnd={minimumReturnTime(selectedStartDate)}
      onReset={onReset}
      onPressFilter={onPressFilter}
      customError={customError}
      isSafari={isSafari}
    />
  );
};

export default withTextLocalizer(EmbeddedCard);
