export type NotificationsState = Array<{
  readonly id: string;
  readonly type: any;
  readonly title: string | null | undefined;
  readonly message: string;
  readonly actionText: string | null | undefined;
  readonly actionCallback: (() => void) | null | undefined;
  readonly dismissCallback: (() => void) | null | undefined;
}>;
enum NotificationTypes {
  SET_NOTIFICATION = 'ui/notifications/SET_NOTIFICATION',
  DISMISS_NOTIFICATION = 'ui/notifications/DISMISS_NOTIFICATION',
  CLEAR_NOTIFICATIONS = 'ui/notifications/CLEAR_NOTIFICATIONS',
}
export default NotificationTypes;
export type SetNotification = {
  type: NotificationTypes.SET_NOTIFICATION;
  payload: {
    id: string;
    type: any;
    message: string;
    title: string | null | undefined;
    actionText: string | null | undefined;
    actionCallback: (() => void) | null | undefined;
    dismissCallback: (() => void) | null | undefined;
  };
};
export type DismissNotification = {
  type: NotificationTypes.DISMISS_NOTIFICATION;
  payload: {
    id: string;
  };
};
export type ClearNotifications = {
  type: NotificationTypes.CLEAR_NOTIFICATIONS;
};
export type NotificationsAction =
  | SetNotification
  | DismissNotification
  | ClearNotifications;
