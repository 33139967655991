export type ServiceTypesState = string[] | undefined;

enum ServiceTypeTypes {
  SET_SERVICE_TYPES = 'appData/serviceTypes/SET_SERVICE_TYPES',
  CLEAR_SERVICE_TYPES = 'appData/serviceTypes/CLEAR_SERVICE_TYPES',
}

export default ServiceTypeTypes;

export type SetServiceTypes = {
  type: ServiceTypeTypes.SET_SERVICE_TYPES;
  payload: {
    serviceTypes: string[];
  };
};

export type ClearServiceTypes = {
  type: ServiceTypeTypes.CLEAR_SERVICE_TYPES;
};

export type ServiceTypesAction = SetServiceTypes | ClearServiceTypes;
