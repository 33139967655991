import React from 'react';
import './RentalDetailsView.less';
import NavigationBar from '../../../../components/NavigationBar/NavigationBar';
import PointItem from '../../../../components/PointItem/PointItem';
import ListItem from '../../../../components/ListItem/ListItem';
import {
  formatDistance,
  InsuranceData,
  isFreeInsurance,
  LocalizePropType,
  msToHour,
} from 'getaway-data-layer';
import { titleForDriverCar } from '../../../../../utils';

type props = {
  localize: LocalizePropType;
  onClose: () => void;
  car: any;
  from: string;
  until: string;
  title: string;
  finalLock: string;
  initialUnlock: string;
  distance: number;
  tripId: string;
  formattedReservedAt: string;
  formattedUnlock: string;
  formattedLock: string;
  rentalInvoiceUrl: string;
  onPressInvoice: () => void;
  onClickCarProfile: () => void;
  insurance: InsuranceData;
  returnAddress: string;
  pickUpAddress: string;
  onPressAddress: (arg: boolean) => void;
};

const BookingView = ({
  localize,
  onClose,
  car,
  from,
  until,
  title,
  finalLock,
  initialUnlock,
  distance,
  tripId,
  formattedReservedAt,
  formattedUnlock,
  formattedLock,
  rentalInvoiceUrl,
  onPressInvoice,
  onClickCarProfile,
  insurance,
  returnAddress,
  pickUpAddress,
  onPressAddress,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={title}
        description={`  ${msToHour(
          // @ts-ignore
          (finalLock ? new Date(finalLock) : new Date()) -
            // @ts-ignore
            new Date(initialUnlock),
          // @ts-ignore
          localize
        )} | ${formatDistance(distance || 1)}`}
        onClose={onClose}
        showArrowBack
      />
      <div className="screen-body rental-details-content">
        <PointItem
          title={localize('rental.section.locked')}
          value={formattedLock}
        />
        <div className="rental-details-section">
          <div className="reserved-card-section-line" />
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={returnAddress !== 'undefined' ? returnAddress : ''}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator
            onClick={() => onPressAddress(false)}
          />
        </div>
        <PointItem
          title={localize('rental.section.unlocked')}
          value={formattedUnlock}
        />
        <div className="rental-details-section">
          <div className="reserved-card-section-line" />
          <ListItem
            title={localize('booking.confirm.profile')}
            carPlate={car.plate}
            showSeparator
            brandValueColor
            rightIcon="arrowRight"
            rightIconSize={12}
            onClick={onClickCarProfile}
          />
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={pickUpAddress}
            rightIcon="navigationArrow"
            rightIconSize={14}
            brandValueColor
            showSeparator
            onClick={() => onPressAddress(true)}
          />
          {insurance && (
            <ListItem
              title={localize('insurance.charterline.headline')}
              value={
                isFreeInsurance(insurance?.dailyRate)
                  ? localize('insurance.label.free')
                  : localize(
                      `insurance.charterline.${
                        // @ts-ignore
                        insurance?.deductibleAmount / 100
                      }`
                    )
              }
              showSeparator
              onClick={() => {}}
            />
          )}
        </div>
        <PointItem
          title={localize('bookings.headline.reservation')}
          value={formattedReservedAt}
        />
        <div className="reserved-card-section">
          <div className="reserved-card-section-line" />
          <ListItem
            containerStyles={{ padding: '10px 16px', margin: 0 }}
            title={localize('booking.confirm.profile')}
            value={`${titleForDriverCar(car)}`}
            showSeparator={false}
            brandValueColor
            onClick={onClickCarProfile}
          />
          <div className="reserved-card-separator" />
          {!!from && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.from')}
                value={from}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!until && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('booking.confirm.to')}
                value={until}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
          {!!tripId && (
            <>
              <ListItem
                containerStyles={{ padding: '10px 16px', margin: 0 }}
                title={localize('station.booking.code')}
                value={<div className="rental-details-code">{tripId}</div>}
                showSeparator={false}
                onClick={() => {}}
              />
              <div className="reserved-card-separator" />
            </>
          )}
        </div>
        {rentalInvoiceUrl && (
          <div className="rental-details-invoice" onClick={onPressInvoice}>
            {localize('rental.history.invoice')}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingView;
