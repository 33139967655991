import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  setGoogleApiAccessToken,
  setApiBaseURL,
  setApiVersion,
  setBundleId,
  setAppleAppId,
  setXBrandId,
} from '../redux/store/config';
import { setPhoneCode } from '../redux/store/ui/firstSteps';
import { setShowHomeWT } from '../redux/store/ui/map';

export type Props = {
  setGoogleApiAccessToken: typeof setGoogleApiAccessToken;
  setApiBaseURL: typeof setApiBaseURL;
  setApiVersion: typeof setApiVersion;
  setBundleId: typeof setBundleId;
  setAppleAppId: typeof setAppleAppId;
  setXBrandId: typeof setXBrandId;
  setPhoneCode: typeof setPhoneCode;
  setShowHomeWT: typeof setShowHomeWT;
};

const withConfigSetter: any = compose(
  connect(null, {
    setGoogleApiAccessToken,
    setApiBaseURL,
    setApiVersion,
    setBundleId,
    setAppleAppId,
    setXBrandId,
    setPhoneCode,
    setShowHomeWT,
  })
);
export default withConfigSetter;
