import { APIResponse, RentalData } from '../../@types';

export const serviceClient = {
  getServices: async (): Promise<APIResponse<Array<RentalData>>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { notModified, data, error } = await makeAPICall({
      method: 'GET',
      url: '/services',
    });
    return {
      notModified,
      data,
      error,
    };
  },
};
