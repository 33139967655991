import axios from 'axios';

export const DEFAULT_RETRY_COUNT = 10;
export const DEFAULT_RETRY_DELAY = 9000;

const RETRY_END_POINTS = [
  '/features',
  '/areas',
  '/services',
  '/cars/equipments',
  '/brand',
  '/cars/categories',
];

// Retry logic for calls
axios.interceptors.response.use(undefined, (err) => {
  const { config } = err;
  if (!config || !config.retryCount || !RETRY_END_POINTS.includes(config.url)) {
    return Promise.reject(err);
  }
  // Don't retry if unauthorized
  if (err?.response?.status === 401) {
    return Promise.reject(err);
  }
  config.retryCount -= 1;
  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, (DEFAULT_RETRY_COUNT - config.retryCount || 1) * config.retryDelay);
  });
  return delayRetryRequest.then(() => axios(config));
});
