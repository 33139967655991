import React from 'react';
import './FuelSettingsView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import ListItem from '../../../components/ListItem/ListItem';
import { FuelInfo, LocalizePropType } from 'getaway-data-layer';

type Props = {
  localize: LocalizePropType;
  fuelInfo: FuelInfo;
  distance: string;
  onClickFuelCard: () => void;
  onClose: () => void;
};

const FuelSettingsView = ({
  localize,
  fuelInfo,
  distance,
  onClickFuelCard,
  onClose,
}: Props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('rental.fuelScreen.title')}
        description={localize('rental.fuelSettings.desc')}
        showHelp
        onClose={onClose}
      />
      <div className="screen-body">
        <div className="fuel-settings-item-header">
          {localize('rental.fuelSettings.stepOne')}
        </div>
        <ListItem title={`~ ${distance} km`} leftIcon="carFuel" />
        <div className="fuel-settings-trusted-stations-wrapper">
          <img
            className="fuel-settings-trusted-stations"
            src="/images/trustedFuelStations.png"
            alt="trustedFuelStations"
          />
          <span className="fuel-settings-trusted-stations-text">...</span>
        </div>

        <div className="fuel-settings-item-description">
          {localize('rental.fuelSettings.gasStation.desc')}
        </div>
        <div className="fuel-settings-item-header">
          {localize('rental.fuelSettings.stepTwo')}
        </div>
        <ListItem
          title={
            fuelInfo && fuelInfo.type
              ? localize(`car.details.fuel.${fuelInfo.type}`)
              : ''
          }
          leftIcon="fuelKind"
        />
        <div className="fuel-settings-item-header">
          {localize('rental.fuelSettings.stepThree')}
        </div>
        <ListItem
          title={
            fuelInfo && fuelInfo.cardNumber
              ? `${localize('rental.fuelSettings.fuelCard')} ${
                  fuelInfo.cardNumber
                }`
              : ''
          }
          leftIcon="fuelCard"
          rightIcon="info"
          rightIconSize={20}
          onClick={onClickFuelCard}
        />
        <ListItem
          title={
            fuelInfo && fuelInfo.cardPin
              ? `${fuelInfo.cardPin[0]}・${fuelInfo.cardPin[1]}・${fuelInfo.cardPin[2]}・${fuelInfo.cardPin[3]}`
              : ''
          }
          leftIcon="fuelCopy"
          onClick={() => {}}
        />
        <div className="fuel-settings-item-description">
          {localize('dongle.rental.fuelSettings.instruction')}
        </div>
      </div>
    </div>
  );
};

export default FuelSettingsView;
