import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RentalState, State } from '../@types';

export type Props = RentalState & {
  carMode:
    | 'reserved'
    | 'opened'
    | 'driving'
    | 'parking'
    | 'cancelled'
    | 'expired'
    | 'finished'
    | 'waitingForCheckin';
};

const withUserProfile: any = compose(
  connect(
    ({
      userData: {
        rental: { rentalData },
      },
    }: State) => ({
      carMode:
        rentalData &&
        (rentalData.carMode === 'reserved' ||
          rentalData.carMode === 'opened' ||
          rentalData.carMode === 'driving' ||
          rentalData.carMode === 'parking')
          ? rentalData.carMode
          : undefined,
    })
  )
);
export default withUserProfile;
