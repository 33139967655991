import React from 'react';
import './LowFuelCallout.less';

type Props = {
  text?: string;
};

const LowFuelCallout = ({ text }: Props) => {
  return (
    <div className="low-fuel-callout-container">
      <div className="low-fuel-callout-container">
        <div className="low-fuel-callout-text">{text}</div>
        <img
          alt="info"
          src="/images/info-white.png"
          style={{ width: 11, height: 11, marginLeft: 4 }}
        />
      </div>
      <div className="low-fuel-callout-arrow" />
    </div>
  );
};

LowFuelCallout.defaultProps = {
  text: '',
};

export default LowFuelCallout;
