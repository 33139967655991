import { UserDataOutput } from '../@types';
import { injectedIntercom } from '../redux/ConfigureGlobals';

export const IntercomManager = {
  registerIntercomUser(
    user: UserDataOutput | null | undefined
  ): Promise<boolean> {
    return new Promise((resolve) => {
      if (injectedIntercom) {
        if (user) {
          let result: Promise<boolean> = null;
          try {
            if (
              Object.prototype.hasOwnProperty.call(
                injectedIntercom,
                'loginUserWithUserAttributes'
              )
            ) {
              result = injectedIntercom.loginUserWithUserAttributes({
                userId: user.id,
                email: user.email,
              });
            } else {
              result = injectedIntercom.loginUserWithUserAttributes({
                userId: user.id,
                email: user.email,
              });
            }
            result
              .then(() => {
                // Handle the resolved Promise
                resolve(true);
              })
              .catch((error) => {
                // Handle the rejected Promise
                // Perform error handling
                resolve(false);
              });
          } catch (e) {
            // @ts-ignore
            // eslint-disable-next-line no-undef
            if (__DEV__) {
              console.log('Intercom register user:', e);
            }
            resolve(false);
          }
        } else {
          if (
            Object.prototype.hasOwnProperty.call(
              injectedIntercom,
              'loginUnidentifiedUser'
            )
          ) {
            injectedIntercom.loginUnidentifiedUser();
          } else {
            injectedIntercom.loginUnidentifiedUser();
          }
          resolve(true); //Assuming this is successful as there's no way to catch error
        }
      } else {
        resolve(false); // Intercom is not available
      }
    });
  },

  logout() {
    if (injectedIntercom) {
      injectedIntercom.logout();
    }
  },
};
