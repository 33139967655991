export default [
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:55:03.319Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3445223 52.5379509',
              '13.3486516 52.5377147',
              '13.3543419 52.5381666',
              '13.3593926 52.5370552',
              '13.3607383 52.5377245',
              '13.3572384 52.5387064',
              '13.3545901 52.539369',
              '13.3529257 52.5394543',
              '13.3515605 52.5390586',
              '13.3471815 52.5385725',
              '13.3449897 52.5387412',
              '13.3445223 52.5379509',
            ],
          },
        },
      ],
    },
    id: '5d3f6b3790e8b12127c0b4db',
    lastUpdatedAt: '2019-08-20T17:27:10.546Z',
    version: 8,
    kmlFilename: 'planned_Berlin_01_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_01',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T21:57:59.072Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3277869 52.5242966',
              '13.3288365 52.5272741',
              '13.3286864 52.5332461',
              '13.3280728 52.5336862',
              '13.3185798 52.5325739',
              '13.3131349 52.531838',
              '13.3060821 52.5318748',
              '13.3060432 52.5319587',
              '13.2961673 52.5307008',
              '13.296296 52.5301525',
              '13.3055013 52.5314709',
              '13.3061343 52.5309814',
              '13.3062121 52.5305906',
              '13.3061666 52.5302129',
              '13.3063596 52.5287884',
              '13.3055504 52.5257236',
              '13.3056639 52.5219668',
              '13.3064273 52.5218557',
              '13.3069672 52.5288398',
              '13.3080897 52.5289956',
              '13.3119386 52.5295651',
              '13.3133334 52.5300024',
              '13.3167471 52.5303774',
              '13.3230194 52.5317358',
              '13.3274096 52.5327371',
              '13.3285733 52.5273178',
              '13.3272049 52.5244843',
              '13.3277869 52.5242966',
            ],
          },
        },
      ],
    },
    id: '5d3f6be790e8b12127c0b4e0',
    lastUpdatedAt: '2019-08-20T17:27:23.135Z',
    version: 8,
    kmlFilename: 'planned_Berlin_02_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_02',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:00:01.829Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4129025 52.4819536',
              '13.4064961 52.4832775',
              '13.4018612 52.4841417',
              '13.4001285 52.483944',
              '13.3946086 52.4843458',
              '13.3945334 52.4835831',
              '13.4174462 52.4798269',
              '13.4209786 52.4797859',
              '13.4206977 52.4805618',
              '13.4129025 52.4819536',
            ],
          },
        },
      ],
    },
    id: '5d3f6c6190e8b12127c0b4e5',
    lastUpdatedAt: '2019-08-20T17:27:56.649Z',
    version: 8,
    kmlFilename: 'planned_Berlin_03_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_03',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:01:29.871Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.1663439 52.5519777',
              '13.164874 52.5498378',
              '13.19844 52.5427451',
              '13.2015565 52.5409959',
              '13.2019588 52.5411526',
              '13.1950304 52.5461718',
              '13.1663439 52.5519777',
            ],
          },
        },
      ],
    },
    id: '5d3f6cb990e8b12127c0b4ea',
    lastUpdatedAt: '2019-08-20T17:28:19.497Z',
    version: 8,
    kmlFilename: 'planned_Berlin_04_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_04',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:03:50.389Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2751553 52.4605064',
              '13.2751232 52.4603086',
              '13.2760351 52.4553614',
              '13.2796615 52.450523',
              '13.2800048 52.450026',
              '13.2828587 52.4511507',
              '13.2848328 52.4521184',
              '13.2866406 52.4517375',
              '13.2849293 52.4484143',
              '13.2855114 52.4482622',
              '13.287747 52.4510698',
              '13.2872575 52.4533869',
              '13.2960535 52.4564631',
              '13.3003289 52.4579406',
              '13.3038802 52.4585943',
              '13.3041591 52.4591958',
              '13.3033437 52.4593331',
              '13.3050925 52.4655496',
              '13.3055753 52.4659091',
              '13.2992346 52.4653601',
              '13.298387 52.4652228',
              '13.296767 52.4646868',
              '13.2963056 52.4644842',
              '13.29637 52.4642555',
              '13.2880981 52.4584048',
              '13.2751553 52.4605064',
            ],
          },
        },
      ],
    },
    id: '5d3f6d4690e8b12127c0b4ef',
    lastUpdatedAt: '2019-08-28T18:13:47.765Z',
    version: 8,
    kmlFilename: 'planned_Berlin_05_190828.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_05',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:23:23.707Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4786814 52.5070657',
              '13.4827423 52.5115682',
              '13.4831071 52.5122766',
              '13.4778768 52.5128578',
              '13.4766645 52.5125574',
              '13.4763641 52.5126129',
              '13.4762407 52.5125117',
              '13.4765464 52.5123909',
              '13.477394 52.509459',
              '13.4785607 52.5071261',
              '13.4786814 52.5070657',
            ],
          },
        },
      ],
    },
    id: '5d3f71db90e8b12127c0b50a',
    lastUpdatedAt: '2019-08-20T17:30:48.279Z',
    version: 8,
    kmlFilename: 'planned_Berlin_06_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_06',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:25:30.54Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5515534 52.428806',
              '13.5628777 52.437007',
              '13.5615447 52.4371821',
              '13.5498556 52.4298592',
              '13.5515534 52.428806',
            ],
          },
        },
      ],
    },
    id: '5d3f725a90e8b12127c0b513',
    lastUpdatedAt: '2019-08-28T18:15:04.276Z',
    version: 9,
    kmlFilename: 'planned_Berlin_07_190828.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_07',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:27:04.092Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2749692 52.4106533',
              '13.2766772 52.4128613',
              '13.2948331 52.4191008',
              '13.2991568 52.4213549',
              '13.2977165 52.4222366',
              '13.293585 52.4203868',
              '13.2899902 52.4187462',
              '13.2892205 52.4185515',
              '13.2820807 52.418022',
              '13.2783179 52.4154214',
              '13.2761473 52.4125204',
              '13.2749692 52.4110819',
              '13.2749692 52.4106533',
            ],
          },
        },
      ],
    },
    id: '5d3f72b890e8b12127c0b51a',
    lastUpdatedAt: '2019-08-20T17:32:02.136Z',
    version: 7,
    kmlFilename: 'planned_Berlin_08_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_08',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:27:43.385Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4778176 52.4423224',
              '13.4758417 52.4401854',
              '13.4698014 52.4349006',
              '13.468825 52.4343642',
              '13.4676127 52.4338606',
              '13.4664003 52.433697',
              '13.4641074 52.4332898',
              '13.4621332 52.4327861',
              '13.4601179 52.4321352',
              '13.4600339 52.431739',
              '13.4597174 52.4319206',
              '13.4549907 52.4302947',
              '13.4469976 52.4272575',
              '13.4468469 52.4268575',
              '13.4480046 52.4257436',
              '13.4492665 52.4247947',
              '13.4509106 52.4241986',
              '13.4613869 52.4217232',
              '13.4618356 52.4218581',
              '13.462692 52.4231838',
              '13.4635269 52.4244767',
              '13.4681305 52.4275447',
              '13.4710093 52.4259765',
              '13.4777581 52.4178437',
              '13.4753173 52.4154128',
              '13.4757411 52.4152917',
              '13.485481 52.4238722',
              '13.4880978 52.4216939',
              '13.4895991 52.4200485',
              '13.492474 52.4177078',
              '13.4942184 52.4166626',
              '13.494988 52.4162418',
              '13.496109 52.4157408',
              '13.4963785 52.4158774',
              '13.4948283 52.4167109',
              '13.4930635 52.4178585',
              '13.4900701 52.4202452',
              '13.4892116 52.4212103',
              '13.488112 52.4223553',
              '13.4858642 52.4240629',
              '13.4953315 52.4315399',
              '13.4951897 52.4318705',
              '13.4920707 52.4332723',
              '13.4927312 52.4347805',
              '13.4824104 52.4394347',
              '13.4778176 52.4423224',
            ],
          },
        },
      ],
    },
    id: '5d3f72df90e8b12127c0b521',
    lastUpdatedAt: '2019-08-20T17:32:25.783Z',
    version: 7,
    kmlFilename: 'planned_Berlin_09_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_09',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:32:35.4Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2736545 52.5418752',
              '13.2736545 52.5417316',
              '13.2761222 52.5412683',
              '13.2796573 52.540929',
              '13.2793838 52.5396076',
              '13.2760417 52.5398654',
              '13.2760095 52.5397936',
              '13.2793623 52.5394706',
              '13.2792818 52.5382242',
              '13.2794132 52.5365455',
              '13.279829 52.5357918',
              '13.2805693 52.5353773',
              '13.2815886 52.5350959',
              '13.2830074 52.5350706',
              '13.2868671 52.5351229',
              '13.289375 52.5351636',
              '13.2897331 52.5353562',
              '13.2900482 52.5355585',
              '13.2905418 52.5362338',
              '13.2910191 52.5372699',
              '13.291223 52.5385342',
              '13.2913089 52.5398442',
              '13.2837826 52.5406484',
              '13.2758003 52.5415489',
              '13.2736545 52.5418752',
            ],
          },
        },
      ],
    },
    id: '5d3f740390e8b12127c0b528',
    lastUpdatedAt: '2019-08-20T17:33:45.906Z',
    version: 7,
    kmlFilename: 'planned_Berlin_10_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_10',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T22:39:00.405Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5075071 52.5463332',
              '13.5072951 52.5451928',
              '13.5080032 52.5433268',
              '13.5111682 52.5390071',
              '13.5057903 52.5368298',
              '13.5056483 52.5356443',
              '13.5057664 52.5346002',
              '13.5171475 52.5352071',
              '13.5181973 52.5338631',
              '13.5186909 52.5327242',
              '13.5188196 52.5313618',
              '13.5189618 52.5297431',
              '13.518833 52.52872',
              '13.5205201 52.5287217',
              '13.523319 52.5287192',
              '13.5233659 52.5288612',
              '13.5197165 52.5289487',
              '13.5192229 52.5329855',
              '13.5188904 52.5344099',
              '13.517914 52.5352983',
              '13.5175707 52.5358016',
              '13.5143091 52.5355797',
              '13.509331 52.5423271',
              '13.508537 52.5439452',
              '13.5081079 52.5462941',
              '13.5075071 52.5463332',
            ],
          },
        },
      ],
    },
    id: '5d3f758490e8b12127c0b538',
    lastUpdatedAt: '2019-08-20T17:34:41.397Z',
    version: 7,
    kmlFilename: 'planned_Berlin_12-190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_12',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:04:26.552Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.211549 52.5271722',
              '13.2146857 52.5266027',
              '13.2170031 52.5268638',
              '13.2381175 52.5264069',
              '13.2526819 52.5228918',
              '13.2612744 52.5212497',
              '13.2724411 52.5192412',
              '13.2726128 52.5196199',
              '13.2676445 52.5205057',
              '13.2626652 52.5213587',
              '13.2527383 52.5232175',
              '13.2442672 52.5290524',
              '13.2135728 52.5319444',
              '13.2127789 52.530453',
              '13.2122157 52.5295523',
              '13.2115558 52.5276791',
              '13.2109926 52.5272499',
              '13.2094905 52.5277101',
              '13.207672 52.5285488',
              '13.2074306 52.5264993',
              '13.2080743 52.5265221',
              '13.211549 52.5271722',
            ],
          },
        },
      ],
    },
    id: '5d3f7b7a90e8b12127c0b53d',
    lastUpdatedAt: '2019-08-20T17:35:28.897Z',
    version: 7,
    kmlFilename: 'planned_Berlin_13_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_13',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:07:30.402Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3629694 52.4672488',
              '13.362311 52.4598223',
              '13.3697876 52.4594668',
              '13.3704153 52.4671736',
              '13.3629694 52.4672488',
            ],
          },
        },
      ],
    },
    id: '5d3f7c3290e8b12127c0b544',
    lastUpdatedAt: '2019-08-20T17:35:48.962Z',
    version: 7,
    kmlFilename: 'planned_Berlin_14_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_14',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:12:16.904Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3295484 52.4291557',
              '13.3313938 52.42648',
              '13.3350416 52.4273926',
              '13.3419537 52.4289806',
              '13.352796 52.4330287',
              '13.358847 52.4199835',
              '13.3597268 52.420206',
              '13.3534182 52.433225',
              '13.361202 52.4343664',
              '13.3704583 52.4299772',
              '13.3734812 52.4256889',
              '13.3734023 52.4254617',
              '13.3710067 52.4229844',
              '13.3632566 52.4139111',
              '13.3636428 52.413574',
              '13.3689769 52.4201222',
              '13.3718672 52.423622',
              '13.3734895 52.4248942',
              '13.3746613 52.4264804',
              '13.3747686 52.4272328',
              '13.3784379 52.4345593',
              '13.3807446 52.4380325',
              '13.3695276 52.4364464',
              '13.3535899 52.4412961',
              '13.3543195 52.442591',
              '13.3535041 52.4425387',
              '13.3497704 52.4390463',
              '13.3436764 52.4352919',
              '13.3360521 52.4310391',
              '13.3295484 52.4291557',
            ],
          },
        },
      ],
    },
    id: '5d3f7d5090e8b12127c0b54b',
    lastUpdatedAt: '2019-08-20T17:36:11.48Z',
    version: 7,
    kmlFilename: 'planned_Berlin_15_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_15',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:13:34.312Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3665814 52.5973283',
              '13.3602351 52.5970129',
              '13.3367175 52.5959572',
              '13.3374041 52.5956183',
              '13.3390778 52.5956835',
              '13.339271 52.5952143',
              '13.3397216 52.5947189',
              '13.3458397 52.5915319',
              '13.3455366 52.5905608',
              '13.3456868 52.5896743',
              '13.3400649 52.5884229',
              '13.3386272 52.5901697',
              '13.3339468 52.5937641',
              '13.3349794 52.5873148',
              '13.3374122 52.5874566',
              '13.3396572 52.5878623',
              '13.3401293 52.5880709',
              '13.3462795 52.5895033',
              '13.3459443 52.5903652',
              '13.3460945 52.5909127',
              '13.3462662 52.591369',
              '13.3476824 52.5914211',
              '13.3489913 52.5919816',
              '13.3561581 52.5922945',
              '13.3596987 52.5926073',
              '13.3605784 52.5930114',
              '13.3611792 52.5936371',
              '13.3606964 52.5968696',
              '13.3666028 52.5971588',
              '13.3665814 52.5973283',
            ],
          },
        },
      ],
    },
    id: '5d3f7d9e90e8b12127c0b552',
    lastUpdatedAt: '2019-08-20T17:36:30.195Z',
    version: 7,
    kmlFilename: 'planned_Berlin_16_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_16',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:16:19.566Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3479406 52.5214358',
              '13.3467454 52.5202602',
              '13.3421942 52.5224567',
              '13.3405624 52.5222885',
              '13.3356045 52.5149258',
              '13.3236323 52.513453',
              '13.3242551 52.5123251',
              '13.3412716 52.5135994',
              '13.3481852 52.5213469',
              '13.3513909 52.5197696',
              '13.3538451 52.5195705',
              '13.3545961 52.5195501',
              '13.3551057 52.5195835',
              '13.355618 52.519626',
              '13.3563235 52.5194155',
              '13.3572649 52.5193943',
              '13.3604728 52.5193877',
              '13.3624148 52.5206901',
              '13.3624094 52.52101',
              '13.3603268 52.5210348',
              '13.3632024 52.522637',
              '13.3565506 52.523476',
              '13.3492281 52.5244648',
              '13.3479406 52.5214358',
            ],
          },
        },
      ],
    },
    id: '5d3f7e4390e8b12127c0b559',
    lastUpdatedAt: '2019-08-20T17:37:05.194Z',
    version: 7,
    kmlFilename: 'planned_Berlin_17_190815.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_17',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:52:20.074Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4478385 52.4775225',
              '13.4465069 52.4765376',
              '13.4427339 52.476356',
              '13.4426052 52.4761893',
              '13.4400356 52.4754346',
              '13.4407146 52.4738271',
              '13.4409559 52.4733696',
              '13.4423453 52.4738467',
              '13.443488 52.4739904',
              '13.4435483 52.4737421',
              '13.4452582 52.4738695',
              '13.4461809 52.4710266',
              '13.4417123 52.4705136',
              '13.4431822 52.469945',
              '13.4433431 52.4698273',
              '13.4485037 52.4703927',
              '13.4502525 52.4706721',
              '13.4519906 52.4709809',
              '13.4526343 52.4711214',
              '13.4538252 52.47169',
              '13.4545011 52.4720299',
              '13.4556437 52.4730951',
              '13.4539217 52.4737944',
              '13.4546191 52.4742551',
              '13.4478385 52.4775225',
            ],
          },
        },
      ],
    },
    id: '5d3f86b490e8b12127c0b562',
    lastUpdatedAt: '2019-09-03T08:08:26.374Z',
    version: 8,
    kmlFilename: 'planned_Berlin_18_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_18',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:53:55.942Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5092001 52.460725',
              '13.5064536 52.4592018',
              '13.4933485 52.4682232',
              '13.4907307 52.4666676',
              '13.5054077 52.4572413',
              '13.5151495 52.45409',
              '13.5282172 52.451867',
              '13.5335173 52.451867',
              '13.5332169 52.4532662',
              '13.5114961 52.4596594',
              '13.5092001 52.460725',
            ],
          },
        },
      ],
    },
    id: '5d3f871390e8b12127c0b569',
    lastUpdatedAt: '2019-07-29T23:54:33.941Z',
    version: 6,
    kmlFilename: 'planned_Berlin_19_190627.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_19',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-29T23:58:57.333Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3994579 52.5046445',
              '13.3993828 52.4985641',
              '13.4143388 52.4988123',
              '13.4146285 52.4999553',
              '13.4152615 52.5010721',
              '13.412472 52.5030314',
              '13.4047258 52.5052127',
              '13.3994579 52.5046445',
            ],
          },
        },
      ],
    },
    id: '5d3f884190e8b12127c0b580',
    lastUpdatedAt: '2019-08-20T17:38:42.029Z',
    version: 7,
    kmlFilename: 'planned_Berlin_23_1906130.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_23',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:00:16.492Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3641945 52.5625341',
              '13.3637895 52.5640292',
              '13.3606861 52.5702098',
              '13.3659594 52.5723682',
              '13.3657448 52.5725303',
              '13.360611 52.5704282',
              '13.351513 52.5677416',
              '13.3383326 52.5663558',
              '13.3356397 52.5655732',
              '13.3307366 52.5628014',
              '13.3310156 52.5626873',
              '13.3355968 52.5648428',
              '13.3391587 52.5507798',
              '13.3394431 52.5502595',
              '13.3641945 52.5625341',
            ],
          },
        },
      ],
    },
    id: '5d3f889090e8b12127c0b587',
    lastUpdatedAt: '2019-08-20T17:39:00.194Z',
    version: 7,
    kmlFilename: 'planned_Berlin_24_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_24',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:03:23.419Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3550573 52.4900163',
              '13.355355 52.4945629',
              '13.352544 52.4947262',
              '13.3489495 52.491865',
              '13.3408497 52.4887946',
              '13.34056 52.4894544',
              '13.3404795 52.4895948',
              '13.3399457 52.4897206',
              '13.3395434 52.489688',
              '13.3390901 52.4894381',
              '13.3390687 52.4892846',
              '13.3392564 52.4884842',
              '13.3385215 52.485822',
              '13.3438268 52.4857011',
              '13.3550573 52.4900163',
            ],
          },
        },
      ],
    },
    id: '5d3f894b90e8b12127c0b58e',
    lastUpdatedAt: '2019-08-20T17:39:36.71Z',
    version: 7,
    kmlFilename: 'planned_Berlin_25_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_25',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:04:50.645Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3532173 52.4842577',
              '13.3530811 52.4841686',
              '13.3499101 52.4813281',
              '13.350861 52.4809948',
              '13.3528351 52.4800931',
              '13.3568012 52.4830889',
              '13.3603386 52.4861891',
              '13.3601093 52.4862838',
              '13.3582827 52.4864194',
              '13.3575987 52.4854378',
              '13.3541869 52.4836345',
              '13.3532173 52.4842577',
            ],
          },
        },
      ],
    },
    id: '5d3f89a290e8b12127c0b595',
    lastUpdatedAt: '2019-08-20T17:39:58.259Z',
    version: 7,
    kmlFilename: 'planned_Berlin_26_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_26',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:05:41.574Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5351886 52.4184831',
              '13.5303552 52.4162239',
              '13.5257123 52.4150542',
              '13.5163702 52.4019137',
              '13.5219841 52.4005145',
              '13.5311129 52.4146829',
              '13.5359403 52.4178193',
              '13.5358941 52.4181821',
              '13.5351886 52.4184831',
            ],
          },
        },
      ],
    },
    id: '5d3f89d590e8b12127c0b59c',
    lastUpdatedAt: '2019-08-20T17:40:23.037Z',
    version: 7,
    kmlFilename: 'planned_Berlin_27_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_27',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:06:56.274Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3913828 52.5563529',
              '13.3765562 52.5577111',
              '13.3642395 52.5582591',
              '13.3440783 52.5485093',
              '13.33721 52.5530519',
              '13.3362014 52.5528692',
              '13.3384599 52.5502938',
              '13.3356274 52.5486498',
              '13.3398332 52.5461966',
              '13.3355416 52.5424384',
              '13.3308495 52.5405196',
              '13.3284891 52.5400889',
              '13.3302057 52.5379094',
              '13.3347548 52.5398997',
              '13.345312 52.5447543',
              '13.3497323 52.5413613',
              '13.3526934 52.5423009',
              '13.3570279 52.5437886',
              '13.359474 52.5459026',
              '13.3575459 52.5471004',
              '13.3490916 52.5475441',
              '13.3672162 52.5559679',
              '13.3910395 52.5544285',
              '13.3913828 52.5563529',
            ],
          },
        },
      ],
    },
    id: '5d3f8a2090e8b12127c0b5a3',
    lastUpdatedAt: '2019-09-16T18:05:02.193Z',
    version: 9,
    kmlFilename: 'planned_Berlin_28_190916.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_28',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:12:03.603Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4737275 52.5382222',
              '13.4734717 52.5418499',
              '13.4725813 52.5455154',
              '13.4671875 52.5445416',
              '13.4699315 52.5402748',
              '13.4703097 52.5400105',
              '13.470417 52.5396043',
              '13.471852 52.5376091',
              '13.4737275 52.5382222',
            ],
          },
        },
      ],
    },
    id: '5d3f8b5390e8b12127c0b5b5',
    lastUpdatedAt: '2019-08-20T17:41:04.245Z',
    version: 7,
    kmlFilename: 'planned_Berlin_31_190630.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_31',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-07-30T00:23:41.11Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '10.0129983 53.5464551',
              '10.0152352 53.545636',
              '10.0161257 53.5465667',
              '10.0156054 53.5471404',
              '10.0130358 53.5464806',
              '10.0129983 53.5464551',
            ],
          },
        },
      ],
    },
    id: '5d3f8e0d90e8b12127c0b5e9',
    lastUpdatedAt: '2019-07-30T00:23:55.28Z',
    version: 6,
    kmlFilename: 'planned_Hamburg_99_190626.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Hamburg_99',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:33:29.613Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3128855 52.4790902',
              '13.3118555 52.4797567',
              '13.3109972 52.4795084',
              '13.3120272 52.4786982',
              '13.3137653 52.4759536',
              '13.3137438 52.4719541',
              '13.3188507 52.4713528',
              '13.320503 52.4725423',
              '13.3207176 52.477339',
              '13.3128855 52.4790902',
            ],
          },
        },
      ],
    },
    id: '5da72a394dcf2f28a748ff61',
    lastUpdatedAt: '2019-10-16T14:49:38.02Z',
    version: 3,
    kmlFilename: 'Berlin_34.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_34',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:33:52.223Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.286473 52.4993901',
              '13.2967513 52.4918261',
              '13.3071797 52.4979401',
              '13.3032315 52.5042362',
              '13.286473 52.4993901',
            ],
          },
        },
      ],
    },
    id: '5da72a504dcf2f28a748ff63',
    lastUpdatedAt: '2019-10-16T14:49:59.923Z',
    version: 3,
    kmlFilename: 'Berlin_35.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_35',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:34:18.465Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.2861297 52.5187319',
              '13.2880395 52.512673',
              '13.2977383 52.5131039',
              '13.2964294 52.5198025',
              '13.2858937 52.5191105',
              '13.2861297 52.5187319',
            ],
          },
        },
      ],
    },
    id: '5da72a6a4dcf2f28a748ff65',
    lastUpdatedAt: '2019-10-16T14:50:14.649Z',
    version: 3,
    kmlFilename: 'Berlin_36.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_36',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:34:42.275Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.37137 52.4852892',
              '13.365555 52.4757231',
              '13.3664562 52.4743246',
              '13.3711339 52.4742331',
              '13.3719279 52.4729261',
              '13.373151 52.4720895',
              '13.373902 52.4725209',
              '13.3744813 52.4723902',
              '13.3797599 52.4723117',
              '13.3839871 52.4713837',
              '13.3853604 52.4713706',
              '13.385532 52.4852892',
              '13.37137 52.4852892',
            ],
          },
        },
      ],
    },
    id: '5da72a824dcf2f28a748ff67',
    lastUpdatedAt: '2019-10-16T14:50:49.168Z',
    version: 3,
    kmlFilename: 'Berlin_37.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_37',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:35:07.429Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4450955 52.4939268',
              '13.439538 52.4894586',
              '13.4404178 52.4872765',
              '13.4412546 52.486584',
              '13.4449024 52.4847808',
              '13.4526916 52.4902817',
              '13.4495373 52.4924113',
              '13.4450955 52.4939268',
            ],
          },
        },
      ],
    },
    id: '5da72a9b4dcf2f28a748ff6b',
    lastUpdatedAt: '2019-10-16T14:51:05.811Z',
    version: 3,
    kmlFilename: 'Berlin_38.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_38',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:45:55.508Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4550267 52.4973161',
              '13.4531599 52.494834',
              '13.4568292 52.4936452',
              '13.4579664 52.4968197',
              '13.4550267 52.4973161',
            ],
          },
        },
      ],
    },
    id: '5da72d234dcf2f28a748ff79',
    lastUpdatedAt: '2019-10-16T14:51:22.661Z',
    version: 3,
    kmlFilename: 'Berlin_39.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_39',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:46:12.978Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5283498 52.4904166',
              '13.5280923 52.4908608',
              '13.5263757 52.4894367',
              '13.52217 52.4891362',
              '13.5160116 52.4859872',
              '13.5352162 52.4772839',
              '13.536611 52.4778589',
              '13.5283498 52.4904166',
            ],
          },
        },
      ],
    },
    id: '5da72d344dcf2f28a748ff7c',
    lastUpdatedAt: '2019-10-16T14:51:43.918Z',
    version: 4,
    kmlFilename: 'Berlin_40.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_40',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:46:58.677Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5006895 52.5262862',
              '13.4998393 52.5262837',
              '13.4997937 52.525307',
              '13.5006815 52.5252923',
              '13.5006895 52.5262862',
            ],
          },
        },
      ],
    },
    id: '5da72d624dcf2f28a748ff80',
    lastUpdatedAt: '2019-10-16T14:52:01.796Z',
    version: 3,
    kmlFilename: 'Berlin_41_ROB_Lichtenberg.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_41_ROB_Lichtenberg',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:47:35.077Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4477543 52.5268681',
              '13.4481835 52.5261632',
              '13.4761212 52.5326509',
              '13.4996388 52.533917',
              '13.4994243 52.5347654',
              '13.4760354 52.5333819',
              '13.4477543 52.5268681',
            ],
          },
        },
      ],
    },
    id: '5da72d874dcf2f28a748ff82',
    lastUpdatedAt: '2019-10-16T14:52:19.477Z',
    version: 3,
    kmlFilename: 'Berlin_42.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_42',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4289935 52.5475937',
              '13.4289238 52.5475953',
              '13.4285912 52.5468075',
              '13.4293663 52.5466591',
              '13.429538 52.5470701',
              '13.4303829 52.5468629',
              '13.4304928 52.5470717',
              '13.4304312 52.5470897',
              '13.4289935 52.5475937',
            ],
          },
        },
      ],
    },
    id: '5da72da74dcf2f28a748ff85',
    lastUpdatedAt: '2020-02-10T13:58:21.402Z',
    createdAt: '2019-10-16T14:48:07.07Z',
    version: 4,
    kmlFilename: 'Berlin_43_ROB_PBERG (1).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_43_ROB_PBERG',
    fileLastUpdatedAt: '2020-02-10T13:58:21.402Z',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-16T14:48:30.982Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.412651 52.5663964',
              '13.4127583 52.5660051',
              '13.427328 52.5677007',
              '13.4285726 52.5723175',
              '13.4230365 52.5713002',
              '13.415419 52.5680658',
              '13.412651 52.5663964',
            ],
          },
        },
      ],
    },
    id: '5da72dbe4dcf2f28a748ff87',
    lastUpdatedAt: '2019-10-16T14:54:20.71Z',
    version: 3,
    kmlFilename: 'Berlin_44.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_44',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4198842 52.4724232',
              '13.4281346 52.4733512',
              '13.4260908 52.4776348',
              '13.4238377 52.4773734',
              '13.4242883 52.4759097',
              '13.4208444 52.4755306',
              '13.4203723 52.4770206',
              '13.4184733 52.4767592',
              '13.4196642 52.4723741',
              '13.4198842 52.4724232',
            ],
          },
        },
      ],
    },
    id: '5da72dd24dcf2f28a748ff8a',
    lastUpdatedAt: '2020-01-30T11:37:18.214Z',
    createdAt: '2019-10-16T14:48:50.855Z',
    version: 4,
    kmlFilename: 'Berlin_45 (1).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_45',
    fileLastUpdatedAt: '2020-01-30T11:37:18.214Z',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-25T15:21:18.469Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4504865 52.4696628',
              '13.4497301 52.4695272',
              '13.4502048 52.4686808',
              '13.4511909 52.4688369',
              '13.4510501 52.4690547',
              '13.4507805 52.4690093',
              '13.4504865 52.4696628',
            ],
          },
        },
      ],
    },
    id: '5db312ee13467868ce6135af',
    lastUpdatedAt: '2019-10-25T15:23:24.156Z',
    version: 3,
    kmlFilename: 'Berlin_46_ROB_NK.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Berlin_46_ROB_NK',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    createdAt: '2019-10-25T15:22:18.223Z',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.3260036 52.5657252',
              '13.3256496 52.564716',
              '13.3260466 52.5646133',
              '13.3264838 52.5656257',
              '13.3260036 52.5657252',
            ],
          },
        },
      ],
    },
    id: '5db3132a13467868ce6135b1',
    lastUpdatedAt: '2019-10-25T15:23:36.397Z',
    version: 3,
    kmlFilename: 'Berlin_47_ROB_REI.kml',
    lastUpdatedBy: 'Kajetan Sokołowski',
    name: 'Berlin_47_ROB_REI',
    fileLastUpdatedBy: 'GETAWAY Admin',
  },
  {
    fleetId: '000000000000000000000007',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4762319 52.5677168',
              '13.474687 52.5601515',
              '13.4862741 52.560308',
              '13.4972604 52.5634908',
              '13.5032686 52.5522196',
              '13.5091051 52.5514889',
              '13.5161432 52.5577512',
              '13.5176881 52.5600993',
              '13.5153707 52.5636473',
              '13.5003503 52.5752286',
              '13.488849 52.571525',
              '13.4919389 52.5679255',
              '13.4906515 52.5657343',
              '13.4762319 52.5677168',
            ],
          },
        },
      ],
    },
    id: '5e5825fecffebe0c47a6f27f',
    lastUpdatedAt: '2020-02-28T08:47:15.879Z',
    createdAt: '2020-02-27T20:26:38.914Z',
    version: 7,
    kmlFilename: 'Berlin_48 (2).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_48',
    fileLastUpdatedAt: '2020-02-28T08:47:15.879Z',
  },
  {
    fleetId: '000000000000000000000007',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.5422814 52.5451738',
              '13.5452855 52.5441821',
              '13.5494054 52.5496103',
              '13.56331 52.5459568',
              '13.5736096 52.5477314',
              '13.5771287 52.5601517',
              '13.5717214 52.5611431',
              '13.5621083 52.5620823',
              '13.5644258 52.5644824',
              '13.5735238 52.561978',
              '13.5789312 52.5621867',
              '13.5824502 52.565578',
              '13.5779012 52.5737162',
              '13.5741246 52.5740813',
              '13.566314 52.5726207',
              '13.5422814 52.5451738',
            ],
          },
        },
      ],
    },
    id: '5e70883acd9b7d0bc2ea73c3',
    lastUpdatedAt: '2020-03-17T08:20:39.188Z',
    createdAt: '2020-03-17T08:20:10.325Z',
    version: 3,
    kmlFilename: 'Berlin_49 (2).kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_49',
    fileLastUpdatedAt: '2020-03-17T08:20:39.188Z',
  },
  {
    fleetId: '000000000000000000000007',
    fileLastUpdatedBy: 'GETAWAY Admin',
    geoJSONData: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              '13.4928333 52.4388121',
              '13.4925329 52.4383674',
              '13.4979403 52.4366407',
              '13.5091412 52.429393',
              '13.514806 52.4288173',
              '13.5069525 52.4376087',
              '13.5078966 52.4385505',
              '13.5066521 52.4385243',
              '13.5063946 52.4378703',
              '13.5028326 52.43685',
              '13.5006869 52.4356726',
              '13.499614 52.4365883',
              '13.4928333 52.4388121',
            ],
          },
        },
      ],
    },
    id: '5e7088c7cd9b7d0bc2ea73c9',
    lastUpdatedAt: '2020-03-17T08:22:48.61Z',
    createdAt: '2020-03-17T08:22:31.191Z',
    version: 3,
    kmlFilename: 'Berlin_50.kml',
    lastUpdatedBy: 'GETAWAY Admin',
    name: 'Berlin_50',
    fileLastUpdatedAt: '2020-03-17T08:22:48.61Z',
  },
];
