import { APIResponse, BrandData } from '../../@types';

export const brandClient = {
  getSettings: async (): Promise<APIResponse<BrandData>> => {
    const { makeAPICall } = await import('./clientHelpers/makeAPICall');
    const { data, error } = await makeAPICall({
      method: 'GET',
      url: '/brand',
      checkModified: false,
    });
    return {
      data,
      error,
    };
  },
};
