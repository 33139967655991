import initialState from './InitialState';
import TYPES from '../../../@types/redux/store/ConfigStoreTypes';
import { ConfigAction, ConfigState } from '../../../@types';

export default (
  state: ConfigState = initialState,
  action: ConfigAction
): ConfigState => {
  switch (action.type) {
    case TYPES.SET_GOOGLE_API_ACCESS_TOKEN: {
      return {
        ...state,
        googleApiAccessToken: action.payload.googleApiAccessToken,
      };
    }

    case TYPES.SET_API_BASE_URL: {
      return { ...state, apiBaseURL: action.payload.apiBaseURL };
    }

    case TYPES.SET_API_VERSION: {
      return { ...state, apiVersion: action.payload.apiVersion };
    }

    case TYPES.SET_BUNDLE_ID: {
      return { ...state, bundleId: action.payload.bundleId };
    }

    case TYPES.SET_APPLE_APP_ID: {
      return { ...state, appleAppId: action.payload.appleAppId };
    }

    case TYPES.SET_X_BRAND_ID: {
      return { ...state, xBrandId: action.payload.xBrandId };
    }

    default: {
      return state;
    }
  }
};
